import * as imageConversion from 'image-conversion';
import { useState, useEffect } from 'react';
import { useGuestAuth } from '../hooks/firebase/useGuestAuth';
import { useChatContext } from '../hooks/useChatContext';

import './Forms.css';
import { langSrc } from '../tools/languageSource';
import { updateUILang } from "../tools/tools";

import LoadingIcon from './LoadingIcon';
import AvatarIcon from '../assets/avatar_icon.svg';
import GoogleTrans from '../assets/google_translate.png';

export default function LoginForm() {
    const { error, loginAsGuest } = useGuestAuth();
    const { session } = useChatContext();
    const [urlParams, setURLParams] = useState(false);
    const [name, setName] = useState('');
    const [lang, setLang] = useState('en');
    const [role, setRole] = useState('guest');
    const [photo, setPhoto] = useState(null);
    const [photoChange, setPhotoChange] = useState(false);
    const [photoError, setPhotoError] = useState(null);
    const [pending, setPending] = useState(false);
    let imgSrc = photo && photoChange ? URL.createObjectURL(photo) : photo;
    if (!imgSrc) imgSrc = AvatarIcon;
    let imgClass = photo ? 'profile-pic has-file mx-auto object-cover' : 'profile-pic mx-auto object-cover';
    let imgHolderClass = photo ? 'profile-pic-holder mx-auto mb-4' : 'profile-pic-holder mx-auto';

    const langOptions = langSrc.map(item => (
        <option key={item.code} value={item.code}>{item.name}</option>
    ));

    // look for url params
    useEffect(() => {
        const urlQuery = window.location.search;
        const params = new URLSearchParams(urlQuery);
        const paramName = params.get('name');
        if (!paramName) return;
        setURLParams(true);
        const paramLang = params.get('lang');
        const paramRole = params.get('role');
        setName(paramName);
        setLang(paramLang);
        setRole(paramRole);

        if (paramLang !== 'en') updateUILang(paramLang);
    }, []);

    const handleFileChange = (e) => {
        setPhoto(null);
        let selected = e.target.files[0];

        if (!selected) {
            setPhotoError('Please select a file');
            return;
        }
        if (!selected.type.includes('image')) {
            setPhotoError('Selected file must be an image');
            return;
        }
        if (selected.size > 10000000) {
            setPhotoError('Image file size must be less than 10mb');
            return;
        }

        // image compression
        imageConversion.compressAccurately(selected, 200)
            .then(res => {
                setPhoto(res);
            });
        
        setPhotoError(null);
        setPhotoChange(true);
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (pending) return;
        setPending(true);
        
        try {
            await loginAsGuest(photo, name, lang, role, session);
        } catch(err) {
            console.log(err.message);
            setPending(false);
        }
    }

    return (
        <>
            {!urlParams && <form onSubmit={handleSubmit} className="guest-form mt-8 flex flex-col gap-2 p-2">
                <div className={imgHolderClass}>
                    <img className={imgClass} src={imgSrc} alt="user profile avatar" />
                </div>
                <label className="flex mx-auto mt-2">
                    <span className="btn btn-border self-center mr-2 change-photo-btn">change photo</span>
                    <input 
                        hidden
                        className="flex-auto ml-2"
                        type="file"
                        onChange={handleFileChange}
                    />
                </label>
                <label className="flex mt-2">
                    <span className="self-center">name:</span>
                    <input 
                        className="flex-auto grow ml-2"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
                <label className="flex">
                    <span className="self-center">language:</span>
                    <select
                        className="flex-auto grow ml-2"
                        onChange={(e) => {
                            setLang(e.target.value);
                        }}
                        value={lang}
                    >
                        {langOptions}
                    </select> 
                </label>
                <label className="flex">
                    <span className="self-center">role:</span>
                    <select
                        className="flex-auto grow ml-2"
                        onChange={(e) => {
                            setRole(e.target.value);
                        }}
                        value={role}
                    >
                        {/* <option value="provider">Provider</option> */}
                        {/* <option value="patient">Patient</option> */}
                        <option value="guest">Guest</option>
                        <option value="interpreter">Guest Interpreter</option>
                    </select> 
                </label>
                <div>
                    <label className="flex justify-start gap-2">
                        <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                        <p className="self-center">I agree to the 
                        <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/terms-and-conditions" target="blank"> Terms & Conditions</a></p>
                    </label>
                    <label className="flex justify-start gap-2">
                        <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                        <p className="self-center">I agree to the 
                        <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/privacy-policy" target="blank"> Privacy Policy</a></p>
                    </label>
                </div>
                {!pending && <button className="btn mx-auto mt-2">join chat</button>}
                {pending && <button className="btn mx-auto mt-2"><LoadingIcon size={'small'} addClass={'mx-auto'} /></button>}
                {photoError && <p className="error">{photoError}</p>}
                {error && <p className="error">{photoError}</p>}
            </form>}
            {urlParams && <form onSubmit={handleSubmit} className="guest-form flex flex-col gap-2 p-2">
                <div className="flex justify-between">
                    <h5><span className="notranslate">👋</span> Welcome <span className="notranslate"><h5 className="inline">{name}</h5></span></h5>
                    <img src={GoogleTrans} className="h-4 w-4 inline" />
                </div>
                <p>Please agree to the following before proceeding to chat!</p>
                <div>
                    <label className="flex justify-start gap-2">
                        <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                        <p className="self-center">I agree to the 
                        <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/terms-and-conditions" target="blank"> Terms & Conditions</a></p>
                    </label>
                    <label className="flex justify-start gap-2">
                        <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                        <p className="self-center">I agree to the 
                        <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/privacy-policy" target="blank"> Privacy Policy</a></p>
                    </label>
                </div>
                {!pending && <button className="btn mx-auto mt-2">join chat</button>}
                {pending && <button className="btn mx-auto mt-2"><LoadingIcon size={'small'} addClass={'mx-auto'} /></button>}
                {photoError && <p className="error">{photoError}</p>}
                {error && <p className="error">{photoError}</p>}
            </form>}
        </>
    )
}