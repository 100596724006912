
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from '../../hooks/useAppContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useChatContext } from '../../hooks/useChatContext';
import { useEndChat } from '../../hooks/useEndChat';
import { useExitChat } from '../../hooks/useExitChat';
import { useLogout } from '../../hooks/firebase/useLogout';
import { useDocListener } from '../../hooks/firebase/useDocListener';
import { useFirestore } from '../../hooks/firebase/useFirestore';

import './Chatroom.css';

import ClickableIcon from '../../components/ClickableIcon';
import ChatPanel from './components/ChatPanel';
import UserList from '../../components/UserList';
import PenguinImg from '../../assets/penguin.png';
import LoadingIcon from '../../components/LoadingIcon';
import RequestModal from '../../components/RequestModal';

export default function Chatroom() {
    const { id } = useParams();
    const { user } = useAuthContext();
    const { output, dispatch, twoWay, } = useAppContext();
    const { session, self, owner, users, dispatch: chatDispatch } = useChatContext();
    const { logout } = useLogout();
    const { endChat, pending: endChatPending } = useEndChat();
    const { exitChat, pending: exitChatPending } = useExitChat();
    const { document: doc, error } = useDocListener('sessions', id, true, 'chatroom');
    const { updateDocument } = useFirestore('users');
    const memoDispatch = useRef(dispatch);
    const memoChatDispatch = useRef(chatDispatch);
    const navigate = useNavigate();
    const [closeAlert, setCloseAlert] = useState(false);
    const [showUserList, setShowUserList] = useState(true);
    const [penguin, setPenguin] = useState('just-showing');
    const memoUsers = useRef(users);
    const memoUser = useRef(user);
    const memoLogout = useRef(logout);
    const memoNavigate = useRef(navigate);

    // on load, set height of output panel
    const setHeightUnit = () => {
        let ch = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--ch', `${ch}px`);
        window.addEventListener('resize', () => {
            document.documentElement.style.setProperty('--ch', `${ch}px`);
        });
    }
    
    // set animation 
    if (doc && !doc.status.active) {
        setTimeout(() => setPenguin('animate-hop just-showing'), 5000);
    }

    // handle formatting
    useEffect(() => {
        output ? setShowUserList(true) : setShowUserList(false);
    }, [output]);

    // set up
    useEffect(() => {
        if (!user || !session || !self) memoNavigate.current(`/chatlobby/${id}`);
        if (user && session && self) memoDispatch.current({ type: 'CHATTING' });
        // if (user && user.isAnonymous) {
        //     window.addEventListener('beforeunload', async () => { 
        //         await exitChat(user.isAnonymous);
        //         memoLogout.current();
        //     });
        // }
    }, [memoDispatch, user, session, self, memoLogout, id, memoNavigate]);

    // update languages if document changes
    useEffect(() => {
        if (!doc) return;
        let result = [];
        Object.values(doc.info).forEach(user => {
            if (result.indexOf(user.lang) === -1) result.push(user.lang);
        });
        result = result.sort();
        
        dispatch({ type: 'SET_LANGUAGES', payload: result });
    }, [doc]);

    // set up self audio language
    useEffect(() => {
        if (!memoUsers.current || !self) return;
        if (!memoUsers.current[self]) return;
        
        dispatch({ type: 'SET_SELFAUDIOLANG', payload: memoUsers.current[self].lang, source: 'chatroom-init' });
    }, [memoUsers, self]);

    // move users to chat when they get banned
    const [banNotice, setBanNotice] = useState(false);
    useEffect(() => {
        if (!self || !users) return;
        if (!users[self]) return;
        if (users[self].ban) {
            setBanNotice(true);
            setTimeout(() => {
                memoDispatch.current({ type: 'END_CHAT', source: 'chatroom_timer' });
                memoChatDispatch.current({ type: 'EXIT', source: 'chatroom_timer' });
                navigate(`/chat`);
            }, 5000);
        }
    }, [users, self]);

    // detect requests
    const [requests, setRequests] = useState(false);
    const [requestData, setRequestData] = useState({});
    useEffect(() => {
        if (!doc || self !== owner) return;
        if (!doc.status.publicChat) {
            if (!doc.requests) return;
        
            let request = false;
            let requestInfo = false;
            Object.values(doc.requests).forEach(entry => {
                if (entry.status === 'pending') {
                    request = true;
                    requestInfo = entry;
                }
            });
            if (request) {
                setRequests(true);
                setRequestData(requestInfo);
            } else {
                setRequests(false);
                setRequestData({});
            }
        }
    }, [doc, self, owner]);

    // logout Guest user when chat ends or move users back to chat page when chat ends
    useEffect(() => {
        if (!doc) return;
        if (!memoUser.current) return;
        if (doc.status.active) return;
        if (memoUser.current.isAnonymous) {
            setTimeout(() => {
                memoLogout.current(true);
            }, 20000);
        } else {
            let activeChats = users.activeChats;
            if (activeChats) {
                activeChats.splice(users.activeChats.indexOf(id), 1);
                updateDocument(user.uid, { activeChats });
            }
            setTimeout(() => {
                memoDispatch.current({ type: 'END_CHAT', source: 'chatroom_timer' });
                memoChatDispatch.current({ type: 'EXIT', source: 'chatroom_timer' });
                navigate(`/chat`);
            }, 20000);
        }
    }, [doc, memoUser, memoLogout, memoChatDispatch, memoDispatch]);

    useEffect(() => {
        if (error) {
            navigate(`/chatdoor/${session}`, { state: { source : 'chatroom', event: 'error' }});
        }
    }, [error]);

    const loadChatLobby = () => {
        dispatch({ type: 'RESET_CHAT' });
        navigate(`/chatlobby/${id}`, { state: { source : 'chatroom' }});
    }

    const handleEndChat = async () => {
        await endChat(doc);
        setCloseAlert(false);
        // AppContext and ChatContext will be reset in lobby
        navigate(`/chatlobby/${id}`, { state: { source : 'chatroom' }});
    }
    const handleExitChat = async () => {
        if (session) await exitChat(user.isAnonymous);
        
        if (!user.isAnonymous) navigate('/');
        if (user.isAnonymous) logout();
    }

    return (
        <>
            {user && (
                <>
                    <div className="pt-4 flex flex-col w-full" onLoad={setHeightUnit}>
                        <div className="flex chatroom-header relative px-4">
                            <h5 className="mt-1 ml-2 self-center">Chat: {session ? session : 'Ended'}</h5>
                            {session && <div className="info-wrapper self-center ml-auto" onClick={loadChatLobby}>
                                <ClickableIcon type="info" addClass="no-background no-hover small self-center" />
                            </div>}
                            {/* {session && <div className="close-holder" onClick={() => {
                                if (!doc.status.active) {
                                    handleExitChat();
                                } else {
                                    setCloseAlert(true);
                                } 
                            }}>
                                <ClickableIcon type="close" addClass="large no-background" />
                            </div>} */}
                        </div>
                        <div className="chatroom-container mt-4 grow bg-neutral-200 flex flex-col">
                            {doc && doc.status.active && (
                                <>
                                    {session && showUserList && <UserList type="header" />}
                                    <div className={`grow flex flex-col panel-wrapper ${twoWay ? '' : 'mt-2'}`}>
                                        {doc.status.chatOpen && (
                                            <ChatPanel />
                                        )}
                                    </div>
                                </>
                            )}
                            {doc && !doc.status.active && (
                                <>
                                    <div className="flex flex-col items-center my-auto" onClick={handleExitChat}>
                                        <img className={`${penguin} h-20 w-20 mx-auto`} src={PenguinImg} alt="Penguin" />
                                        <i className="p-emphasize mt-2">Chat has Ended</i>
                                    </div>
                                    {user.isAnonymous && <p className="absolute bottom-2 p-emphasize">You will be logged out shortly.</p>}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
            {closeAlert && <div className="screen">
                <div className="center-modal relative">
                    {self === owner && (
                        <>
                            <h4>End Chat?</h4>
                            <p className="mt-2">Exit and end chat. This will end the session for everyone because you are the owner.</p>
                            {/* <p className="mt-2 p-small">All messages and identifying user information will be deleted. Only a summary of this session will be saved in the database.</p> */}
                            {!endChatPending && <div className="text-center">
                                <button className="mx-auto mt-8" onClick={handleEndChat}>end</button>
                            </div>}
                            {endChatPending && <div className="text-center">
                                <button className="mx-auto mt-8 flex justify-center">
                                    <LoadingIcon size="small" addClass="self-center" />
                                </button>
                            </div>}
                            <div className="absolute top-0 right-0" onClick={() => setCloseAlert(false)}>
                                <ClickableIcon
                                    type="close"
                                    addClass="no-background"
                                />
                            </div>
                        </>
                    )}
                    {self !== owner && (
                        <>
                            <h4>Exit Chat?</h4>
                            <p className="mt-2">Keep the session ID if you want to return later!</p>
                            <div className="mt-4 flex justify-center">
                                {!exitChatPending && <button onClick={handleExitChat}>exit</button>}
                                {exitChatPending && <button className="flex justify-center">
                                    <LoadingIcon size="small" addClass="self-center" />
                                </button>}
                            </div>
                            <div className="absolute top-0 right-0" onClick={() => setCloseAlert(false)}>
                                <ClickableIcon
                                    type="close"
                                    addClass="no-background"
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>}
            {banNotice && <div className="screen">
                <div className="center-modal">
                    <h4>Sorry :(</h4>
                    <p className="mt-4">You have been removed from this chat. You will be redirected shortly.</p>
                </div>
            </div>}
            {requests && <RequestModal user={requestData} doc={doc}/>}
        </>
    )
}