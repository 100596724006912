
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';

import './TwoWayTool.css';

import { langSrc } from '../../../../tools/languageSource';

import ClickableIcon from '../../../../components/ClickableIcon';
import SpeechRec from '../SpeechRec';

export default function TwoWayTool({ returnFxn }) {
    const { otherSpeechMic, selfAudio, selfAudioLang, speechStatus, selfSpeechAuto, dispatch } = useAppContext();
    const [status, setStatus] = useState('Tap to toggle speech recognition.');

    const handleAudioClick = () => {
        if (selfAudio) {
            dispatch({ type: 'SELFAUDIO_OFF'});
        } else {
            dispatch({ type: 'SELFAUDIO_ON'});
        }
    }

    const handleOtherMicClick = () => {
        if (otherSpeechMic) {
            dispatch({ type: 'OTHERSPEECHMIC_OFF'});
        } else {
            dispatch({ type: 'OTHERSPEECHMIC_ON'});
        } 
    }

    useEffect(() => {
        switch (speechStatus.status) {
            case 'start' :
                setStatus('Mic is active and listening...');
                return;
            case 'stop' :
                setStatus('Mic stopped.');
                setTimeout(() => setStatus('Tap to toggle speech recognition.'), 3000);
                return;
            case 'processing':
                setStatus('Processing received audio...');
                return;
            case 'interim':
                setStatus(`"${speechStatus.text}"`);
                return;
            case 'success':
                setStatus(`"${speechStatus.text}"`);
                setTimeout(() => setStatus('Tap to toggle speech recognition.'), 5000);
                return;
            case 'failed':
                setStatus('Audio failed to process :(');
                setTimeout(() => setStatus('Tap to toggle speech recognition.'), 3000);
                return;
            default:
                setStatus('Tap to toggle speech recognition.');
                return;
        }
    }, [speechStatus]);

    const handleAutoClick = () => {
        (selfSpeechAuto) ? dispatch({ type: 'SELFSPEECHAUTO_OFF' }) : dispatch({ type: 'SELFSPEECHAUTO_ON' });
    }

    return (
        <>
            <hr className="mt-2 opacity-30"></hr>
            <p className="p-small mt-2">Select other person's language and toggle speech.</p>
            <div className="flex justify-around mt-2">
                <select 
                    className="twoWay-select self-center"
                    onChange={e => {
                        dispatch({ type: 'SET_SELFAUDIOLANG', payload: e.target.value });
                        dispatch({ type: 'SET_OTHERSPEECHLANG', payload: e.target.value });
                    }}
                    value={selfAudioLang}
                >
                    {langSrc && langSrc.map(item => (
                        <option key={item.code} value={item.code}>{item.name}</option>
                    ))}
                </select>
                <div className={`twoWay-audio self-center ${selfAudio ? 'active' : ''}`} onClick={handleAudioClick}>
                    <ClickableIcon type="speaker" addClass="no-background no-hover invert self-center" />
                </div>
            </div>
            <hr className="mt-2 opacity-30"></hr>
            <p className="speech-tool-status p-small mt-2">{status}</p>
            <div className="grow flex justify-between relative">
                <div className="twoWay-mic-holder left self-center" onClick={handleOtherMicClick}>
                    <p className="p-emphasize title self-center">Other</p>
                    <div className="relative">
                        <ClickableIcon type="mic" addClass={`self-center no-hover large ${otherSpeechMic ? 'animate-ping' : ''}`} / >
                        <ClickableIcon type="mic" addClass={`self-center no-hover large ${otherSpeechMic ? 'mic-active' : ''}`} / >
                    </div>
                </div>
                <div className="relative self-center flex">
                    <div className={`auto-send twoWay ${selfSpeechAuto ? 'active' : ''}`} onClick={handleAutoClick}>auto</div>
                    <div className="twoWay-mic-holder right self-center">
                        <div className="relative">
                            <SpeechRec messageFxn={(text) => returnFxn('text', { text, direct: selfSpeechAuto })} method="circle" watchStatus="self" />
                        </div>
                        <p className="p-emphasize title self-center">Self</p>
                    </div>
                </div>
            </div>
        </>
    )
}