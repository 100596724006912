import { useEffect, useState } from 'react';
import { useChatContext } from '../../../hooks/useChatContext';
import { useAppContext } from '../../../hooks/useAppContext';
import { langSrc } from '../../../tools/languageSource';

import './ChatInterpreterTools.css';

import ClickableIcon from '../../../components/ClickableIcon';

export default function ChatInterpreterTools() {
    const { languages, interpretLangOne, interpretLangTwo, dispatch } = useAppContext();
    const { users, self } = useChatContext();
    const [langOneName, setLangOneName] = useState('');
    const [langTwoName, setLangTwoName] = useState('');

    useEffect(() => {
        if (!languages) return;
        if (!langSrc) return;        
        if (typeof(interpretLangOne) !== 'number' || typeof(interpretLangTwo) !== 'number') {
            langSrc.forEach(lang => {
                if (lang.code === languages[0]) setLangOneName(lang.name);
                if (lang.code === languages[0]) setLangTwoName(lang.name);
            });
        } else {
            langSrc.forEach(lang => {
                if (lang.code === languages[interpretLangOne]) setLangOneName(lang.name);
                if (lang.code === languages[interpretLangTwo]) setLangTwoName(lang.name);
            });
        }
    }, [languages, interpretLangOne, interpretLangTwo]);

    useEffect(() => {
        if (!users || !self || !users[self]) return;
        if (languages) {
            const index = languages.indexOf(users[self].lang);
            let indexTwo = index + 1;
            if (indexTwo === languages.length) indexTwo = 0;
            if (index > -1) dispatch({ type: 'SET_INTERPRETLANGONE', payload: index });
            if (indexTwo > -1) dispatch({ type: 'SET_INTERPRETLANGTWO', payload: indexTwo });
            
        }
    }, [users, self, languages]);

    const handleClick = (e, category) => {
        if (category === 'langOne') {
            let index = interpretLangOne;
            index++;
            if (index === languages.length) index = 0;
            dispatch({ type: 'SET_INTERPRETLANGONE', payload: index });
        }
        if (category === 'langTwo') {
            let index = interpretLangTwo;
            index++;
            if (index === languages.length) index = 0;
            dispatch({ type: 'SET_INTERPRETLANGTWO', payload: index });
        }
    }

    return (
        <div className="interpret-tools-wrapper grid grid-cols-7">
            <div className={`interpret-tools-btn col-span-3 mx-auto`} onClick={e => handleClick(e, 'langOne')}>
                {langOneName}
            </div>
            <ClickableIcon type="swap" addClass="no-background no-hover invert self-center mx-auto" />
            <div className={`interpret-tools-btn col-span-3 mx-auto`} onClick={e => handleClick(e, 'langTwo')}>
                {langTwoName}
            </div>
        </div>
    )
}