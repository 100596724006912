import { useState, useEffect } from "react"
import { useAppContext } from "../../../hooks/useAppContext";

import ClickableIcon from "../../../components/ClickableIcon";

import { langSrc } from "../../../tools/languageSource";

export default function SpeakerInstructTools({ returnFxn, langStatus, speakerStatus }) {
    const [status, setStatus] = useState('Toggle audio and speech recognition.');
    const [langOptions, setLangOptions] = useState([]);
    const [lang, setLang] = useState(langStatus);
    const [watch, setWatch] = useState(false);
    const [speaker, setSpeaker] = useState(speakerStatus);
    const [selfAutoAudio, setSelfAutoAudio] = useState(false);
    const { chromeIOS } = useAppContext();
    
    useEffect(() => {
        const options = langSrc.map(item => (
            <option key={item.code} value={item.code}>{item.name}</option>
        ));
        setLangOptions(options);
    }, [langSrc]);

    useEffect(() => {
        returnFxn('status', { lang, speaker, autoAudio: selfAutoAudio });
    }, [lang, speaker, selfAutoAudio]);

    const handleAudioClick = () => {
        speaker ? setSpeaker(false) : setSpeaker(true);
    }

    const handleAutoClick = () => {
        selfAutoAudio ? setSelfAutoAudio(false) : setSelfAutoAudio(true);
    }

    const off = () => {
        recognition.stop();
        setWatch(false);
    }
    const on = () => {
        recognition.start();
        setWatch(true);
    }

    const updateStatus = (recognStatus, text) => {
        if (recognStatus === 'start') setStatus('Listening...');
        if (recognStatus === 'interim') setStatus('"' + text + '..."');
        if (recognStatus === 'success') {
            setStatus('"' + text + '"');
            setTimeout(() => setStatus('Toggle audio and speech recognition.'), 1000);
        }
    }

    var SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.onstart = function() {
        console.log('Listening...Langauge:', lang);
        updateStatus('start');
    }

    recognition.onspeechend = function() {
        console.log('speech end');
        off();
        updateStatus('processing');
    }
    
    recognition.onresult = function(event) {
        let interim = '';
        let final = '';
        let confidence = 1;
        for (let i = event.resultIndex; i < event.results.length; i++) {
            if (event.results[i].isFinal) {
                recognition.stop();
                off();
                confidence = event.results[i][0].confidence;
                final += event.results[i][0].transcript;
                returnFxn('text', { text: final });
                updateStatus('success', final);
            } else {
                confidence = event.results[i][0].confidence;
                interim += event.results[i][0].transcript;
                updateStatus('interim', interim);
            }
        }
    }

    return (<>
        <p className="p-small mt-2">Select output language.</p>
        <div className="instruct-lang-wrapper speaker-tools">
            <select className="speaker-lang-select"
                onChange={(e) => setLang(e.target.value)}
                value={lang}
            >
                {langOptions}
            </select>
        </div>
        <hr className="opacity-30"></hr>
        <p className="speech-tool-status p-small mt-2">{status}</p>
        <div className="instruct-audio-wrapper speaker-tools mt-2">
            <div className={`instruct-audio ${speaker ? 'active' : ''}`} onClick={handleAudioClick}>
                <ClickableIcon type="speaker" addClass="no-background no-hover invert" />
            </div>
            {!chromeIOS && <div className="relative flex">
                <div className={`auto-send ${selfAutoAudio ? 'active' : ''}`} onClick={handleAutoClick}>auto</div>
                <div className="mic-holder" onClick={() => watch ? off() : on()}>
                    <ClickableIcon type="mic" addClass={`no-hover large ${watch ? 'animate-ping' : ''}`} />
                    <ClickableIcon type="mic" addClass={`no-hover large ${watch ? 'mic-active' : ''}`} />
                </div>
            </div>}
        </div>
    </>)
}