
import { useState, useEffect } from 'react';

import './Privacy.css';

// import { langSrc } from '../../tools/languageSource';
// import GoogleTranslate from '../../assets/google_translate.png';
import ClickableIcon from '../../components/ClickableIcon';
import { termsAndConditionsDate, termsAndConditionsVersion } from '../../tools/tools';
import TranslateButton from '../../components/TranslateButton';

export default function TermsAndConditions() {
    // const [lang, setLang] = useState('en');
    // const langOptions = langSrc.map(item => (
    //     <option key={item.code} value={item.code}>{item.name}</option>
    // ));

    useEffect(() =>{
        const notificationEl = document.querySelector('.notification');
        if (!notificationEl) return;
        notificationEl.classList.add('hide');
    }, []);

    return (
        <div className="privacy flex flex-col hide-scroll p-4">
            <div className="flex justify-end icon_btn">
                <TranslateButton addClass="no-background" />
            </div>
            <h2>Terms & Conditions</h2>
            <p className="p-emphasize">{termsAndConditionsDate} | version {termsAndConditionsVersion}</p>
            <p>Welcome to www.peelchat.com (also peelchat.com and peel.chat), brought to you by Shyroboto LLC. (referred to in these Terms as "we", "us", "our", and "Shyroboto"). We are a company incorporated in New York, USA with a mail address at  67 West St, Suite 401, Brooklyn, NY, 11222.  We can also be reached at hello@peelchat.com.</p>
            <p>These Terms & Conditions (the "Terms") govern your access to and use of Shyroboto's websites, mobile application(s) and other platforms Shyroboto owns or controls and makes available to you (the "Services"), and any other linked pages or social media platforms, blogs, features, content (including any information, text, graphics, photos, comments, reviews, links, or other materials uploaded, downloaded or appearing on, or linked to the Services), or applications offered from time to time by Shyroboto in connection therewith (collectively referred to as "Content"). By accessing or using the Services you agree to be bound by these Terms.</p>
            <p>The Services are always evolving, and the form and nature of the Services may change from time to time. Shyroboto retains the right to create limitations on use and storage at its sole discretion at any time without prior notice or any liability to you. Shyroboto also reserves the right to revise these Terms in its sole discretion at any time and without prior notice to you other than by posting the revised Terms & Conditions on all Shyroboto websites and platforms. Any revisions to the Terms are effective upon posting. The Terms will be identified as of the most recent date of revision. Subject to the above sentences, should any revision of the Terms (or the Services) is related to your personal data, before the changes are effective, Shyroboto will notify you with the changes and provide you with an option to delete your persona data from us.</p>
            <p>Notwithstanding the preceding sentences of this paragraph, no revisions to this Terms will apply to any dispute between you and Shyroboto that arose prior to the date of such revision.</p>
            <p>IF YOU DO NOT AGREE WITH ANY OF THE TERMS SET FORTH BELOW, PLEASE STOP USING THIS SITE OR ANY SERVCES OF SHYROBOTO.</p>
            <h5>I. BASIC TERMS</h5>
            <p>You are solely responsible for your use of the Services, for any content you submit to the Services, and for any consequences thereof. The content you submit (including, but not limited to, documents, photos or personally identifiable information) may be viewed by us and by third party services and websites that we use to provide the Service for you. You can request that we delete the content and identifiable personal data that you provided, however, in doing so, you may not be able to engage us to create product for you.</p>
            <p>You may use the Services only if you are at least 18 years of age. We do not knowingly market or sell products for purchase by children. You may only buy items from Shyroboto if you are legally capable of forming a binding contract with Shyroboto and are able to abide by and comply with these Terms, and are not a person barred from receiving the Services under the laws of the United States or any other applicable jurisdiction. Additionally, we do not collect any personally identifiable data from minors under the age of 18. If you are under 18, please do not provide any personal data to us and please direct your request to delete your data to hello@peelchat.com. You may use the Services only in compliance with these Terms and all applicable local, state, national, and international laws, rules and regulations. If you use the Services on behalf of a company, organization, or other entity, then you represent and warrant that you are authorized on behalf of that entity to bind the entity to these Terms; and you agree to these Terms on the entity's behalf.</p>
            <h5>II. YOUR PERSONAL DATA</h5>
            <p>All personal and sensitive information disclosed by you on the Services will be collected and processed in accordance with our Privacy Policy (https://www.peelchat.com/privacy-policy) and the following terms. We collect and use the data sole for creating work product for you. You represent and warrant that the personal data you provided is authorized by all parties for the use by Shyroboto, specifically, if the personal data contains medical record of a patient, you represent and warrant that you have the patient’s consent to share the data with us and our professional partners. You shall indemnify and hold Shyroboto harmless for any dispute arising from the data that you provided to Shyroboto. You warrant that all information and personal information provided to Shyroboto is true and accurate.</p>
            <h5>III. PURCHASE PRODUCTS</h5>
            <p>Shyroboto allows users to purchase services and products provided by us. When you make online payments through Shyroboto, the payments will be processed by Shyroboto. In order to process these payments we will need to collect and share your personal data, shipping information and payment information that are related to such transactions with our professional partners, but only using our secured/encrypted systems via HTTPS/TLS or Secure Sockets Layer 3.0 (SSL). The personal data, shipping information and payment information you provide to us will be collected, stored and processed in the United States and in accordance with Shyroboto Privacy Policy (https://www.peelchat.com/privacy-policy).</p>
            <p>Please contact Shyroboto directly in respect of any queries you have on the items you purchase or the delivery of the items you purchase. All communication and Services provided by Shyroboto are in no way intended to be used for medical purposes, and no healthcare consultation or information is to be derived from our communications or services.</p>
            <p className="p-title">Payment information</p>
            <p>When you make a payment through Shyroboto, you will need to follow instructions on the checkout page. All credit/debit card holders are subject to validation checks and authorization by the card issuer. If the issuer of your payment card refuses to authorize payment, we will not be liable for any delay or non-delivery.</p>
            <p>We take reasonable care to make our platform secure. Please note, however, that we are not responsible for any personal information or payment information that is shared by the payment processors.</p>
            <p>Furthermore, we take reasonable care, in so far as it is in our power to do so, to keep the information of your order and payment secure, but in the absence of negligence on our part we are not liable for any loss you may suffer if a third party procures unauthorized access to any data you provide when accessing or ordering through the Services.</p>
            <p>Online payment can be made by any card accepted by Shyroboto. Shyroboto accepts payment through Stripe. Shyroboto does not accept payments by checks, postal orders, cash, money orders, international wire transfers, or bank transfers.</p>
            <p className="p-title">Payment processed by third parties</p>
            <p>For certain transactions made in connection with Shyroboto, we might use third parties to process payments. When we use a third party to process payments, we will fully encrypt your data and only permit the use of your date for processing the transactions which you have initiated. For any transactions processed by a third party, we will provide the third party with the information of your card as well as your billing information.</p>
            <h5>IV. ACCESSING THE SERVICES</h5>
            <p>Although the Services are normally available, there will be occasions when the Services will be interrupted for scheduled maintenance or upgrades, for emergency repairs, or due to failure of telecommunications links and equipment that are beyond the control of Shyroboto. From time to time, we may restrict access to some or all parts of our Services for any reason. We do not guarantee your access to Services at all time, and we reserve the right to withdraw or amend the Services without notice and the right to modify, restrict access or terminate access to the Services at any time. We will not be liable if for any reason the Services are unavailable at any time or for any period. You are responsible for making all arrangements necessary for you to have access to Services. You are also responsible for ensuring that all persons who access Services through your internet connection are aware of these Terms, and that they comply with them.</p>
            <p>Although Shyroboto will normally only delete Content that violates the Terms, Shyroboto reserves the right to delete any Content for any reason, without prior notice. Deleted content may be stored by Shyroboto in order to comply with certain legal obligations and is not retrievable without a valid court order. Consequently, Shyroboto encourages you to maintain your own backup of your content. In other words, Shyroboto is not a backup service. Shyroboto will not be liable to you for any modification, suspension, or discontinuation of the Services, or the loss of any Content.</p>
            <h5>V. CONTENT ON THE SERVICES</h5>
            <p>All Submissions (defined in “Your Rights” section below) including all content you share on or with the Services (such as copies of documents or product reviews) is the sole responsibility of the person who originated such content.</p>
            <p>Shyroboto performs technical functions necessary to offer the Shyroboto Services, including but not limited to transcoding and/or reformatting content to allow its use throughout the Shyroboto Services.</p>
            <h5>VI. INTELLECTUAL PROPERTY</h5>
            <p>All intellectual property rights (including but not limited to copyright, trademarks, registered and unregistered designs, database rights, domain names and goodwill in all content included on this website and in SHYROBOTO trademarks, including all text, designs, graphics, logos, photographs and images) is the exclusive property of Shyroboto LLC or its licensors and is protected by applicable laws around the world. Any commercial use, including the reproduction, modification, distribution and transmission of any Content without the prior written consent of Shyroboto is strictly prohibited.</p>
            <p>When you provide content to Shyroboto, you also grant to us a perpetual, non-exclusive and royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, create derivative works from, and distribute the content that you provide. If Shyroboto creates a product for you, Shyroboto retains the ownership to the intellectual property within the product and retains the right to archive and display the product worldwide, provided that, any patient sensitive information shall not be displayed absent of a separate and express consent from the patient.</p>
            <p className="p-title">Shyroboto Trademarks</p>
            <p>“SHYROBOTO”, “PEELCHAT” and its logos and designs are trademarks in the United States owned by Shyroboto LLC. Shyroboto’s trademarks are important company assets and Shyroboto requires that they be used properly and with proper authorization from Shyroboto.</p>
            <h5>VII. YOUR RIGHTS</h5>
            <p>When you submit emails, communications, questions, comments, suggestions, ideas, public postings, material submitted via web forms, or any other information ("Submissions") to Shyroboto, you grant Shyroboto permission to use such Submissions for internal and archival purposes. You agree that Shyroboto will have no obligation to keep any Submissions confidential and you will not bring a claim against Shyroboto based on "moral rights" or the like arising from Shyroboto's use of a Submission.</p>
            <p>The Services contain Content owned by Shyroboto. Content is protected by copyright, trademark, patent, trade secret and other laws, and Shyroboto owns and retains all rights in the Content and the Services. Shyroboto hereby grants you a limited, revocable, non-sublicensable license to reproduce and display the Content (excluding any software code) solely for your own use in connection with viewing Shyroboto platform and using Services. The Services contain content of users and other Shyroboto licensors. Except as provided within these Terms, you may not copy, modify, translate, publish, broadcast, transmit, distribute, perform, display, or sell any Content appearing on or through the Services and Shyroboto platform. Except to the extent permitted by applicable laws, you may not copy, reproduce, republish, disassemble, decompile, reverse engineer, adapt, alter, edit, re-position, rebrand, change, distribute, lend, hire, sub-license, rent, make a derivative work from the software in the Services.</p>
            <h5>VIII. SHYROBOTO RIGHTS</h5>
            <p>You retain your rights, including copyright and other intellectual property rights, to any content you submit. By submitting content on or through the Services from time to time, you grant Shyroboto a worldwide, non-exclusive, perpetual, irrevocable, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, create derivative works from, and distribute such content, provided that, any patient sensitive information shall not be displayed absent of a separate and express consent from the patient.</p>
            <p>You agree that this license includes the right for Shyroboto to make such content available, with limited purposes and time, to other companies, organizations or individuals who partner with Shyroboto for the design, production and transporting of such content (embedded in product) on other media and services.</p>
            <p>You agree that no compensation will be payable to you in respect of any of the foregoing uses by Shyroboto, or other companies, organizations or individuals who partner with Shyroboto with respect to the content that you submit to or otherwise make available through the Services. You waive all moral rights (or similar or equivalent rights in other jurisdictions) you have in such content to the fullest extent permitted by law.</p>
            <p>All right (including copyright, trademarks and other intellectual property rights), title, and interest in and to the Services and the Content (excluding Postings and Submissions) including but not limited to all information, data, text, maps, graphics, the "look and feel", logos, icons, trademarks, images, video clips, sound clips, editorial content, notices, data compilations, page layout, selection or arrangement of the contents of the Services, and the underlying code and software in the Services are and will remain the exclusive property of Shyroboto and its licensors. The Services are protected by copyright, trademark, and other laws and treaties around the world. All such rights in the Services, Content (excluding Content provided by users of the Services) and related material are reserved.</p>
            <p>You may print off one copy, and may download extracts, of any page(s) from our Services for your personal reference and you may draw the attention of others within your organization to material posted on our Services. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
            <h5>IX. RESTRICTIONS ON CONTENT AND USE OF THE SERVICES</h5>
            <p>You shall not use the Services:</p>
            <ul>
                <li>in any way that violates any local, national or other laws or regulations or any order of a court in any relevant jurisdiction;</li>
                <li>for any purpose that is not permitted by these Terms;</li>
                <li>in any way that infringes the rights of any person or entity, including their copyright, trademark or other intellectual property rights, or other privacy or contractual rights;</li>
                <li>to distribute advertisements of any kind or post or otherwise communicate any false or misleading material or message of any kind;</li>
                <li>in any way that intentionally or unintentionally harasses, annoys, threatens or intimidates any other user;</li>
                <li>in any way that promotes or incites, whether intentionally or unintentionally, racism, bigotry, hatred or physical harm of any kind;</li>
                <li>in any way that is abusive, defamatory, inaccurate, obscene, offensive, objectionable or sexually explicit;</li>
                <li>to post photographs or images of another person without his/her permission (and if a minor, the permission of the minor's legal guardian);</li>
                <li>to promote illegal or unlawful activities or in any way which may lead to the encouragement, procurement or carrying out of any unlawful or criminal activity or which may cause any harm, distress or inconvenience to any person;</li>
                <li>to access, tamper with, cause damage to, or use non-public areas of the Services, Shyroboto's computer systems, servers or equipment or the technical delivery systems of Shyroboto's providers;</li>
                <li>to access or attempt to access any data of other users of the Services or to penetrate any of the security measures relating to the Services, or to probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures;</li>
                <li>in any way that intentionally or unintentionally deceives, defrauds or swindles any other user;</li>
                <li>to introduce any malware, virus or other harmful software program that intentionally or unintentionally damages or interferes with the operation of the Services, including but not limited to cancelbots, denial of service attacks, time-bombs, worms, Trojan horses, viruses or any other maleficent software or hardware;</li>
                <li>interfere with, or disrupt, (or attempt to do so), the access of any user, host or network including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services;</li>
                <li>to copy, modify, or distribute any other users' Content without their consent;</li>
                <li>for commercial purposes other than as expressly permitted in these Terms;</li>
                <li>to bypass measures used to prevent or restrict access to the Services;</li>
                <li>to solicit or provide unlawful services;</li>
                <li>to harvest or otherwise collect information about others without their consent;</li>
                <li>to gain unauthorized access to the Services, the server on which the Services is stored or any server, computer or database connected to the Services;</li>
                <li>to forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information;</li>
                <li>to scrape, crawl or spider or otherwise use the Services or any Content for phishing, spamming, trolling or any unauthorized (commercial) purpose; or</li>
                <li>to promote or support or solicit involvement in any political platform or cause, religion (recognized as organized or unorganized), cult or sect of any kind.</li>
            </ul>
            <p>Shyroboto reserves the right at all times (but will not have an obligation) to remove or refuse to distribute any Content on the Services and to terminate your access to the Site. Shyroboto also reserves the right to access, read, preserve, or disclose any information as it reasonably believes is necessary to: (i) satisfy any applicable law, regulation, legal process or governmental request; (ii) enforce the Terms, including investigation of potential violations hereof; (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or; (v) protect the rights, property or safety of Shyroboto, its users and the public.</p>
            <p>While we will do what we can to protect the security of your Content and account, we cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any compromise or unauthorized use of your account.</p>
            <h5>X. LIMITATION OF LIABILITY</h5>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT SHYROBOTO AND ITS EMPLOYEES, CONTRACTORS, AGENTS AND AFFILIATES, INCLUDING BUT NOT LIMITED TO ANY THIRD PARTIES RESPONSIBLE FOR THE CREATION, MAINTENANCE AND UPKEEP OF THE SITE, SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SHYROBOTO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM THE USE OF THIS SITE OR ANY COMPUTER VIRUSES ON YOUR COMPUTER OR ELECTRONIC DEVICES.  IN NO EVENT SHALL SHYROBOTO’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS ($100). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SITE.</p>
            <h5>XI. INDEMNIFICATION</h5>
            <p>You will indemnify and hold Shyroboto and its employees, representatives, agents, affiliates, directors, officers, managers and shareholders (the "Parties") harmless from any damage, loss, or expense (including without limitation, attorneys' fees and costs) incurred in connection with any third-party claim, demand or action ("Claim") brought against any of the Parties alleging that you have breached any portion of these Terms. If you have to indemnify Shyroboto under this Section, Shyroboto will have the right to control the defense, settlement, and resolution of any Claim at your sole expense. You may not settle or otherwise resolve any Claim without Shyroboto' express written permission.</p>
            <h5>XII. ARBITRATION</h5>
            <p>If a dispute arises from or relates to these Terms, your use of the Shyroboto Site or Services, or the breach thereof, and if the dispute cannot be settled through direct discussions, the parties agree that any such unresolved controversy or claim shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules.  Judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Claims shall be heard by a single arbitrator, unless the claim amount exceeds one hundred thousand dollars ($100,000), in which case the dispute shall be heard by a panel of three (3) arbitrators. The place of arbitration shall be in the city, county and state of New York.  The arbitration shall be governed by the laws of the State of New York. The prevailing party shall be entitled to an award of reasonable attorney fees, if awarded by the arbitrator(s).</p>
            <p>Notwithstanding the foregoing, if a third party claim is brought against either party for copyright infringement, violation of rights of publicity, rights of privacy, or other unauthorized use of content which is contrary to the rights granted in these Terms, the parties shall not be bound by this arbitration provision and may defend themselves and make a claim against the other party in the appropriate court of law and/or equity.</p>
            <p>In the event of a dispute arising out of these Terms and not subject to the arbitration provision above, the prevailing party shall have the right to collect from the other party its reasonable costs and necessary disbursements and attorneys' fees.</p>
            <h5>XIII. GOVERNING LAW</h5>
            <p>These Terms shall be governed in all respects by the laws of the State of New York as they apply to agreements entered into and to be performed entirely within New York between New York residents, without regard to conflict of law provisions. The parties to these Terms agree that any claim or dispute arising under these Terms must be resolved by a court located in city, county and state of New York, except as otherwise agreed by the parties or as described in the Arbitration provision above. The parties agree to submit to the personal jurisdiction of the courts located within the city, county and state of New York for the purpose of litigating all such claims or disputes.</p>
            <h5>XIV. CONSENT AND CHANGES</h5>
            <p>Your use of this Site shall be deemed express approval of all terms of these Terms. If you do not consent to these Terms or any of the above terms, please immediately exit and discontinue use of the Site.  Shyroboto has the discretion to update these Terms at any time. In the event these Terms is updated, we will also update the date set forth below. We will timely notify you for any changes related to your personal data. Please check this page regularly to keep informed of any such updates.</p>
            <p>Your continued use of the Site shall be deemed acceptance of these Terms and any changes thereto.</p>
            <h5>XV. CONTACT</h5>
            <p>If you have any questions about these Terms or your dealings with this Site, please contact us at hello@peelchat.com.</p>
            <p>This Policy has been drawn up in the English language. In case of discrepancies between the English text version of this Policy and any translation, the English version shall prevail.</p>
            <p className="mt-12">Last Revised: {termsAndConditionsDate}.</p>
        </div>
    )
}