import { useContext } from 'react';
import { ChatContext } from '../context/ChatContext';

export const useChatContext = () => {
    const context = useContext(ChatContext);
    
    if (!context) {
        throw new Error('Chat context must be used within its own provider.');
    }

    return context;
}