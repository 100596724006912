
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { functions } from '../../firebase/config'
import { httpsCallable } from 'firebase/functions'

import SpeakerChatPanel from './components/SpeakerChatPanel'

import SpeakerphoneImg from '../../assets/speakerphone.png'

import './Speaker.css'
import LoadingIcon from '../../components/LoadingIcon'

export default function Speaker() {
    const getUserSubscriptionStatusFxn = httpsCallable(functions, 'getUserSubscriptionStatus');
    const { user } = useAuthContext();
    const [warning, setWarning] = useState(false);
    const [emailVerification, setEmailVerification] = useState(false);
    const [tierNotification, setTierNotification] = useState(false);
    const [pass, setPass] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (!user.emailVerified) {
            setEmailVerification(true);
            return
        }
        getUserSubscriptionStatusFxn({ uid: user.uid })
            .then((subStatus) => {
                if (subStatus.data.tier !== 'trial' && subStatus.data.tier !== 'basic') {
                    setTierNotification(true);
                    return;
                }
                if (user?.activeChats?.length > 0) {
                    setWarning(true) 
                    return;
                }

                // passed
                setPass(true);
            }).catch(err => {
                console.log(err);
            })
    }, [user]);

    useEffect(() => {

    }, [])

    return <>
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <div className="">
                <img src={SpeakerphoneImg} className="w-4 h-4 inline-block" />
                <h5 className="mt-1 inline-block ml-1">Speakerphone</h5>
            </div>
            <div className={`mt-4 grow bg-neutral-200 flex justify-center items-stretch relative`}>
                {emailVerification && <div className="m-auto w-52">
                        <h4 className="font-extrabold self-center">Hmmm...🤔</h4>
                        <p className="mt-1">Email verification required to use <span className="">Speakerphone</span> feature.</p>
                    </div>
                }
                {warning && <div className="m-auto w-48">
                    <h4 className="font-extrabold self-center">Hmmm...🤔</h4>
                    <p className="mt-1">Please exit current chat to use <span className="">Speakerphone</span> feature.</p>
                </div>}
                {tierNotification && <div className="m-auto w-48">
                    <h4 className="font-extrabold self-center">Subscribe!</h4>
                    <p className="mt-2">Please sign up for our <a href="/subscriptions" className="font-semibold">Basic Plan</a> to use the Speakerphone feature.</p>
                    <div className="flex justify-center mt-6">
                        <button onClick={() => {
                            navigate('/subscriptions');
                        }}>go</button>
                    </div>
                </div>}
                {pass && <SpeakerChatPanel />}
                {!pass && !emailVerification && !warning && !tierNotification && <div className="flex items-center"><LoadingIcon addClass="" /></div>}
            </div>
        </div>
    </>
}