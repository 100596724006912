import { useState, useEffect } from 'react';
import { useLogin } from '../hooks/firebase/useLogin';

import LoadingIcon from './LoadingIcon';

export default function LoginForm() {
    const [forgotPw, setForgotPw] = useState(false);
    const [formComplete, setFormComplete] = useState(false);
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('');
    const { login, error, pending, resetPw } = useLogin();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!error) return;
        if (error.includes('user-not-found')) {
            setErrorMessage('Account not found or password mismatch.')
        }
    }, [error]);

    const toggleForgotPw = () => {
        forgotPw ? setForgotPw(false) : setForgotPw(true);
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (forgotPw) {
            resetPw(email);
            setTimeout(() => setForgotPw(false), 3000);
        } else {
            login(email, pw);
        }

        setFormComplete(true);
        setEmail('');
        setPw('');
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 p-2">
                <label className="flex">
                    <span className="self-center form-inline-label">email:</span>
                    <input 
                        className="flex-auto grow ml-2"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                {!forgotPw && (        
                    <label className="flex">
                        <span className="self-center form-inline-label">password:</span>
                        <input 
                            className="flex-auto grow ml-2"
                            type="password"
                            value={pw}
                            onChange={(e) => setPw(e.target.value)}
                            required
                        />
                    </label>
                )}
            {!forgotPw && <p onClick={toggleForgotPw} className="self-end font-semibold cursor-pointer">forgot password?</p>}
            {!pending && !forgotPw && <button className="btn mx-auto mt-2">login</button>}
            {!pending && forgotPw && <button className="btn mx-auto mt-2">send password reset email</button>}
            {pending && <button className="btn mx-auto mt-2"><LoadingIcon size={'small'} addClass={'mx-auto'} /></button>}
            {forgotPw && !error && !pending && formComplete && <p className="text-center mt-2">📧 Email sent!</p>}
            {error && <p className="error">{errorMessage}</p>}
        </form>
    )
}