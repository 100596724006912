import { useState, useEffect } from 'react';
import { colorPalette } from "../tools/colorPalette";

import './LoadingIcon.css'

export default function LoadingIcon({ size, addClass }) {
    const [style, setStyle] = useState({ background: '#000000' });
    const [classList, setClassList] = useState('');
    
    useEffect(() => {
        let random = Math.floor(Math.random() * (colorPalette.length));
        let color = colorPalette[random];
        setStyle({ background: color });

        const colorInterval = setInterval(() => {
            random = Math.floor(Math.random() * (colorPalette.length));
            color = colorPalette[random];
            setStyle({ background: color });
        }, 3000);

        let classes = '';
        switch (size) {
            case 'large': 
                classes = addClass + ' loading-icon large';
                break;
            case 'medium':
                classes = addClass + ' loading-icon medium';
                break;
            case 'small':
                classes = addClass + ' loading-icon small';
                break;
            case 'mini':
                classes = addClass + ' loading-icon mini';
                break;
            default:
                classes = addClass + ' loading-icon duration-700';
                break;
        }
        setClassList(classes);
    }, []);

    return <div className={classList} style={style}></div>
}