import { useState, useEffect } from 'react'
import { auth } from '../../firebase/config'
import { signOut } from 'firebase/auth'
import { useAuthContext } from '../useAuthContext'
import { useChatContext } from '../useChatContext'
import { useNavigate } from 'react-router-dom';
import { useExitChat } from '../useExitChat'
import { useFirestore } from './useFirestore'
import { functions } from '../../firebase/config'
import { httpsCallable } from 'firebase/functions'

export const useLogout = (account) => {
    const deleteGuestFxn = httpsCallable(functions, 'deleteGuest');
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { session, dispatch: chatDispatch, self } = useChatContext();
    const { user, dispatch } = useAuthContext();
    const { exitChat } = useExitChat();
    const { updateDocument } = useFirestore('users');
    
    const navigate = useNavigate();

    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const logout = async (forced = false) => {
        setError(null);
        setPending(true);
        dispatch({ type: 'LOGGINGOUT' });

        try {
            if (session && self && !forced) {
                chatDispatch({ type: 'PAUSE' }); // prevent page jumping
                await exitChat(user.isAnonymous);
            }

            if (!user?.isAnonymous) {
                await updateDocument(user.uid, { online: false });
            }

            if (user?.isAnonymous) {
                deleteGuestFxn({ uid: user.uid }); // don't wait
            }
            
            if (user?.unsub) user.unsub();
            await signOut(auth);
            dispatch({ type: 'LOGOUT' });
            navigate('/home');
            
            reset();
        } catch(err) {
            console.log(err.message);
            reset(err);
        }
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, [])
    

    return { error, pending, logout };
}