import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions'
import { useSubFirestore } from '../../hooks/firebase/useSubFirestore';
import { useParams } from 'react-router-dom';

import './Subscriptions.css'

import ClickableIcon from '../../components/ClickableIcon';
import LoadingIcon from '../../components/LoadingIcon';
import DuckImg from '../../assets/duck.png';
import ChickImg from '../../assets/chick.png';
import StarImg from '../../assets/star.png';
import PeaceImg from '../../assets/peace.png';

export default function Subscriptions() {
    const toStripePortalFxn = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    const { user } = useAuthContext();
    const { addSubCollectionDocument, listenSubCollectionDocument, response: subResponse } = useSubFirestore();
    const [trial, setTrial] = useState(0);
    const [stripeSuccessScreen, setStripeSuccessScreen] = useState(false);
    const navigate = useNavigate();
    const [stripePending, setStripePending] = useState(false);
    const [waitingForCheckout, setWaitingForCheckout] = useState(false);
    let location = window.location.origin + '/subscriptions';

    const { result } = useParams();

    useEffect(() => {
        if (result === 'success') {
            setStripeSuccessScreen(true);
        }
    }, [result]);

    useEffect(() => {
        if (!user) return;
        const initTime = Number(user.metadata.createdAt);
        const currentTime = new Date().getTime();
        const days = (currentTime - initTime) / (24 * 60 * 60 * 1000);
        setTrial(Math.floor(7 - days));
    }, [user]);

    const toStripePortal = async () => {
        setStripePending(true);
        const { data } = await toStripePortalFxn({
            returnUrl: location
        });
        window.location.assign(data.url);
    }

    const handleBasicSubscribe = async () => {
        setStripePending(true);
        const save = {
            price: 'price_1MpdVzKhwGhKS8fMWOcaL1DS',
            allow_promotion_codes: true,
            success_url: `${location}/success`,
            cancel_url: `${location}/cancel`
        };
        const doc = await addSubCollectionDocument('customers', user.uid, `checkout_sessions`, null, save);
        
        await listenSubCollectionDocument('customers', user.uid, 'checkout_sessions', doc.id, 'stripe');
        setWaitingForCheckout(true);
    }

    useEffect(() => {
        if (!waitingForCheckout || !subResponse) return;
        if (subResponse.pending) return;
        if (subResponse.error) return;
        if (subResponse.document) {
            if (subResponse.document.url) window.location.assign(subResponse.document.url);
        }
    }, [waitingForCheckout, subResponse]);

    return (
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <h5 className="mt-1">Subscriptions</h5>
            <div className="mt-4 grow bg-neutral-200 flex flex-col justify-center items-center p-4 relative">
                <div className="flex absolute left-0 top-2" onClick={() => navigate('/settings')}>
                    <ClickableIcon type="arrow_back" addClass="no-background no-hover" />
                </div>
                <div className="flex flex-col items-center">
                    {user.tier === 'trial' && <div className="subscription-package active">
                        <div className="mt-2 flex justify-between">
                            <div className="flex gap-1">
                                <img src={PeaceImg} className="h-8 w-8" alt="chick" />
                                <h4 className="font-bold self-center">Free Trial</h4>
                            </div>
                            <div className="trial-days-wrapper">
                                <h5 className=" mt-2 font-bold self-center">{trial} <span className="text-xs">days left</span></h5>
                            </div>
                        </div>
                        <p className="mt-2 mb-2">One week free trial. We hope you like us!</p>
                    </div>}
                    {user.tier !== 'trial' && <div className={`subscription-package ${user.tier === 'free' ? 'active' : ''}`}>
                        <div className="flex justify-between mt-2">
                            <div className="flex gap-1">
                                {user.tier === 'free' && <img src={ChickImg} className="h-8 w-8" alt="chick" />}
                                <h4 className="font-bold self-center notranslate">Free</h4>
                            </div>
                            <h4 className="font-bold self-center">$0<span className="text-xs">/month</span></h4>
                        </div>
                        <p className="mt-2 mb-2">Join chats with your personalized chat tools!</p>
                    </div>}
                    <div className={`subscription-package ${user.tier === 'basic' ? 'active' : ''}`}>
                        <div className="flex justify-between mt-2">
                            <div className="flex gap-1">
                                {user.tier === 'basic' && <img src={DuckImg} className="h-8 w-8" alt="duck" />}
                                <h4 className="font-bold self-center notranslate">Basic</h4>
                            </div>
                            <h4 className="font-bold self-center">$4<span className="text-xs">/month</span></h4>
                        </div>
                        <p className="mt-2">Host chats and invite others to join even if they don't have an account. *Beta pricing*</p>
                        {user.tier !== 'basic' && <div className="flex justify-end mt-4">
                            {!stripePending && <button onClick={handleBasicSubscribe}>select</button>}
                            {stripePending && <button className="flex justify-center">
                                <LoadingIcon size="small" addClass="self-center" />
                            </button>}
                        </div>}
                        {user.tier === 'basic' && <div className="flex justify-end mt-4">
                            {!stripePending && <button onClick={toStripePortal}>manage</button>}
                            {stripePending && <button className="flex justify-center">
                                <LoadingIcon size="small" addClass="self-center" />
                            </button>}
                        </div>}
                    </div>
                    <div className={`subscription-package ${user.tier === 'pro' ? 'active' : ''} disabled`}>
                        <div className="flex justify-between mt-2">
                            <h4 className="font-bold self-center notranslate">Pro</h4>
                        </div>
                        <p className="mt-2">For professionals that require certified interpretations and AI-powered generative summary documents.</p>
                        <div className="flex justify-end mt-4">
                            <button className="disabled-btn">coming soon</button>
                        </div>
                    </div>
                    <p className="mt-4 font-semibold footer-spacing">Powered by <span className="notranslate"> Stripe</span></p>
                </div>
            </div>
            {stripeSuccessScreen && <div className="screen" onClick={() => setStripeSuccessScreen(false)}>
                <div className="center-modal">
                    <div className="flex gap-2 mr-6">
                        <img src={StarImg} className="h-12 w-12 animate-bounce" alt="mushroom" />
                        <h3 className="self-end font-bold">Level Up!</h3>
                    </div>
                    <p className="font-semibold ml-4 mt-4 w-48">You now have the power to host chats! Lucky!</p>
                </div>
            </div>}
        </div>
    )
}