import { useQRCode } from 'next-qrcode';
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useAppContext } from '../../hooks/useAppContext';
import { useDocListener } from '../../hooks/firebase/useDocListener';
import { useChatContext } from '../../hooks/useChatContext';
import { useJoinChat } from '../../hooks/useJoinChat';
import { useReturnChat } from '../../hooks/useReturnChat';
import { useRequestChat } from '../../hooks/useRequestChat';
import { useFirestore } from '../../hooks/firebase/useFirestore';
import { useLogout } from '../../hooks/firebase/useLogout';

import '../chatlobby/ChatLobby.css';
import './ChatDoor.css';

import JoinChatForm from './components/JoinChatForm';
import LinkShare from '../chatlobby/components/LinkShare';
import HelpBubble from '../../components/HelpBubble';
import PenguinImg from '../../assets/penguin.png';
import LoadingIcon from '../../components/LoadingIcon';

export default function ChatDoor({ gdpr, returnFxn }) {
    const { Canvas } = useQRCode();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { id } = useParams();
    const { document: doc, exist, pending: listenerPending, error } = useDocListener('sessionSnaps', id);
    const { dispatch } = useChatContext();
    const { dispatch: appDispatch } = useAppContext();
    const { joinChat, pending } = useJoinChat('chatdoor');
    const { returnChat, pending: returnPending } = useReturnChat();
    const { requestChat, pending: requestPending } = useRequestChat();
    const { updateDocument } = useFirestore('users');
    const { logout } = useLogout(user);
    const [guests, setGuests] = useState(false);
    const [publicChat, setPublicChat] = useState(false);
    const [oldChatter, setOldChatter] = useState(false);
    const [requestWaiting, setRequestWaiting] = useState(false);
    const [requestDenied, setRequestDenied] = useState(false);
    const locHook = useLocation();
    let location = window.location.href;
    const memoDispatch = useRef(dispatch);
    const memoAppDispatch = useRef(appDispatch);
    const memoLogout = useRef(logout);

    // style
    const [loading, setLoading] = useState(true);
    const [penguin, setPenguin] = useState('just-showing');

    useEffect(() => {
        if (doc && !doc.status.active) {
            setTimeout(() => setPenguin('animate-hop just-showing'), 5000);
        }
    }, [doc])
    
    useEffect(() => {
        if (!locHook || !locHook.state) return;
        if (locHook.state.event === 'error') {
            memoAppDispatch.current({ type: 'END_CHAT', source: 'auto_chatDoor'});
            memoDispatch.current({ type: 'EXIT', source: 'auto_chatDoor' });
            if (user && user.isAnonymous) {
                setTimeout(() => {
                    memoLogout.current();
                }, 10000);
            }
            
            clearActiveChats(id);
        }
    }, [id, locHook, memoAppDispatch, memoDispatch, memoLogout]);
    
    useEffect(() => {
        if (!doc) return;
        if (!doc.status.active) return;
        doc.status.allowGuests ? setGuests(true) : setGuests(false);
        if (user && user.isAnonymous) return; // guests are handled below

        if (doc.status.publicChat) {
            setPublicChat(true);
            setRequestWaiting(false);
        } else {
            if (!user) return;
            if (doc.requests && doc.requests[user.uid]) {
                if (doc.requests[user.uid].status === 'pending') setRequestWaiting(true);
                if (doc.requests[user.uid].status === 'approved') {
                    joinChat(id);
                }
                if (doc.requests[user.uid].status === 'denied') {
                    setRequestWaiting(false);
                    setRequestDenied(true);
                }
            } else {
                setRequestWaiting(false);
            }
        }
    }, [doc, user]);

    // handle guests
    useEffect(() => {
        if (!user) return;
        if (!user.isAnonymous) return;
        if (!user.displayName) return; // wait for displayname
        
        if (!doc) return;
        if (!doc.status.active) return;
        if (locHook?.state?.exit === 'exit') if (locHook.state.uid === user.uid) return;
        
        if (doc.status.publicChat) {
            setPublicChat(true);
            joinChat(id, true);
        } else {
            if (doc.requests && doc.requests[user.uid]) {
                if (doc.requests[user.uid].status === 'pending') setRequestWaiting(true);
                if (doc.requests[user.uid].status === 'approved') {
                    joinChat(id, true);
                }
                if (doc.requests[user.uid].status === 'denied') {
                    setRequestWaiting(false);
                    setRequestDenied(true);
                    memoDispatch.current({ type: 'EXIT', source: 'auto_chatDoor' });
                    setTimeout(() => {
                        memoLogout.current();
                    }, 10000);
                }
            } else {
                requestChat(id, true);
                setRequestWaiting(true);
            }
        }
    }, [user, doc, locHook]);

    useEffect(() => {
        const func = async () => {
            if (!user) return setLoading(false);;
            if (listenerPending) return setLoading(false);
            if (!exist) {
                await clearActiveChats(id);
                return navigate(`/chat`);
            }
            if (!doc) return;
            if (!doc.status.active) {
                await clearActiveChats(id);
                return setLoading(false);;
            }
            if (doc.info[user.uid]) {
                if (doc.info[user.uid].ban) {
                    setRequestDenied(true);
                    await clearActiveChats(id);
                    return;
                }
                if (doc.info[user.uid].active) {
                    if (error && error.includes('could not fetch the data')) {
                        console.log('error fetching data');
                    } else {
                        if (!pending) navigate(`/chatlobby/${id}`);
                        return setLoading(false);;
                    }
                } else {
                    setOldChatter(true);
                }
            } 
            let payload = { session: id };
            Object.values(doc.info).forEach(async item => {
                if (user && item.user === user.uid) {
                    payload.self = item.tempID;
                    payload.active = item.active;
                }
                if (item.owner) payload.owner = item.tempID;
            });
            payload.users = doc.info;
            payload.guests = doc.status.allowGuests || false;
            payload.invites = doc.invites || [];
            memoDispatch.current({ type: 'CHAT_LOBBY', payload });

            setLoading(false);
        }
        
        func();
    }, [user, doc, id, memoDispatch, exist, pending, listenerPending]);

    const clearActiveChats = async (sessionID) => {
        return new Promise(async (resolve, reject) => {
            if (user.isAnonymous) return;
            if (user?.activeChats?.indexOf(sessionID) !== -1) {
                let activeChats = [...user.activeChats];
                while (activeChats.indexOf(sessionID) !== -1) {
                    activeChats.splice(activeChats.indexOf(sessionID));
                }
                try {
                    await updateDocument(user.uid, { activeChats });
                    resolve('user document updated');
                    return;
                } catch(err) {
                    resolve('user document not updated. Active chat session found.');
                    return;
                }
            }
            resolve('no active chat session.');
            return;
        });
    }

    const handleJoinChat = () => {
        if (pending || returnPending || requestPending) return;
        
        if (oldChatter) {
            returnChat(id);
        } else {
            if (publicChat) {
                joinChat(id);
            } else {
                if (!requestWaiting) requestChat(id);
            } 
        }
    }

    const handleExitChat = async () => {
        if (!user.isAnonymous) {
            if (user.activeChats && user.activeChats.indexOf(id) !== -1) {
                let newActiveChats = user.activeChats.splice(user.activeChats.indexOf(id), 1);
                await updateDocument(user.uid, { activeChats: newActiveChats });
            }
            navigate('/');
        }
        if (user.isAnonymous) logout();
    }

    const [buttonText, setButtonText] = useState('join chat');
    useEffect(() => {
        if (oldChatter) {
            setButtonText('rejoin chat');
        } else {
            if (publicChat) {
                setButtonText('join chat');
            } else {
                if (requestWaiting) {
                    setButtonText('request sent');
                } else {
                    setButtonText('request to join');
                }
            }
        }
    }, [oldChatter, publicChat, requestWaiting]);
    
    return (
        <>
            <div className={`ml-2 p-5 pt-4 flex flex-col w-full ${loading? 'loading' : ''}`} style={{transition : '0.2s'}}>
                <div className={`relative`}>
                    <h5 className="mt-1">Chat Door {id}</h5>
                </div>
                <div className={`bg-container mt-4 grow bg-neutral-200 flex justify-center relative`}>
                    {doc && doc.status.active && <>
                        <div className="chatlobby-container self-center text-center flex flex-col gap-2">
                            {user && <>
                                {!requestDenied && <>
                                    {!pending && !returnPending && !requestPending && <button onClick={handleJoinChat} className="btn self-center relative">{buttonText}{requestWaiting && <p className="btn-wait-text">waiting for host...</p>}</button>}
                                    {(pending || returnPending || requestPending) && <button className="btn self-center flex justify-center">
                                        <LoadingIcon size="small" addClass="self-center" />
                                    </button>}
                                </>} 
                                {requestDenied && <button className="btn self-center relative" style={{ background: 'var(--gray) '}}>unable to join 😓{user.isAnonymous && <p className="btn-wait-text">logging out shortly...</p>}</button>}
                            </>}
                            <div className="self-center mt-4">
                                <Canvas
                                    text={location}
                                    options={{
                                        color: {
                                            dark: '#0f172a'
                                        }
                                    }}
                                />
                            </div>
                            <p className="mt-2 font-bold">Session</p>
                            <h4 className="session-display notranslate">{id}</h4>
                            <LinkShare url={location} />
                        </div>
                        <HelpBubble text={`Join this session by entering the session ID in the chat page or by navigating to ${location} in the browser via QR code, text or email.`} button={true} />
                    </>}
                    {doc && !doc.status.active && (
                        <div className="flex flex-col items-center my-auto" onClick={handleExitChat}>
                            <img className={`${penguin} h-20 w-20 mx-auto`} src={PenguinImg} alt="Penguin" />
                            <i className="p-emphasize mt-2">Chat has Ended</i>
                        </div>
                    )}
                </div>
                {!user && <JoinChatForm guests={guests} gdpr={gdpr} returnFxn={returnFxn} />}
            </div>
        </>
    )
}
