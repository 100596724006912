import './ClickableIcon.css';
import AddIcon from '../assets/add_icon.svg';
import ArrowBackIcon from '../assets/arrow_back_icon.svg';
import ArrowDownIcon from '../assets/arrow_down_icon.svg';
import ArrowRightIcon from '../assets/arrow_right_icon.svg';
import ArrowUpIcon from '../assets/arrow_up_icon.svg';
import AvatarIcon from '../assets/avatar_icon.svg';
import BellIcon from '../assets/bell_icon.svg';
import BoxIcon from '../assets/box_icon.svg';
import CalendarIcon from '../assets/calendar_icon.svg';
import CampaignIcon from '../assets/campaign_icon.svg';
import CancelIcon from '../assets/cancel_icon.svg';
import ChatIcon from '../assets/chat_icon.svg';
import CheckIcon from '../assets/check_icon.svg';
import CloseIcon from '../assets/close_icon.svg';
import CollapseIcon from '../assets/collapse_icon.svg';
import CopyIcon from '../assets/copy_icon.svg';
import DeleteIcon from '../assets/delete_icon.svg';
import EditIcon from '../assets/edit_icon.svg';
import EmailIcon from '../assets/email_icon.svg';
import ExpandIcon from '../assets/expand_icon.svg';
import ForumIcon from '../assets/forum_icon.svg';
import GroupIcon from '../assets/group_icon.svg';
import GroupAddIcon from '../assets/group_add_icon.svg';
import HDRStrongIcon from '../assets/hdr_strong_icon.svg';
import HDRWeakIcon from '../assets/hdr_weak_icon.svg';
import HearingIcon from '../assets/hearing_icon.svg';
import HistoryIcon from '../assets/history_icon.svg';
import HomeIcon from '../assets/home_icon.svg';
import ImageIcon from '../assets/image_icon.svg';
import InfoIcon from '../assets/info_icon.svg';
import LimitIcon from '../assets/person_chat_icon.svg';
import LockIcon from '../assets/lock_icon.svg';
import LogoutIcon from '../assets/logout_icon.svg';
import MenuIcon from '../assets/menu_icon.svg';
import MessageIcon from '../assets/message_icon.svg';
import MicIcon from '../assets/mic_icon.svg';
import NewTabIcon from '../assets/new_tab_icon.svg';
import PersonAddIcon from '../assets/person_add_icon.svg';
import QRCodeIcon from '../assets/qrcode_icon.svg';
import QuestionIcon from '../assets/question_icon.svg';
import ReceiveAudioIcon from '../assets/receive_audio_icon.svg';
import ReturnIcon from '../assets/return_icon.svg';
import SaveIcon from '../assets/save_icon.svg';
import SenderAudioIcon from '../assets/sender_audio_icon.svg';
import SettingsIcon from '../assets/settings_icon.svg';
import SMSIcon from '../assets/sms_icon.svg';
import SpeakerIcon from '../assets/speaker_icon.svg';
import SupervisedIcon from '../assets/supervised_icon.svg';
import SwapIcon from '../assets/swap_icon.svg';
import TranslateIcon from '../assets/translate_icon.svg';
import TrashIcon from '../assets/trash_icon.svg';
import TreeIcon from '../assets/tree_icon.svg';
import TipsIcon from '../assets/tips_icon.svg';
import TuneIcon from '../assets/tune_icon.svg';
import WidgetsIcon from '../assets/widgets_icon.svg';

import LoadingIcon from './LoadingIcon';

export default function ClickableIcon({ type, size, tipText, tipDirection, addClass, addImgClass }) {
    let icon = type;
    switch (type) {
        case 'add':
            icon = AddIcon;
            break;
        case 'arrow_back':
            icon = ArrowBackIcon;
            break;
        case 'arrow_down':
            icon = ArrowDownIcon;
            break;
        case 'arrow_right':
            icon = ArrowRightIcon;
            break;
        case 'arrow_up':
            icon = ArrowUpIcon;
            break;
        case 'avatar':
            icon = AvatarIcon;
            break;
        case 'bell':
            icon = BellIcon;
            break;
        case 'box':
            icon = BoxIcon;
            break;
        case 'calendar':
            icon = CalendarIcon;
            break;
        case 'campaign':
            icon = CampaignIcon;
            break;
        case 'cancel':
            icon = CancelIcon;
            break;
        case 'chat':
            icon = ChatIcon;
            break;
        case 'check':
            icon = CheckIcon;
            break;
        case 'close':
            icon = CloseIcon;
            break;
        case 'collapse':
            icon = CollapseIcon;
            break;
        case 'copy':
            icon = CopyIcon;
            break;
        case 'delete':
            icon = DeleteIcon;
            break;
        case 'edit':
            icon = EditIcon;
            break;
        case 'email':
            icon = EmailIcon;
            break;
        case 'expand':
            icon = ExpandIcon;
            break;
        case 'forum':
            icon = ForumIcon;
            break;
        case 'group':
            icon = GroupIcon;
            break;
        case 'group_add':
            icon = GroupAddIcon;
            break;
        case 'hdr_strong':
            icon = HDRStrongIcon;
            break;
        case 'hdr_weak':
            icon = HDRWeakIcon;
            break;
        case 'hearing':
            icon = HearingIcon;
            break;
        case 'history':
            icon = HistoryIcon;
            break;
        case 'home':
            icon = HomeIcon;
            break;
        case 'image':
            icon = ImageIcon;
            break;
        case 'info':
            icon = InfoIcon;
            break;
        case 'limit':
            icon = LimitIcon;
            break;
        case 'lock':
            icon = LockIcon;
            break;
        case 'logout':
            icon = LogoutIcon;
            break;
        case 'menu':
            icon = MenuIcon;
            break;
        case 'message':
            icon = MessageIcon;
            break;
        case 'mic':
            icon = MicIcon;
            break;
        case 'new_tab':
            icon = NewTabIcon;
            break;
        case 'personAdd':
            icon = PersonAddIcon;
            break;
        case 'qrcode':
            icon = QRCodeIcon;
            break;
        case 'receiveAudio':
            icon = ReceiveAudioIcon;
            break;
        case 'return':
            icon = ReturnIcon;
            break;
        case 'question':
            icon = QuestionIcon;
            break;
        case 'save':
            icon = SaveIcon;
            break;
        case 'senderAudio':
            icon = SenderAudioIcon;
            break;
        case 'settings':
            icon = SettingsIcon;
            break;
        case 'sms':
            icon = SMSIcon;
            break;
        case 'speaker':
            icon = SpeakerIcon;
            break;
        case 'supervised':
            icon = SupervisedIcon;
            break;
        case 'swap':
            icon = SwapIcon;
            break;
        case 'translate':
            icon = TranslateIcon;
            break;
        case 'trash':
            icon = TrashIcon;
            break;
        case 'tree':
            icon = TreeIcon;
            break;
        case 'tips':
            icon = TipsIcon;
            break;
        case 'tune':
            icon = TuneIcon;
            break;
        case 'widgets':
            icon = WidgetsIcon;
            break;
        default:
            icon = null;
            break;
    }

    let classes = 'pin ' + addClass || '';

    if (type === 'loading') {
        return (
            <div className={classes + ' background'}>
                <LoadingIcon size={size} />
            </div>
        )
    } else {
        return (
            <div className={classes}>
                <img className={addImgClass || ''} src={icon} alt={type + ' icon button'} />
                {tipText && <div className={`tooltip ${tipDirection}`}>{tipText}</div>}
            </div>
        )
    }
}