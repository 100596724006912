import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { randomIDGenerator } from '../../../tools/tools';

import { langSrc } from '../../../tools/languageSource';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';

export default function PhraseForm({ data, returnFxn, method, id }) {
    const { user } = useAuthContext();
    const [title, setTitle] = useState('');
    const [phrase, setPhrase] = useState('');
    const [questionPhrase, setQuestionPhrase] = useState('');
    const [limit, setLimit] = useState(false);
    const { updateDocument, response } = useFirestore('users');
    const [question, setQuestion] = useState(false);
    const [drop, setDrop] = useState(false);
    
    let formattedLang = user.lang;
    langSrc.forEach(item => {
        if (item.code === user.lang) formattedLang = item.name;
    });

    useEffect(() => {
        if (method === 'edit') {
            setTitle(data.phrases[id].title || '');
            setPhrase(data.phrases[id].text || '');
            setQuestionPhrase(data.phrases[id].altText || '');
            setLimit(data.phrases[id].limit || false);
            setQuestion(data.phrases[id].altText ? true : false);
            setDrop(data.phrases[id].drop || false);
        }
    }, [method, id, data.phrases]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (response.isPending || response.success || response.error) {
            return;
        }

        let randomID = id ? id : randomIDGenerator(16);
        if (!id && data.phrases) {
            while (Object.keys(data.phrases).indexOf(randomID) !== -1) {
                randomID = randomIDGenerator(16);
            }
        }
        
        let save = { phrases: data.phrases || {}, phraseSequence: data.phraseSequence || [] };
        save.phrases[randomID] = {
            id: randomID,
            title,
            text: phrase,
            altText: questionPhrase,
            drop,
            limit,
            lang: user.lang,
            createdAt: new Date().getTime()
        }
        if(!id) save.phraseSequence.push(randomID);
        
        await updateDocument(user.uid, save);
    }

    useEffect(() => {
        if (response.success) {
            setTimeout(returnFxn('list'), 1000);
        }
    }, [response.success, returnFxn]);

    return (
        <div className="tree-form-wrapper">
            <button className="cancel-tree-form btn-circle small" onClick={() => returnFxn('list')}>
                <ClickableIcon type="close" addClass="no-background mini mx-auto invert" />
            </button>
            <p className="p-small self-end">Your language is set to <span className="p-emphasize">{formattedLang}</span></p>
            <form onSubmit={handleSubmit} className="tree-form flex flex-col mt-2">
                <label className="flex gap-2">
                    <span className="p-emphasize self-center form-inline-label">Title:</span>
                    <input
                        required
                        className="grow"
                        type="text"
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                    />
                </label>
                <p className="p-small mt-1">Keep titles short so that you can spot it easier. Don't forget you can use emojis!</p>
                <label className="flex gap-2 mt-4">
                    <span className="p-emphasize self-center form-inline-label">Phrase:</span>
                    <input
                        required
                        className="grow"
                        type="text"
                        onChange={e => setPhrase(e.target.value)}
                        value={phrase}
                    />
                </label>
                <p className="mt-1 p-small">The actual message you want to translate and send over to others.</p>
                {question && (
                    <>
                    <label className="flex gap-2 mt-4">
                        <span className="p-emphasize self-center">Question Phrase:</span>
                        <input
                            className="grow"
                            type="text"
                            onChange={e => setQuestionPhrase(e.target.value)}
                            value={questionPhrase}
                        />
                    </label>
                    <p className="mt-1 p-small">This phrase in question format.</p>
                    </>
                )}
                <label className="flex justify-between mt-4">
                    <span className="self-center p-emphasize form-inline-label">❓ toggle:</span>
                    <div className="switch mr-4">
                        <input
                            type="checkbox"
                            onChange={e => setQuestion(e.target.checked)}
                            checked={question}
                        />
                        <span className="slider"></span>
                    </div>
                </label>
                <p className="mt-1 p-small">Rephrase in question form.</p>
                {/* <label className="flex justify-between mt-4">
                    <span className="self-center p-emphasize">Drop:</span>
                    <div className="switch mr-4">
                        <input
                            type="checkbox"
                            onChange={e => setDrop(e.target.checked)}
                            checked={drop}
                        />
                        <span className="slider"></span>
                    </div>
                </label>
                <p className="mt-1 p-small">Drop this phrase to the bottom after use.</p> */}
                <label className="flex gap-2 mt-4">
                    <span className="self-center p-emphasize">Limit response:</span>
                    <select 
                        className="grow"
                        onChange={e => setLimit(e.target.value)}
                        value={limit}
                    >
                        <option value={false}>No</option>
                        {data.limits && Object.values(data.limits).map(entry => (
                            <option key={entry.id} value={entry.id}>{entry.title}</option>
                        ))}
                    </select>
                </label>
                <p className="mt-1 p-small">Limit how the recipient can respond.</p>
                {!response.isPending && !response.success && !response.error && <button className="submit-tree-form btn-circle mt-2 self-center">
                    <ClickableIcon type="check" addClass="no-background small mx-auto invert" />
                </button>}
                {!response.isPending && response.success && !response.error && <button className="submit-tree-form btn-circle mt-2 self-center" disabled>
                    <ClickableIcon type="check" addClass="no-background small mx-auto invert" />
                </button>}
                {response.isPending && <button className="submit-tree-form btn-circle mt-2 self-center" disabled>
                    <LoadingIcon size="small" />
                </button>}
                {!response.isPending && response.error && <p className="submit-tree-form-error mt-2 error">{response.error}</p>}
            </form>
        </div>
    )
}