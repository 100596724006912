import { useEffect, useState } from 'react'
import { updateProfile, updateEmail  } from 'firebase/auth'
import { useAuthContext } from '../useAuthContext'
import { auth, storage } from '../../firebase/config'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { useFirestore } from './useFirestore'
import { updateUILang } from '../../tools/tools'

export const useUpdateUser = () => {
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { user } = useAuthContext();
    const { updateDocument } = useFirestore('users');
    
    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const updateUser = async (email, displayName, photo, lang, role, gender, organization, license, newsLetter, translateUI, { emailChange, nameChange, photoChange, docChange }) => {
        setError(null);
        setPending(true);
        
        if (nameChange || photoChange) docChange = true;
        const userChange = nameChange || photoChange ? true : false;

        try {
            // upload file to storage
            let imgURL = '';
            if (photo && photoChange) {
                const storageRef = ref(storage, `profiles/${user.uid}.jpg`);
                const img = await uploadBytesResumable(storageRef, photo);
                imgURL = await getDownloadURL(img.ref);
            }
            let data = { displayName };
            if (photo && photoChange) data = { displayName, photoURL: imgURL };
            
            // update auth
            if (userChange) await updateProfile(auth.currentUser, data);
            if (emailChange) await updateEmail(auth.currentUser, email);
            
            // update firestore doc
            let docData = { displayName, lang, role, gender, organization, license, newsLetter, translateUI };
            if (photo && photoChange) {
                docData = { ...docData, photoURL: imgURL };
            }
            if (docChange) await updateDocument(user.uid, docData);
            
            //update UI language
            if (translateUI) updateUILang(lang);
            
            reset();
        } catch(err) {
            console.log(err.message);
            reset(err);
        }
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, updateUser };
}