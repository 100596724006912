
export function randomIDGenerator(length) {
    const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let random = "";
    for (let i = 0; i < length; i++) {
        random += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }
    return random;
}

export function updateUILang(lang) {
    const translateHolder = document.querySelector('.googleTranslateElement');
    if (!translateHolder) return;
    const translateOptions = translateHolder.querySelector('select');
    if (!translateOptions) return;

    let langExists = false;
    if (translateOptions) {
    translateOptions.querySelectorAll('option').forEach(el => {
            if (el.value === lang) {
            langExists = true;
            }
    })};

    if (langExists) {
        const event = new Event('change');
        translateOptions.value = lang;
        translateOptions.dispatchEvent(event);
    }
}

export function formatRoleName(roleStr) {
    if (roleStr === 'general' || roleStr === 'provider' || roleStr === 'patient' || roleStr === 'other') return 'member';
    if (roleStr === 'interpreter') return roleStr;
}

export const usernameAllowed = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '_'];
export const appVersion = '1.0.2';
export const privacyPolicyVersion = '1.1';
export const privacyPolicyDate = 'Mar 26, 2023';
export const termsAndConditionsVersion = '1.1';
export const termsAndConditionsDate = 'Mar 26, 2023';
export const appName = 'PeelChat';
