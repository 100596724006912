import { useState, useRef } from 'react'

import SpeakerPhraseTree from './SpeakerPhraseTree'
import SpeakerInstructTools from './SpeakerInstructTools'
import TreeImg from '../../../assets/tree.png'
import SpeakingHeadImg from '../../../assets/speaking-head.png'

export default function SpeakerChatTools({ data, messageFxn, lang, speakerStatus, instructReturnFxn }) {
    const [phrase, setPhrase] = useState(false);
    const [tools, setTools] = useState(false);
    const [selected, setSelected] = useState(false);
    const connect1 = useRef();
    const connect3 = useRef();
    const wrapper = useRef();
    
    const handleCategoryClick = (cat) => {
        if (cat === 'phrase') {
            setTools(false);
            if (phrase) {
                setPhrase(false);
                setSelected(false);
                
                setConnectors(null);
            } else {
                setPhrase(true);
                setSelected(true);
                
                setConnectors(1);
            }
            return;
        }
        if (cat === 'instruct') {
            setPhrase(false);
            if (tools) {
                setTools(false);
                setSelected(false);
                
                setConnectors(null);
            } else {
                setTools(true);
                setSelected(true);
                
                setConnectors(3);
            }
            return;
        }
    }

    const setConnectors = (activate) => {
        connect1.current.classList.remove('active');
        connect3.current.classList.remove('active');
        if (activate === 1) connect1.current.classList.add('active');
        if (activate === 3) connect3.current.classList.add('active');
    }

    return (
        <>
            <div className={`tools-category speaker-tools ${selected? 'shrink' : ''}`}>
                <div className={`tools-category-option ${phrase ? 'active' : ''}`} onClick={() => handleCategoryClick('phrase')}>
                    <img src={TreeImg} alt="Tree" />
                </div>
                <div className={`tools-category-option ${tools ? 'active' : ''}`} onClick={() => handleCategoryClick('instruct')}>
                    <img src={SpeakingHeadImg} alt="Talking Person" />
                </div>
            </div>
            <div className={`tools-connect speaker-tools`}>
                <div className="tools-connect-unit" ref={connect1}></div>
                <div className="tools-connect-unit" ref={connect3}></div>
            </div>
            <div className={`tool-box-wrapper speaker-tools ${selected ? 'show' : ''}`} ref={wrapper}>
                {phrase && <SpeakerPhraseTree data={data} wrapper={wrapper.current} messageFxn={messageFxn} />}
                {tools && <div className={`tool-box-holder speaker-tools`}>
                    <h5>Controls</h5>
                    <hr className="mt-2 opacity-30"></hr>
                    <SpeakerInstructTools returnFxn={instructReturnFxn} langStatus={lang} speakerStatus={speakerStatus} />
                </div>}
            </div>
        </>
    )
}