
import { useEffect, useState, useRef } from 'react';
import { useQRCode } from 'next-qrcode';
import { useParams, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useChatContext } from '../../hooks/useChatContext';
import { useAppContext } from '../../hooks/useAppContext';
import { useFirestore } from '../../hooks/firebase/useFirestore'
import { useDocListener } from '../../hooks/firebase/useDocListener';
import { useExitChat } from '../../hooks/useExitChat';
import { useEndChat } from '../../hooks/useEndChat';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../hooks/firebase/useLogout';
import { functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';

import './ChatLobby.css';

import HelpBubble from '../../components/HelpBubble';
import LinkShare from './components/LinkShare';
import UserList from '../../components/UserList';
import ClickableIcon from '../../components/ClickableIcon';

import CatImg from '../../assets/cat.png';
import PenguinImg from '../../assets/penguin.png';
import LoadingIcon from '../../components/LoadingIcon';
import RequestModal from '../../components/RequestModal';

export default function ChatLobby({ returnFxn }) {
    const publicChatFxn = httpsCallable(functions, 'handlePublicChat');
    const { Canvas } = useQRCode();
    const { user } = useAuthContext();
    const { session, owner, self, active, dispatch, users, pause } = useChatContext();
    const { id } = useParams();
    const { updateDocument } = useFirestore('sessions');
    const { updateDocument: updateUserDocument } = useFirestore('users');
    const { updateDocument: updateSnapDocument } = useFirestore('sessionSnaps');
    const { document: doc, pending, exist, error } = useDocListener('sessions', id, true, 'chatlobby');
    const { exitChat, pending: exitChatPending } = useExitChat();
    const { endChat, pending: endChatPending } = useEndChat();
    const { logout, pending: logoutPending } = useLogout();
    let location = window.location.href;
    location = location.replace('chatlobby', 'chatdoor');
    const navigate = useNavigate();
    const locHook = useLocation();
    const [source, setSource] = useState(null);
    const [isOwner, setIsOwner] = useState(false);
    const [animation, setAnimation] = useState('text-xl self-center');
    const [penguin, setPenguin] = useState('just-showing');
    const [closeAlert, setCloseAlert] = useState(false);
    const { chatting, dispatch: appDispatch } = useAppContext();
    const [loading, setLoading] = useState(true);
    const memoAppDispatch = useRef(appDispatch);
    const memoDispatch = useRef(dispatch);
    const memoLogout = useRef(logout);
    const [pauseAction, setPauseAction] = useState(false);
    const [tuneScreen, setTuneScreen] = useState(false);
    const [noButtonChange, setNoButtonChange] = useState(false);

    // set waiting for user animation
    setTimeout(() => {
        if (user && self && !isOwner) setAnimation('animate-walk-left-long text-xl self-center')
    }, 5000);
    if (doc && !doc.status.active) {
        setTimeout(() => setPenguin('animate-hop just-showing'), 5000);
    }
    
    // redirect to chatdoor if guest or if user has not joined
    useEffect(() => {
        if (!user) return navigate(`/chatdoor/${id}`);
        if (error) {
            return navigate(`/chatdoor/${id}`, { state: { source: 'chatlobby', event: 'error' }});
        }
        if (doc && !doc.info[user.uid]) {
            return navigate(`/chatdoor/${id}`);
        }
        if (doc?.info[user.uid] && !doc.info[user.uid].active) {
            if (!pauseAction && 
                !exitChatPending &&
                !logoutPending &&
                !pause) {
                    return navigate(`/chatdoor/${id}`, { state: { source: 'chatlobby', event: 'exit', uid: user.uid }});
                }
        }
        if (doc?.info[user.uid] && doc.info[user.uid].active) {
            memoDispatch.current({ type: 'UPDATE', payload: { active: true }});
        }
    }, [user, doc, error, memoDispatch]);

    // geting where user came from
    useEffect(() => {
        if (!locHook.state) return;
        if (locHook.state.source) setSource(locHook.state.source);
    }, [locHook.state]);

    // setting owner state
    useEffect(() => {
        if (owner && self) if (owner === self) setIsOwner(true);
    }, [owner, self]);

    // remove user if banned
    const [banNotice, setBanNotice] = useState(false);
    useEffect(() => {
        if (!self || !users) return;
        if (!users[self]) return;
        if (users[self].ban) {
            setPauseAction(true);
            setBanNotice(true);
            setTimeout(() => {
                memoAppDispatch.current({ type: 'END_CHAT', source: 'chatroom_timer' });
                memoDispatch.current({ type: 'EXIT', source: 'chatroom_timer' });
                navigate(`/chat`);
            }, 5000);
        }
    }, [users, self, memoAppDispatch, memoDispatch]);

    // log out Guest users when session ends 
    useEffect(() => {
        if (doc && user && user.isAnonymous) {
            if (!doc.status.active) {
                setTimeout(() => {
                    memoLogout.current(true);
                }, 20000);
            };
        }
    }, [doc, user, memoLogout]);

    // settings up CHAT CONTEXT if no prior or conflicts 
    useEffect(() => {
        if (pending) return;
        if (!session || (session && !self) || (session !== id)) {
            if (!exist) return navigate(`/chat`);
            if (!doc) return;
            if (!doc.status.active) return;
            if (!loading) return;
            let payload = { session: id };
            Object.values(doc.info).forEach(async item => {
                if (user && item.tempID === user.uid) {
                    payload.self = item.tempID;
                    payload.active = item.active;
                }
                if (item.owner) payload.owner = item.tempID;
            });
            payload.users = doc.info;
            payload.guests = doc.status.allowGuests || false;
            payload.invites = doc.invites || [];
            memoDispatch.current({ type: 'CHAT_LOBBY', payload });
            setLoading(false);
        } else {
            setLoading(false);
        }
        
    }, [pending, session, self, user, id, doc, memoDispatch, exist]);

    // setting up reactions to chatroom start and end
    useEffect(() => {
        if (pending || loading) return;
        if (session) {
            if (!doc || !doc.status.active) {

                memoAppDispatch.current({ type: 'END_CHAT', source: 'auto_chatlobby'});
                memoDispatch.current({ type: 'EXIT', source: 'auto_chatlobby' });
            } else if (doc && doc.status.chatOpen) {
                if (self && doc.info[self].active) {
                    memoAppDispatch.current({ type: 'CHATTING'});
                }
            }
        }
    }, [session, loading, pending, self, doc, memoAppDispatch, memoDispatch]);

    // detect requests
    const [requests, setRequests] = useState(false);
    const [requestData, setRequestData] = useState({});
    useEffect(() => {
        if (!doc || !isOwner) return;
        if (!doc.status.publicChat) {
            if (!doc.requests) return;
        
            let request = false;
            let requestInfo = false;
            Object.values(doc.requests).forEach(entry => {
                if (entry.status === 'pending') {
                    request = true;
                    requestInfo = entry;
                }
            });
            if (request) {
                setRequests(true);
                setRequestData(requestInfo);
            } else {
                setRequests(false);
                setRequestData({});
            }
        }
    }, [doc, isOwner]);
    
    const handleStartChat = async (source) => {
        if (source === 'init') {
            setNoButtonChange(true);
            returnFxn({ method: 'startChat' });
        }
        await updateDocument(id, { status: { ...doc.status, chatOpen: true }});
        navigate(`/chatroom/${id}`);
    }
    const handleExitChat = async () => {
        if (session) await exitChat(user.isAnonymous);
        
        if (!user.isAnonymous) navigate('/');
        if (user.isAnonymous) logout();
    }
    const handleEndChat = async () => {
        await endChat(doc);

        navigate('/');
        setCloseAlert(false);
    }
    
    const [guests, setGuests] = useState(true);
    const [publicChat, setPublicChat] = useState(false);
    useEffect(() => {
        if (!doc) return;
        doc.status.allowGuests ? setGuests(true) : setGuests(false);
        doc.status.publicChat ? setPublicChat(true) : setPublicChat(false);
    }, [doc]);
    const handleGuestsCheckbox = (value) => {
        setGuests(value);
        let toSave = {
            status: {
                ...doc.status,
                allowGuests: value
            }
        }
        updateDocument(id, toSave);
        updateSnapDocument(id, toSave);
        dispatch({ type: 'UPDATE', payload: { guests: value }});
    }
    const handlePublicChatCheckbox = (value) => {
        setPublicChat(value);

        publicChatFxn({
            session,
            publicChat: value
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
        {exist && exist !== 'pending' && <div className={`ml-2 p-5 pt-4 flex flex-col w-full`} style={{transition : '0.2s'}}>
            <div className={`relative`}>
                <h5 className="mt-1">Chat Lobby</h5>
                {chatting && <div className={`chatlobby-close-holder`} onClick={() => setCloseAlert(true)}>
                    <ClickableIcon type="close" addClass="large no-background" />
                </div>}
            </div>
            <div className={`bg-container mt-4 grow bg-neutral-200 flex justify-center relative`}>
                <>
                    {doc && doc.status.active && (
                        <div className="chatlobby-container self-center text-center flex flex-col gap-2">
                            {!chatting && (
                                <>
                                    {user && self && isOwner && <button onClick={() => handleStartChat('init')} className="btn self-center">start</button>}
                                    {user && self && active && !isOwner && (
                                        <>
                                            <div className="flex justify-center">
                                                <p className="self-center font-bold">waiting for chat to start...</p>
                                                <img className={`self-center h-5 w-5 ${animation}`} src={CatImg} alt="Walking Cat" />
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {chatting && noButtonChange && <button className="btn self-center">
                                <LoadingIcon size="small" addClass="mx-auto self-center" />
                            </button>}
                            {source !== 'chatroom' && chatting && user && self && !noButtonChange && <button onClick={() => handleStartChat()} className="btn self-center special-btn">Go Chat!</button>}
                            {source === 'chatroom' && chatting && user && self && (
                                <button onClick={() => handleStartChat()} className="btn self-center">Return to Chat</button>
                            )}
                            <div className="self-center mt-4">
                                <Canvas
                                    text={location}
                                    options={{
                                        color: {
                                            dark: '#0f172a'
                                        }
                                    }}
                                />
                            </div>
                            <p className="mt-2 font-bold">Session</p>
                            <h4 className="session-display notranslate">{id}</h4>
                            {session && doc && doc.status.active && <div className="flex justify-center mt-2">
                                {<UserList />}
                            </div>}
                            <LinkShare url={location} />
                            {/* {isOwner && <label className="absolute bottom-1 right-2 flex justify-end mt-4">
                                <div className="flex flex-col items-end">
                                    <div className="switch">
                                        <input
                                            type="checkbox"
                                            onChange={e => handleGuestsCheckbox(e.target.checked)}
                                            checked={guests}
                                        />
                                        <span className="slider"></span>
                                    </div>
                                    <span className="mt-1 self-center p-emphasize text-end">{guests ? 'guests allowed' : 'no guests'}</span>
                                </div>
                            </label>} */}
                            {isOwner && <div className="absolute bottom-2 right-2" onClick={() => {
                                tuneScreen ? setTuneScreen(false) : setTuneScreen(true);
                            }}>
                                <ClickableIcon type="tune" addClass="large no-background no-hover" />
                            </div>}
                            {user && self && !chatting && (
                                <div onClick={() => setCloseAlert(true)} className="close-btn absolute top-0 right-0">
                                    <ClickableIcon 
                                        type="close"
                                        addClass="no-background large"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {doc && !doc.status.active && (
                        <>
                            <div className="flex flex-col items-center my-auto" onClick={handleExitChat}>
                                <img className={`${penguin} h-20 w-20 mx-auto`} src={PenguinImg} alt="Penguin" />
                                <i className="p-emphasize mt-2">Chat has Ended</i>
                            </div>
                            {user && user.isAnonymous && <p className="absolute bottom-2 p-emphasize">You will be logged out shortly.</p>}
                        </>
                    )}
                    {doc && doc.status.active && (
                        <HelpBubble text={`Join this session by entering the session ID in the chat page or by navigating to ${location} in the browser via QR code, text or email.`} button={true} />
                    )}
                </>
            </div>
            {closeAlert && <div className="screen">
                <div className="center-modal relative">
                    {isOwner && (
                        <>
                            <h4>End Chat?</h4>
                            <p className="mt-2">Exit and end chat. This will end the session for everyone because you are the owner.</p>
                            {/* <p className="mt-2 p-small">All messages and identifying user information will be deleted. Only a summary of this session will be saved in the database.</p> */}
                            {!endChatPending && <div className="text-center">
                                <button className="mx-auto mt-8" onClick={handleEndChat}>end</button>
                            </div>}
                            {endChatPending && <div className="text-center">
                                <button className="mx-auto mt-4 flex justify-center">
                                    <LoadingIcon size="small" addClass="self-center" />
                                </button>
                            </div>}
                            <div className="absolute top-0 right-0" onClick={() => setCloseAlert(false)}>
                                <ClickableIcon
                                    type="close"
                                    addClass="no-background"
                                />
                            </div>
                        </>
                    )}
                    {!isOwner && (
                        <>
                        <h4>Exit Chat?</h4>
                        <p className="mt-2">Keep the session ID if you want to return later!</p>
                        {!exitChatPending && <div className="text-center">
                            <button className="mx-auto mt-4" onClick={handleExitChat}>exit</button>
                        </div>}
                        {exitChatPending && <div className="text-center">
                            <button className="mx-auto mt-4 flex justify-center">
                                <LoadingIcon size="small" addClass="self-center" />
                            </button>
                        </div>}
                        <div className="absolute top-0 right-0" onClick={() => setCloseAlert(false)}>
                            <ClickableIcon
                                type="close"
                                addClass="no-background"
                            />
                        </div>
                    </>
                    )}
                </div>
            </div>}
            {tuneScreen && <div className="screen" onClick={(e) => {
                if (e.target.closest('.center-modal')) return;
                setTuneScreen(false);
            }}>
                <div className="center-modal">
                    <div className="center-modal-close-btn" onClick={() => setTuneScreen(false)}>
                        <ClickableIcon type="close" addClass="invert" />
                    </div>
                    <h4>Chat Settings</h4>
                    <label>
                        <div className="flex justify-between gap-4 mt-6">
                            <span className="mt-1 self-center p-emphasize text-end">Allow Guests</span>
                            <div className="switch">
                                <input
                                    type="checkbox"
                                    onChange={e => handleGuestsCheckbox(e.target.checked)}
                                    checked={guests}
                                />
                                <span className="slider"></span>
                            </div>
                        </div>
                    </label>
                    {guests && <p className="mt-2">Anonymous guests are allowed to join.</p>}
                    {!guests && <p className="mt-2">Anonymous guests are not allowed to join.</p>}
                    <label>
                        <div className="flex justify-between gap-4 mt-6">
                            <span className="mt-1 self-center p-emphasize text-end">Public</span>
                            <div className="switch">
                                <input
                                    type="checkbox"
                                    onChange={e => handlePublicChatCheckbox(e.target.checked)}
                                    checked={publicChat}
                                />
                                <span className="slider"></span>
                            </div>
                        </div>
                    </label>
                    {publicChat && <p className="mt-2">Anyone with a link can join.</p>}
                    {!publicChat && <p className="mt-2">Host approval needed for everyone joining this chat.</p>}
                </div>
            </div>}
            {banNotice && <div className="screen">
                <div className="center-modal">
                    <h4>Sorry :(</h4>
                    <p className="mt-4">You have been removed from this chat. You will be redirected shortly.</p>
                </div>
            </div>}
            {requests && <RequestModal user={requestData} doc={doc}/>}
        </div>}
        </>
    )
}
