import { useState, useEffect, useRef } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useNavigate } from 'react-router-dom'
import { useFirestore } from '../../hooks/firebase/useFirestore'
import { randomIDGenerator } from '../../tools/tools'
import { serverTimestamp } from 'firebase/firestore'
import { useChatContext } from '../../hooks/useChatContext'
import { functions, analytics } from '../../firebase/config'
import { httpsCallable } from 'firebase/functions'
import { logEvent } from 'firebase/analytics'
import { useJoinChat } from '../../hooks/useJoinChat'
import { useRequestChat } from '../../hooks/useRequestChat'

import './Chat.css'

import UserCard from '../../components/UserCard'
import ChatroomList from '../../components/ChatroomList'
import LoadingIcon from '../../components/LoadingIcon'
import ClickableIcon from '../../components/ClickableIcon'
import EmailVerificationModal from '../../components/EmailVerificationModal'

export default function Dashboard() {
    const getUserSubscriptionStatusFxn = httpsCallable(functions, 'getUserSubscriptionStatus');
    const initChatFxn = httpsCallable(functions, 'initChat');
    const checkChatFxn = httpsCallable(functions, 'chatCheck');
    const navigate = useNavigate();
    const { session, self, active, dispatch } = useChatContext();
    const { user, dispatch: authDispatch } = useAuthContext();
    const [pending, setPending] = useState(true);
    const [join, setJoin] = useState(false);
    const [error, setError] = useState(null);
    const { addDocument, getCollection } = useFirestore('sessions');
    const { getDocument } = useFirestore('sessionSnaps');
    const { addDocument: addSnapshotDocument, checkDocumentExists } = useFirestore('sessionSnaps');
    const { updateDocument } = useFirestore('users');
    const inputSession = useRef();
    const memoNavigate = useRef(navigate);
    const memoGetCollection = useRef(getCollection);
    const [emailVeri, setEmailVeri] = useState(false);
    const [tierNotification, setTierNotification] = useState(false);
    const [startPending, setStartPending] = useState(false);
    const [info, setInfo] = useState(false);
    const { joinChat, pending: joinChatPending } = useJoinChat();
    const { requestChat, pending: requestChatPending } = useRequestChat();

    const [newChatScreen, setNewChatScreen] = useState(false);
    const [joinChatScreen, setJoinChatScreen] = useState(false);
    const [existChatrooms, setExistChatrooms] = useState(false);

    useEffect(() => {
        if (user.tier === 'free') setJoin(true);
    }, [user]);
    
    useEffect(() => {
        setPending(true);
        if (session && self && active) {
            memoNavigate.current(`/chatlobby/${session}`);
        } else {
            dispatch({ type: 'EXIT' });
        }
        setPending(false);
    }, [session, self, memoNavigate]);

    useEffect(() => {
        setPending(true);
        
        if (user.activeChats) {
            if (user.activeChats.length > 0) {
                memoNavigate.current(`/chatdoor/${user.activeChats[0]}`);
            }
        }

        setPending(false);
    }, [memoGetCollection, memoNavigate]);
    
    const toggleJoinChatForm = () => {
        if (join) {
            if (!user.emailVerified) {
                setEmailVeri(true);
                return;
            } else if (user.tier === 'free') {
                setTierNotification(true);
                return;
            } else {
                setJoin(false);
                return;
            }
        } else {
            setJoin(true);
        }
    }

    const initChat = async () => {
        if (!user) return;

        setStartPending(true);

        // user needs to verify email
        if (!user.emailVerified) {
            setEmailVeri(true);
            setStartPending(false);
            return;
        }

        // check user privileges 
        const subStatus = await getUserSubscriptionStatusFxn({ uid: user.uid });
        if (subStatus.data.tier !== 'trial' && subStatus.data.tier !== 'basic') {
            setTierNotification(true);
            setStartPending(false);
            return;
        }
        
        let sessionID = randomIDGenerator(6);
        let alreadyExists = await checkDocumentExists(sessionID);
        while (alreadyExists) {
            sessionID = randomIDGenerator(6);
            alreadyExists = await checkDocumentExists(sessionID);
        }

        const timestamp = new Date().getTime();
        const fsServerTimestamp = serverTimestamp();
        let activeChats = user.activeChats ? [...user.activeChats, sessionID] : [sessionID];

        const data = {
            user: {
                uid: user.uid,
                username: user.username || false,
                timestamp,
                serverTimestamp: fsServerTimestamp,
                displayName: user.displayName,
                photoURL: user.photoURL || '',
                lang: user.lang,
                role: user.role,
                gender: user.gender,
                organization: user.organization || '',
                licanse: user.license || '',
                activeChats
            },
            timestamp,
            serverTimestamp: fsServerTimestamp,
            allowGuests: true,
            publicChat: false,
            requestInterpreter: false,
            sessionID
        }
        
        try {
            const res = await initChatFxn(data);
            setStartPending(false);

            if (res.data.includes('not allowed')) {
                setTierNotification(true);
                return;
            }
            dispatch({ type: 'INIT', payload: {
                session: sessionID,
                owner: user.uid,
                self: user.uid,
                users: data.user,
                guests: true,
                active: true,
                invites: []
            }});
            authDispatch({ type: 'UPDATE', payload: {
                ...user, activeChats
            }, source: 'CHT#INTC' });

            logEvent(analytics, 'new_chat', {
                content_type: 'event',
                content_id: sessionID
            });
            
            navigate(`/chatlobby/${sessionID}`);
            return;
        } catch(err) {
            console.log(err);
            setStartPending(false);
            return;
        }
    }

    const joinChatHandler = async (e) => {
        e.preventDefault();
        const doc = await getDocument(inputSession.current.value);
        if (!doc) return setError('Session does not exist');
        if (!doc.status.active) return setError('Session has already ended');
        if (doc.info[user.uid] && doc.info[user.uid].ban) return setError('Unable to join this session.');
        if (doc.requests && doc.requests[user.uid] && doc.requests[user.uid].status === 'denied') return setError('Unable to join this session.');
        if (doc.status.active) {
            setStartPending(true);
            navigate(`/chatdoor/${inputSession.current.value}`);
        }
    }

    const closeScreen = (e) => {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON') return;
        setNewChatScreen(false);
        setJoinChatScreen(false);
        setError(null);
    }

    return (<>
        {!pending && existChatrooms && (
            <div className="ml-2 p-5 pt-4 flex flex-col w-full">
                <h5 className="mt-1">Chat</h5>
                <div className="mt-4 grow bg-neutral-200 flex flex-col content-center relative">
                    <div className="chatroom-list-holder flex flex-col justify-center items-center mt-6 grow hide-scroll mx-auto">
                        <div className="w-full">
                            <p className="p-title ml-2">Active Chatrooms</p>
                        </div>
                        <ChatroomList />
                    </div>
                    <div className="flex justify-center mt-auto gap-4 pb-4">
                        <button className="btn" onClick={() => {
                            setJoinChatScreen(false);
                            setNewChatScreen(true);
                        }}>new chat</button>
                        <button className="btn" onClick={() => {
                            setJoinChatScreen(true);
                            setNewChatScreen(false);
                        }}>join chat</button>
                    </div>
                </div>
                {newChatScreen && <div className="screen" onClick={e => closeScreen(e)}>
                    <div className="center-modal colored flex flex-col items-center">
                        <UserCard size="large" user={user} />
                        <div className="flex justify-center">
                            <button className="btn mt-6" onClick={initChat}>open new chat</button>
                        </div>
                        {error && <p className="error mt-4">{error}</p>}
                    </div>
                </div>}
                {joinChatScreen && <div className="screen" onClick={e => closeScreen(e)}>
                    <div className="center-modal colored flex flex-col items-center">
                        <UserCard size="large" user={user} />
                        <form className="mt-6 w-full flex flex-col items-center" onSubmit={joinChatHandler}>
                            <label>
                                <input 
                                    required 
                                    className="w-full" 
                                    ref={inputSession} 
                                    type="text" 
                                    placeholder="session ID" 
                                    onChange={() => setError(null)} 
                                />
                            </label>                   
                            <div className="flex justify-center">
                                <button className="btn mt-4">find chat</button>
                            </div>
                        </form>
                        {error && <p className="error mt-4">{error}</p>}
                    </div>
                </div>}
            </div>
        )}
        {!pending && !existChatrooms && (
            <div className="ml-2 p-5 pt-4 flex flex-col w-full">
                <h5 className="mt-1">Chat</h5>
                <div className="mt-4 grow bg-neutral-200 flex justify-center relative">
                    <div className="m-auto flex flex-col items-center text-center">
                        <UserCard size="large" user={user} profileBackground={true} />
                        {!join && <>
                            {!startPending && <button className="btn mt-4" onClick={initChat}>new chat</button>}
                            {startPending && <button className="btn mt-4">
                                <LoadingIcon size="small" addClass="mx-auto self-center" />
                            </button>}
                            <div className="mt-4" onClick={() => setInfo(true)}>
                                <ClickableIcon type="question" addClass="small mx-auto" />
                            </div>
                        </>}
                        {join && <>
                            <div className="mt-4 w-full">
                                <form className="flex flex-col items-center" onSubmit={joinChatHandler}>
                                    <label>
                                        <input required className="w-full" style={{width: '240px' }} ref={inputSession} type="text" placeholder="session ID" onChange={() => setError(null)}></input>
                                    </label>
                                    {!startPending && <button className="btn mt-4">find chat</button>}
                                    {startPending && <button className="btn mt-4">
                                        <LoadingIcon size="small" addClass="mx-auto self-center" />
                                    </button>}
                                </form>
                            </div>
                        </>}
                        {error && <p className="error mt-4">{error}</p>}
                    </div>
                    <div className="absolute left-0 bottom-4 w-full flex justify-center">
                        {!startPending && <button className="btn btn-border" onClick={toggleJoinChatForm}>{join ? 'start chat' : 'join chat'}</button>}
                        {startPending && <button className="btn btn-border">{join ? 'start chat' : 'join chat'}</button>}
                    </div>
                </div>
            </div>
        )}
        {pending && <div className="m-auto self-center flex flex-col items-center">
        </div>}
        <EmailVerificationModal returnFxn={(emailVeriStatus) => setEmailVeri(emailVeriStatus)} status={emailVeri} />
        {tierNotification && <div className="screen" onClick={(e) => {
            if (!e.target.closest('.center-modal')) setTierNotification(false);
        }}>
            <div className="center-modal">
                <div className="center-modal-close-btn" onClick={() => {
                    setTierNotification(false);
                }}>
                    <ClickableIcon type="close" addClass="no-hover invert mini" />
                </div>
                <h4 className="font-extrabold self-center">Subscribe!</h4>
                <p className="mt-2">Please sign up for our <a href="/subscriptions" className="font-semibold">Basic Plan</a> to host your own chats.</p>
                <div className="flex justify-center mt-6">
                    <button onClick={() => {
                        navigate('/subscriptions');
                    }}>go</button>
                </div>
            </div>
        </div>}
        {info && <div className="screen" onClick={e => {
            if (!e.target.closest('.center-modal')) setInfo(false);
        }}>
            <div className="center-modal">
                <div className="center-modal-close-btn" onClick={() => setInfo(false)}>
                    <ClickableIcon type="close" addClass="small no-hover invert" />
                </div>
                <h5>About Chats</h5>
                <ul className="mt-2 info">
                    <li><p>▪ Only one active chat is allowed at a time for now.</p></li>
                    <li><p>▪ Chats need to be actively closed before another chat can be initiated.</p></li>
                    <li><p>▪ Any messages or identifying information will be deleted from the database upon chat end.</p></li>
                    <li><p>▪ Idle chats for longer than 12hrs will be closed automatically.</p></li>
                    <li><p>▪ Chat session ID will be removed automatically after 24hrs once chat ends.</p></li>
                </ul>
            </div>
        </div>}
        </>
    )
}