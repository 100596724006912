import { useChatContext } from "./useChatContext";
import { useAuthContext } from "./useAuthContext";
import { useState, useEffect } from 'react';
import { functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { useFirestore } from "./firebase/useFirestore";

export const useEndChat = () => {
    const endChatFxn = httpsCallable(functions, 'endChat');
    const { user, dispatch } = useAuthContext();
    const { session } = useChatContext();
    const { updateDocument } = useFirestore('sessions');
    const { updateDocument: updateSnapDocument } = useFirestore('sessionSnaps');
    const { updateDocument: updateUserDocument } = useFirestore('users');
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [cancel, setCancel] = useState(false);

    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }
    
    const endChat = (data) => {
        return new Promise(async (resolve, reject) => {
            setPending(true);

            try {
                // await endChatFxn({ sessionID: session });
                // will need to update this when multiple chats are activated
                await updateUserDocument(user.uid, { activeChats: [] });
                const endTimestamp = new Date().getTime();
                await updateDocument(session, { status: { ...data.status, active: false, endTimestamp }});
                await updateSnapDocument(session, { status: { ...data.status, active: false, endTimestamp }});
                // dispatch({ type: 'UPDATE', payload: { ...user, activeChats: [] }});

                reset();
                resolve('End chat successful');
                return;
            } catch(err) {
                console.log(err);
                reset(err);
                reject(new Error('End chat failed'));
                return;
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, endChat };
}