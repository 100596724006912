import { useState, useEffect, useRef } from 'react';
import { useChatContext } from '../hooks/useChatContext';
import { useAppContext } from '../hooks/useAppContext';
import { useDocListener } from '../hooks/firebase/useDocListener';

import ProfilePicWithStatus from './ProfilePicWithStatus';
import UserCard from './UserCard';

import './UserList.css';

import CatImg from '../assets/cat.png';

export default function UserList({ type, sessionID }) {
    const [animation, setAnimation] = useState('text-xl self-center');
    const { session, owner, self, dispatch } = useChatContext();
    const querySession = sessionID ? sessionID : session;
    // const { data: document, dataError: error, dataPending: pending} = useAppContext();
    const { document, error, pending } = useDocListener('sessions', querySession, false, 'userList');
    const [showInfo, setShowInfo] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [scrollClass, setScrollClass] = useState('');
    const [scrollTop, setScrollTop] = useState(0);
    let scrolling = false;
    let isOwner = owner === self ? true : false;
    let isAlone = true;
    const [users, setUsers] = useState([]);
    const [holder, setHolder] = useState(null);
    const memoDispatch = useRef(dispatch);
        
    const handleScroll = () => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }

    useEffect(() => {
        if (!pending && document && document.status.active) {
            let doc = document || { info: {}};
            let sort = [];
            let sortPractioner = [];
            let sortGeneral = [];
            let sortPatient = [];
            let sortInterpreter = [];
            let sortOther = [];
            let sortGuests = [];
            const values = Object.values(doc.info);
            values.forEach(user => {
                if (user.role === 'provider' && sortPractioner.indexOf(user.tempID) === -1) sortPractioner.push(user.tempID);
            });
            values.forEach(user => {
                if (user.role === 'general' && sortGeneral.indexOf(user.tempID) === -1) sortPractioner.push(user.tempID);
            });
            values.forEach(user => {
                if (user.role === 'patient' && sortPatient.indexOf(user.tempID) === -1) sortPatient.push(user.tempID);
            });
            values.forEach(user => {
                if (user.role === 'interpreter' && sortInterpreter.indexOf(user.tempID) === -1) sortInterpreter.push(user.tempID);
            });
            values.forEach(user => {
                if (user.role === 'other' && sortOther.indexOf(user.tempID) === -1) sortOther.push(user.tempID);
            });
            values.forEach(user => {
                if (user.role === 'guest' && sortGuests.indexOf(user.tempID) === -1) sortGuests.push(user.tempID);
            });
            sortPractioner = sortPractioner.sort((a, b) => a.tempID < b.tempID);
            sortGeneral = sortGeneral.sort((a, b) => a.tempID < b.tempID);
            sortPatient = sortPatient.sort((a, b) => a.tempID < b.tempID);
            sortInterpreter = sortInterpreter.sort((a, b) => a.tempID < b.tempID);
            sortOther = sortOther.sort((a, b) => a.tempID < b.tempID);
            sortGuests = sortGuests.sort((a, b) => a.tempID < b.tempID);
            sortPractioner.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            sortGeneral.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            sortPatient.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            sortInterpreter.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            sortOther.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            sortGuests.forEach(r => {
                if (sort.indexOf(r) === -1) sort.push(r);
            });
            let result = sort.map(r => doc.info[r]);
            setUsers(result);
            memoDispatch.current({ type: 'UPDATE', payload: { users: doc.info, guests: doc.status.allowGuests, invites: doc.invites }});
        }
        if (document && !document.status.active) setUsers([]);
    }, [document, pending, memoDispatch]);

    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);
            
    users.forEach(user => {
        if (!user) return;
        if (user.tempID !== self) isAlone = false;
    });

    const handleClick = (user) => {
        setShowInfo(true);
        setUserInfo(user);
    }
    const closeInfo = (e) => {
        if (e.target.closest('.edit-element')) return;
        setShowInfo(false);
        setUserInfo(null);
    }
  
    setTimeout(() => setAnimation('animate-walk-left text-xl self-center'), 5000);

    if (type === "sidebar") {
        return (
            <>
                <ul className="nav-bar user-list absolute left-0 flex flex-col gap-4 justify-center z-40 user-list-wrapper">
                    {users && users.map(user => (
                        <li key={user.tempID} onClick={() => handleClick(user)}>
                            <ProfilePicWithStatus size={'micro'} status={user.active} text={user.role} url={user.photoURL} background={true} user={user} />
                        </li>
                    ))}
                </ul>
                {showInfo && (
                    <div className="screen" onClick={(e) => {
                            if (!e.target.closest('.card-add-friend-btn')) closeInfo(e);
                        }}>
                        <div className="center-modal transparent relative">
                            {userInfo && <div className="mt-8">
                                <UserCard user={userInfo} profileBackground={true} context="chat" document={document} />
                            </div>}
                        </div>
                    </div>
                )}
            </>
        )
    } else if (type === 'header') {
        return (
            <>
                <ul className="flex gap-2 user-list-wrapper hide-scroll">
                    {users && users.map(user => (
                        <li key={user.tempID} onClick={() => handleClick(user)}>
                            <ProfilePicWithStatus size={'micro'} status={user.active} text={user.role} url={user.photoURL} user={user} />
                        </li>
                    ))}
                </ul>
                {showInfo && (
                    <div className="screen" onClick={(e) => {
                        if (!e.target.closest('.card-add-friend-btn')) closeInfo(e);
                    }}>
                        <div className="center-modal transparent relative">
                            {userInfo && <div className="mt-8">
                                <UserCard user={userInfo} profileBackground={true} context="chat" document={document} />
                            </div>}
                        </div>
                    </div>
                )}
            </>
        )
    } else {
        return (
            <>
                {isOwner && isAlone && (
                    <div className="flex gap-1">
                        <p className="self-center">Waiting for others...</p>
                        <img className={`self-center h-5 w-5 ${animation}`} src={CatImg} alt="Walking Cat" />
                    </div>
                )}
                {!isAlone && !error && (
                    <>
                        <div className={`chat-user-list grid grid-cols-2 gap-2 hide-scroll ${scrollClass}`} onScroll={handleScroll} onLoad={(e) => {setHolder(e.target.closest('.chat-user-list'))}}>
                            {users && users.map(user => (
                                <div key={user.tempID} className="entry flex" onClick={() => handleClick(user)}>
                                    <div className="self-center">
                                        <ProfilePicWithStatus size={'micro'} status={user.active} text={user.role} url={user.photoURL} user={user} />
                                    </div>
                                    <div className="ml-2 flex flex-col justify-center items-start text-start relative">
                                        <p className="ml-1 notranslate user-list-name">{user.displayName}</p>  
                                        {/* <div className={`badge ${user.owner ? 'owner' : ''}`}>{user.role}</div> */}
                                        {user.owner && user.role !== 'interpreter' && <div className="badge owner">host</div>}
                                        {user.owner && user.role === 'interpreter'  && <div className="badge owner">interpreter</div>}
                                        {!user.owner && user.role === 'interpreter'  && <div className="badge">interpreter</div>}
                                        {!user.owner && user.role !== 'interpreter' && user.user === 'guest' && <div className="badge guest">guest</div>}
                                        {!user.owner && user.role !== 'interpreter' && user.user !== 'guest' && <div className="badge">member</div>}
                                    </div>
                                </div>
                            ))}
                        </div>  
                        {showInfo && (
                            <div className="screen" onClick={(e) => {
                                if (!e.target.closest('.card-add-friend-btn')) closeInfo(e);
                            }}>
                                <div className="center-modal transparent relative">
                                    {userInfo && <div className="mt-8">
                                        <UserCard user={userInfo} profileBackground={true} context="chat" document={document} />
                                    </div>}
                                </div>
                            </div>
                        )}
                    </>
                )}
                {!isAlone && error && <p className="error">{error}</p>}
            </>
        )
    }
}