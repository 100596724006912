import ClickableIcon from "./ClickableIcon";

export default function TranslateButton({ size, addClass}) {

    const check = () => {
        const translateHolder = document.querySelector('.googleTranslateElement');
        if (!translateHolder) return;
        const translateOptions = translateHolder.querySelector('select');
        if (!translateOptions) return;
        const banner = document.querySelector('iframe.skiptranslate');
        if (!banner) return;
        let bannerActive = false;
        if (!banner.parentElement.style.display) bannerActive = true;
        if (bannerActive && !translateHolder.querySelector('.btn')) {
            const endBtn = document.createElement('button');
            endBtn.classList.add('btn', 'mt-4', 'mx-auto');
            endBtn.style.width = '100%';
            endBtn.innerHTML = 'cancel';
            translateHolder.appendChild(endBtn);
            endBtn.addEventListener('click', () => {
            const googleClose = banner.contentWindow.document.querySelector('.goog-close-link');
            if (googleClose) googleClose.click();
            const googleCloseV2 = banner.contentWindow.document.querySelector('[title="Close"]');
            if (googleCloseV2) googleCloseV2.click();
            endBtn.remove();
            translateHolder.classList.remove('show');
            })
        } else if (!bannerActive) {
            if (translateHolder.querySelector('.btn')) {
                translateHolder.querySelector('.btn').remove();
            }
        }

        const floater = document.querySelector('#goog-gt-tt');
        if (floater) floater.style.display = 'none';
    }

    return (<div className="translate-button" onClick={() => {
            const el = document.querySelector('.googleTranslateElement');
            el.classList.contains('show') ? el.classList.remove('show') : el.classList.add('show');

            check();
        }}>
            <ClickableIcon type="translate" size={size} addClass={addClass} />
        </div>)
} 