import { useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext';
import { useChatContext } from '../hooks/useChatContext';
import { functions } from '../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { useDocListener } from '../hooks/firebase/useDocListener';

import ProfilePic from './ProfilePic';
import UserCard from './UserCard';
import ClickableIcon from './ClickableIcon';
import LoadingIcon from './LoadingIcon';
import NoAccessImg from '../assets/no-access.png';
import CheckImg from '../assets/check.png';

import './FriendsList.css';

export default function FriendsList({ returnFxn, removable = true, chat }) {
    const fetchFriendsFxn = httpsCallable(functions, 'fetchFriendsData');
    const friendRequestFxn = httpsCallable(functions, 'handleFriendRequests');
    const inviteFriendFxn = httpsCallable(functions, 'handleChatInvite');
    const { user } = useAuthContext();
    const { users, session, invites = [] } = useChatContext();
    const [friends, setFriends] = useState([]);
    const [friendsData, setFriendsData] = useState([]);
    const [deleteFriendScreen, setDeleteFriendScreen] = useState(false);
    const [inviteFriendScreen, setInviteFriendScreen] = useState(false);
    const [targetFriend, setTargetFriend] = useState(null);
    const [removeFriendPending, setRemoveFriendPending] = useState(false);
    const [removeFriendStatus, setRemoveFriendStatus]  = useState(null);
    const [inviteFriendPending, setInviteFriendPending] = useState(false);
    const [inviteFriendStatus, setInviteFriendStatus] = useState(null);
    const [activeChat, setActiveChat] = useState(false);
    const [activeChatSession, setActiveChatSession] = useState(null);
    const { document: doc, exist } = useDocListener('sessionSnaps', activeChatSession, true);
    
    useEffect(() => {
        if (!user?.friends?.length) return;
        
        let pass = false;
        user.friends.forEach(r => {
            if (friends.indexOf(r) === -1) pass = true;
        });
        friends.forEach(r => {
            if (user.friends.indexOf(r) === -1) pass = true;
        });

        if (!pass) return;

        fetchFriendsFxn({
            friends: user.friends
        }).then((results) => {
            for (let i = results.data.length -1; i > -1; i--) {
                if (!results.data[i]) return results.data.splice(i,1);
                if (Object.keys(results.data[i]).length === 0) return results.data.splice(i,1);
            }
            results.data = results.data.sort((a, b) => {
                if (a.username < b.username) return -1
            })
            returnFxn({ number: results.data.length, data: results.data || {}});
            setFriends(user.friends);
            if (results.data) {
                setFriendsData(results.data);
            }
        }).catch(err => {
            console.log(err);
        });

        if (user.activeChats && user.activeChats.length > 0) {
            setActiveChat(true);
            setActiveChatSession(user.activeChats[0]);
        } else {
            setActiveChat(false);
        }
    }, [user]);
    
    const handleRemoveFriend = () => {
        if (removeFriendPending) return;
        if (!targetFriend) {
            setRemoveFriendStatus('Error');
            return;
        }

        setRemoveFriendPending(true);
        friendRequestFxn({
            method: 'remove',
            sender: user.uid,
            receiver: targetFriend.uid
        }).then(res => {
            setRemoveFriendPending(false);
            setDeleteFriendScreen(false);
            setTargetFriend(null);
        }).catch(err => {
            console.log(err);
            setRemoveFriendPending(false);
            setRemoveFriendStatus(err.data);
        })
    }

    const handleFriendInvite = (target) => {
        if (inviteFriendPending) return;
        setInviteFriendPending(true);
        
        inviteFriendFxn({
            receiver: target.uid,
            sender: user.uid,
            session: session || user.activeChats[0],
            method: 'send'
        }).then(resp => {
            setInviteFriendPending(false);
            setInviteFriendScreen(false);
            setTargetFriend(null);
            
            // dispatch({ type: 'UPDATE', payload: { invites: [...invites, target.uid] }});
        }).catch(err => {
            console.log(err);
            setInviteFriendStatus(err.data);
            setInviteFriendPending(false);
            setInviteFriendScreen(false);
            setTargetFriend(null);
        });
    }

    const [viewProfile, setViewProfile] = useState(false);
    const [viewProfileTarget, setViewProfileTarget] = useState(null);
    const handleProfileClick = (e, entry) => {
        if (chat) return;

        if (e.target.closest('.friend-request-accept') || e.target.closest('.friend-request-deny')) return;
        if (!entry) return;

        setViewProfileTarget(entry);
        setViewProfile(true);
    }

    return <>
        {friendsData.length > 0 && <>
            {viewProfile && <div className="screen items-center" onClick={() => {
                setViewProfile(false);
                setViewProfileTarget(null);
            }}>
                <UserCard user={viewProfileTarget} profileBackground={true} />
            </div>}
            {friendsData.map((entry, index) => (
                <div key={index} className="friend-entry" onClick={(e) => handleProfileClick(e, entry)}>
                    <ProfilePic size="small" url={entry.photoURL} />
                    <div className="self-center ml-1 overflow-clip whitespace-nowrap text-left">
                        <p className="p-emphasize notranslate">{entry.displayName || entry.username}</p>
                        <p className="p-emphasize badge invert notranslate">@{entry.username}</p>
                    </div>
                    {chat && <>
                        {Object.keys(users).indexOf(entry.uid) > -1 &&  <>
                            {users[entry.uid]?.ban && <div className="ml-auto self-center">
                                <img src={NoAccessImg} className="icon-img" />
                            </div>}
                            {users[entry.uid]?.ban !== true && users[entry.uid]?.active && <div className="ml-auto self-center mr-2">
                                <img src={CheckImg} className="icon-img" />
                            </div>}
                        </>}
                        {(Object.keys(users).indexOf(entry.uid) === -1 || !users[entry.uid].active) && invites.indexOf(entry.uid) !== -1 && <div className="ml-auto self-center friend-request-accept"><p className="invited-friend">invited</p></div>}
                        {(Object.keys(users).indexOf(entry.uid) === -1 || !users[entry.uid].active) && invites.indexOf(entry.uid) === -1 && <div className="ml-auto self-center friend-request-accept" onClick={() => { 
                            if (inviteFriendPending) return;
                            handleFriendInvite(entry);
                            setTargetFriend(entry);
                        }}>
                            {!inviteFriendPending && <ClickableIcon type="chat" addClass="no-background" />}
                            {inviteFriendPending && (targetFriend?.uid !== entry.uid) && <ClickableIcon type="chat" addClass="no-background opacity-30" />}
                            {inviteFriendPending && (targetFriend?.uid === entry.uid) && <LoadingIcon size="medium" addClass="no-background self-center mr-2" />}
                        </div>}
                    </>}
                    {!chat && activeChat && doc?.info && exist && <>
                        {doc.info[entry.uid] && <>
                            {doc.info[entry.uid].ban && <div className="ml-auto self-center">
                                <img src={NoAccessImg} className="mr-2 h-6 w-6" />
                            </div>}
                            {!doc.info[entry.uid].ban && doc.info[entry.uid].active && <div className="ml-auto self-center">
                                <img src={CheckImg} className="mr-2 h-6 w-6" />
                            </div>}
                        </>}
                        {!doc.info[entry.uid] && <>
                            {doc.invites && doc.invites.indexOf(entry.uid) > -1 && <div className="ml-auto self-center">
                                <p className="invited-friend">invited</p>
                            </div>}
                            {(!doc.invites || doc.invites.indexOf(entry.uid) === -1) && <div className="ml-auto self-center friend-request-accept" onClick={() => {
                                setInviteFriendScreen(true);
                                setTargetFriend(entry);
                            }}>
                                <ClickableIcon type="chat" addClass="no-background" />
                            </div>}
                        </>}
                    </>}
                    {removable && <div className={`self-center ${activeChat && exist ? 'ml-6' : 'ml-auto'} friend-request-deny`}
                    onClick={() => {
                        setDeleteFriendScreen(true);
                        setTargetFriend(entry);
                    }}>
                        <ClickableIcon type="close" addClass="no-background" />
                    </div>}
                </div>
            ))}
            <p className={`error invite-error ${inviteFriendStatus ? 'show' : ''}`}>{inviteFriendStatus}</p>
        </>}
        {deleteFriendScreen && <div className="screen" onClick={(e) => {
            if (!e.target.closest('.center-modal')) {
                setDeleteFriendScreen(false);
                setTargetFriend(null);
            }
        }}>
            <div className="center-modal">
                <div className="center-modal-close-btn" onClick={() => {
                    setDeleteFriendScreen(false);
                    setTargetFriend(null);
                }}>
                    <ClickableIcon type="close" addClass="invert" />
                </div>
                <h5>Remove Friend?</h5> 
                <div className="friend-entry mt-2 mx-auto">
                    <ProfilePic size="small" url={targetFriend.photoURL} />
                    <div className="self-center ml-1 text-left">
                        <p className="p-emphasize">{targetFriend.displayName || targetFriend.username}</p>
                        <p className="p-emphasize badge invert">@{targetFriend.username}</p>
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    {!removeFriendPending && <button className="btn" onClick={handleRemoveFriend}>confirm</button>}
                    {removeFriendPending && <button className="btn flex justify-center items-center">
                        <LoadingIcon size="mini" />
                    </button>}
                </div>
                {removeFriendStatus && <p className="error mt-4">{removeFriendStatus}</p>}
            </div>
        </div>}
        {inviteFriendScreen && <div className="screen" onClick={(e) => {
            if (!e.target.closest('.center-modal')) {
                setInviteFriendScreen(false);
                setTargetFriend(null);
            }
        }}>
            <div className="center-modal">
                <div className="center-modal-close-btn" onClick={() => {
                    setInviteFriendScreen(false);
                    setTargetFriend(null);
                }}>
                    <ClickableIcon type="close" addClass="invert" />
                </div>
                <h5>Invite Friend</h5>
                <div className="friend-entry mt-2 mx-auto">
                    <ProfilePic size="small" url={targetFriend.photoURL} />
                    <div className="self-center ml-1">
                        <p className="p-emphasize">{targetFriend.displayName || targetFriend.username}</p>
                        <p className="p-emphasize badge invert">@{targetFriend.username}</p>
                    </div>
                </div>
                <p className="mt-2">Invite this friend to the current chat session.</p>
                <div className="flex justify-center mt-4">
                    {!inviteFriendPending && <button className="btn" onClick={() => handleFriendInvite(targetFriend)}>confirm</button>}
                    {inviteFriendPending && <button className="btn flex justify-center items-center">
                        <LoadingIcon size="mini" />
                    </button>}
                </div>
                {inviteFriendStatus && <p className="error mt-4">{inviteFriendStatus}</p>}
            </div>
        </div>}
    </>
}