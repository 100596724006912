
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useFirestore } from '../hooks/firebase/useFirestore'
import { functions } from '../firebase/config'
import { httpsCallable } from 'firebase/functions'

import './ChatInvites.css'

import ClickableIcon from './ClickableIcon'
import ProfilePic from './ProfilePic'
import LoadingIcon from './LoadingIcon'
import ThinkingFaceImg from '../assets/thinking.png'

export default function ChatInvites({ friendsData }) {
    const { user } = useAuthContext();
    const { getCollection } = useFirestore('sessionSnaps');
    const handleInviteFxn = httpsCallable(functions, 'handleChatInvite');
    const [open, setOpen] = useState(false);
    const [list, setList] = useState([]);
    const [friends, setFriends] = useState({});
    const [rejectPending, setRejectPending] = useState(false);
    const [activeChat, setActiveChat] = useState(false);
    const [noInvites, setNoInvites] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (!user.invites) return;

        if (user.activeChats && user.activeChats.length > 0) setActiveChat(true);

        // remove invites
        getCollection()
            .then(results => {
                let passed = [];
                results.forEach(item => {
                    if (!item.status.active) return;
                    if (item.info[user.uid]) {
                        if (item.info[user.uid].ban) return;
                        if (item.info[user.uid].active) return;
                    }
                    passed.push(item.id);
                });
                
                let filtered = [];
                Object.values(user.invites).forEach(item => {
                    if (passed.indexOf(item.session) > -1) filtered.push(item);
                });
                
                if (filtered.length === 0) {
                    setNoInvites(true);
                    setOpen(false);
                } else {
                    setNoInvites(false);
                }

                //sort invites
                let sorted = Object.values(filtered).sort((a, b) => 
                    (Number(a.timestamp) - Number(b.timestamp))
                );

                setList(filtered);
                
            }).catch(err => console.log(err));
        
    }, [user]);

    useEffect(() => {
        if (!friendsData) return;
        let results = {};
        friendsData.forEach(item => {
            results[item.uid] = item;
        });

        setFriends(results);
    }, [friendsData]);

    const handleEntryClick = (e, entry) => {
        if (activeChat) return;
        if (e.target.closest('.invite-entry-reject')) {
            if (rejectPending) return;
            setRejectPending(true);

            handleInviteFxn({
                method: 'reject',
                session: entry.session,
                receiver: user.uid,
                sender: entry.sender,
                read: entry.read || false,
                timestamp: entry.timestamp
            }).then(() => {
                setTimeout(() => setRejectPending(false), 2000);
            }).catch(err => {
                console.log(err);
                setRejectPending(false);
            });
        } else {
            navigate(`/chatdoor/${entry.session}`);
        }
    }

    return (<div className={`chatInvite-holder ${open? 'show' : ''} ${noInvites ? 'small hidden' : ''}`} onClick={(e) => {
            if (!e.target.closest('.invite-list-holder')) {
                open? setOpen(false) : setOpen(true)
            }
            // handle unread vs read
        }}>
        {!open && <ClickableIcon type="bell" addClass="no-background shadow" />}
        {open && <>
            <h5>Chat Invites</h5>
            <div className={`invite-list-holder hide-scroll`}>
                {list.map((entry, index) => {
                    if (friends[entry.sender]) {
                        return (<div key={index} className={`invite-entry unread-entry`} onClick={(e) => handleEntryClick(e, entry)}>
                            <ProfilePic size="small" url={friends[entry.sender].photoURL} />
                            <div className="self-center">
                                <h4>{entry.session}</h4>
                                <p className="p-emphasize badge mt-1 notranslate">{friends[entry.sender].displayName}</p>
                            </div>
                            <div className="ml-auto self-center invite-entry-reject">
                                {!rejectPending && <ClickableIcon type="close" addClass={'no-background'} />}
                                {rejectPending && <div className="px-3">
                                    <LoadingIcon size="small" />
                                </div>}
                            </div>
                        </div>)
                    }
                })}
            </div>
            {noInvites && <div className="w-full flex">
                <p className="p-emphasize self-end ml-auto">empty...</p>
                <img src={ThinkingFaceImg} className="w-6 h-6 ml-1 mr-auto" />
            </div>}
            {activeChat && !noInvites && <div className="invite-screen">
                <p className="p-emphasize">Please exit any active chats before proceeding</p>
            </div>}
        </>}
    </div>)
}