export const langSrc = [
    { name: 'Albanian', code: 'sq' },
    { name: 'Arabic', code: 'ar' },
    { name: 'Armenian', code: 'hy' },
    { name: 'Belarusian', code: 'be' },
    { name: 'Bengali', code: 'bn' },
    { name: 'Catalan', code: 'ca' },
    { name: 'Chinese (Simplified)', code: 'zh-CN' },
    { name: 'Chinese (Traditional)', code: 'zh-TW' },
    { name: 'Croatian', code: 'hr' },
    { name: 'Czech', code: 'cs' },
    { name: 'Danish', code: 'da' },
    { name: 'Dutch', code: 'nl' },
    { name: 'English', code: 'en' },
    { name: 'French', code: 'fr' },
    { name: 'German', code: 'de' },
    { name: 'Greek', code: 'el' },
    { name: 'Hebrew', code: 'he' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Hungarian', code: 'hu' },
    { name: 'Icelandic', code: 'is' },
    { name: 'Indonesian', code: 'id' },
    { name: 'Irish', code: 'ga' },
    { name: 'Italian', code: 'it' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Korean', code: 'ko' },
    { name: 'Latin', code: 'la' },
    { name: 'Malay', code: 'ms' },
    { name: 'Norwegian', code: 'no' },
    { name: 'Persian', code: 'fa' },
    { name: 'Polish', code: 'pl' },
    { name: 'Portuguese', code: 'pt' },
    { name: 'Russian', code: 'ru' },
    { name: 'Spanish', code: 'es' },
    { name: 'Swedish', code: 'sv' },
    { name: 'Tagalog (Filipino)', code: 'tl' },
    { name: 'Thai', code: 'th' },
    { name: 'Turkish', code: 'tr' },
    { name: 'Vietnamese', code: 'vi' }
]

// const fullLangSrc = [
//     { name: 'Albanian', code: 'sq' },
//     { name: 'Arabic', code: 'ar' },
//     { name: 'Armenian', code: 'hy' },
//     { name: 'Belarusian', code: 'be' },
//     { name: 'Bengali', code: 'bn' },
//     { name: 'Bosnian', code: 'bs' },
//     { name: 'Bulgarian', code: 'bg' },
//     { name: 'Catalan', code: 'ca' },
//     { name: 'Cebuano', code: 'ceb' }, 
//     { name: 'Chinese (Simplified)', code: 'zh-CN' },
//     { name: 'Chinese (Traditional)', code: 'zh-TW' },
//     { name: 'Croatian', code: 'hr' },
//     { name: 'Czech', code: 'cs' },
//     { name: 'Danish', code: 'da' },
//     { name: 'Dutch', code: 'nl' },
//     { name: 'English', code: 'en' },
//     { name: 'Esperanto', code: 'eo' },
//     { name: 'Estonian', code: 'et' },
//     { name: 'Finnish', code: 'fi' },
//     { name: 'French', code: 'fr' },
//     { name: 'Georgian', code: 'ka' },
//     { name: 'German', code: 'de' },
//     { name: 'Greek', code: 'el' },
//     { name: 'Haitian Creole', code: 'ht' },
//     { name: 'Hebrew', code: 'he' },
//     { name: 'Hindi', code: 'hi' },
//     { name: 'Hungarian', code: 'hu' },
//     { name: 'Icelandic', code: 'is' },
//     { name: 'Indonesian', code: 'id' },
//     { name: 'Irish', code: 'ga' },
//     { name: 'Italian', code: 'it' },
//     { name: 'Japanese', code: 'ja' },
//     { name: 'Korean', code: 'ko' },
//     { name: 'Latin', code: 'la' },
//     { name: 'Malay', code: 'ms' },
//     { name: 'Myanmar (Burmese)', code: 'my' },
//     { name: 'Nepali', code: 'ne' },
//     { name: 'Norwegian', code: 'no' },
//     { name: 'Persian', code: 'fa' },
//     { name: 'Polish', code: 'pl' },
//     { name: 'Portuguese (Portugal, Brazil)', code: 'pt' },
//     { name: 'Punjabi', code: 'pa' },
//     { name: 'Romanian', code: 'ro' },
//     { name: 'Russian', code: 'ru' },
//     { name: 'Samoan', code: 'sm' },
//     { name: 'Sindhi', code: 'sd' },
//     { name: 'Slovak', code: 'sk' },
//     { name: 'Somali', code: 'so' },
//     { name: 'Spanish', code: 'es' },
//     { name: 'Swahili', code: 'sw' },
//     { name: 'Swedish', code: 'sv' },
//     { name: 'Tagalog (Filipino)', code: 'tl' },
//     { name: 'Thai', code: 'th' },
//     { name: 'Turkish', code: 'tr' },
//     { name: 'Ukrainian', code: 'uk' },
//     { name: 'Uzbek', code: 'uz' },
//     { name: 'Vietnamese', code: 'vi' },
//     { name: 'Welsh', code: 'cy' },
//     { name: 'Yiddish', code: 'yi' }
// ];
