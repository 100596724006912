import { useState, useEffect } from 'react'
import { auth } from '../../firebase/config'
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth'
import { useAuthContext } from '../useAuthContext'
import { useFirestore } from './useFirestore'

export const useLogin = () => {
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { dispatch } = useAuthContext();
    const { updateDocument } = useFirestore('users');

    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const login = async (email, password) => {
        setError(null);
        setPending(true);

        try {
            const res = await signInWithEmailAndPassword(auth, email, password);
            if (!res.user) throw new Error('Failed to login account');
            
            await updateDocument(res.user.uid, { online: true, refreshToken: res.user.refreshToken });
            
            dispatch({ type: 'LOGGINGIN' });
            
            setTimeout(() => {
                dispatch({ type: 'LOGIN', payload: {}});
            }, 300);
            
            reset();
        } catch(err) {
            console.log(err.message);
            reset(err);
        }
    }

    const resetPw = async (email) => {
        setError(null);
        setPending(true);

        try {
            await sendPasswordResetEmail(auth, email);

            reset();
        } catch(err) {
            console.log(err.message);
            reset(err);
        }
    }

    useEffect(() => {   
        setCancel(false);
        return () => setCancel(true);
    }, [])

    return { error, pending, login, resetPw };
}