import { useState, useEffect } from 'react';
import { useAppContext } from '../../../hooks/useAppContext';
import { useChatContext } from '../../../hooks/useChatContext';

import './SpeechRec.css';

import ClickableIcon from '../../../components/ClickableIcon';

export default function SpeechRec({ messageFxn, method, add, watchStatus }) {
    const { selfSpeechMic, otherSpeechMic, otherSpeechLang, dispatch } = useAppContext();
    const { users, self } = useChatContext();
    const [watch, setWatch] = useState(false);

    useEffect(() => {
        if (watchStatus === 'self') {
            selfSpeechMic ? on() : off();
        } else if (watchStatus === 'other') {
            otherSpeechMic ? on() : off();
        }
    }, [watch, watchStatus, selfSpeechMic, otherSpeechMic]);

    const off = () => {
        recognition.stop();
        if (watchStatus === 'self') dispatch({ type: 'SELFSPEECHMIC_OFF'});
        if (watchStatus === 'other') dispatch({ type: 'OTHERSPEECHMIC_OFF' });
        setWatch(false);
    }
    const on = () => {
        if (watchStatus === 'self') dispatch({ type: 'SELFSPEECHMIC_ON'});
        if (watchStatus === 'other') dispatch({ type: 'OTHERSPEECHMIC_ON' });
        setWatch(true);
    }
    const updateStatus = (status, text) => {
        dispatch({ type: 'UPDATE_SPEECHSTATUS', payload: { status, text: text || '', source: watchStatus }});
    }

    var SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    var recognition = new SpeechRecognition();

    recognition.onstart = function() {
        if (watchStatus === 'other') console.log('Listening...Langauge:', otherSpeechLang);
        if (watchStatus === 'self') console.log('Listening...Langauge:', users[self].lang);
        updateStatus('start');
    }

    recognition.onspeechend = function() {
        console.log('speech end');
        off();
        updateStatus('processing');
    }
    
    recognition.onresult = function(event) {
        let interim = '';
        let final = '';
        let confidence = 1;
        for (let i = event.resultIndex; i < event.results.length; i++) {
            if (event.results[i].isFinal) {
                recognition.stop();
                off();
                confidence = event.results[i][0].confidence;
                final += event.results[i][0].transcript;
                messageFxn(final);
                updateStatus('success', final);
            } else {
                confidence = event.results[i][0].confidence;
                interim += event.results[i][0].transcript;
                updateStatus('interim', interim);
            }
        }
        // recognition.stop();
        // off();
        // var transcript = event.results[0][0].transcript;
        // var confidence = event.results[0][0].confidence;
        // if (confidence > 0.2) {
        //     console.log(transcript);
        //     messageFxn(transcript);
        //     updateStatus('success', transcript);
        // } else {
        //     console.log('not enough confidence:', transcript);
        //     messageFxn('(Not enough confidence in translation. Please try again.)');
        //     updateStatus('failed');
        // }
    }
    
    useEffect(() => { 
        if (watch) {
            if (watchStatus === 'other') recognition.lang = otherSpeechLang;
            if (watchStatus === 'self') recognition.lang = users[self].lang;
            recognition.interimResults = true;
            recognition.start();
        } else if (!watch) {
            console.log('speech stop');
            recognition.stop();
            updateStatus('stop');
        }
    }, [watch, users, self, otherSpeechLang]);

    if (method === 'circle') {
        return (
            <div className="mic-holder" onClick={() => watch ? off() : on()}>
                <ClickableIcon type="mic" addClass={`no-hover large ${watch ? 'animate-ping' : ''}`} />
                <ClickableIcon type="mic" addClass={`no-hover large ${watch ? 'mic-active' : ''} ${add === 'flip' ? 'flip-180' : ''}`} />
            </div>
        )
    } else {
        return (
            <div className={`speechToText-btn flex justify-center self-center ${watch ? 'bg-red-700' : 'bg-slate-700'} md:w-24`} onClick={() => watch ? off() : on() }>
                <div className="mx-auto self-center relative">
                    <ClickableIcon type="mic" addClass="small no-background"/>
                    {watch && <ClickableIcon type="mic" addClass="absolute top-0 small no-background animate-ping"/>}
                </div>
            </div>
        )
    }
}