
import { useState, useRef, useEffect } from 'react'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useDocListener } from '../../../hooks/firebase/useDocListener'
import { functions } from '../../../firebase/config'
import { httpsCallable } from 'firebase/functions'

import './SpeakerChatPanel.css'

import SpeakerChatTools from './SpeakerChatTools'
import ClickableIcon from '../../../components/ClickableIcon'
import LoadingIcon from '../../../components/LoadingIcon'

export default function SpeakerChatPanel() {
    const translateMsgFxn = httpsCallable(functions, 'translateMessage');
    const translateMsgWithSpeechFxn = httpsCallable(functions, 'translateWithSpeech');
    const textToSpeechFxn = httpsCallable(functions, 'textToSpeech');
    const { user } = useAuthContext();
    const { document: toolsPayload } = useDocListener('users', user.uid);
    const [message, setMessage] = useState('');
    const [msgStatus, setMsgStatus] = useState('');
    const [content, setContent] = useState('');
    const [targetLang, setTargetLang] = useState('en');
    const [speakerStatus, setSpeakerStatus] = useState(false);
    const [expand, setExpand] = useState(0);
    
    const [selfAutoAudio, setSelfAutoAudio] = useState(false);
    const input = useRef();
    const outputElement = useRef();

    useEffect(() => {
        if (!user) return;

        setTargetLang(user.lang);
    }, [user]);

    const closeToolTips = (e) => {
        if (!e.target.closest('.msg-status') && e.currentTarget.querySelector('.msg-status')) {
            e.currentTarget.querySelector('.msg-status').classList.remove('tip');
        }
    }

    const addMessage = async (e, text) => {
        console.log('trig')
        if (e.preventDefault) e.preventDefault();
        let msg = text ? text.toString() : message;
        if (!msg) return console.log('empty message');

        //set status
        setMsgStatus('sending');
        let gender = user.gender;
        if (gender === 'other') gender = 'neutral';
        gender = gender.toUpperCase();

        if (user.lang === targetLang) {
            if (speakerStatus) {
                textToSpeechFxn({
                    lang: targetLang,
                    text: msg,
                    gender
                }).then(resp => {
                    speakMessage(resp.data);
                }).catch(err => {
                    console.log(err);
                    setMsgStatus('fail');
                    setTimeout(() => setMsgStatus(''), 2000);
                });
            }
            setContent(msg);
            setMsgStatus('');
        } else if (targetLang !== user.lang && !speakerStatus) {
            translateMsgFxn({
                lang: targetLang,
                text: msg
            }).then(resp => {
                setContent(resp.data);
                setMsgStatus('done');
                setTimeout(() => setMsgStatus(''), 2000);
            }).catch(err => {
                console.log(err);
                setMsgStatus('fail');
                setTimeout(() => setMsgStatus(''), 2000);
            });
        } else if (targetLang !== user.lang && speakerStatus) {
            translateMsgWithSpeechFxn({
                lang: targetLang,
                text: msg,
                gender
            }).then(resp => {
                setContent(resp.data.text);
                speakMessage(resp.data.speech);
                setMsgStatus('done');
                setTimeout(() => setMsgStatus(''), 2000);
            }).catch(err => {
                console.log(err);
                setMsgStatus('fail');
                setTimeout(() => setMsgStatus(''), 2000);
            });
        }

        setMessage('');
    }

    const instructHandler = (method, { lang, text, direct, speaker, autoAudio }) => {
        if (method === 'status') {
            setTargetLang(lang);
            setSpeakerStatus(speaker);
            setSelfAutoAudio(autoAudio);
        }
        if (method === 'text') {
            if (text === '(Not enough confidence in translation. Please try again.)') return;
            selfAutoAudio ? addMessage({}, text) : setMessage(text);
        }
    }

    const handleExpand = () => {
        if (expand === 0) return setExpand(1);
        if (expand === 1) return setExpand(2);
        if (expand === 2) return setExpand(0);
    }

    const speakMessage = (data) => {
        var audioContxt = window.AudioContext || window.webkitAudioContext;
        const audioContext = new audioContxt();
        audioContext.resume()
            .then(async () => {
                const audioContent = data;
                const content = Object.values(audioContent);
                const arr = Uint8Array.from(content);
                
                const audio = await audioContext.decodeAudioData(arr.buffer.slice(0));
                const source = audioContext.createBufferSource();
                source.buffer = audio;
                source.connect(audioContext.destination);
                source.start(0);
            }).catch(err => console.log(err.message));
    }

    return <>
        <div className={`speaker-panel`} onClick={closeToolTips}>
            <div className="output instruct hide-scroll grow" ref={outputElement}>
                <div className="instruct-holder speaker-tool">
                    <div className={`instruct-content notranslate hide-scroll ${expand >= 1 ? 'expand-frame' : ''} ${expand === 2 ? 'expand-font' : ''}`}>{content}</div>
                    <div className="instruct-holder-expand-btn" onClick={handleExpand}>
                        {(!expand || expand === 1) && <ClickableIcon type="expand" addClass="small no-background no-hover" />}
                        {expand === 2 && <ClickableIcon type="collapse" addClass="small no-background no-hover" />}
                    </div>
                </div>
            </div>
            <form onSubmit={(e) => addMessage(e)} className={`panel-form flex justify-center speaker-tool ${selfAutoAudio ? 'hide' : ''}`}>
                <input type="text" 
                    required
                    ref={input} 
                    className="grow send-msg-input notranslate ml-4"
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />
                <button className="send-msg-btn mr-4">
                    <ClickableIcon 
                        type="check"
                        addClass="no-background small invert"
                    />
                </button>
                {msgStatus === 'sending' && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    <LoadingIcon size="small" /><div className="msg-status-tip">{msgStatus}...</div>
                </div>}
                {msgStatus === 'done' && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    👍<div className="msg-status-tip">{msgStatus}</div>
                </div>}
                {msgStatus.includes('fail') && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    😩<div className="msg-status-tip">{msgStatus}</div>
                </div>}
            </form>
            <div className={`mt-2`}></div>
            <div className={`chat-tools-wrapper overflow-hidden show`}>
                <SpeakerChatTools data={toolsPayload} messageFxn={addMessage} instructReturnFxn={instructHandler} lang={targetLang} speakerStatus={speakerStatus} />
            </div>
        </div>
    </>
}