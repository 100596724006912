import { useState, useEffect, useRef } from 'react';


import ClickableIcon from '../../../../components/ClickableIcon';

import './PhraseTreeTool.css';

import LockImg from '../../../../assets/lock.png';

export default function PhraseTreeTool({ data, wrapper, messageFxn, returnFxn }) {
    const [filteredBuckets, setFilteredBuckets] = useState([]);
    const [filteredPhrases, setFilteredPhrases] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const holder = useRef();
    const colScenarios = useRef();
    const colBuckets = useRef();
    const colPhrases = useRef();

    useEffect(() => {
        if (!data) return;
        setFilteredBuckets(data.bucketSequence);
        setFilteredPhrases(data.phraseSequence);
    }, [data]);

    const handleScenarioClick = (e, key) => {
        colScenarios.current.classList.remove('expand');
        colScenarios.current.classList.remove('shrink');
        colBuckets.current.classList.remove('expand');
        colBuckets.current.classList.remove('shrink');
        colPhrases.current.classList.remove('expand');
        colPhrases.current.classList.remove('shrink');

        colPhrases.current.classList.remove('focus');
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active');
            setFilteredBuckets(data.bucketSequence);
            setFilteredPhrases(data.phraseSequence);
            colBuckets.current.classList.remove('focus');
            colBuckets.current.classList.remove('active');
            document.querySelectorAll('.bucket-entry').forEach(el => {
                el.classList.remove('active');
            });
            colPhrases.current.classList.add('default');
            colPhrases.current.classList.remove('active');
            return;
        }
        document.querySelectorAll('.scenario-entry').forEach(el => {
            el.classList.remove('active');
            colBuckets.current.classList.remove('active');
            colPhrases.current.classList.remove('active');
        });
        e.target.classList.add('active');
        setFilteredBuckets(data.scenarios[key].buckets);
        setFilteredPhrases(data.phraseSequence);
        colBuckets.current.classList.add('focus');
        colPhrases.current.classList.remove('default');
    }
    const handleBucketClick = (e, key) => {
        colScenarios.current.classList.remove('expand');
        colScenarios.current.classList.remove('shrink');
        colBuckets.current.classList.remove('expand');
        colBuckets.current.classList.remove('shrink');
        colPhrases.current.classList.remove('expand');
        colPhrases.current.classList.remove('shrink');
        if (colBuckets.current.classList.contains('focus')) {
            if (e.target.classList.contains('active')) {
                e.target.classList.remove('active');
                colScenarios.current.classList.remove('shrink');
                colBuckets.current.classList.remove('active');
                colPhrases.current.classList.remove('active');
                colPhrases.current.classList.remove('default');
                setFilteredPhrases(data.phraseSequence);
                return;
            }
            document.querySelectorAll('.bucket-entry').forEach(el => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
            colScenarios.current.classList.add('shrink');
            colBuckets.current.classList.add('active');
            setFilteredPhrases(data.buckets[key].phrases);
            colPhrases.current.classList.remove('active');
            colPhrases.current.classList.add('active');
            colPhrases.current.classList.add('default');
        } else {
            if (e.target.classList.contains('active')) {
                e.target.classList.remove('active');
                setFilteredPhrases(data.phraseSequence);
                colScenarios.current.classList.remove('shrink');
                colPhrases.current.classList.remove('focus');
                colPhrases.current.classList.add('default');
                return;
            }
            document.querySelectorAll('.bucket-entry').forEach(el => {
                el.classList.remove('active');
            });
            e.target.classList.add('active');
            setFilteredPhrases(data.buckets[key].phrases);
            colScenarios.current.classList.add('shrink');
            colPhrases.current.classList.add('focus');
            colPhrases.current.classList.add('default');
        }
    }

    const expandTreeColumn = (e, cat) => {
        if (!e.target.closest('.pin')) return;
        if (cat === 'scenarios') {
            colBuckets.current.classList.remove('focus');
            colBuckets.current.classList.remove('active');
            colBuckets.current.classList.remove('expand');
            colBuckets.current.classList.remove('shrink');
            colPhrases.current.classList.remove('focus');
            colPhrases.current.classList.remove('active');
            colPhrases.current.classList.remove('expand');
            if (colScenarios.current.classList.contains('expand')) {
                colScenarios.current.classList.remove('expand');
                colPhrases.current.classList.remove('shrink');
                colPhrases.current.classList.add('default');
            } else {
                colScenarios.current.classList.remove('shrink');
                colScenarios.current.classList.add('expand');
                colPhrases.current.classList.remove('default');
                colPhrases.current.classList.add('shrink');
            }
        }
        if (cat === 'buckets') {
            colScenarios.current.classList.remove('expand');
            colBuckets.current.classList.remove('shrink');
            colPhrases.current.classList.remove('focus');
            colPhrases.current.classList.remove('active');
            colPhrases.current.classList.remove('expand');
            if (colBuckets.current.classList.contains('expand')) {
                colBuckets.current.classList.remove('expand');
                colScenarios.current.classList.remove('shrink');
                colScenarios.current.classList.remove('expand');
                colPhrases.current.classList.remove('shrink');
                colPhrases.current.classList.add('default');
            } else {
                colBuckets.current.classList.add('expand');
                colScenarios.current.classList.add('shrink');
                colPhrases.current.classList.remove('default');
                colPhrases.current.classList.add('shrink');
            }
        }
        if (cat === 'phrases') {
            colScenarios.current.classList.remove('expand');
            colBuckets.current.classList.remove('focus');
            colBuckets.current.classList.remove('active');
            colBuckets.current.classList.remove('expand');
            colPhrases.current.classList.remove('shrink');
            if (colPhrases.current.classList.contains('expand')) {
                colPhrases.current.classList.add('default');
                colPhrases.current.classList.remove('expand');
                colScenarios.current.classList.remove('shrink');
                colBuckets.current.classList.remove('shrink');
            } else {
                colPhrases.current.classList.remove('default');
                colPhrases.current.classList.add('expand');
                colScenarios.current.classList.add('shrink');
                colBuckets.current.classList.add('shrink');
            }
        }
    }

    const resetSelections = (e) => {
        if (e.target.closest('.tools-entry')) return;
        if (e.target.closest('.col-icon')) return;

        document.querySelectorAll('.tools-entry').forEach(el => {
            el.classList.remove('active');
        });
        colScenarios.current.classList.remove('expand');
        colScenarios.current.classList.remove('shrink');
        colBuckets.current.classList.remove('focus');
        colBuckets.current.classList.remove('active');
        colBuckets.current.classList.remove('shrink');
        colBuckets.current.classList.remove('expand');
        colPhrases.current.classList.remove('focus');
        colPhrases.current.classList.remove('active');
        colPhrases.current.classList.remove('shrink');
        colPhrases.current.classList.remove('expand');
        colPhrases.current.classList.add('default');
    }

    const handleToolExpand = () => {
        const container = document.querySelector('.chat-tools-wrapper');
        if (!container) return;

        if (holder.current.classList.contains('phrase-expand')) {
            setTimeout(() => {
                container.classList.add('overflow-hidden');
                wrapper.classList.remove('phrase-expand');  
            }, 300);
            holder.current.classList.remove('phrase-expand');
            setExpanded(false);
        } else {
            container.classList.remove('overflow-hidden');
            wrapper.classList.add('phrase-expand');
            holder.current.classList.add('phrase-expand');
            setExpanded(true);
        }
    }

    return <>
        <div className="tool-box-holder" ref={holder}>
            <div className="relative">
                <h5>Phrase Tree</h5>
                <div className="absolute right-0" style={{ top: '-0.5rem' }} onClick={handleToolExpand}>
                    {!expanded && <ClickableIcon type="expand" addClass="small no-background no-hover invert" />}
                    {expanded && <ClickableIcon type="collapse" addClass="small no-background no-hover invert" />}
                </div>
            </div>
            <div className="tools-phrase-wrapper mt-2 scroll-shadow-both" onClick={resetSelections}>
                <div className="flex flex-col gap-2 tool-box-col hide-scroll col-scenario" ref={colScenarios}>
                    <div className="flex justify-center" onClick={(e) => expandTreeColumn(e, 'scenarios')}>
                        <ClickableIcon type="supervised" addClass="small no-background no-hover invert mr-2 col-icon" />
                    </div>
                    {data.scenarios && data.scenarioSequence.map((key, index) => (
                        <div key={key} className="tools-entry scenario-entry" onClick={(e) => handleScenarioClick(e, key, index)}>
                            {data.scenarios[key].title}
                        </div>
                    ))}
                </div>
                <div className={`flex flex-col gap-2 tool-box-col hide-scroll col-buckets`} ref={colBuckets}>
                    <div className="flex justify-center" onClick={(e) => expandTreeColumn(e, 'buckets')}>
                        <ClickableIcon type="box" addClass="small no-background no-hover mr-2 col-icon" />
                    </div>
                    {filteredBuckets && filteredBuckets.map((key, index) => {
                        if (data.buckets[key]) return <div key={key} className="tools-entry bucket-entry" onClick={(e) => handleBucketClick(e, key, index)}>
                            {data.buckets[key].title}
                        </div>
                    })}
                </div>
                <div className={`flex flex-col gap-2 tool-box-col hide-scroll col-phrases default`} ref={colPhrases}>
                    <div className="col-span-2 flex justify-center" onClick={(e) => expandTreeColumn(e, 'phrases')}>
                        <ClickableIcon type="message" addClass="small no-background no-hover col-icon" />
                    </div>
                    {filteredPhrases && filteredPhrases.map((key) => {
                        if (data.phrases[key]) return <div key={key} className="tools-entry phrase-entry" onClick={(e) => {
                            if (data.phrases[key].limit) {
                                if (data.limits[data.phrases[key].limit]) {
                                    returnFxn({ method: 'limit', value: data.phrases[key].limit })
                                }
                            }
                            if (e.target.closest('.alt-text')) {
                                messageFxn(e, data.phrases[key].altText);
                            } else {
                                messageFxn(e, data.phrases[key].text);
                            }
                        }}>
                            {data.phrases[key].limit && data.limits[data.phrases[key].limit] && <span className="phrase-entry-limit"><img src={LockImg} alt="Padlock" /></span>}
                            {data.phrases[key].title}
                            {data.phrases[key].altText && <div className="alt-text ml-auto">❓</div>}
                        </div>
                    })}
                </div>
            </div>
        </div>
    </>
}