
import { useState } from 'react';
import { useDocListener } from '../../hooks/firebase/useDocListener';
import { useAuthContext } from '../../hooks/useAuthContext';

import './Tree.css';

import Phrases from './components/Phrases';
import Buckets from './components/Buckets';
import Scenarios from './components/Scenarios';
import ClickableIcon from '../../components/ClickableIcon';

import TreeImg from '../../assets/tree.png';

export default function Tree() {
    const { user } = useAuthContext();
    const { document, pending, error } = useDocListener('users', user.uid);
    const [phrases, setPhrases] = useState(true);
    const [buckets, setBuckets] = useState(false);
    const [scenarios, setScenarios] = useState(false);

    const handleClick = (category) => {
        if (category === 'phrases') {
            setPhrases(true);
            setBuckets(false);
            setScenarios(false);
        } else if (category === 'buckets') {
            setPhrases(false);
            setBuckets(true);
            setScenarios(false);
        } else if (category === 'scenarios') {
            setPhrases(false);
            setBuckets(false);
            setScenarios(true);
        }
    }
    
    return (
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <div className="flex gap-1">
                <h5 className="mt-1 self-center">Phrase Trees</h5>
                <img className="h-4 w-4 self-center" src={TreeImg} alt="Tree" />
            </div>
            <div className="mt-4 grow bg-neutral-200 flex flex-col items-center relative">
                {!pending && !error && <div className="content-wrapper grow flex w-full">
                    {phrases && <Phrases data={document} />}
                    {buckets && <Buckets data={document} />}
                    {scenarios && <Scenarios data={document} />}
                </div>}
                {error && <div className="grow flex items-center">
                    <p className="error mx-auto">{error}</p>
                </div>}
                {pending && <div className="grow w-full"></div>}
                <div className="flex justify-center mb-4">
                    <div className={`tree-cat-btn ${phrases ? 'active' : ''}`} onClick={e => handleClick('phrases')}>
                        <ClickableIcon type="message" addClass="small no-background" />
                    </div>
                    <div className={`tree-cat-btn ${buckets ? 'active' : ''}`} onClick={e => handleClick('buckets')}>
                        <ClickableIcon type="box" addClass="small no-background" />
                    </div>
                    <div className={`tree-cat-btn ${scenarios ? 'active' : ''}`} onClick={e => handleClick('scenarios')}>
                        <ClickableIcon type="supervised" addClass="small no-background" />
                    </div>
                </div>
            </div>
        </div>
    )
}
