import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
import { useAuthContext } from "../hooks/useAuthContext";
import { useState } from "react";

import MushroomImg from '../assets/mushroom.png';
import ClickableIcon from "./ClickableIcon";
import LoadingIcon from "./LoadingIcon";

export default function CheatForm({ returnFxn }) {
    const checkCheatCodesFxn = httpsCallable(functions, 'checkDiscountCodes');
    const { user } = useAuthContext();
    const [cheatScreen, setCheatScreen] = useState(false);
    const [cheatFxnPending, setCheatFxnPending] = useState(false);
    const [cheatSuccess, setCheatSuccess] = useState(false);
    const [cheat, setCheat] = useState('');
    const [cheatError, setCheatError] = useState(false);

    const handleCheatCodeSubmit = async (e) => {
        e.preventDefault();
        setCheatFxnPending(true);

        const res = await checkCheatCodesFxn({ uid: user.uid, code: cheat });

        if (res.data) {
            setCheatSuccess(true);
            setCheatScreen(true);
            setCheat('');
            returnFxn('hide');
        } else {
            setCheat('❌❌❌');
            setCheatError(true);
            setTimeout(() => {
                setCheat('');
                setCheatError(false);
            }, 3000);
        }

        setCheatFxnPending(false);
    }

    return <>
        {cheatSuccess && <div className="screen" onClick={(e) => {
                if (!e.target.closest('.center-modal') && !e.target.closest('.cheat-form')) {
                    setCheatScreen(false);
                    returnFxn('close');
                }
            }}>
                <div className="center-modal">
                    <div className="flex gap-2 mr-6">
                        <img src={MushroomImg} className="h-12 w-12 animate-bounce" alt="mushroom" />
                        <h3 className="self-end font-bold">Level Up!</h3>
                    </div>
                    <p className="font-semibold ml-2 mt-4 w-48">You now have the power to host chats! Lucky!</p>
                </div>
            </div>
        }
        {!cheatSuccess && !cheatScreen && <form className="cheat-form flex justify-between gap-2" onSubmit={handleCheatCodeSubmit}>
            <label>
                <input 
                    type="text"
                    className={`grow`}
                    placeholder="cheat code"
                    onChange={(e) => {
                        setCheatError(false);
                        setCheat(e.target.value);
                    }}
                    value={cheat}
                />
            </label>
            {!cheatFxnPending && <button className="btn-free-width">
                <ClickableIcon type="check" addClass="small no-background no-hover invert self-center" />
            </button>}
            {cheatFxnPending && <button className="btn-free-width flex justify-center items-center relative">
                <ClickableIcon type="check" addClass="small no-background no-hover invert self-center opacity-0" />
                <LoadingIcon size="small" addClass="self-center absolute" />
            </button>}
        </form>}
    </>
}