
import { createContext, useReducer } from "react";
import { functions } from "../firebase/config";
import { httpsCallable } from 'firebase/functions';

import { fs } from "../firebase/config";
import { doc, onSnapshot } from 'firebase/firestore'

export const AppContext = createContext();

export const appReducer = (state, action) => {
    // console.log('appcontext', action.type, action.payload, action.source);
    switch (action.type) {
        case 'CHATTING':
            return { ...state, 
                chatting: true,
                timestamp: new Date().getTime()
            };
        case 'END_CHAT':
            // state.dataUnsub();
            return {
                ...state,
                chatting: false,
                languages: [],
                output: true,
                outputState: 'full',
                tools: false,
                deactivateLimit: false,
                instruct: false,
                instructLang: 'en',
                twoWay: false,
                speechStatus: '',
                otherSpeechMic: false,
                otherSpeechLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en',
                data: null,
                dataPending: false,
                dataError: false,
                dataExists: 'pending',
                dataUnsub: null,
            };
        case 'PAUSE_CHAT': 
            return {
                ...state,
                chatting: false,
                languages: [],
                output: true,
                outputState: 'full',
                tools: false,
                deactivateLimit: false,
                instruct: false,
                instructLang: 'en',
                twoWay: false,
                speechStatus: '',
                otherSpeechMic: false,
                otherSpeechLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en',
                data: null,
                dataPending: false,
                dataError: false,
                dataExists: 'pending',
                dataUnsub: null,
            };
        case 'SET_LANGUAGES':
            return {
                ...state,
                languages: action.payload
            }
        case 'TOOLS_ON':
            return {
                ...state,
                tools: true,
                outputState: 'half'
            }
        case 'TOOLS_OFF':
            return {
                ...state,
                tools: false,
                outputState: 'full'
            }
        case 'INSTRUCT_ON':
            return {
                ...state,
                output: false,
                outputState: 'shrink',
                deactivateLimit: true,
                instruct: true,
                instructLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en'
            }
        case 'INSTRUCT_OFF':
            return {
                ...state,
                output: true,
                outputState: 'half',
                deactivateLimit: false,
                instruct: false,
                instructLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en'
            }
        case 'SET_INSTRUCTLANG':
            return {
                ...state,
                instructLang: action.payload
            }
        case 'TWOWAY_ON':
            return {
                ...state,
                output: false,
                outputState: 'shrink',
                deactivateLimit: true,
                twoWay: true,
                speechStatus: '',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en'
            }
        case 'TWOWAY_OFF':
            return {
                ...state,
                output: true,
                outputState: 'half',
                deactivateLimit: false,
                twoWay: false,
                speechStatus: '',
                otherSpeechMic: false,
                otherSpeechLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en'
            }
        case 'UPDATE_SPEECHSTATUS':
            return {
                ...state,
                speechStatus: action.payload
            }
        case 'OTHERSPEECHMIC_ON':
            return {
                ...state,
                otherSpeechMic: true,
                selfSpeechMic: false,
                selfAudio: false,
                selfAudioIgnore: true
            }
        case 'OTHERSPEECHMIC_OFF':
            return {
                ...state,
                otherSpeechMic: false,
                selfAudioIgnore: false
            }
        case 'SET_OTHERSPEECHLANG':
            return {
                ...state,
                otherSpeechLang: action.payload
            }
        case 'SELFSPEECHMIC_ON':
            return {
                ...state,
                selfSpeechMic: true,
                otherSpeechMic: false
            }
        case 'SELFSPEECHMIC_OFF':
            return {
                ...state,
                selfSpeechMic: false
            }
        case 'SELFSPEECHAUTO_ON':
            return {
                ...state,
                selfSpeechAuto: true
            }
        case 'SELFSPEECHAUTO_OFF':
            return {
                ...state,
                selfSpeechAuto: false
            }
        case 'SELFAUDIO_ON':
            return {
                ...state,
                selfAudio: true
            }
        case 'SELFAUDIO_OFF':
            return {
                ...state,
                selfAudio: false
            }
        case 'SET_SELFAUDIOLANG':
            return {
                ...state,
                selfAudioLang: action.payload
            }
        case 'SET_INTERPRETLANGONE':
            return {
                ...state,
                interpretLangOne: action.payload
            }
        case 'SET_INTERPRETLANGTWO':
            return {
                ...state,
                interpretLangTwo: action.payload
            }
        case 'RESET_OUTPUTSTATE':
            return {
                ...state,
                outputState: 'full'
            }
        case 'RESET_TWOWAY':
            return {
                ...state,
                otherSpeechMic: false,
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en'
            }
        case 'RESET_CHAT':
            return {
                ...state,
                output: true,
                outputState: 'full',
                tools: false,
                deactivateLimit: false,
                instruct: false,
                instructLang: 'en',
                twoWay: false,
                speechStatus: '',
                otherSpeechMic: false,
                otherSpeechLang: 'en',
                selfSpeechMic: false,
                selfSpeechAuto: false,
                selfAudio: false,
                selfAudioLang: 'en',
            }
        case 'UPDATE_DOCUMENT':
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}

export const AppContextProvider = ({ children }) => {
    
    let isIOS = false;
    let chromeIOS = false;
    if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('Mac')) {
        isIOS = true;
    }
    if (navigator.userAgent.includes('CriOS')) {
        chromeIOS = true;
    }

    const [state, dispatch] = useReducer(appReducer, {
        chatting: false,
        languages: [],
        output: true,
        outputState: 'full',
        tools: false,
        deactivateLimit: false,
        instruct: false,
        instructLang: 'en',
        twoWay: false,
        speechStatus: '',
        otherSpeechMic: false,
        otherSpeechLang: 'en',
        selfSpeechMic: false,
        selfAudio: false,
        selfAudioLang: 'en',
        selfAudioIgnore: false,
        interpretLangOne: null,
        interpretLangTwo: null,
        data: null,
        dataPending: false,
        dataError: false,
        dataExists: 'pending',
        dataUnsub: null,
        chromeIOS,
        isIOS
    });

    const addMsgFxn = httpsCallable(functions, 'translateAndSaveMessage');

    const sendMessage = (text) => {
        return new Promise(async (resolve, reject) => {

        // work in progress...until everything is saved in state
        // text is always a variable
        // we have session, sender, type, textLang, lang
        // need to figure out responseTo, actions.sender, actions.receiver, limit
        //     addMsgFxn({
        //         session,
        //         type: 'text',
        //         sender,
        //         text: msg,
        //         textLang: selfData.lang,
        //         lang: outputLang,
        //         responseTo: responseTo || '',
        //         actions: { 
        //             sender: senderAction.action, 
        //             receiver: receiverAction,
        //             limit: limitData ? {...limitData } : false
        //         }
        //     }).then(() => {
        //         console.log('message sent');
        //         scrollAnchor.current.scrollIntoView();
        //         if (!twoWay) if (content) e.target.classList.remove('speaking');
        //     }).catch(err => console.log(err.message));
        });
    }

    // can't seem to unsubscribe
    const getData = (id) => {
        dispatch({ type: 'UPDATE_DOCUMENT', source: 'local', payload: { 
            dataPending: true
        }});

        const ref = doc(fs, 'sessions', id);
        const unsub = onSnapshot(ref, snapshot => {
            if (snapshot.exists()) {
                console.log('Data listener established');
                
                dispatch({ type: 'UPDATE_DOCUMENT', source: 'local', payload: { 
                    data: snapshot.data(),
                    dataPending: false,
                    dataExists: true
                }});
            } else {
                console.log('Data does not exist - AppContext');
                
                dispatch({ type: 'UPDATE_DOCUMENT', source: 'local', payload: { 
                    dataPending: false,
                    dataError: 'Data does not exist',
                    dataExists: false
                }});
            }
        }, error => {
            console.log('Failed to retrieve data in AppContext');
            
            dispatch({ type: 'UPDATE_DOCUMENT', source: 'local', payload: { 
                dataPending: false,
                dataError: 'Failed to retrieve data',
            }});
        });
        state.dataUnsub = unsub;
    }

    return (
        <AppContext.Provider value={{ ...state, dispatch, sendMessage, getData }}>
            { children }
        </AppContext.Provider>
    )
}