
import { useState, useRef, useEffect } from 'react';
import { useChatContext } from '../../../hooks/useChatContext';
import { useAppContext } from '../../../hooks/useAppContext';
import ClickableIcon from '../../../components/ClickableIcon';

import './ChatTools.css';

import TreeImg from '../../../assets/tree.png';
import LockImg from '../../../assets/lock.png';
import SpeakerImg from '../../../assets/speakerphone.png';
import NoAccessImg from '../../../assets/no-access.png';
import SpeakingHeadImg from '../../../assets/speaking-head.png';
import TwoWayTool from './subcomponents/TwoWayTool';
import InstructionsTool from './subcomponents/InstructionsTool';
import PhraseTreeTool from './subcomponents/PhraseTreeTool';

export default function ChatTools({ data, toolStatus, messageFxn, limitFxn, instructReturnFxn, twoWayReturnFxn }) {
    const { self, users } = useChatContext();
    const { deactivateLimit, instruct, twoWay, dispatch, chromeIOS } = useAppContext();
    const [phrase, setPhrase] = useState(false);
    const [limit, setLimit] = useState(false);
    const [tools, setTools] = useState(false);
    const [selected, setSelected] = useState(false);
    const connect1 = useRef();
    const connect2 = useRef();
    const connect3 = useRef();
    const wrapper = useRef();
    const [selectedLimit, setSelectedLimit] = useState(null);    
    const [userRole, setUserRole] = useState('other');

    useEffect(() => {
        if (!users || !self) return;
        if (!users[self]) return;
        setUserRole(users[self].role);
    }, [users, self]);

    useEffect(() => {
        if (!toolStatus) {
            setLimit(false);
            setTools(false);
            setPhrase(false);
            setSelected(false);
            setConnectors(null);
            dispatch({ type: 'TOOLS_OFF' });
        }
    }, [toolStatus]);

    const handleCategoryClick = (cat) => {
        if (cat === 'phrase') {
            setLimit(false);
            setTools(false);
            if (phrase) {
                setPhrase(false);
                setSelected(false);
                
                setConnectors(null);
                dispatch({ type: 'TOOLS_OFF' });
            } else {
                setPhrase(true);
                setSelected(true);
                
                setConnectors(1);
                dispatch({ type: 'TOOLS_ON' });
            }
            return;
        }
        if (cat === 'limit') {
            setPhrase(false);
            setTools(false);
            if (limit) {
                setLimit(false);
                setSelected(false);
                
                setConnectors(null);
                dispatch({ type: 'TOOLS_OFF' });
            } else {
                setLimit(true);
                setSelected(true);
                
                setConnectors(2);
                dispatch({ type: 'TOOLS_ON' });
            }
            return;
        }
        if (cat === 'instruct') {
            setPhrase(false);
            setLimit(false);
            if (tools) {
                setTools(false);
                setSelected(false);
                
                setConnectors(null);
                // dispatch({ type: 'RESET_TWOWAY' });
                dispatch({ type: 'TOOLS_OFF' });
                dispatch({ type: 'SELFSPEECHAUTO_OFF' });
            } else {
                setTools(true);
                setSelected(true);
                
                setConnectors(3);
                dispatch({ type: 'TOOLS_ON' });
            }
            return;
        }
    }
    const setConnectors = (activate) => {
        connect1.current.classList.remove('active');
        if (connect2.current) connect2.current.classList.remove('active');
        connect3.current.classList.remove('active');
        if (activate === 1) connect1.current.classList.add('active');
        if (activate === 2) connect2.current.classList.add('active');
        if (activate === 3) connect3.current.classList.add('active');
    }

    const handleLimitClick = (id, data) => {
        if (selectedLimit === id) {
            setSelectedLimit(null);
            limitFxn(null);
        } else {
            setSelectedLimit(id);
            limitFxn(data);
        }
    }

    const handleInstructClick = () => {
        setSelectedLimit(null);
        if (users && self && users[self]) dispatch({ type: 'SET_SELFAUDIOLANG', payload: users[self].lang || 'en' });
        if (instruct) {
            dispatch({ type: 'INSTRUCT_OFF' });
            instructReturnFxn('exit', {});
        } else {
            dispatch({ type: 'TWOWAY_OFF' });
            dispatch({ type: 'INSTRUCT_ON' });
        } 
    }
    const handleTwoWayClick = () => {
        setSelectedLimit(null);
        if (users && self && users[self]) dispatch({ type: 'SET_SELFAUDIOLANG', payload: users[self].lang || 'en' });
        if (twoWay) {
            dispatch({ type: 'TWOWAY_OFF' });
            twoWayReturnFxn('exit', {});
        } else {
            dispatch({ type: 'INSTRUCT_OFF' });
            dispatch({ type: 'TWOWAY_ON' });
        }
    }

    const phraseTreeReturnHandler = ({ method, value }) => {
        if (method === 'limit') {
            limitFxn(value);
            setSelectedLimit(null);
        }
    }

    return (
        <>
            <div className={`tools-category ${selected? 'shrink' : ''} ${userRole !== 'provider' && userRole !== 'general' ? 'limited' : ''}`}>
                <div className={`tools-category-option ${phrase ? 'active' : ''}`} onClick={() => handleCategoryClick('phrase')}>
                    <img src={TreeImg} alt="Tree" />
                </div>
                {(userRole === 'provider' || userRole === 'general') && <div className={`tools-category-option ${deactivateLimit ? 'deactive' : ''} ${limit ? 'active' : ''}`} onClick={() => deactivateLimit ? '' : handleCategoryClick('limit')}>
                    {deactivateLimit ? <img src={NoAccessImg} alt="No Access" /> : <img src={LockImg} alt="Padlock" />}
                </div>}
                <div className={`tools-category-option ${tools ? 'active' : ''}`} onClick={() => handleCategoryClick('instruct')}>
                <img src={SpeakingHeadImg} alt="Talking Person" />
                </div>
            </div>
            <div className={`tools-connect ${userRole !== 'provider' && userRole !== 'general' ? 'limited' : ''}`}>
                <div className="tools-connect-unit" ref={connect1}></div>
                {userRole === 'provider' || userRole === 'general' && <div className="tools-connect-unit" ref={connect2}></div>}
                <div className="tools-connect-unit" ref={connect3}></div>
            </div>
            <div className={`tool-box-wrapper ${selected ? 'show' : ''}`} ref={wrapper}>
                {phrase && <PhraseTreeTool data={data} wrapper={wrapper.current} messageFxn={messageFxn} returnFxn={phraseTreeReturnHandler} />}
                {limit && <div className="tool-box-holder">
                    <h5>Restrict Responses</h5>
                    <div className="tools-limit-wrapper content-center flex gap-2 p-2 flex-wrap mt-2 hide-scroll scroll-shadow-both">
                        {data.limits && data.limitSequence.map(key => {
                            if (data.limits[key]) return <div key={key} className={`tools-limit-entry flex-initial ${selectedLimit === key ? 'active' : ''}`} onClick={() => handleLimitClick(key, data.limits[key])}>
                                <div className="limit-category">
                                    {data.limits[key].category === 'choice' ? 'o' : 's'}
                                </div>
                                {data.limits[key].title}
                            </div>
                        })}
                    </div>
                </div>}
                {tools && <div className={`tool-box-holder`}>
                    {!instruct && !twoWay && <h5 className="mb-2">Speakerphone Mode</h5>}
                    {!twoWay && <>
                        {!instruct && <p className="p-small">Use this device to speak and display translated text.</p>}
                        <div className="grow flex flex-col justify-center w-full">
                            <div className={`chat-mode-btn mx-auto ${instruct ? 'active' : ''}`} onClick={handleInstructClick}>
                                {!instruct && <><img src={SpeakerImg} className="h-8 w-8 mr-2" /><p style={{ fontSize: '1rem', fontWeight: 800 }}>Start</p></>}
                                {instruct && <p style={{ fontSize: '0.8rem', fontWeight: 800 }}><span style={{ fontSize: '0.8rem'}} className="p-title mr-1">exit</span>Speakerphone Mode</p>}
                            </div>
                        </div>
                        {/* <hr className="mt-2 opacity-30"></hr> */}
                    </>}
                    {instruct && <InstructionsTool returnFxn={instructReturnFxn} />}
                    {/* {!instruct && <>
                        {!twoWay && <p className="p-small mt-2">Use this device to receive and translate audio input from others.</p>}
                        {!chromeIOS && <div className={`chat-mode-btn mx-2 mb-2 ${twoWay ? 'active mt-2' : 'mt-4'}`} onClick={handleTwoWayClick}>
                            {!twoWay && <p style={{ fontSize: '1rem', fontWeight: 800 }}>Two-Way<span className="p-title ml-1"> mode</span></p>}
                            {twoWay && <p style={{ fontSize: '1rem', fontWeight: 800 }}><span className="p-title mr-1">exit </span>Two Way Mode</p>}
                        </div>}
                        {chromeIOS && <div className="chat-mode-btn deactive mt-4 mx-2 mb-2">
                            Not available on Chrome iOS
                        </div>}
                    </>} */}
                    {twoWay && <TwoWayTool returnFxn={twoWayReturnFxn}/>}
                </div>}
            </div>
        </>
    )
}