import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCollectionListener } from "../hooks/firebase/useCollectionListener"
import { useAuthContext } from "../hooks/useAuthContext";

import './ChatroomList.css';

import UserList from "./UserList";

export default function ChatroomList() {
    const { user } = useAuthContext();
    const { documents, error, pending } = useCollectionListener('sessions', [['status.active', '==', true]], ['timestamp', 'desc']);
    // const { documents, error, pending } = useCollectionListener('sessions');
    const navigate = useNavigate();
    const [filteredDocuments, setFilteredDocuments] = useState(documents);

    const getLastMessage = (doc) => {
        let lastMessageTimestamp = 0;
        Object.keys(doc).forEach(key => {
            if (key === 'timestamp' || key === 'status' || key === 'info') return;
            if (Number(key) > lastMessageTimestamp) lastMessageTimestamp = Number(key);
        });
        if (lastMessageTimestamp > 0) {
            return new Date(lastMessageTimestamp).toLocaleString();
        } else {
            return doc.status.initTimestamp.toDate().toLocaleString();
        }
    }

    const handleClick = (e, id) => {
        if (!e.target.closest('.user-list-wrapper')) {
            navigate(`/chatlobby/${id}`);
        }
    }

    useEffect(() => {
        if (!documents || pending) return;
        let results = documents.filter(doc => {
            if (doc.info[user.uid].active) return doc;
        });
        setFilteredDocuments(results);

    }, [documents, pending, user.uid])

    return (
        <div className="chatroom-list-wrapper flex flex-col justify-center gap-4 hide-scroll">
            {filteredDocuments && filteredDocuments.map(doc => (
                <div key={doc.id} className="chatroom-entry">
                    <div className="flex justify-between header" onClick={(e) => handleClick(e, doc.id)}>
                        <h5>{doc.id}</h5>
                        <p className="self-center">{getLastMessage(doc)}</p>
                    </div>
                <div className="users">
                    <UserList type="header" sessionID={doc.id} />
                </div>
                </div>
            ))}
            {pending && <p>loading...</p>}
            {error && <p className="error">{error}</p>}
        </div>
    )
}