import { useState, useEffect } from 'react';
import { useChatContext } from '../../../../hooks/useChatContext';
import { useAppContext } from '../../../../hooks/useAppContext';

import './Instructions.css';

import ClickableIcon from '../../../../components/ClickableIcon';

export default function Instruction({ data }) {
    const [content, setContent] = useState('');
    const [initTime, setInitTime] = useState(0);
    const { self, users } = useChatContext();
    const { instructLang } = useAppContext();

    useEffect(() => {
        setInitTime(new Date().getTime());
    }, []);

    useEffect(() => {
        if (!data) return;
        if (initTime === 0) return;
        if (!users) return;
        const keys = Object.keys(data);
        keys.splice(keys.indexOf('info'), 1);
        keys.splice(keys.indexOf('status'), 1);
        if (keys.indexOf('timestamp') !== -1) keys.splice(keys.indexOf('timestamp'), 1);
        let senderKeys = keys.filter(r => data[r].sender === users[self].tempID);
        senderKeys = senderKeys.map(r => Number(r));
        senderKeys = senderKeys.sort();
        let largestKey = Math.max(...senderKeys);
        if (isNaN(largestKey) || largestKey < 0) largestKey = 0;
        
        let result = '';
        let match = {};

        if (largestKey < initTime) return;

        match = data[largestKey];
        if (match.translatedText) {
            match.translatedText.forEach(entry => {
                if (entry.lang === instructLang) result = entry.text;
            });
        }

        setContent(result);
    }, [data, users, self, initTime, instructLang]);

    const [expand, setExpand] = useState(0);
    const handleExpand = () => {
        if (expand === 0) return setExpand(1);
        if (expand === 1) return setExpand(2);
        if (expand === 2) return setExpand(0);
    }

    return (
        <div className="instruct-holder">
            <div className="instruct-holder-expand-btn" onClick={handleExpand}>
                {(!expand || expand === 1) && <ClickableIcon type="expand" addClass="small no-background no-hover" />}
                {expand === 2 && <ClickableIcon type="collapse" addClass="small no-background no-hover" />}
            </div>
            <div className={`instruct-content notranslate hide-scroll ${expand >= 1 ? 'expand-frame' : ''} ${expand === 2 ? 'expand-font' : ''}`}>{content}</div>
        </div>
    )
}