import { useState, useEffect, useRef, memo } from 'react';
import { useChatContext } from '../../../hooks/useChatContext';
import { useAppContext } from '../../../hooks/useAppContext';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { functions } from '../../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { serverTimestamp } from 'firebase/firestore';

import './ChatEntry.css';

import ProfilePic from '../../../components/ProfilePic';
import ClickableIcon from '../../../components/ClickableIcon';
import LimitSlider from '../../../components/LimitSlider';
import LoadingIcon from '../../../components/LoadingIcon';

export default function ChatEntry({ data, messageFxn }) {
    const speakMsgFxn = httpsCallable(functions, 'textToSpeech');
    const { updateDocument, response } = useFirestore('sessions');
    const { session, self, users } = useChatContext();
    const { timestamp, languages, interpretLangOne, interpretLangTwo } = useAppContext();
    const memoActions = useRef(data.actions);
    const speakerIcon = useRef();
    const largeSpeaker = useRef();
    const isSender = self === data.sender ? true : false;
    const isTwoWayGuest = data.sender === 'twoWay-guest' ? true : false;
    const [incomingAudio, setIncomingAudio] = useState(false);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [limit, setLimit] = useState(false);
    const [scaleOptions, setScaleOptions] = useState([]);
    const [scaleValue, setScaleValue] = useState(null);
    const [responseStatus, setResponseStatus] = useState(false);
    const [context, setContext] = useState('');
    const [persistentContext, setPersistentContext] = useState('');
    const [pureContext, setPureContext] = useState('');
    const [gender, setGender] = useState('NEUTRAL');
    const [senderData, setSenderData] = useState({ photoURL: ''});
    const [selfData, setSelfData] = useState({});
    const [isImg, setIsImg] = useState(false);
    const [imgFullScreen, setImgFullScreen] = useState(false);
    const memoSelfData = useRef(selfData);

    const [isInterpreter, setIsInterpreter] = useState(false);
    const [interpreterSender, setInterpreterSender] = useState(false);
    const [interpreterContext, setInterpreterContext] = useState('');
    const [interpreterPhotoURL, setInterpreterPhotoURL] = useState(null);
    const [interpreterDisplayName, setInterpreterDisplayName] = useState('');
    const [correction, setCorrection] = useState(false);
    const [correctComment, setCorrectComment] = useState('');
    const [correctionNotification, setCorrectionNotification] = useState(false);

    const msgTime = new Date(Number(data.timestamp));
    const formattedTime = formatDistanceToNow(msgTime, { addSuffix: true }).replace('about ', '');

    // scroll handlers
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    let scrolling = false;
    const holder1 = useRef();

    const handleScroll = () => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder1.current.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder1.current) return;
        let height = holder1.current.offsetHeight || 0;
        let totalHeight = holder1.current.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder1, scrollTop]);

    useEffect(() => {
        if (!users || !self) return;
        setSelfData(users[self]);
    }, [users, self]);

    // limits
    useEffect(() => {
        if (!selfData) return;
        if (!data.actions.limit) return;
        if (!data.actions.limit.translated) return;
        // if (data.actions.limit.category !== 'scale') return;
        if (data.actions.limit.category === 'scale') {
            let result = {};
            Object.values(data.actions.limit.translated).forEach(x => {
                if (x.lang === selfData.lang) result = x.text;
            });
            
            setScaleOptions(result);
        }
        
        let reStatus = false;
        if (selfData.role === 'patient' || selfData.role === 'other' || selfData.role === 'general') {

            let hasAllResponded = true;
            if (data.actions.targeted && data.actions.targeted.length > 0) {
                if (!data.actions.limit.status) {
                    hasAllResponded = false;
                } else {
                    data.actions.targeted.forEach(u => {
                        if (!data.actions.limit.status[u]) hasAllResponded = false;
                    });
                }
            } else {
                Object.values(users).forEach(u => {
                    if (u.tempID !== data.sender && u.role !== 'provider' && u.role !== 'interpreter') {
                        // only set to false if there are actually other users
                        if (!data.actions.limit.status) {
                            hasAllResponded = false;
                        } else {
                            if (!data.actions.limit.status[u.tempID]) hasAllResponded = false;
                        }
                    }
                });
            }
            reStatus = hasAllResponded ? 'old' : 'new';            
        }
        if (selfData.role === 'intepreter') {
            if (!data.actions.limit.status) {
                reStatus = 'waiting';
            } else {
                let check = true;
                Object.values(users).forEach(u => {
                    if (u.role === 'patient' || u.role === 'other' || u.role === 'general') {
                        if (Number(u.timestamp) < Number(data.timestamp)) {
                            if (!data.actions.limit.status[u.tempID]) check = false;
                        }
                    }
                });
                reStatus = check ? 'old' : 'waiting';
            }
        }
        setResponseStatus(reStatus);
    }, [data, selfData, isSender]);

    // audio && limit modals
    useEffect(() => {
        if (!selfData) return;
        if (isSender) {
            if (data.actions.sender === 'audio') {
                // speakerIcon.current.click();
            }
        } else {
            if (selfData.role === 'other' || selfData.role === 'patient' || selfData.role === 'general') {
                if (data.actions.receiver === 'audio') {
                    if (data.timestamp > timestamp) {
                        let match = false;
                        if (!data.actions.targeted) {
                            match = true
                        } else {
                            if (data.actions.targeted.length === 0) {
                                match = true;
                            } else {
                                if (data.actions.targeted.indexOf(selfData.tempID) !== -1) match = true;
                            }
                        };
                        if (match) {
                            if ((data.actions.actionCompleted && data.actions.actionCompleted.indexOf(self) === -1) || !data.actions.actionCompleted) {
                                setIncomingAudio(true);
                            }
                        }
                    }
                }   
            }
        }
        
        if (!isSender) if (selfData.role === 'other' || selfData.role === 'patient' || selfData.role === 'general') {
            if (data.timestamp > timestamp) {
                if (!data.actions.limit) return;
                let match = false;
                if (!data.actions.targeted) {
                    match = true;
                } else {
                    if (data.actions.targeted.length === 0) {
                        match = true;
                    } else {
                        if (data.actions.targeted.indexOf(selfData.tempID) !== -1) {
                            
                            match = true;
                        }
                    }
                }
                if (match) {
                    if (!data.actions.limit.status) return setLimit(true);
                    if (!data.actions.limit.status[selfData.tempID]) return setLimit(true);
                }
            }
        }
    }, [isSender, selfData, data, timestamp, data.timestamp]);
    
    // setup
    useEffect(() => {
        if (data && data.type === 'text') {
            setContext(data.text);
            setPersistentContext(data.text);
            if (selfData) {
                if (selfData.role !== 'interpreter') {
                    data.translatedText.forEach(async item => {
                        if (item.lang === selfData.lang) {
                            if (data.actions.targeted && data.actions.targeted.length > 0) {
                                let targetedUsers = [];
                                data.actions.targeted.forEach(entry => {
                                    if (users[entry]) {
                                        const name = '@' + users[entry].displayName.replaceAll(' ', '-').toLowerCase();
                                        if (targetedUsers.indexOf(name) === -1) targetedUsers.push(name);
                                    }
                                });
                                setContext(targetedUsers.join(' ') + ' ' + item.text);
                                setPersistentContext(targetedUsers.join(' ') + ' ' + item.text);
                            } else {
                                setContext(item.text);
                                setPersistentContext(item.text);
                            }
                            setPureContext(item.text);
                        }
                    });
                } else {
                    let firstLang = languages[interpretLangOne];
                    let secondLang = languages[interpretLangTwo];
                    if (data.textLang === firstLang) {
                        data.translatedText.forEach(async item => {
                            if (item.lang === secondLang) setInterpreterContext(<p>▪ "{item.text}"</p>);
                        });
                    } else if (data.textLang === secondLang) {
                        data.translatedText.forEach(async item => {
                            if (item.lang === firstLang) setInterpreterContext(<p>▪ "{item.text}"</p>);
                        });
                    } else {
                        let firstText = '';
                        let secondText = '';
                        data.translatedText.forEach(async item => {
                            if (item.lang === firstLang) firstText = `"${item.text}"`;
                            if (item.lang === secondLang) secondText = `"${item.text}"`;
                        });
                        if (firstLang === secondLang) {
                            setInterpreterContext(<p>▪ {firstText}</p>);
                        } else {
                            setInterpreterContext((
                                <>
                                    <p>▪ {firstText}</p>
                                    <p>▪ {secondText}</p>
                                </>
                            ));
                        }
                    }
                }
            }

            if (users && users[data.sender]) {
                if (users[data.sender].gender === 'male') setGender('MALE');
                if (users[data.sender].gender === 'female') setGender('FEMALE');
            }
        }
        if (data && data.type === 'image') {
            setIsImg(true);
            setContext((<img src={data.imgURL} className="message-content-img" onClick={() => setImgFullScreen(true)} />));
            setPersistentContext((<img src={data.imgURL} className="message-content-img" onClick={() => setImgFullScreen(true)} />));
        }

        if (users) {
            if (data.sender && users[data.sender]) {
                setSenderData(users[data.sender]);
                if (users[data.sender].role === 'interpreter') setInterpreterSender(true);
            }
            if (self && users[self]) {
                setSelfData(users[self]);
                if (users[self].role === 'interpreter') setIsInterpreter(true);
            }

            if (data.correction) {
                if (data.correctionContext.interpreter) {
                    const interpreterData = users[data.correctionContext.interpreter];
                    if (interpreterData) {
                        setInterpreterPhotoURL(interpreterData.photoURL);
                        setInterpreterDisplayName(interpreterData.displayName);
                    }
                }
            }
        }
    }, [data, users, selfData, memoSelfData, interpretLangOne, interpretLangTwo, languages, self]);

    // corrections
    useEffect(() => {
        if (!users || !self || !users[self]) return;
        if (users[self].role === 'interpreter') return;
        if (!data.correction) return;
        if (data.correctionContext.read.indexOf(self) === -1) {
            setCorrectionNotification(true);
        }
    }, [data, users, self]);

    const speakMessage = () => {
        return new Promise((resolve, reject) => {
            let contextFiltered = context;
            if (context && context[0] === '@') {
                let strArr = context.split(' ');
                strArr.shift();
                contextFiltered = strArr.join(' ');
            }
            setAudioPlaying(true);
            var audioContxt = window.AudioContext || window.webkitAudioContext;
            const audioContext = new audioContxt();
            audioContext.resume()
                .then(() => {
                    speakMsgFxn({ text: contextFiltered, lang: selfData.lang, gender })
                        .then(async resp => {
                            const audioContent = resp.data;
                            const content = Object.values(audioContent);
                            // const audioContext = new AudioContext();
                        
                            const arr = Uint8Array.from(content);
                            
                            const audio = await audioContext.decodeAudioData(arr.buffer.slice(0));
                            const source = audioContext.createBufferSource();
                            source.buffer = audio;
                            source.connect(audioContext.destination);
                            source.start(0);

                            const icon = speakerIcon.current.querySelector('.pin');
                            icon.classList.remove('mini');
                            icon.classList.add('small');
                            icon.classList.add('active');
                            if (largeSpeaker.current) {
                                largeSpeaker.current.classList.add('animate-custom-pulse');
                            }
                            source.onended = (() => {
                                icon.classList.add('mini');
                                icon.classList.remove('small');
                                icon.classList.remove('active');
                                if (largeSpeaker.current) {
                                    largeSpeaker.current.classList.remove('animate-custom-pulse');
                                }
                                setAudioPlaying(false);
                                setIncomingAudio(false);
                                resolve('done');
                            });
                        })
                }).catch(err => {
                    console.log(err.message);
                    resolve('done');
                });
        });
    }

    const listenAudio = async (e) => {
        if (e.target.classList.contains('dismiss')) {
            setIncomingAudio(false);
        } else {
            if (!audioPlaying) await speakMessage();
        }

        let actionCompleted = data.actions.actionCompleted ? [...data.actions.actionCompleted, self] : [self];
        let save = {
            actions: { 
                ...data.actions,
                actionCompleted
            }
        }
        let toSave = {};
        toSave[data.timestamp] = { ...data, ...save};

        await updateDocument(session, toSave);

        handleMultipleScreens('clear');
    }

    const handleEntryClick = () => {
        if (selfData.role === 'provider') return;
        if (selfData.role === 'interpreter') {
            if (!isSender) setCorrection(true);
            return;
        }
        if (isSender) return;
        if (!data.actions.limit) return;
        if (data.actions.limit.status && data.actions.limit.status[selfData.tempID]) return;
        setLimit(true);
    }

    const handleChoiceMessage = async (e, content) => {
        let save = {};
        save[data.timestamp] = { ...data };
        save[data.timestamp].actions.limit.status = { ...save[data.timestamp].actions.limit.status };
        save[data.timestamp].actions.limit.status[selfData.tempID] = true;
        await updateDocument(session, save);

        messageFxn(e, content, data.text);
        setLimit(false);

        handleMultipleScreens('clear');
    }

    const getSliderValue = (result) => {
        setScaleValue(result);
    }

    const handleScaleMessage = async (e) => {
        if (!scaleValue) return;
        let content = scaleValue.value;
        if (scaleValue.text) content += ' - ' + scaleValue.text;
        let save = {};
        save[data.timestamp] = { ...data };
        save[data.timestamp].actions.limit.status = { ...save[data.timestamp].actions.limit.status };
        save[data.timestamp].actions.limit.status[selfData.tempID] = true;
        await updateDocument(session, save);

        messageFxn(e, content, data.text);
        setScaleValue(null);
        setLimit(false);

        handleMultipleScreens('clear');
    }

    const handleScreenClose = (e) => {
        if (e.target.closest('.center-modal')) return;
        setCorrection(false);
    }
    const handleCorrectionSubmit = async (e) => {
        e.preventDefault();
        if (!correctComment) return;
        if (response.isPending) return;

        let save = { 
            correction: true,
            correctionContext: {
                interpreter: selfData.tempID, // eventually license?
                timestamp: new Date().getTime(),
                serverTimestamp: serverTimestamp(),
                text: correctComment,
                read: []
            }
        };
        let toSave = {};
        toSave[data.timestamp] = { ...data, ...save};
        
        await updateDocument(session, toSave);
        messageFxn({}, `Interpreter comment made to message sent by ${senderData.displayName || 'uknown guest'} ${formattedTime}`);

        setCorrection(false);
        setCorrectComment('');
    }

    const handleCorrectionAcknowledge = async () => {
        let save = {
            correctionContext : {
                ...data.correctionContext,
                read: [...data.correctionContext.read, self]
            }
        };
        let toSave = {};
        toSave[data.timestamp] = { ...data, ...save};
        
        await updateDocument(session, toSave);

        setCorrectionNotification(false);

        handleMultipleScreens('clear');
    }

    const handleMultipleScreens = (method) => {
        document.querySelectorAll('.screen').forEach((el, index) => {
            
            el.classList.remove('hide-modal');
            el.classList.remove('no-shade');
            const length = document.querySelectorAll('.screen').length;
            const zIndex = (length - index + 1) * 100;
            el.style.zIndex = zIndex;

            el.classList.add('no-shade');
            if (index !== 0) {
                el.classList.add('hide-modal');
            }
            if (method === 'clear') {
                if (index === 1) el.classList.remove('hide-modal');
            }
            if (index === length - 1) {
                el.classList.remove('no-shade');
            }
        });

    }

    const [reveal, setReveal] = useState(false);
    useEffect(() => {
        if (!data) return;

        if (reveal) {
            setContext(data.text);
        } else {
            setContext(persistentContext);
        }
    }, [reveal, persistentContext, data]);
    
    return (
        <div className={`w-full flex ${isSender ? 'sender' : ''} ${isSender ? 'justify-end' : ''} ${isInterpreter ? 'interpreter' : ''} ${data.correction ? 'correct' : ''}`}>
            <div className={`chat-content-wrapper ${isSender ? 'sender' : ''}`}>
                <div className={`chat-entry ${isTwoWayGuest ? 'twoWay-guest' : ''} ${isSender ? 'sender' : ''} ${interpreterSender ? 'message-interpreter' : ''}`}>
                    {isSender && !isInterpreter && !isImg && (
                        <div ref={speakerIcon} onClick={speakMessage} className="self-center">
                            <ClickableIcon 
                                addClass="no-background mini speak-message" 
                                type="speaker" 
                            />
                        </div>
                    )}
                    <div className={`chat-entry-content ${selfData && selfData.role !== 'provider' ? 'cursor-pointer' : ''}`} onClick={handleEntryClick}>
                        <ProfilePic url={senderData.photoURL} size="mini" />
                        <div className="ml-2 w-full">
                            {!isTwoWayGuest && <p className="message-sender notranslate">{senderData.displayName}</p>}
                            {isTwoWayGuest && <p className="message-sender">Unknown Guest</p>}
                            <p className="message-content notranslate">{context}</p>
                            {isInterpreter && !isSender && <div className="message-translate-wrapper mt-1">
                                <p className="message-translate-title">Translation:</p>
                                <div className="message-translate-content notranslate">{interpreterContext}</div>
                            </div>}
                            {!isInterpreter && data.correction && users && <div className="correct-comment-wrapper mt-1">
                                <div className="correct-comment-title">
                                    <ProfilePic url={interpreterPhotoURL} size="micro" />
                                    {interpreterDisplayName} commented:
                                </div>
                                <div className="correct-comment-content notranslate">{data.correctionContext.text}</div>
                            </div>}
                        </div>
                    </div>
                    {!isSender && !isInterpreter && !isImg && (
                        <div ref={speakerIcon} onClick={speakMessage} className="self-center">
                            <ClickableIcon 
                                type="speaker" 
                                addClass="no-background mini speak-message" 
                            />
                        </div>
                    )}
                    {data && data.correction && <div className="correct-flag">🚩</div>}
                    {isTwoWayGuest && <div className="twoWay-guest-status">unregistered</div>}
                    {interpreterSender && <div className="interpreter-status">interpreter</div>}
                    {responseStatus === 'new' && <div className="response-status"><div className="blip">Re</div><p className="p-small">Re</p></div>}
                    {responseStatus === 'waiting' && <div className="response-status"><div className="blip no-animation">Re</div><p className="p-small">Re</p></div>}
                    {responseStatus === 'old' && <div className="response-status complete"><p className="p-small">Re</p><ClickableIcon type="check" addClass="micro no-background no-hover invert" /></div>}
                    {data.responseTo && <div className="response-info">Re: "{data.responseTo}"</div>}
                </div>
                <p className="msg-timestamp mt-1">{formattedTime}</p>
            </div>

            {!isSender && data.type !== 'image' && selfData.role !== 'interpreter' && <div className={`reveal-wrapper flex flex-col ml-auto ${reveal ? 'show' : ''}`} onClick={() => reveal ? setReveal(false) : setReveal(true)}>
                <div className="grow self-center flex items-center">
                    {!reveal && <ClickableIcon type="hdr_strong" addClass="mini no-background" />}
                    {reveal && <ClickableIcon type="hdr_weak" addClass="mini no-background" />}
                </div>
                <p className="mt-1" style={{ fontSize: '10px', height: '12.5px' }}></p>
            </div>}

            {incomingAudio && <div className="screen" onClick={listenAudio} onLoad={handleMultipleScreens}>
                <div className="center-modal">
                    <h4>Incoming audio</h4>
                    <p>from {senderData.displayName}</p>
                    <div className="large-speaker flex justify-center" ref={largeSpeaker}>
                        <ClickableIcon type="speaker" addClass="no-background x-large opacity-80" />
                    </div>
                    <p className="mt-1 text-center">Tap on the screen to listen to audio</p>
                    <div className="mt-6 flex justify-center">
                        <button className="dismiss" onClick={() => setIncomingAudio(false)}>dismiss</button>
                    </div>
                </div>
            </div>}

            {limit && data.actions.limit.category === 'choice' && <div className="screen" onLoad={handleMultipleScreens}>
                <div className="center-modal choice">
                    <div className="flex w-full items-center" style={{ marginTop: '-0.5rem', marginLeft: '-0.5rem' }}>
                        <ProfilePic url={senderData.photoURL} size="mini" />
                        <p className="ml-1 font-bold">{senderData.displayName}:</p>
                    </div>
                    <div className="w-full flex justify-center">
                        <p className="response-context notranslate">"{pureContext}"</p>
                    </div>
                    <div ref={holder1} className={`response-choice-wrapper hide-scroll mt-4 ${scrollClass}`} onScroll={handleScroll}>
                        {data.actions.limit.choices && data.actions.limit.translated.map((c) => {
                            if (c.lang === selfData.lang) {
                                return c.text.map((t, indx) => (
                                    <div key={indx} className="response-choice" onClick={(e) => handleChoiceMessage(e, t)}>{t}</div>
                                ))
                            }
                        })}
                        <div className="response-choice" onClick={(e) => handleChoiceMessage(e, '...')}>Not Applicable</div>
                    </div>
                    <div className="flex justify-center mt-4">
                        <div onClick={(e) => handleChoiceMessage(e, 'Not Applicable')}>
                            <ClickableIcon type="close" addClass="no-hover alt-color" />
                        </div>
                    </div>
                </div>
            </div>}

            {limit && data.actions.limit.category === 'scale' && <div className="screen">
                <div className="center-modal scale">
                    <button className="cancel-response btn-circle small" onClick={(e) => handleChoiceMessage(e, '...')}>
                        <ClickableIcon type="close" addClass="no-background mini mx-auto invert" />
                    </button>
                    <div className="flex w-full items-center" style={{ marginTop: '-0.5rem', marginLeft: '-0.5rem' }}>
                        <ProfilePic url={senderData.photoURL} size="mini" />
                        <p className="ml-1 font-bold">{senderData.displayName}:</p>
                    </div>
                    <div className="w-full flex justify-center">
                        <p className="response-context notranslate">"{pureContext}"</p>
                    </div>
                    <div className="response-scale-wrapper mt-4">
                        <LimitSlider 
                            min={data.actions.limit.scale.min} 
                            max={data.actions.limit.scale.max} 
                            options={scaleOptions} 
                            returnFxn={getSliderValue}
                        />
                    </div>
                    <div className="flex justify-center mt-4">
                        <div onClick={handleScaleMessage}>
                            <ClickableIcon type="check" addClass="no-hover alt-color" />
                        </div>
                    </div>
                </div> 
            </div>}

            {imgFullScreen && <div className="screen img-fullscreen-wrapper" onClick={() => setImgFullScreen(false)}>
                <img src={data.imgURL} className="message-img-fullscreen" />
            </div>}

            {correction && <div className="screen" onClick={handleScreenClose}>
                <div className="center-modal correction">
                    <div className="correct-modal-close" onClick={() => setCorrection(false)}>
                        <ClickableIcon type="close" addClass="mini no-hover invert" />
                    </div>
                    <h5>Make Correction</h5>
                    <div className="correct-header mt-4">
                        <ProfilePic url={senderData.photoURL} size="mini" background={true} />
                        {!isTwoWayGuest && <p className="p-emphasize self-center notranslate">{senderData.displayName} :</p>}
                        {isTwoWayGuest && <p className="p-emphasize self-center">Unknown Guest :</p>}
                        <div className="correct-timestamp ml-auto self-center">{formattedTime}</div>
                    </div>
                    <div className="correct-content notranslate">
                        "{context}"
                    </div>
                    <p className="p-emphasize mt-2">Translation:</p>
                    <div className="correct-translation notranslate">
                        <div>
                            {interpreterContext}
                        </div>
                    </div>
                    <form className="mt-8" onSubmit={handleCorrectionSubmit}>
                        <label className="flex flex-col">
                            <span className="p-emphasize">Comment:</span>
                            <input
                                className="mt-2"
                                type="text"
                                onChange={(e) => setCorrectComment(e.target.value)}
                                value={correctComment}
                            />
                        </label>
                        {!response.isPending && <div className="mt-4 correct-modal-btn" onClick={handleCorrectionSubmit}>
                            <ClickableIcon type="check" addClass="no-hover invert mx-auto" />
                        </div>}
                        {response.isPending && <div className="mt-4 correct-modal-btn">
                            <ClickableIcon type="loading" size="small" addClass="mx-auto no-hover" />
                        </div>}
                        {response.error && <p className="error">{response.error}</p>}
                    </form>
                </div>
            </div>}
            {correctionNotification && <div className="screen" onLoad={handleMultipleScreens}>
                <div className="center-modal correct-notification">
                    <h5>Interpreter Correction</h5>
                    <div className="correct-header mt-4">
                        <ProfilePic url={senderData.photoURL} size="mini" background={true} />
                        {!isTwoWayGuest && <p className="p-emphasize self-center notranslate">{senderData.displayName} :</p>}
                        {isTwoWayGuest && <p className="p-emphasize self-center">Unknown Guest :</p>}
                        <div className="correct-timestamp ml-auto self-center">{formattedTime}</div>
                    </div>
                    <div className="correct-content notranslate">
                        "{context}"
                    </div>
                    <div className="correct-header mt-2">
                        <ProfilePic url={users[data.correctionContext.interpreter].photoURL} badge={true} text={'interpreter'} size="mini" background={true} />
                        <p className="p-emphasize self-center notranslate">{users[data.correctionContext.interpreter].displayName} :</p>
                        <div className="correct-timestamp ml-auto self-center">{formatDistanceToNow(data.correctionContext.serverTimestamp.toDate(), { addSuffix: true }).replace('about ', '')}</div>
                    </div>
                    <div className="correct-translation notranslate">
                        <div>
                            "{data.correctionContext.text}"
                        </div>
                    </div>
                    {!response.isPending && <div className="flex justify-center mt-8">
                        <button className="w-40" onClick={handleCorrectionAcknowledge}>ok</button>
                    </div>}
                    {response.isPending && <div className="flex justify-center mt-8">
                        <button className="flex justify-center w-40">
                            <LoadingIcon size="small" addClass="self-center" />
                        </button>
                    </div>}
                    {response.error && <p className="error">{response.error}</p>}
                </div>
            </div>}
        </div>
    )
}
