
import { createContext, useReducer } from "react";

export const ChatContext = createContext();

export const chatReducer = (state, action) => {
    // console.log('chatContext', action.type, action.payload, action.source);
    switch (action.type) {
        case 'INIT':
            return { ...state, 
                session: action.payload.session, 
                owner: action.payload.owner, 
                self: action.payload.self, 
                users: action.payload.users,
                active: action.payload.active,
                guests: action.payload.guests,
                invites: action.payload.invites
            };
        case 'CHAT_LOBBY':
            return { ...state,
                session: action.payload.session, 
                owner: action.payload.owner, 
                self: action.payload.self,
                users: action.payload.users,
                active: action.payload.active,
                guests: action.payload.guests,
                invites: action.payload.invites
            }
        case 'JOIN':
            return {
                ...state,
                self: action.payload.self,
                users: action.payload.users,
                active: action.payload.active
            }
        case 'UPDATE':
            return { ...state, ...action.payload }
        case 'RETURN':
            return {
                ...state,
                active: true
            }
        case 'PAUSE': 
            return {
                ...state,
                pause: true
            }
        case 'UNPAUSE': 
            return {
                ...state,
                pause: false
            }
        case 'EXIT':
            return {
                session: null,
                owner: null,
                users: null,
                self: null,
                active: false,
                pause: false,
                clear: false,
                guests: true,
                invites: []
            }
        case 'END':
            return {
                session: null,
                owner: null,
                users: null,
                self: null,
                active: false,
                pause: false,
                clear: false,
                guests: true,
                invites: []
            }   
        case 'LOGOUT':
            return {
                session: null,
                owner: null,
                users: null,
                self: null,
                active: false,
                pause: false,
                clear: false,
                guests: true,
                invites: []
            }
        case 'CLEAR':
            return {
                ...state,
                clear: true
            }
        case 'RESET_CLEAR':
            return {
                ...state,
                clear: false
            }
        default:
            return state;
    }
}

export const ChatContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(chatReducer, {
        session: null,
        owner: null,
        users: null,
        self: null,
        active: false,
        pause: false,
        clear: false,
        guests: true,
        invites: []
    });

    return (
        <ChatContext.Provider value={{ ...state, dispatch }}>
            { children }
        </ChatContext.Provider>
    )
}