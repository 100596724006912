import { useEffect, useState, useRef } from 'react';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { useAuthContext } from '../../../hooks/useAuthContext';

import './ScenarioList.css';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';
import SnowmanImg from '../../../assets/snowman.png';

export default function ScenarioList({ data, returnFxn }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [animate, setAnimate] = useState(false);
    const [scenarios, setScenarios] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [holder, setHolder] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    const [processingID, setProcessingID] = useState(null);
    const [doubleTap, setDoubleTap] = useState(null);
    // const memoData = useRef(data).current;
    let scrolling = false;

    // scroll handlers
    const handleScroll = () => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);

    useEffect(() => {
        if (!data) return setIsEmpty(true);
        if (!data.scenarios) return setIsEmpty(true);
        setIsEmpty(false);
        let entries = [];
        data.scenarioSequence.forEach(item => {
            if (!data.scenarios[item]) return;
            entries.push(data.scenarios[item]);
        });

        setScenarios(entries);
        if (entries.length === 0) setIsEmpty(true);
    }, [data])

    const toggleAnimation = () => {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500);
    }

    const onLoad = (e) => {
        setHolder(e.target.closest('.scenario-list-wrapper'));
        document.addEventListener('click', (e) => {
            if (e.target.closest('.scenario-list-entry')) return;
            document.querySelectorAll('.scenario-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
            setDoubleTap(null);
        })
    }

    const handleEditEntry = async (e, id) => {
        if (e.target.closest('.delete-entry')) {
            setProcessingID(id);
            let save = { scenarios: data.scenarios || {}, scenarioSequence: data.scenarioSequence };
            delete save.scenarios[id];
            save.scenarioSequence.splice(save.scenarioSequence.indexOf(id), 1);

            await updateDocument(user.uid, save);
        } else if (e.target.closest('.up-arrow') || e.target.closest('.down-arrow')) {
            return;
        } else {
            if (doubleTap === id) {
                returnFxn('edit', id);
            } else {
                document.querySelectorAll('.scenario-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
                e.target.closest('.scenario-list-entry').classList.add('highlight');
                setDoubleTap(id);
            }
        }
    }

    const changeSequence = async (method, id, index) => {
        if (response.isPending) return;
        if (method === 'up') {
            if (index - 1 < 0) return;
            const target = data.scenarioSequence[index];
            data.scenarioSequence.splice(index, 1);
            data.scenarioSequence.splice(index - 1, 0, target);
        } else if (method === 'down') {
            const target = data.scenarioSequence[index];
            if (index + 1 === data.scenarioSequence.length) return;
            data.scenarioSequence.splice(index, 1);
            data.scenarioSequence.splice(index + 1, 0, target);
        }

        await updateDocument(user.uid, { scenarioSequence: data.scenarioSequence });
    }
    
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const handleSort = async () => {
        let items = [...scenarios];
        const draggedItemContent = items[dragItem.current];
        items.splice(dragItem.current, 1);
        items.splice(dragOverItem.current, 0, draggedItemContent);
        setScenarios(items);

        let seq = [];
        items.forEach(item => seq.push(item.id));
        
        await updateDocument(user.uid, {scenarioSequence: seq});
    }

    return (
        <>
            {isEmpty && (
                <div className="scenario-list-wrapper flex items-center" onLoad={(e) => setHolder(e.target.closest('.scenario-list-wrapper'))}>
                    <div className="m-auto flex flex-col items-center">
                        <div className="my-auto">
                            {!animate && <h1 className="just-showing" onClick={toggleAnimation} unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            {animate && <h1 onClick={toggleAnimation} className="just-showing animate-shake" unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            <i className="p-emphasize mt-2">No items</i>
                        </div>
                    </div>
                </div>
            )}
            {scenarios && scenarios.length > 0 && (
                <div className={`scenario-list-wrapper hide-scroll ${scrollClass} grid grid-cols-1 gap-4 auto-rows-max md:pt-16 md:pb-16`} onScroll={handleScroll} onLoad={onLoad}>
                    {scenarios && scenarios.map((sc, index) => (
                        <div 
                            key={sc.id} 
                            className="scenario-list-entry" 
                            onClick={(e) => handleEditEntry(e, sc.id)} 
                            data-index={index}
                            onDragStart={(e)=> dragItem.current = index} 
                            onDragEnter={(e) => dragOverItem.current = index} 
                            onDragEnd={handleSort} 
                            onDragOver={(e) => e.preventDefault()}
                            draggable
                        >
                            <div className="grow flex flex-col h-full">
                                <div className="entry-header flex justify-between">
                                    <p className="p-title self-center">{sc.title}</p>
                                    <div className="delete-entry">
                                        {!response.isPending && <ClickableIcon type="trash" addClass="mini no-background self-center" />}
                                        {response.isPending && (processingID === sc.id) && <LoadingIcon size="small" />}
                                    </div>
                                </div>
                            </div>
                            <div className="up-arrow" onClick={e => changeSequence('up', sc.id, index)}>
                                <ClickableIcon type="arrow_up" addClass="alt-color no-hover" />
                            </div>
                            <div className="down-arrow" onClick={e => changeSequence('down', sc.id, index)}>
                                <ClickableIcon type="arrow_down" 
                                addClass="alt-color no-hover" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}