import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFirestore } from "../hooks/firebase/useFirestore";

import ClickableIcon from "./ClickableIcon";

import { appName } from "../tools/tools";
import HandWaveImg from '../assets/hand_wave.png';
import CheatForm from "./CheatForm";

import './Welcome.css';

export default function Welcome({ returnFxn }) {
    const { user, dispatch } = useAuthContext();
    const { updateDocument } = useFirestore('users');
    const [cheatSuccess, setCheatSuccess] = useState(false);

    const handleGotItClick = () => {
        if (!user) return;
        updateDocument(user.uid, { init: false });
        handleScreenClick();
    }
    const handleScreenClick = () => {
        dispatch({ type: 'UPDATE_OTHER', payload: { welcomeViewed: true }, source: 'DB#fHSC' });
        returnFxn();
    }

    return <div className={`screen ${cheatSuccess ? 'hide-screen-content' : ''}`}>
        <div className="center-modal welcome-modal">
            <div className="center-modal-close-btn" onClick={handleScreenClick}>
                <ClickableIcon type="close" addClass="no-hover small invert" />
            </div>
            <div className="flex gap-2">
                <h4 className="font-extrabold self-center">Welcome</h4>
                <img src={HandWaveImg} className="w-7 h-7" alt="hand waving" />
            </div>
            <p className="mt-2">
                <span className="font-semibold">{appName}</span> is still in limited beta and we apologize for any bugs or hiccups in advance. Promise we are working hard to improve your experience.
            </p>
            <p className="mt-2">
                You'll automatically have a 7-day free trial to try us out. Please consider supporting us by subscribing to our <a href="/subscriptions"><span className="font-semibold">Basic Plan</span></a> if you like us!
            </p>
            <div className="flex flex-col gap-1 mt-4">
                <button className="btn mt-2 mx-auto welcome-button" onClick={handleGotItClick}>Got it!</button>
                <p className="p-emphasize text-center">don't show this again</p>
            </div>
        </div>
        <CheatForm returnFxn={(method) => {
            if (method === 'hide') setCheatSuccess(true);
            if (method === 'close') {
                setCheatSuccess(false);
                handleScreenClick();
            }
        }} />
    </div>
}