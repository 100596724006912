import { useState, useRef, useEffect } from 'react';

import './LimitSlider.css';

export default function LimitSlider({ min, max, options, returnFxn }) {
    const [scaleTicks, setScaleTicks] = useState([0]);
    const [sliderValue, setSliderValue] = useState(0);
    const [activeDisplay, setActiveDisplay] = useState(null);
    const scaleSlider = useRef();
    const ticks = useRef();

    useEffect(() => {
        let start = min;
        let results = [];
        for (let i = start; i < Number(max) + 1; i++) {
            results.push(i);
        }
        setScaleTicks(results);
    }, [min, max]);

    useEffect(() => {
        if (!ticks.current) return;
        ticks.current.querySelectorAll('.scale-tick').forEach(tick => {
            tick.classList.remove('active');
            if (Number(tick.dataset.tick) === Number(sliderValue)) {
                tick.classList.add('active');
                if (options[sliderValue]) tick.classList.add('display-info');
            }
        });
        if (options[sliderValue]) {
            setActiveDisplay(`"${options[sliderValue]}"`);
        } else {
            setActiveDisplay(null);
        }

        returnFxn({ value: sliderValue, text: options[sliderValue] });
    }, [sliderValue, options]);

    const handleSlider = (e) => {
        const value = Number(e.target.value);
        const round = Math.round(value);
        setSliderValue(round);
    }
    
    return (
        <div className="scale-wrapper flex flex-col gap-2">
            <input 
                className="input-slider mt-4" 
                ref={scaleSlider}
                type="range" 
                min={min} 
                max={max} 
                step="0.01"
                onMouseDown={handleSlider}
                onTouchStart={handleSlider}
                onChange={handleSlider}
                value={sliderValue}
            />
            <div className="ticks" ref={ticks}>
                {scaleTicks.map((tick, index) => {
                    let maxTicks = Number(max);
                    if (Number(min) === 0) maxTicks++;
                    let spaces = maxTicks - 1;
                    let style = { left: `calc((((100% - 2rem) / ${spaces}) * ${index}) + 0.9rem)`}
                    if (spaces < 1) style.left = '1rem';
                    return (<div className="scale-tick" style={style} data-tick={tick} key={tick}>{tick}</div>
                )})}
            </div>
            <div className="tick-info-wrapper pt-2">
                <div className="tick-info">
                    {activeDisplay && (
                        <>
                            <h4 className="self-center">{`${sliderValue}:`}</h4>
                            <p className="self-center ml-2">{activeDisplay}</p>
                        </>
                    )}
                    {!activeDisplay && <h4 className="self-center">{`${sliderValue}`}</h4>}
                </div>
            </div>
        </div>
    )
}