import { useEffect, useState } from 'react'
import { auth } from '../../firebase/config'
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useAuthContext } from '../useAuthContext'
import { useFirestore } from './useFirestore'
import { functions, analytics } from '../../firebase/config'
import { httpsCallable } from 'firebase/functions'
import { setUserProperties } from 'firebase/analytics'
import { updateUILang } from '../../tools/tools'

import { privacyPolicyVersion, termsAndConditionsVersion } from '../../tools/tools'

export const useSignup = () => {
    const signLegalFxn = httpsCallable(functions, 'signLegal');
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { dispatch } = useAuthContext();
    const { addDocument } = useFirestore('users');
        
    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const generateDefaultOptions = () => {
        // ids are 16 digits
        const time = new Date().getTime();
        let phrases = {};
        let phraseSequence = [];
        const phraseEntries = [
            { title: 'Hello', text: 'Hello', alt: 'How are you?' },
            { title: 'Goodbye', text: 'Goodbye' },
            { title: 'Thanks', text: 'Thank you' },
            { title: 'Yes', text: 'Yes' },
            { title: 'No', text: 'No', },
            { title: '😣 Where', text: 'Where does it hurt?' },
            { title: '😣 When', text: 'When did your pain start?' },
            { title: '😣 What', text: 'Can you describe how the pain feels?' },
            { title: '😣 Why', text: 'What were you doing when the pain started?' },
            { title: '😣 How', text: 'How severe is the pain?' }
        ];
        phraseEntries.forEach((p, index) => {
            const id = `p000000${index}`;
            phrases[id] = {
                id,
                altText: p.alt || '',
                createdAt: time,
                drop: false,
                lang: 'en',
                limit: false,
                text: p.text,
                title: p.title
            }
            phraseSequence.push(id);
        });

        let buckets = {};
        let bucketSequence = [];
        const bucketEntries = [
            { title: 'Greetings', phrases: ['p0000000', 'p0000001', 'p0000002'] },
            { title: 'Pain', phrases: ['p0000005', 'p0000006', 'p0000007', 'p0000008', 'p0000009'] }
        ]
        bucketEntries.forEach((b, index) => {
            const id = `b000000${index}`;
            buckets[id] = {
                id,
                title: b.title,
                phrases: b.phrases
            }
            bucketSequence.push(id);
        });
        
        let scenarios = {};
        let scenarioSequence = [];
        const scenarioEntries = [
            { title: 'HPI', buckets: ['b0000001'] },
            { title: 'Registration', buckets: ['b0000000'] }
        ]
        scenarioEntries.forEach((s, index) => {
            const id = `s000000${index}`;
            scenarios[id] = {
                id,
                title: s.title,
                buckets: s.buckets
            }
            scenarioSequence.push(id);
        });

        let limits = {};
        let limitSequence = [];
        const limitEntries = [
            { title: 'Yes/No', category: 'choice', choices: ['Yes', 'No'], scale: {} },
            { title: '0-10', category: 'scale', scale: { max: 10, min: 0 }}
        ];
        limitEntries.forEach((l, index) => {
            const id = `l000000${index}`;
            limits[id] = {
                id,
                title: l.title,
                category: l.category,
                choices: l.choices || [],
                lang: 'en',
                scale : {
                    max: l.scale.max || 0,
                    min: l.scale.min || 0,
                    options: []
                }
            }
            limitSequence.push(id);
        });

        let result = {
            phrases,
            phraseSequence,
            buckets,
            bucketSequence,
            scenarios,
            scenarioSequence,
            limits,
            limitSequence
        };

        return result;
    }

    const signup = (name, email, password, lang = 'en', role = 'general', gender = "other") => {
        return new Promise(async (resolve, reject) => {
            setError(null);
            setPending(true);

            try {
                const res = await createUserWithEmailAndPassword(auth, email, password);
                
                if (!res.user) throw new Error('Failed to create new account');

                // add displayName to user
                await updateProfile(res.user, { displayName: name });

                // create a user document and add default scenarios, buckets and phrases
                const options = generateDefaultOptions();

                await addDocument(res.user.uid, { 
                    activeChats: [],
                    displayName: name,
                    username: name,
                    lang, 
                    role, 
                    gender, 
                    online: true,
                    refreshToken: res.user.refreshToken,
                    init: true,
                    newsLetter: true,
                    privacyPolicy: privacyPolicyVersion,
                    termsAndConditionVersion: termsAndConditionsVersion,
                    ...options
                });

                await signLegalFxn({ uid: res.user.uid })

                dispatch({ type: 'LOGGINGIN' });
                
                setTimeout(() => {
                    dispatch({ type: 'LOGIN' });
                    // dispatch({ type: 'LOGIN', payload: {
                    //     ...res.user, 
                    //     lang, 
                    //     role, 
                    //     gender, 
                    //     init: true, 
                    //     tier: 'trial',
                    //     username: name
                    // }});

                    // update UI language
                    // updateUILang(lang);

                    reset();
                    resolve('signup successful');
                    return;
            }, 300);
                
                setUserProperties(analytics, {
                    role,
                    gender,
                    lang,
                    tier: 'trial'
                });
            } catch(err) {
                console.log(err.message);
                reset(err);
                reject(new Error('signup failed'));
                return;
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, signup };
}