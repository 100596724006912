import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useLogout } from '../hooks/firebase/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import { useAppContext } from '../hooks/useAppContext';
import { useChatContext } from '../hooks/useChatContext';
import { useLocation } from 'react-router-dom';
import { useEndChat } from '../hooks/useEndChat';
import { useExitChat } from '../hooks/useExitChat';

import './NavBar.css'

import ClickableIcon from './ClickableIcon';
import { useState } from 'react';

export default function NavBar() {
    const { user, dispatch: authDispatch } = useAuthContext();
    const { chatting, dispatch } = useAppContext();
    const { logout } = useLogout();
    const { owner, self, clear, dispatch: chatDispatch } = useChatContext();
    const location = useLocation();
    const { exitChat } = useExitChat();
    const [block, setBlock] = useState(false);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        if (clear) setOpen(false);
    }, [clear]);
    
    const handleExitModal = () => {
        if (self === owner ) {
            authDispatch({ type: 'LOGGINGOUT_WARNING', source: 'NAV#HEM' });
            return;
        }
    }

    const exitChatAndLogout = async () => {
        setBlock(true);
        await exitChat(user.isAnonymous);
        dispatch({ type: 'END_CHAT'});
        chatDispatch({ type: 'EXIT' });
        logout();
    }
    
    if (chatting && location.pathname.includes('chatroom')) {
        return (
            <></>
        )
    } else if (chatting && !location.pathname.includes('chatroom')) {
        return (<>
            {!user.isAnonymous && <>
            <ul className={`nav-main nav-bar nav-bar-btn flex flex-col gap-4 justify-center z-50 chatting ${open ? 'hide' : ''}`}>
                <li className="special-nav-btn" onClick={() => {
                    setOpen(true);
                }}>
                    <ClickableIcon type="menu" addClass="no-background no-hover" addImgClass="navIcon" />
                </li>
            </ul>
            <ul className={`nav-bar flex flex-col gap-4 justify-center z-50 chatting ${open ? '' : 'hide'}`} onClick={(e) => {
                if (e.target.closest('li')) setOpen(false);
            }}>
                {/* <li onClick={() => setOpen(false)}>
                    <ClickableIcon type={'close'} addClass="neutral-background no-hover" addImgClass="navIcon" />
                </li> */}
                <li><NavLink to="/dashboard">
                    <ClickableIcon type={'home'} addImgClass="navIcon" />
                </NavLink></li>
                <li className="special-nav-btn"><NavLink to="/chat">
                    <ClickableIcon type={'chat'} addClass="no-background" addImgClass="navIcon" />
                </NavLink></li>
                <li><NavLink to="/tree">
                    <ClickableIcon type={'tree'} addImgClass="navIcon" />
                </NavLink></li>
                {(user.role === 'provider' || user.role === 'general') && <li><NavLink to="/limits">
                    <ClickableIcon type={'lock'} addImgClass="navIcon" />
                </NavLink></li>}
                <li><NavLink to="/speaker">
                    <ClickableIcon type={'campaign'} addImgClass="navIcon" />
                </NavLink></li>
                <li><NavLink to="/settings">
                    <ClickableIcon type={'settings'} addImgClass="navIcon" />
                </NavLink></li>
                {owner !== self && <li onClick={exitChatAndLogout}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>} 
                {owner === self && <li onClick={handleExitModal}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>}
                {/* {modal && owner === self && <div className="screen logout-notification">
                    <div className="center-modal">
                        <div className="center-modal-close-btn" onClick={handleExitModal}>
                            <ClickableIcon type="close" addClass="small invert no-hover" />
                        </div>
                        <h4>Logging Out</h4>
                        <p className="mt-2">You are the owner of an active chat session. Logging out will end this chat for everyone.</p>
                        <div className="mt-8 flex justify-center">
                            <button onClick={endChatAndLogout}>log out</button>
                        </div>
                    </div>
                </div>} */}
                <div className={`block-screen ${block ? 'active' : ''}`}></div>
            </ul></>}
            {user.isAnonymous && <ul className="nav-bar flex flex-col gap-4 justify-center z-50 chatting">
                <li onClick={exitChatAndLogout}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>
            </ul>}
            </>
        )
    } else {
        return (
            <>
            <ul className={`nav-main nav-bar nav-bar-btn flex flex-col gap-4 justify-center z-50 ${open ? 'hide' : ''}`}>
                <li onClick={() => {
                    setOpen(true);
                }}>
                    <ClickableIcon type="menu" addImgClass="navIcon" />
                </li>
            </ul>
            <ul className={`nav-bar flex flex-col gap-4 justify-center z-50 ${open ? '' : 'hide'}`} onClick={(e) => {
                if (e.target.closest('li')) setOpen(false);
            }}>
                {!user.isAnonymous && (
                    <>
                        {/* <li onClick={() => setOpen(false)}>
                            <ClickableIcon type={'close'} addClass="neutral-background no-hover" addImgClass="navIcon" />
                        </li> */}
                        <li><NavLink to="/dashboard">
                            <ClickableIcon type={'home'} addImgClass="navIcon" />
                        </NavLink></li>
                        <li><NavLink to="/chat">
                            <ClickableIcon type={'chat'} addImgClass="navIcon" />
                        </NavLink></li>
                        <li><NavLink to="/tree">
                            <ClickableIcon type={'tree'} addImgClass="navIcon" />
                        </NavLink></li>
                        {(user.role === 'provider' || user.role === 'general') && <li><NavLink to="/limits">
                            <ClickableIcon type={'lock'} addImgClass="navIcon" />
                        </NavLink></li>}
                        <li><NavLink to="/speaker">
                            <ClickableIcon type={'campaign'} addImgClass="navIcon" />
                        </NavLink></li>
                        <li><NavLink to="/settings">
                            <ClickableIcon type={'settings'} addImgClass="navIcon" />
                        </NavLink></li>
                    </>
                )}
                
                {owner && self && owner !== self && <li onClick={exitChatAndLogout}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>} 
                {owner && self && owner === self && <li onClick={handleExitModal}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>}
                {(!owner || !self) && <li onClick={logout}>
                    <ClickableIcon type={'logout'} addImgClass="navIcon" />
                </li>}
            </ul>
            </>
        )
    }
}