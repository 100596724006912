
import { useState, useRef, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { randomIDGenerator } from '../../../tools/tools';

import './LimitForm.css';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';

export default function LimitForm({ data, method, id, returnFxn }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [name, setName] = useState('');
    const [choice, setChoice] = useState(true);
    const [choiceInput, setChoiceInput] = useState('');
    const [choiceOptions, setChoiceOptions] = useState([]);
    const [scale, setScale] = useState(false);
    const [scaleMax, setScaleMax] = useState(0);
    const [scaleOptions, setScaleOptions] = useState({});
    const [scaleTicks, setScaleTicks] = useState([0]);
    const [includeZero, setIncludeZero] = useState(true);
    const [sliderValue, setSliderValue] = useState(0);
    const [scaleInput, setScaleInput] = useState('');
    const [activeDisplay, setActiveDisplay] = useState(null);
    const formInput = useRef();
    const scaleSlider = useRef();
    const ticks = useRef();

    useEffect(() => {
        if (method !== 'edit' || !data || !id)  return;

        const entry = data.limits[id];
        if (!entry) return;

        setName(entry.title);
        if (entry.category === 'scale') {
            setChoice(false);
            setScale(true);
        }
        setChoiceOptions([...entry.choices]);
        setScaleMax(Number(entry.scale.max));
        entry.scale.min === 0 ? setIncludeZero(true) : setIncludeZero(false);
        if (entry.scale.options) setScaleOptions({ ...entry.scale.options});

    }, [data, method, id]);

    useEffect(() => {
        let start = includeZero ? 0 : 1;
        let results = [];
        for (let i = start; i < Number(scaleMax) + 1; i++) {
            results.push(i);
        }
        setScaleTicks(results);
    }, [scaleMax, includeZero]);

    useEffect(() => {
        if (!ticks.current) return;
        ticks.current.querySelectorAll('.scale-tick').forEach(tick => {
            tick.classList.remove('active');
            if (Number(tick.dataset.tick) === Number(sliderValue)) {
                tick.classList.add('active');
            }
        });
        if (scaleOptions[sliderValue]) {
            setActiveDisplay(scaleOptions[sliderValue]);
        } else {
            setActiveDisplay(null);
        }
    }, [sliderValue, scaleOptions]);

    const handleScaleLoad = (e) => {
        const wrapper = e.target.closest('.limit-category-wrapper');
        if (!wrapper) return;
        wrapper.querySelectorAll('.scale-tick').forEach(tick => {
            tick.classList.remove('active');
            if (Number(tick.dataset.tick) === Number(sliderValue)) {
                tick.classList.add('active');
                if (scaleOptions[sliderValue]) tick.classList.add('display-info');
            }
        });
        if (scaleOptions[sliderValue]) setActiveDisplay(scaleOptions[sliderValue]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (method === 'new') {
            let randomID = randomIDGenerator(16);
            if (data.limits) {
                while (Object.keys(data.limits).indexOf(randomID) !== -1) {
                    randomID = randomIDGenerator(16);
                }
            }
            
            let save = { limits: data.limits || {}, limitSequence: data.limitSequence || [] };
            save.limits[randomID] = { 
                id: randomID, 
                title: name,
                category: choice ? 'choice' : 'scale',
                choices: choiceOptions,
                scale: {
                    options: scaleOptions,
                    min: includeZero? 0 : 1,
                    max: scaleMax
                },
                lang: user.lang
            };
            
            save.limitSequence.push(randomID);

            await updateDocument(user.uid, save);
        }
        if (method === 'edit') {
            let save = { limits: data.limits || {}, limitSequence: data.limitSequence || [] };
            save.limits[id] = { ...data.limits[id] };
            save.limits[id] = {
                id, 
                title: name,
                category: choice ? 'choice' : 'scale',
                choices: choiceOptions,
                scale: {
                    options: scaleOptions,
                    min: includeZero? 0 : 1,
                    max: scaleMax
                },
                lang: user.lang
            }

            await updateDocument(user.uid, save);
        }

        setTimeout(() => returnFxn('back'), 500);
    }
    const handleChoiceInput = (e) => {
        if (e.keyCode === 13 || e.method === 'add') {
            if (e.keyCode) e.preventDefault();
            const results = [...choiceOptions, choiceInput];
            setChoiceOptions(results);
            setChoiceInput('');
        } else {
            setChoiceInput(e.target.value);
        }
    }
    const handleScaleInput = (e) => {
        if (e.keyCode === 13 || e.method === 'add') {
            if (e.keyCode) e.preventDefault();
            let results = {...scaleOptions }
            results[sliderValue] = scaleInput;
            setScaleOptions(results);
        } else if (e.keyCode === 37) {
            let target = Number(sliderValue) - 1;
            if (target < 0) target = 0;
            setSliderValue(target);
            scaleOptions[target] ? setScaleInput(scaleOptions[target]) : setScaleInput('');
        } else if (e.keyCode ===39) {
            let target = Number(sliderValue) + 1;
            if (target > scaleMax) target = Number(sliderValue);
            setSliderValue(target);
            if (scaleOptions[target]) {

                setScaleInput(scaleOptions[target])
            } else {
                setScaleInput('');
            } 
        } else {
            setScaleInput(e.target.value);
        }
    }
    const removeScaleInput = () => {
        if (!scaleOptions[sliderValue]) return;
        delete scaleOptions[sliderValue];
        ticks.current.querySelectorAll('.scale-tick').forEach(tick => {
            if (Number(tick.dataset.tick) === Number(sliderValue)) {
                tick.classList.remove('display-info');
            }
        });
        setActiveDisplay(null);
    }
    const handleCategoryClick = (e, category) => {
        e.preventDefault();

        if (category === 'choice') {
            setChoice(true);
            setScale(false);
        }
        if (category === 'scale') {
            setScale(true);
            setChoice(false);
        }
    }

    // const handleSliderChange = (e) => {
    //     setSliderValue(e.target.value);
    // }
    const handleSlider = (e) => {
        // setActiveDisplay(null);
        const value = Number(e.target.value);
        const round = Math.round(value);
        setSliderValue(round);
        scaleOptions[round] ? setScaleInput(scaleOptions[round]) : setScaleInput('');
    }

    return (
        <form className="mt-4" onSubmit={handleSubmit}>
            <label className="w-full flex justify-center gap-2">
                <span className="self-center form-inline-label">Name:</span>
                <input 
                    ref={formInput}
                    className="grow"
                    required
                    type="text"
                    onChange={e => setName(e.target.value)}
                    value={name}
                />
            </label>
            <p className="p-small mt-2">Recommend to keep the name short but distinct for better recognizability.</p>
            <div className="flex justify-center mt-4">
                <div className={`limit-category-btn ${choice ? 'active' : ''}`} onClick={e => handleCategoryClick(e, 'choice')}>
                    Options
                </div>
                <div className={`limit-category-btn flex justify-center items-center ${scale ? 'active' : ''}`} onClick={e => handleCategoryClick(e, 'scale')}>
                    Scale
                </div>
            </div>
            {choice && <div className="limit-category-wrapper mt-4">
                <div className="limit-category-description">
                    <p className="p-small">Provide <span className="font-bold text-xs">multiple-choice options</span> for others to pick from. A "not applicaple" option will always be available.</p>
                </div>
                <div className="limit-category-output flex flex-col gap-2 hide-scroll">
                    {choiceOptions && choiceOptions.map((option, index) => (
                        <div key={index} className="choice-entry">
                            <p>{option}</p>
                            <div className="remove-choice-entry">
                                <ClickableIcon type="trash"     addClass="no-background no-hover mini" />
                            </div>
                        </div>
                    ))}
                </div>
                <label className="flex justify-between gap-2 mt-2">
                    <span className="self-center form-inline-label">Add:</span>
                    <input 
                        type="text" 
                        className="grow"
                        style={{ width: '60px' }}
                        onChange={handleChoiceInput}
                        onKeyDown={handleChoiceInput}
                        value={choiceInput}
                    />
                    <div className="btn-circle btn-darkgray small self-center" style={{ minWidth: '1.5rem' }} onClick={() => handleChoiceInput({ method: 'add' })}>
                        <ClickableIcon type="add" addClass="mini self-center alt-color" />
                    </div>
                </label>
            </div>}
            {scale && <div className="limit-category-wrapper mt-4" onLoad={handleScaleLoad}>
                <div className="limit-category-description">
                    <p className="p-small">Provide a <span className="font-bold text-xs">sliding scale</span> for others to pick from. Sliding scales are always incremented by 1 and range from 0 to a maximum of 10. </p>
                </div>
                <div className="scale-category-output flex flex-col gap-2 hide-scroll">
                    <input 
                        className="input-slider mt-4" 
                        ref={scaleSlider}
                        type="range" 
                        min={includeZero ? 0 : 1} 
                        max={scaleMax} 
                        step="0.01" 
                        // onClick={handleSlider}
                        onMouseDown={handleSlider}
                        onTouchStart={handleSlider}
                        // onMouseUp={handleSlider}
                        // onTouchEnd={handleSlider}
                        onChange={handleSlider}
                        value={sliderValue}
                    />
                    <div className="ticks" ref={ticks}>
                        {scaleTicks.map((tick, index) => {
                            let max = Number(scaleMax);
                            if (includeZero) max++;
                            let spaces = max - 1;
                            let style = { left: `calc((((100% - 2rem) / ${spaces}) * ${index}) + 0.9rem)`}
                            if (spaces < 1) style.left = '1rem';
                            return (<div className="scale-tick" style={style} data-tick={tick} key={tick}>{tick}</div>
                        )})}
                    </div>
                    <div className="tick-info-wrapper pt-2">
                        <div className="tick-info">
                            {activeDisplay && (
                                <>
                                    <h5 className="self-center">{`${sliderValue}:`}</h5>
                                    <p>"{activeDisplay}"</p>
                                    <div className="remove-scale-input" onClick={removeScaleInput}>
                                        <ClickableIcon type="trash" addClass="mini no-background no-hover" />
                                    </div>
                                </>
                            )}
                            {!activeDisplay && <h5 className="self-center mx-auto">{`${sliderValue}`}</h5>}
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                    <label className="flex justify-between gap-2 mt-2">
                        <span className="self-center form-inline-label">Max:</span>
                        <select
                            className="w-auto"
                            onChange={(e) => setScaleMax(e.target.value)}
                            value={scaleMax}
                        >
                            {includeZero && <option value={0}>0</option>}
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                        </select>
                    </label>
                    <div className="border-r-2 h-6 self-center mt-2"></div>
                    <label className="flex justify-between gap-2 mt-2 self-center">
                        <span className="self-center form-inline-label">Zero?</span>
                        <div className="switch">
                        <input
                            type="checkbox"
                            onChange={e => {
                                if (sliderValue === 0) {
                                    e.target.checked ? setSliderValue(0) : setSliderValue(1);
                                }
                                setIncludeZero(e.target.checked);
                            }}
                            checked={includeZero}
                        />
                        <span className="slider"></span>
                    </div>
                    </label>
                </div>
                <div className="mt-2">
                    <p>Add Text:</p>
                    <label className="flex justify-between gap-1 mt-1">
                        <input 
                            type="text" 
                            className="grow"
                            onChange={handleScaleInput}
                            onKeyDown={handleScaleInput}
                            value={scaleInput}
                        />
                        <div className="btn-circle btn-darkgray" onClick={() => handleScaleInput({ method: 'add' })}>
                            {!activeDisplay && <ClickableIcon type="add" addClass="mini self-center alt-color" />}
                            {activeDisplay && <ClickableIcon type="edit" addClass="mini self-center alt-color" />}
                        </div>
                    </label>
                </div>
            </div>}

            {response.error && <p className="error mt-2">{response.error}</p>}
            {!response.isPending && <button className="btn btn-circle mx-auto mt-4">
                <ClickableIcon type="check" addClass="no-background no-hover invert mini" />
            </button>}
            {response.isPending && <button className="btn btn-circle mx-auto mt-4" disabled>
                <LoadingIcon size="small" />
            </button>}
        </form>
    )
}