import { useState, useEffect } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import { functions } from "../firebase/config"
import { httpsCallable } from "firebase/functions"

import LoadingIcon from "./LoadingIcon"
import ClickableIcon from "./ClickableIcon"
import { appName } from "../tools/tools"

export default function EmailVerificationModal({ returnFxn, status }) {
    const resendEmailVerificationFxn = httpsCallable(functions, 'resendEmailVerification');
    const { user } = useAuthContext();
    const [emailVeri, setEmailVeri] = useState(false);
    const [emailPending, setEmailPending] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    // useEffect(() => {
    //     console.log('triggered. status:', status, emailVeri, user);
    // }, []);

    useEffect(() => {
        if (!user || !user.uid) return;
        setEmailVeri(status);
    }, [status]);

    useEffect(() => {
        returnFxn(emailVeri);
    }, [emailVeri]);

    const resendEmailVerification = async () => {
        setEmailPending(true);
        
        await resendEmailVerificationFxn({ uid: user.uid, email: user.email });
    
        setEmailPending(false);
        setEmailSent(true);
    }

    return (<>
        {emailVeri && <div className="screen" onClick={(e) => {
            if (e.target.closest('.center-modal')) return;
            setEmailVeri(false);
        }}>
            <div className="center-modal">
                <div className="center-modal-close-btn" onClick={() => setEmailVeri(false)}>
                    <ClickableIcon type="close" addClass="invert" />
                </div>
                <h4 style={{ lineHeight: '1.1em' }}>Account Needs Verification</h4>
                <p className="mt-2">Please verify your email address to continue using <span className="font-semibold">{appName}</span>. Check your inbox for an email link. Please reload the site to apply any updated changes. Thank you 🙏!!</p>
                {!emailPending && !emailSent && <div className="mt-8 flex justify-center">
                    <button onClick={resendEmailVerification}>resend</button>
                </div>}
                {emailPending && <div className="mt-8 flex justify-center">
                    <button className="flex justify-center">
                    <LoadingIcon size="small" addClass="no-background no-hover self-center" />
                    </button>
                </div>}
                {!emailPending && emailSent && <div className="mt-8 flex justify-center">
                <button>sent!</button>
              </div>}
            </div>
        </div>}
    </>)
}