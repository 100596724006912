import { useChatContext } from "./useChatContext";
import { useState, useEffect } from 'react';
import { useAppContext } from "./useAppContext";
import { functions } from "../firebase/config";
import { httpsCallable } from 'firebase/functions';
import { useAuthContext } from "./useAuthContext";

export const useExitChat = () => {
    const handleChatFxn = httpsCallable(functions, 'handleChatEntryExit');
    const { user } = useAuthContext();
    const { session, dispatch } = useChatContext();
    const { dispatch: appDispatch } = useAppContext();
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [cancel, setCancel] = useState(false);

    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }
    
    const exitChat = (guest = false) => {
        return new Promise(async (resolve, reject) => {
            setPending(true);

            if (!user) {
                console.log('User not authenticated')
                setError('User not authenticated');
                setPending(false);
            } else {
                let activeChats = user.activeChats || [];
                while (activeChats.indexOf(session) !== -1) {
                    activeChats.splice(activeChats.indexOf(session), 1);
                }
                
                let save = {
                    method: 'exit',
                    session,
                    user: {
                        uid: user.uid,
                        activeChats,
                        guest: guest || false
                    }
                }
                
                try {
                    const res = await handleChatFxn(save);

                    dispatch({ type: 'EXIT', source: 'exit_chat_hook' });
                    appDispatch({ type: 'END_CHAT', source: 'exit_chat_hook' });
                    
                    reset();
                    resolve(res);
                    return;
                } catch(err) {
                    console.log('failed', err.message);
                    reset(err);
                    reject(err.message);
                    return;
                }        
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, exitChat };
}