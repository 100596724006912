import { useState } from 'react';
import { useSignup } from '../hooks/firebase/useSignup'
import { functions } from '../firebase/config';
import { httpsCallable } from 'firebase/functions';

import './Forms.css';
import { langSrc } from '../tools/languageSource';

import LoadingIcon from './LoadingIcon';
import './SignupForm.css';
import { usernameAllowed } from '../tools/tools';

export default function SignupForm({ expanded }) {
    const checkUsername = httpsCallable(functions, 'checkUsername');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pw, setPw] = useState('');
    const [lang, setLang] = useState('en');
    const [role, setRole] = useState('provider');
    const [usernameStatus, setUsernameStatus] = useState(false);
    const { signup, error, pending } = useSignup();
    
    const langOptions = langSrc.map(item => (
        <option key={item.code} value={item.code}>{item.name}</option>
    ));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUsernameStatus(false);

        if (name.length < 6) {
            setUsernameStatus('username must be 6 letters or longer');
            setTimeout(() => setUsernameStatus(false), 5000);
            return;
        }
        let checkUsernameSymbols = false;
        for (let i = 0; i < name.length; i++) {
            if (usernameAllowed.indexOf(name[i].toLocaleLowerCase()) === -1) checkUsernameSymbols = true;
        }
        if (checkUsernameSymbols) {
            setUsernameStatus(`only "_" special character is allowed`);
            setTimeout(() => setUsernameStatus(false), 5000);
            return;
        }
        const res = await checkUsername({ username: name });
        if (res.data === 'taken') {
            setUsernameStatus('username is unavailable');
            setTimeout(() => setUsernameStatus(false), 5000);
            return;
        }
        
        await signup(name.trim(), email, pw, lang, role);

        setName('');
        setEmail('');
        setPw('');
    }

    return (
        <form onSubmit={handleSubmit} className="signup-form flex flex-col gap-2 p-2">
            <label className="flex">
                <span className="self-center form-inline-label">email:</span>
                <input 
                    className="flex-auto grow ml-2"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </label>
            <label className="flex">
                <span className="self-center form-inline-label">password:</span>
                <input 
                    className="flex-auto grow ml-2"
                    type="password"
                    value={pw}
                    onChange={(e) => setPw(e.target.value)}
                    required
                />
            </label>
            <label className="flex relative">
                <span className="self-center form-inline-label">username:</span>
                <input 
                    className={`flex-auto grow ml-2 transition ${usernameStatus ? 'error' : ''}`}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                {usernameStatus && <p className="error signup-error">{usernameStatus}</p>}
            </label>
            {expanded && <label className="flex">
                <span className="self-center form-inline-label">language:</span>
                <select
                    className="flex-auto grow ml-2"
                    onChange={(e) => {
                        setLang(e.target.value);
                    }}
                    value={lang}
                >
                    {langOptions}
                </select> 
            </label>}
            {expanded && <label className="flex">
                <span className="self-center form-inline-label">role:</span>
                <select
                    className="flex-auto grow ml-2"
                    onChange={(e) => {
                        setRole(e.target.value);
                    }}
                    value={role}
                >
                    <option value="provider">Regular</option>
                    {/* <option value="patient">Patient</option> */}
                    <option value="interpreter">Interpreter</option>
                    {/* <option value="other">Other</option> */}
                </select> 
            </label>}
            <div>
                <label className="flex justify-start gap-2">
                    <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                    <p className="self-center">I agree to the 
                    <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/terms-and-conditions" target="blank"> Terms & Conditions</a></p>
                </label>
                <label className="flex justify-start gap-2">
                    <input type="checkbox" style={{ height: '1.5rem',  width: 'fit-content' }} className="self-center" required />
                    <p className="self-center">I agree to the 
                    <a className="p-emphasize" style={{ fontSize: '0.8rem'}} href="/privacy-policy" target="blank"> Privacy Policy</a></p>
                </label>
            </div>
            {!pending && <button className="btn mx-auto mt-2">signup</button>}
            {pending && <button className="btn mx-auto mt-2"><LoadingIcon size={'small'} addClass={'mx-auto'} /></button>}
            {error && <p className="error">{error}</p>}
        </form>
    )
}