import { useState, useEffect } from 'react';

import { useChatContext } from '../../../../hooks/useChatContext';

import './UserPicker.css';

export default function UserPicker({ input, targeted, returnFxn }) {
    const { self, users } = useChatContext();
    const [userPicker, setUserPicker] = useState(false);
    const [pickerList, setPickerList] = useState(false);
    const [userList, setUserList] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        let exisitingUsers = [];
        Object.values(users).forEach(u => {
            if (u.tempID === self) return;
            if (targeted.indexOf(u.tempID) !== -1) return;
            let name = u.displayName.replaceAll(' ', '-').toLowerCase();
            if (search === '@') {
                exisitingUsers.push({ text: name, targetID: u.tempID });
                if (u.username) exisitingUsers.push({ text: u.username, targetID: u.tempID });
            } else {
                const str = search.slice(1).toLowerCase();
                if (name.indexOf(str) === 0) exisitingUsers.push({ text: name, targetID: u.tempID });
                if (u.username && u.username.indexOf(str) === 0) exisitingUsers.push({ text: u.username, targetID: u.tempID });
            }
        });
        
        setUserList(exisitingUsers);
    }, [users, search, input, targeted]);

    useEffect(() => {
        let words = input.split(' ');
        let lastWord = words[words.length - 1];
        if (lastWord.substring(0,1) === '@') {
            setUserPicker(true);
            setPickerList(true);
            setSearch(lastWord);
        } else if (targeted && targeted.length > 0) {
            setUserPicker(true);
            setPickerList(false);
            setSearch('');
        } else {
            setUserPicker(false);
            setPickerList(false);
            setSearch('');
        }
    }, [input, targeted]);

    const handleClick = (method, user) => {
        returnFxn( { method, text: user, targetID: user });
    }

    return (
        <div className={`userPicker hide-scroll ${userPicker ? 'show' : ''}`}>
            {userPicker && <div className="targeted-entry header">to:</div>}
            {targeted && targeted.map((user, index) => (
                <div key={index} className="targeted-entry" onClick={() => handleClick('remove', user)}>
                    @{users[user].displayName.replaceAll(' ', '-').toLowerCase()}
                </div>
            ))}
            <div className={`pick-list hide-scroll ${pickerList ? '' : 'hide' }`}>
                {userList && userList.map((user, index) => (
                    <p key={index} className="pick-entry" onClick={() => handleClick('add', user.targetID)}>+@{user.text}</p>
                ))}
            </div>
        </div>
    )
}