import { useEffect, useState, useRef } from "react"
import { fs } from '../../firebase/config'
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore'

export const useCollectionListener = (collectionName, _query, _orderBy) => {
    const [documents, setDocuments] = useState(null);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);

    const queryParam = useRef(_query).current;
    const orderByParam = useRef(_orderBy).current;
    
    useEffect(() => {
        setPending(true);
        let q = collection(fs, collectionName);
        
        if (queryParam && orderByParam) {
            let fullQuery = [];
            queryParam.forEach(entry => {
                fullQuery.push(where(...entry));
            });
            q = query(q, ...fullQuery, orderBy(...orderByParam));
        } else if (queryParam && !orderByParam) {
            let fullQuery = [];
            queryParam.forEach(entry => {
                fullQuery.push(where(...entry));
            });
            q = query(q, ...fullQuery);
        } else if (!queryParam && orderByParam) {
            q = query(q, orderBy(...orderByParam));
        }
        
        const unsubscribe = onSnapshot(q, snapshot => {
            let results = [];
            snapshot.docs.forEach(doc => {
                results.push({...doc.data(), id: doc.id});
            });
            
            setDocuments(results);
            setPending(false);
            setError(null);
            }, error => {
                console.log(error.message);
                setError('could not fetch collection data');
                setPending(false);
        })

        // unsubscribe on unmount
        return () => unsubscribe()

    }, [collectionName, queryParam, orderByParam])

    return { documents, error, pending };
}