import { useState } from 'react'

import ClickableIcon from './ClickableIcon'

import UserCard from './UserCard'
import ProfilePic from './ProfilePic'

export default function RequestModal({ user, doc }) {
    return <>
            {user && <div className="screen">
                <div className="center-modal transparent relative">
                    <div className="mt-8">
                        <UserCard user={user} profileBackground={true} document={doc} request={true} />
                    </div>
                </div>
            </div>}
            {!user && <></>}
        </>
}