import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

export const useAppContext = () => {
    const context = useContext(AppContext);
    
    if (!context) {
        throw new Error('App context must be used within its own provider.');
    }

    return context;
}