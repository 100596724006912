import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function OptOut({ returnFxn }) {
    const [optStatus, setOptStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() =>{
        const notificationEl = document.querySelector('.notification');
        if (!notificationEl) return;
        notificationEl.classList.add('hide');
    }, []);

    const handleClick = () => {
        returnFxn({ method: 'analytics', value: false });
        setOptStatus(true);
    }

    return (
        <div className="screen">
            <div className="center-modal">
                <h5>Opt Out of Analytics</h5>
                <p className="mt-2">We implement Google Analytics Services to help us improve our services and products. Opt out of Google Analytics for this session by clicking the button bellow.</p>
                <div className="flex justify-center">
                    {!optStatus && <button className="mt-6" onClick={handleClick}>Opt Out</button>}
                    {optStatus && <div>
                        <button className="mt-6" onClick={() => {
                            navigate('/');
                        }}>return to homepage</button>
                        <p className="p-emphasize text-center mt-4">Google Analytics stopped</p>
                    </div>}
                </div>
            </div>
        </div>
    )
}