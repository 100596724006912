import { useEffect, useState, useRef } from 'react';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { useAuthContext } from '../../../hooks/useAuthContext';

import './PhraseList.css';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';
import SnowmanImg from '../../../assets/snowman.png';

export default function PhraseList({ data, returnFxn }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [animate, setAnimate] = useState(false);
    const [phrases, setPhrases] = useState(null);  
    const [listDesktopRows, setListDesktopRows] = useState('none');
    const [isEmpty, setIsEmpty] = useState(false);
    const [holder, setHolder] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    const [processingID, setProcessingID] = useState(null);
    const [doubleTap, setDoubleTap] = useState(null);
    // const memoData = useRef(data).current;
    let scrolling = false;
    let mobile = window.innerWidth < 768;
        
    const handleScroll = () => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);

    useEffect(() => {
        if (!data) return setIsEmpty(true);
        if (!data.phrases) return setIsEmpty(true);
        setIsEmpty(false);
        let entries = [];
        data.phraseSequence.forEach(item => {
            if (!data.phrases[item]) return;
            entries.push(data.phrases[item]);
        });

        setPhrases(entries);
        if (window.innerHeight < 768) {
            setListDesktopRows('none');
        } else {
            setListDesktopRows(`repeat(${Math.ceil(entries.length / 2)}, minmax(auto, 1fr)`);
        }
        if (entries.length === 0) setIsEmpty(true);
    }, [data, window]);

    const toggleAnimation = () => {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500);
    }

    const handleEditEntry = async (e, id) => {
        if (e.target.closest('.delete-entry')) {
            setProcessingID(id);
            let save = { phrases: data.phrases || {}, phraseSequence: data.phraseSequence };
            delete save.phrases[id];
            save.phraseSequence.splice(save.phraseSequence.indexOf(id), 1);

            await updateDocument(user.uid, save);
        } else if (e.target.closest('.up-arrow') || e.target.closest('.down-arrow')) {
            return;
        } else {
            if (doubleTap === id) {
                returnFxn('edit', id);
            } else {
                document.querySelectorAll('.phrase-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
                e.target.closest('.phrase-list-entry').classList.add('highlight');
                setDoubleTap(id);
            }
        }
    }

    const onLoad = (e) => {
        setHolder(e.target.closest('.phrase-list-wrapper'));
        document.addEventListener('click', (e) => {
            if (e.target.closest('.phrase-list-entry')) return;
            document.querySelectorAll('.phrase-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
            setDoubleTap(null);
        })
    }

    const changeSequence = async (method, id, index) => {
        if (response.isPending) return;
        if (method === 'up') {
            if (index - 1 < 0) return;
            const target = data.phraseSequence[index];
            data.phraseSequence.splice(index, 1);
            data.phraseSequence.splice(index - 1, 0, target);
        } else if (method === 'down') {
            const target = data.phraseSequence[index];
            if (index + 1 === data.phraseSequence.length) return;
            data.phraseSequence.splice(index, 1);
            data.phraseSequence.splice(index + 1, 0, target);
        }

        await updateDocument(user.uid, { phraseSequence: data.phraseSequence });
    }
       
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const handleSort = async () => {
        let items = [...phrases];
        const draggedItemContent = items[dragItem.current];
        items.splice(dragItem.current, 1);
        items.splice(dragOverItem.current, 0, draggedItemContent);
        setPhrases(items);

        let seq = [];
        items.forEach(item => seq.push(item.id));
        
        await updateDocument(user.uid, {phraseSequence: seq});
    }

    return (
        <>
            {isEmpty && (
                <div className="phrase-list-wrapper flex items-center" onLoad={(e) => setHolder(e.target.closest('.phrase-list-wrapper'))}>
                    <div className="m-auto flex flex-col items-center">
                        <div className="my-auto">
                            {!animate && <h1 className="just-showing" onClick={toggleAnimation} unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            {animate && <h1 onClick={toggleAnimation} className="just-showing animate-shake" unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            <i className="p-emphasize mt-2">No items</i>
                        </div>
                    </div>
                </div>
            )}
            {phrases && phrases.length > 0 && (
                <div className={`phrase-list-wrapper hide-scroll ${scrollClass} grid grid-cols-1 gap-4 auto-rows-max md:pt-16 md:pb-16`} onLoad={onLoad}>
                    {phrases && phrases.map((ph, index) => (
                        <div 
                            key={ph.id} 
                            className="phrase-list-entry" 
                            onClick={(e) => handleEditEntry(e, ph.id)} 
                            data-index={index}
                            onDragStart={(e)=> dragItem.current = index} 
                            onDragEnter={(e) => dragOverItem.current = index} 
                            onDragEnd={handleSort} 
                            onDragOver={(e) => e.preventDefault()}
                            draggable
                        >
                            <div className="grow flex flex-col h-full">
                                <div className="entry-header flex justify-between">
                                    <p className="p-title self-center">{ph.title}</p>
                                    <div className="delete-entry">
                                        {!response.isPending && <ClickableIcon type="trash" addClass="mini no-background self-center" />}
                                        {response.isPending && (processingID === ph.id) && <LoadingIcon size="small" />}
                                    </div>
                                </div>
                                <div className="entry-content">
                                    <p>{ph.text}</p>
                                    <p className="alt-phrase">{ph.altText}</p>
                                </div>
                            </div>
                            <div className="up-arrow" onClick={e => changeSequence('up', ph.id, index)}>
                                <ClickableIcon type="arrow_up" addClass="no-hover alt-color" />
                            </div>
                            <div className="down-arrow" onClick={e => changeSequence('down', ph.id, index)}>
                                <ClickableIcon type="arrow_down" 
                                addClass="no-hover alt-color" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}