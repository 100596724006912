import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import { useChatContext } from './hooks/useChatContext';
import { useAppContext } from './hooks/useAppContext';
import { useEndChat } from './hooks/useEndChat';
import { auth, functions, analytics, fs } from './firebase/config';
import { signOut } from 'firebase/auth'
import { useFirestore } from './hooks/firebase/useFirestore';
import { onSnapshot, doc as fsDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { setAnalyticsCollectionEnabled, setUserProperties } from 'firebase/analytics';
import { updateUILang } from './tools/tools';

import './App.css';

import { appName } from './tools/tools';
import DXTools from './pages/dxtools/DXTools';
import NavBar from './components/NavBar';
import Home from './pages/home/Home'
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import Chat from './pages/chat/Chat';
import Tree from './pages/tree/Tree';
import Limits from './pages/limits/Limits';
import Speaker from './pages/speaker/Speaker';
import ChatLobby from './pages/chatlobby/ChatLobby';
import Chatroom from './pages/chatroom/Chatroom';
import ChatDoor from './pages/chatdoor/ChatDoor';
import Subscriptions from './pages/subscriptions/Subscriptions';
import ClickableIcon from './components/ClickableIcon';
import LoadingIcon from './components/LoadingIcon';
import PrivacyPolicy from './pages/privacy/Privacy';
import TermsAndConditions from './pages/privacy/Terms';
import OptOut from './pages/optout/OptOut';
import NewTabIcon from './assets/new_tab_icon.svg';
import Orange from './assets/orange-2.png';
import EmailVerificationModal from './components/EmailVerificationModal';
import Tutorial from './components/Tutorial';
import Welcome from './components/Welcome';

function App() {
  const resendEmailVerificationFxn = httpsCallable(functions, 'resendEmailVerification');
  const { user, authIsReady, screen, loggingOut, dispatch, welcomeViewed, tutorialDone } = useAuthContext();
  const { dispatch: chatDispatch } = useChatContext();
  const { data, dispatch: appDispatch } = useAppContext();
  const { updateDocument } = useFirestore('users');
  const { endChat } = useEndChat();
  const [modal, setModal] = useState(true);
  const [emailVeri, setEmailVeri] = useState(false);
  const [initCheckEmailVeri, setInitCheckEmailVeri] = useState(false);
  const [gdpr, setGdpr] = useState(true);
  const [gdprAccept, setGdprAccept] = useState('undecided');
  const [gdprCookie, setGdprCookie] = useState(false);
  const [pauseAnalytics, setPauseAnalytics] = useState(false);
  const [hideBg, setHideBg] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [welcome, setWelcome] = useState(false);

  const location = window.location.href;

  // if (location.includes('med.chat')) setMedVersion(true);

  // handle google page translate UI
  useEffect(() => {
    const translateHolder = document.querySelector('.googleTranslateElement');
    if (!translateHolder) return;
    const translateOptions = translateHolder.querySelector('select');
    if (!translateOptions) return;
    
    translateOptions.addEventListener('click', (e) => {
      const banner = document.querySelector('iframe.skiptranslate');
      if (!banner) return;
      let bannerActive = false;
      if (!banner.parentElement.style.display) bannerActive = true;
      if (e.target.value && e.target.value !== 'en' && !translateHolder.querySelector('.btn')) {
        const endBtn = document.createElement('button');
        endBtn.classList.add('btn', 'mt-4', 'mx-auto');
        endBtn.style.width = '100%';
        endBtn.innerHTML = 'cancel';
        translateHolder.appendChild(endBtn);
        endBtn.addEventListener('click', () => {
          const googleClose = banner.contentWindow.document.querySelector('.goog-close-link');
          if (googleClose) googleClose.click();
          endBtn.remove();
          translateHolder.classList.remove('show');
        })
      } else if (!bannerActive) {
        if (translateHolder.querySelector('.btn')) {
          translateHolder.querySelector('.btn').remove();
        }
      } else if (e.target.value === 'en') {
        const googleClose = banner.contentWindow.document.querySelector('.goog-close-link');
        if (googleClose) googleClose.click();
        translateHolder.classList.remove('show');
        if (translateHolder.querySelector('.btn')) {
          translateHolder.querySelector('.btn').remove();
        }
      }
    });
  }, []);

  // // detect browser language
  // useEffect(() => {
  //   const translateHolder = document.querySelector('.googleTranslateElement');
  //   if (!translateHolder) return;
  //   const translateOptions = translateHolder.querySelector('select');
  //   if (!translateOptions) return;

  //   let langExists = false;
  //   if (translateOptions) {
  //     translateOptions.querySelectorAll('option').forEach(el => {
  //       if (el.value === navigator.language) {
  //         langExists = true;
  //       }
  //     })
  //   }

  //   if (langExists) {
  //     const event = new Event('change');
  //     translateOptions.value = navigator.language;
  //     translateOptions.dispatchEvent(event);
  //   }
  // }, []);

  // remove bg and nav bar for legal pages
  useEffect(() => {
    if (location.includes('privacy') || location.includes('terms-and-conditions')) {
      setHideBg(true);
    } else {
      setHideBg(false);
    }
  }, [location]);
  
  // on init disable Google Analytics
  useEffect(() => {
    setAnalyticsCollectionEnabled(analytics, false);
  }, []);
  
  useEffect(() => {
    loggingOut ? setModal(true) : setModal(false);
  }, [loggingOut]);

  useEffect(() => {
    if (!user) return;
    
    // handle Google Analytics for users
    if (pauseAnalytics) setAnalyticsCollectionEnabled(analytics, false); 
    if (!pauseAnalytics) setAnalyticsCollectionEnabled(analytics, true); 

    if (authIsReady) {
      if (user.isAnonymous) return;
      if (!user.emailVerified && !user.init && !initCheckEmailVeri) {
        setEmailVeri(true);
        setInitCheckEmailVeri(true);
      } 
      if (user.emailVerified) {
        setEmailVeri(false);
      }
    }

    user.translateUI ? updateUILang(user.lang) : updateUILang('en');
  }, [authIsReady, user, pauseAnalytics]);

  // check GDPR cookies
  useEffect(() => {
    let cookies = document.cookie;
    cookies = cookies.replaceAll('; ', ';');
    const value = cookies.split(';').find(row => row.startsWith('gdpr'))?.split('=')[1];
    
    if (value) {
      if (value === 'accept') {
        setGdpr(false);
        setGdprAccept(true);
      }
      if (value === 'denied') {
        setGdpr(false);
        setGdprAccept(false);
      }
    }
  }, []);

  useEffect(() => {
    if (gdprAccept) document.cookie = 'gdpr=accept';
    if (gdprAccept && !pauseAnalytics) setAnalyticsCollectionEnabled(analytics, true); 
    if (pauseAnalytics) setAnalyticsCollectionEnabled(analytics, false);
  }, [gdprAccept, pauseAnalytics]);

  // handle tutorial
  useEffect(() => {
    if (!user || !welcomeViewed || emailVeri) return;
    if (!tutorialDone) setShowTutorial(true);
  }, [user, welcomeViewed, emailVeri, tutorialDone]);

  // handle welcome
  useEffect(() => {
    if (!user) return;
    if (!welcomeViewed) return setWelcome(true);
}, [user, welcomeViewed]);

  const tutorialHandler = (payload) => {
    if (payload.action === 'close') {
      setShowTutorial(false);
      dispatch({ type: 'UPDATE_OTHER', payload: { tutorialDone: true }, source: 'A#tH' });
      if (user && !user.tutorialDone) {
        updateDocument(user.uid, { tutorialDone: true });
      }
    }
  }

  const handleExitModal = (e) => {
    setModal(false);
    dispatch({ type: 'RESET_LOGGINGOUT_WARNING', source: 'APP#HEMo' });
  }

  const endChatAndLogout = async () => {
    dispatch({ type: 'LOGGINGOUT', source: 'APP#ECALo' });
    setTimeout(async () => {
      await endChat(data);
      appDispatch({ type: 'END_CHAT'});
      chatDispatch({ type: 'EXIT' });
      // can't use logout hook here
      if (!user.isAnonymous) await updateDocument(user.uid, { online: false });
      await signOut(auth);
      
      let url = window.location.href;
      url = url.slice(0, url.indexOf('/chat'));
      window.location.replace(url);
      setModal(false);
    }, 500);
  }

  const handleReturnToAppFxn = ({ method, value }) => {
    if (method === 'gdpr') {
      if (value) setGdprAccept(true);
    }
    if (method === 'analytics') {
      if (!value) setPauseAnalytics(true);
    }
    if (method === 'startChat') {
      setHideBg(true);
    }
  }


  const handleAppClearClick = (e) => {
    const el = document.querySelector('.googleTranslateElement');
    if (!e.target.closest('.translate-button')) el.classList.remove('show');

    if (!e.target.closest('.nav-bar') && !e.target.classList.contains('navIcon') && !e.target.closest('.help-bubble-btn') && !e.target.closest('.search-user-btn')) {
      chatDispatch({ type: 'CLEAR' });
      setTimeout(() => {
        chatDispatch({ type: 'RESET_CLEAR' });
      }, 500);
    }
  }

  const [bounce, setBounce] = useState(false);
  const toggleBounce = () => {
    setBounce(true);
    setTimeout(() => setBounce(false), 4000);
}
 
  return (
    <>
    {<div className={`app-loading ${authIsReady ? 'hide' : ''}`}>
      <div className="self-center text-center flex flex-col gap-2">
            {!bounce && <h1 className="just-showing" onClick={toggleBounce} unselectable="on">
              <img className="h-20 w-20 mx-auto" src={Orange} alt="Orange" />
            </h1>}
            {bounce && <h1 onClick={toggleBounce} className="just-showing animate-custom-bounce" unselectable="on">
              <img className="h-20 w-20 mx-auto" src={Orange} alt="Orange" />
            </h1>}
            <div className="flex">
              <p className="p-emphasize mr-1">{appName} Loading...</p>
              <LoadingIcon size="small"/>
            </div>
        </div>
    </div>}
    <div className={`App container md:max-w-2xl mx-auto flex content-center ${screen ? 'block-content' : ''}`} onClick={handleAppClearClick}>
      {user && !hideBg && <div className={`bg-placeholder `}></div>}
      {authIsReady && (
        <BrowserRouter>
        {user && !hideBg && <NavBar />}
        <Routes>
            <Route path="/chatlobby/:id" element={<ChatLobby returnFxn={handleReturnToAppFxn} />} />
            <Route path="/chatdoor/:id" element={<ChatDoor gdpr={gdprAccept} returnFxn={handleReturnToAppFxn} />} />
            <Route path="/chatdoor" element={<Navigate to="/chat" />} />
            <Route path="/chatlobby" element={<Navigate to="/chat" />} />
            <Route path="/chatroom" element={<Navigate to="/chat" />} />
            <Route path="/chatroom/:id" element={<Chatroom />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/opt-out" element={<OptOut returnFxn={handleReturnToAppFxn} />} />
            {!user && <Route path="/*" element={<Home gdpr={gdprAccept} returnFxn={handleReturnToAppFxn} />} />}
            {user && (
              <>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/home" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/tree" element={<Tree />} />
                <Route path="/limit" element={<Limits />} />
                <Route path="/limits" element={<Limits />} />
                <Route path="/speaker" element={<Speaker />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/dxtools" element={<DXTools />} />
                <Route path="/subscriptions" element={<Subscriptions />} />
                <Route path="/subscriptions/:result" element={<Subscriptions />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      )}
      {modal && <div className="screen">
          <div className="center-modal">
              <div className="center-modal-close-btn" onClick={handleExitModal}>
                  <ClickableIcon type="close" addClass="small invert no-hover" />
              </div>
              <h4>Logging Out</h4>
              <p className="mt-2">You are the owner of an active chat session. Logging out will end this chat for everyone.</p>
              <div className="mt-8 flex justify-center">
                  <button onClick={endChatAndLogout}>log out</button>
              </div>
          </div>
      </div>}
      <EmailVerificationModal returnFxn={(emailVeriStatus) => setEmailVeri(emailVeriStatus)} status={emailVeri} />
      {welcome && <Welcome returnFxn={() => setWelcome(false)}/>}
      {showTutorial && <Tutorial returnFxn={tutorialHandler} />}
      {!user && authIsReady && gdpr && <div className="notification">
          <p>We and our partners use cookies and similar technologies to provide you with a variety of services and to improve the usability of our website. By using the website, you agree to the <a className="p-emphasize" href="/terms-and-conditions" target="blank">Terms & Conditions <img src={NewTabIcon} className="inline self-center invert" style={{ height: '10px', width: '10px' }}/></a> and the use of cookies in accordance with our <a className="p-emphasize" href="/privacy-policy" target="blank">Privacy & Cookies Policy <img src={NewTabIcon} className="inline self-center invert" style={{ height: '10px', width: '10px' }}/></a>. 
          </p>
          <div className="flex justify-around mt-4">
            <button style={{ fontSize: '12px' }} onClick={() => {
              setGdpr(false);
              setGdprAccept(false);
              document.cookie = 'gdpr=denied';
            }}>Opt Out</button>
            <button className="invert" style={{ fontSize: '12px' }} onClick={() => {
              setGdpr(false);
              setGdprAccept(true);
              document.cookie = 'gdpr=accept';
            }}>I Agree</button>
          </div>
      </div>}
    </div>
    </>
  );
}

export default App;
