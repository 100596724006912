import { useState, useEffect } from 'react';

import './Privacy.css';

// import { langSrc } from '../../tools/languageSource';
// import GoogleTranslate from '../../assets/google_translate.png';
import { privacyPolicyVersion, privacyPolicyDate } from '../../tools/tools';
import ClickableIcon from '../../components/ClickableIcon';

export default function PrivacyPolicy() {
    // const [lang, setLang] = useState('en');

    // const langOptions = langSrc.map(item => (
    //     <option key={item.code} value={item.code}>{item.name}</option>
    // ));

    useEffect(() =>{
        const notificationEl = document.querySelector('.notification');
        if (!notificationEl) return;
        notificationEl.classList.add('hide');
    }, []);
    
    return (
        <div className="privacy flex flex-col hide-scroll p-4" onClick={(e) => {
            const el = document.querySelector('.googleTranslateElement');
            if (!e.target.closest('.icon_btn')) el.classList.remove('show');
        }}>
            <div className="flex justify-end icon_btn">
                <div onClick={() => {
                    const el = document.querySelector('.googleTranslateElement');
                    el.classList.contains('show') ? el.classList.remove('show') : el.classList.add('show');
                }}>
                    <ClickableIcon type="translate" addClass="no-background" />
                </div>
            </div>
            <h2>Privacy & Cookies Policy</h2>
            <p className="p-emphasize">{privacyPolicyDate} | version {privacyPolicyVersion}</p>
            <p>This Privacy & Cookies Policy (“Policy” or “Notice”) describes the ways that Shyroboto, our licensees and assignees (referred to in this Policy as “Shyroboto”, “us”, “we” or “our”) collect, store, use, and manage the information, including personal information, that you (“you”, “your” or “user”) provide or we collect in connection with the www.peelchat.com, peelchat.com and peel.chat (the “Site”) or any Shyroboto services provided on a mobile platform (ex. iOS and Android) (collectively, the "Services").</p>
            <p>Any personal data that provided to or gathered by the Services is controlled by Shyroboto LLC, a company incorporated in New York, USA, with a mailing address at 67 West St, Suite 401, New York, NY 11222 ("Shyroboto"). We can also be reached at hello@peelchat.com.</p>
            <p>Shyroboto reserves the right to revise this Policy in its sole discretion at any time and without prior notice to you other than by posting the revised Policy on the Site, except when the changes concerns your personal data, we will promptly notify you of these changes and provide you with options relating to the use, storage and sharing of your personal data. The revisions to the Policy are effective upon posting. The Policy will be identified as of the most recent date of revision. Notwithstanding the preceding sentences of this paragraph, no revisions to this Policy will apply to any dispute between you and Shyroboto that arose prior to the date of such revision.</p>
            <p>Shyroboto is not responsible for the actions of third parties or companies, the content of their sites, or the use of information you provide to them.</p>
            <p>When you visit the Site or use the Services, you access linked pages, features, and content, including any information, text, graphics, photos, comments, reviews, links, or other materials uploaded, downloaded or appearing on, or linked to the Services (collectively, the “Content”)</p>
            <p>While you are using our Services, your computer’s operating system, the mobile device you use, your Internet Protocol (IP) address, access times, browser type and your language preferences may be recorded automatically. We may use this information to monitor, develop and analyze your use of the Service. In addition, we collect and process your personal data, including but not limited to names, photos, gender, usernames and e-mail. We may use this information to provide you services for you. We may use your email address to contact you about your experience with Shyroboto Services and notify you for Shyroboto news and promotions. You may opt out of this contact by deactivating the “receive newsletters” option in your account settings. Your gender may be used to analyze user trends and target certain promotions.</p>
            <h5>I. THE COLLECTION, STORAGE AND SHARING OF YOUR PERSONAL DATA</h5>
            <p>Shyroboto collects information as described below. Shyroboto’s primary goals in collecting and using information is to provide Services and work product to you, improve our Service, contact you, conduct research and create reports for internal use.</p>
            <p>This Policy provides notice to you regarding Shyroboto’s treatment of personal data that Shyroboto gathers when you access the Services and/or use the Content.  We explain what data we collect, why we collect such data, how we store the collected data and what options you have regarding your personal information.</p>
            <p className="p-title">A. Types of Data We Collect</p>
            <p>We may collect two types of data and information from you:</p>
            <p>The first type of information is non-identifiable and anonymous information (“Non-personal Information”). We are not aware of the identity of the user from which we have collected Non-Personal Information. Non-Personal Information is any unconcealed information which is available to us while users are using the Sites. Non-personal Information which is being gathered consists of technical information and behavioral information, including, but not limited to, the operating system, type of browser, screen resolution, the period of time the User visited the Site, etc.</p>
            <p>The second type of information is individually identifiable information (“Personal Information”).</p>
            <p>This information may identify an individual or may be of a private and/or sensitive nature. Personal Information which is being gathered consists of any personal details provided consciously and voluntarily by you including name, email, address, IP address and other unique identifiers and any other information you choose to provide to Shyroboto, such as medical records, documents, photos and identification of others.</p>
            <p>The types of personal data collected may include your full name, other person’s full name, email address, IP address, browser information, username, password, billing address, credit card information and any other information necessary for us to provide the Services, including the usernames and passwords for your accounts and profiles on third-party sites with which the Services interoperates. In addition, when you upload material to our Services, we separately collect the data and any information necessary for us to provide Services while you are using said Service or temporarily after the Service ends, including any sensitive information that you may have uploaded.</p>
            <p>As customers, you do not have any legal obligation to provide any information to Shyroboto. However, we require certain information from you to operate properly, including your payment information for completing a transaction on your behalf and your IP address which enable us to provide services. You hereby agree and acknowledge that any Personal Information you do provide to us is provided at your own free will (and in the extent that such information belongs to a third party, you warrant that all relevant consent is obtained), for the purposes mentioned in this Policy and that we may keep such Personal Information in a database(s) which will be registered and kept in accordance with applicable laws and regulations.</p>
            <p>We retain personal information no longer than is legally permissible and delete personal information when it is no longer necessary for the purposes set out in this Policy.</p>
            <p className="p-title">B. Methods We Use to Collect Data</p>
            <p>We collect Personal Information when you upload or submit material to Shyroboto through our portal at https://www.peelchat.com. We receive and store any information you enter via the portal in the United States. We will collect the payment and transaction information you enter on the Services and the records of your orders. If you contact us, we may keep a record of that correspondence.</p>
            <p>You can choose not to provide us with certain information, but then you may not be able to access or use many of our features (please see below for further information on the choices you have).</p>
            <p>We receive and store certain types of information whenever you interact with our platforms or use another feature of our Service. In other words, when you are using the Sites, we may gather, collect and store the information relating to such usage, either independently or through the help of third-party services as detailed below. Shyroboto automatically receives and stores information on our server logs from your browser including your IP address, certain cookie information (please see below for further information) and the page you visited.</p>
            <p>More generally, our Services automatically collect usage information, such as the numbers and frequency of visitors to our Services and its components. Shyroboto only uses this data in aggregate form, that is, as a statistical measure, and not in a manner that would identify you personally. This type of aggregate data enables us to figure out how often customers use parts of the Services or another feature of the Content so that we can make the Services appealing to as many customers as possible, and improve the Content. As part of this use of information, we may provide aggregate information to our partners about how our customers, collectively, use our Content. We share this type of statistical data so that our partners also understand how often people use our Content, so that they, too, may provide you with an optimal online experience. Again, Shyroboto never discloses aggregate information to a partner in a manner that would identify you personally, unless it needs to complete a transaction on your behalf. Shyroboto only discloses information to third parties it trusts.</p>
            <p>We often receive a confirmation when you open an email from Shyroboto if your computer supports this type of program. Shyroboto uses this confirmation to help us make emails more interesting and helpful. We also review our customer list to avoid sending unnecessary messages to our customers.</p>
            <p>When you use any of the Services on a mobile platform, we may collect and record certain information such as your unique device ID (persistent / non-persistent), hardware type, media access control (“MAC”) address, international mobile equipment identity (“IMEI”), the version of your operating system (“OS”), your device name, your email address, and your location (based on your IP address). This information is useful to us for troubleshooting and helps us understand usage trends.</p>
            <p>When you download or use mobile applications created by Shyroboto or its affiliates, you have requested or consented to location services, we may receive information about your mobile device and your location, including a unique identifier for your device. We may use this information to provide you with personalized content and location-based services, such as search results. Most mobile devices allow you to turn off location services. To learn more about what information your device makes available to us, please also check the policies of your device manufacturer and software provider.</p>
            <p className="p-title">C. Why We Collect Your Data</p>
            <p>Non-personal Information is collected to create aggregated statistical data for research purposes and customization and improvement of the Sites.</p>
            <p>Personal Information is collected to:</p>
            <ul>
                <li>Operate the Services;</li>
                <li>Process payments;</li>
                <li>Communicate with you about our products, services, promotional offers and your orders;</li>
                <li>Administer your accounts and update our records;</li>
                <li>Display content and recommend merchandise and services that might be of interest to you;</li>
                <li>Improve our Services and platform, prevent or detect abuses of our Services and enable third parties to carry out technical, logistical or other functions on our behalf;</li>
                <li>Contact you for providing technical assistance, sale reminders and other related information to the Services and collect feedback;</li>
                <li>Use for market research, troubleshooting problems, detecting and protecting against error, fraud or other criminal activity;</li>
                <li>Use for risk control, for fraud detection and prevention, to comply with laws and regulations, and to comply with other legal process and law enforcement requirements;</li>
                <li>Share with third-party contractors;</li>
                <li>Enforce Shyroboto's Terms & Conditions, and Privacy & Cookies Policy.</li>
            </ul>
            <p>We may use mobile analytics software to allow us to better understand the functionality of our mobile software on your device. This software may record information such as how often you use the application, the events that occur within the application, aggregated usage, performance data, and where the application was downloaded from. We do not link the information we store within the analytics software to any identifiable personal information you submit within the mobile application.</p>
            <p className="p-title">D. Where We Store Your Data</p>
            <p>We may transfer and/or store your Personal Information on servers and equipment in the United States or in other countries. Your Personal Information and data may also be processed and used by staff operating outside the United States who work for us or for our contractors. Such staff may be engaged in, among other things, the fulfilment of your order, the processing of your payment information, the communication with you and other support services. By using Shyroboto Services and providing your Personal Information to Sites, you agree to the transfer, storing and processing of your Personal Information. We take all reasonably necessary steps to ensure that your data is treated securely and in accordance with this Policy.</p>
            <p>All information you provide to us is hosted and stored on Google Cloud Platform cloud-based services with data centers in the United States of America, which is in compliant with the European Union General Data Protection Regulation (GDPR) standard, and Stripe cloud-based services, which is also in compliant with the European Union General Data Protection Regulation (GDPR) standard. For further information on the measures taken by Google Cloud Services to protect the security of their servers and your personal data, please see: https://cloud.google.com/security/gdpr. For further information on the measures taken by Stripe Services to protect the security of their servers and your personal data, please see: https://stripe.com/guides/general-data-protection-regulation. If you are not satisfied with the levels of security offered by our cloud providers, you should restrict the nature and amount of Personal Information or confidential information which you include in your log data or not use the Services. Any payment transactions will be encrypted using either  HTTPS/TLS encryption or Secure Sockets Layer 3.0 (SLL) technology.</p>
            <p className="p-title">E. How We Secure Your Data</p>
            <p>Shyroboto takes reasonable measures to protect your information from unauthorized access or against loss, misuse or alteration by third parties.  Although we make good faith efforts to store the information collected on the Service in a secure operating environment that is not available to the public, we cannot guarantee the absolute security of that information during its transmission or its storage on our systems.  Further, while we attempt to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers" from illegally obtaining access to this information.  We do not warrant or represent that your information will be protected against, loss, misuse, or alteration by third parties. No method of transmission over the Internet, or method of electronic storage, is 100% secure.  Therefore, we cannot guarantee its absolute security.</p>
            <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of our Services, you are responsible for keeping this password confidential. You must not share a password with anyone.</p>
            <p className="p-title">F. We Share Data with Third Parties</p>
            <p>We share your Personal Information only as described in this Policy and require commercial entities with which we share your Personal Information to agree to keep your information confidential.</p>
            <p className="p-italic">a. We Do Not Control Other Websites</p>
            <p>The Services may contain links to websites and other sites. Shyroboto is not responsible for the privacy policies and/or practices on other sites. When linking to another site you should read the privacy policy stated on that site. This Policy only governs information collected on the Services. We maintain appropriate safeguards to ensure the security, integrity and privacy of your information and we encrypt your information to protect it from unauthorized use.</p>
            <p className="p-italic">b. We Share Your Data with Third Parties</p>
            <p>We do not disclose data containing information about identifiable individuals to our advertisers, but we may provide them with aggregate data about our users (for example, we may share with them that 200 female users under the age of 35 have clicked on certain advertisement on a given day). We may also use such aggregate data to help advertisers reach the kind of audience they want to target (for example, male above age of 40). We may make use of the Personal Information we have collected from you to enable us to comply with our advertisers' wishes by displaying their advertisements to targeted audience.</p>
            <p>Please note that we do not control the advertisers and the advertisers may collect and use information about you, such as your device identifier, MAC address, IMEI, geo-location information and IP address. You may opt out on sharing such data by changing your account settings on their respective platforms.</p>
            <p>We may share your Personal Information to analytics and search engine providers that assist us in the improvement and optimization of our platform. We have implemented the Google Analytics feature. You can opt out of this feature by changing your settings here: https://www.peelchat/opt-out.</p>
            <p className="p-italic">c. We Hire Agents and Outsource</p>
            <p>We may share your Personal Information to the extent necessary with selected third parties including contractors and employees that work on your work product.</p>
            <p>We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products and Services to you. Unless we tell you differently, Shyroboto’s agents do not have any right to use Personal Information we share with them beyond what is necessary to assist us.</p>
            <h5>II. INFORMATION WE COLLECT IN YOUR ACTIVITIES</h5>
            <p className="p-title">A. Your Submissions</p>
            <p>All Submissions including all content you share on or with the Services (such as copies of documents or photos) is the sole responsibility of the person who originated such content. Shyroboto performs technical functions necessary to offer the Services, including but not limited to transcoding and/or reformatting content to allow its use throughout the Shyroboto Services. We collect the necessary data in your Submissions in order to provide Services based on the Submissions.</p>
            <p className="p-title">B. Communication in Response to Users</p>
            <p>As part of the Services, you will receive from Shyroboto email and other communication relating to your use of the Services. You acknowledge and agree that by submitting any content to Shyroboto, Shyroboto may send you email and other communication about your Submissions at Shyroboto’s sole discretion. From time to time, Shyroboto might need to send you announcements and administrative messages. These communications are necessary for the operation of Services, which you may not be able to opt-out from receiving.</p>
            <p className="p-title">C. Business Transfers</p>
            <p>In some cases, we may choose to buy or sell assets. In these types of transactions, customer information is typically one of the business assets that are transferred. Moreover, if Shyroboto, or substantially of its assets were acquired, or in the unlikely event that Shyroboto goes out of business or enters bankruptcy, customer information would be one of the assets that is transferred to or acquired by a third party. You acknowledge that such transfers may occur, and that any successor of Shyroboto may continue to use your Personal Information as set forth in this Policy.</p>
            <h5>III. LEGAL COMPLIANCE</h5>
            <p>We may release Personal Information when we believe in good faith that release is necessary to comply with the law, regulation or legal request; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Shyroboto, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. You agree that we may also share your data with entities where Shyroboto is engaged in a merger, acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Policy.</p>
            <h5>IV. YOUR ACCESS OF PERSONAL INFORMATION</h5>
            <p>Shyroboto allows you to access and view the following information, and in certain situations, to update that information: your name; your email address; zip code, billing address, and payment information.</p>
            <p>If you are using the Services from European Union countries, the General Data Protection Regulation (GDPR) gives you the right to access information about you. For any request or question regarding obtaining a copy of personal data we hold relating to you, and/or correction or deletion of your personal data, and/or object to any processing of your personal data, you can submit a Data Access Request by emailing us at hello@peelchat.com. We will respond to your access and/or correction request within 30 days.</p>
            <p>However, please note that although your Personal Information may be removed from our databases, Shyroboto will retain the anonymous information contained in the data you provided, and such information will continue to be used by us for statistical purpose.</p>
            <p>After a user requests to delete any data, an automated process will begin to permanently delete the requested data. Once the process begins, it cannot be reversed, and the data will be permanently deleted. Any kept shall be retained in an anonymized manner.</p>
            <p>Similarly, Shyroboto collects and retains usage data, other metadata and statistical information concerning the use of the Service are not subject to the deletion procedures in this Policy and may be retained by Shyroboto. Some data may also be retained on our third-party service providers’ servers.</p>
            <p>You can always opt not to disclose information by contacting us at hello@peelchat.com. However, if you opt not to disclose certain information, your access to certain Services may be restricted.</p>
            <h5>V. LEGAL NOTICES</h5>
            <p>We do not knowingly market or sell products for purchase by children. The Services are not designed for use by children under 18 years old. Children under 18 are not allowed to register with or use the Services, or submit personal data through the Services. We do not knowingly collect personal data from anyone under the age of 18. If we discover that we have collected Personal Information from a child under 18, we will delete that information immediately and completely. Please do not submit material to us that contains data of children.</p>
            <p>If you choose not to receive legal notices from us, such as an update of this Policy, those legal notices will still govern your use of the Services, and you are responsible for reviewing such legal notices for changes.</p>
            <p>Shyroboto reserves the right to change this Policy from time to time. Use of information we collect now is subject to the Policy in effect at the time such information is used. If we make substantial changes in the way we use personal data, we will notify you by posting an announcement on our Services or sending you an email. Otherwise, all other changes to this Policy are effective as of the stated “Last Revised” date, and your continued use of Shyroboto Services after the Last Revisited date will constitute your acceptance to those changes.</p>
            <h5>VI. COOKIES POLICY</h5>
            <p>A cookie is a small text file that is stored on a user's computer for tracking and record-keeping purposes. Cookies alone cannot be used to identify you as they never contain personal information such as name and email address. Unless you have adjusted your browser setting so that it will refuse cookies, we will drop cookies as soon as you visit the Services. Persistent cookies enable us to track and target your interests to enhance your experience on our Sites. For example, we use this data to understand how you interact with the Services so we can make improvements to the design of the Services. By using the Services, you consent to the cookies referenced above being dropped onto your computers and devices.</p>
            <p>When you access Shyroboto Sites, we may use cookies or other similar industry-wide technologies to collect and store certain information on your computer or device. By doing so, we can distinguish you from other Shyroboto users and enable certain features to provide a better customer to you. It is easy to change your cookie settings. Most browsers will allow you to erase cookies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. However, if you block or erase cookies, your online experience with the Services may be affected.</p>
            <p className="p-title">A. Cookies We Use</p>
            <p>We use the following cookies:</p>
            <ul>
                <li>Strictly necessary cookies. These are cookies that are required for the operation of our Services. They include, for example, cookies that enable you to log into certain areas of our Services, use a shopping cart and online payment features. Without these cookies, we may not be able to provide the necessary services such as remembering your username or shopping cart.</li>
                <li>Analytical/performance cookies. They allow us to track users’ activities on the Services which helps us to improve the features on the Services such as making popular features easier to access. These cookies collect anonymous information only and do not show identifiable personal information.</li>
                <li>Functionality cookies. These cookies are used to recognize you and remember your account settings, preferences and the items in your shopping cart.</li>
                <li>Targeting cookies. These cookies record your activities on the Services including the pages you have visited and the items you have liked. We use these cookies to make our Services and the advertisement displayed more relevant to your interests. We may also share this information with third parties for the same purpose.</li>
                <li>We also use the above cookies for conducting diagnostics to improve Shyroboto's Content, products and Services, fraud prevention and security.</li>
            </ul>
            <p>We use both session ID cookies and persistent cookies, to make it easier for you to navigate our platform. A session ID cookie expires when you close your browser. A persistent cookie remains on your hard drive for an extended period of time. You can remove persistent cookies via your browser’s ‘clear cookie’ function.</p>
            <p>Please note that third parties such as advertising networks and web traffic analysis providers may also use the above cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.</p>
            <p>You can opt out of data collection or use by some of the analytics providers by clicking the following links:</p>
            <ul>
                <li>https://tools.google.com/dlpage/gaoptout</li>
            </ul>
            <p>If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.</p>
            <ul>
                <li>For the Chrome web browser, please visit this page from Google: https://support.google.com/accounts/answer/32050</li>
                <li>For the Internet Explorer web browser, please visit this page from Microsoft:  https://support.microsoft.com/kb/278835</li>
                <li>For the Firefox web browser, please visit this page from Mozilla: https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</li>
                <li>For the Safari web browser on MacOS operating system, please visit this page from Apple: https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</li>
                <li>For the Safari web browser on iOS operating system, please visit this page from Apple: https://support.apple.com/en-us/HT201265</li>
            </ul>
            <p className="p-title">B. Your Control of the Cookies</p>
            <p>You can block any cookies by changing the settings on your browser or the settings on your mobile device. The "Help" feature on most browsers and the “Privacy” feature on most mobile devices will tell you how to disable cookies entirely, how to stop accepting new cookies and how to set up notifications when you receive new cookie. Additionally, you can disable or delete similar data used by browser add-ons, such as flash cookies, by changing the add-on's settings or visiting the website of its manufacturer. However, if you use your browser settings to block all cookies (including necessary cookies) you may not be able to access all or parts of our Services. Please see www.allaboutcookies.org for more information about blocking cookies on your particular browser.</p>
            <h5>VII. LIMITATION OF LIABILITY</h5>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT SHYROBOTO AND ITS EMPLOYEES, CONTRACTORS, AGENTS AND AFFILIATES, INCLUDING BUT NOT LIMITED TO ANY THIRD PARTIES RESPONSIBLE FOR THE CREATION, MAINTENANCE AND UPKEEP OF THE SITE, SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SHYROBOTO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM THE USE OF THIS SITE OR ANY COMPUTER VIRUSES ON YOUR COMPUTER OR ELECTRONIC DEVICES.  IN NO EVENT SHALL SHYROBOTO’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED ONE HUNDRED DOLLARS ($100). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE OR WITH THIS AGREEMENT, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SITE.</p>
            <h5>VIII. INDEMNIFICATION</h5>
            <p>You will indemnify and hold Shyroboto and its employees, representatives, agents, affiliates, directors, officers, managers and shareholders (the "Parties") harmless from any damage, loss, or expense (including without limitation, attorneys' fees and costs) incurred in connection with any third-party claim, demand or action ("Claim") brought against any of the Parties alleging that you have breached any portion of this Policy, including but not limited to any damages caused by the material you upload or submit to Shyroboto. If you have to indemnify Shyroboto under this Section, Shyroboto will have the right to control the defense, settlement, and resolution of any Claim at your sole expense. You may not settle or otherwise resolve any Claim without Shyroboto's express written permission.</p>
            <h5>IX. ARBITRATION</h5>
            <p>If a dispute arises from or relates to this Policy, your use of the Shyroboto Site or Services or the breach thereof, and if the dispute cannot be settled through direct discussions, the parties agree that any such unresolved controversy or claim shall be settled by arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules.  Judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. Claims shall be heard by a single arbitrator, unless the claim amount exceeds one hundred thousand dollars ($100,000), in which case the dispute shall be heard by a panel of three (3) arbitrators. The place of arbitration shall be in the city, county and state of New York. The arbitration shall be governed by the laws of the State of New York. The prevailing party shall be entitled to an award of reasonable attorney fees, if awarded by the arbitrator(s).</p>
            <p>Notwithstanding the foregoing, if a third party claim is brought against either party for copyright infringement, violation of rights of publicity, rights of privacy, or other unauthorized use of content which is contrary to the rights granted in this Policy, the parties shall not be bound by this arbitration provision and may defend themselves and make a claim against the other party in the appropriate court of law and/or equity.</p>
            <p>In the event of a dispute arising out of this Policy and not subject to the arbitration provision above, the prevailing party shall have the right to collect from the other party its reasonable costs and necessary disbursements and attorneys' fees.</p>
            <h5>X. GOVERNING LAW</h5>
            <p>This Policy shall be governed in all respects by the laws of the State of New York as they apply to agreements entered into and to be performed entirely within New York between New York residents, without regard to conflict of law provisions. The parties to this Policy agree that any claim or dispute arising under this Policy must be resolved by a court located in the city, county and state of New York, except as otherwise agreed by the parties or as described in the Arbitration provision above. The parties agree to submit to the personal jurisdiction of the courts located within city, county and state of New York for the purpose of litigating all such claims or disputes.</p>
            <h5>XI. CONSENT AND CHANGES</h5>
            <p>By using the Services, you consent to our sharing of personal data for the above purposes. We may share any of your personal data with any of our parent companies, subsidiaries, joint ventures, or other companies under common control with us. For the avoidance of doubt, Shyroboto may transfer and disclose Non-personal information to third parties at its own discretion.</p>
            <p>If you do not consent to this Policy or any of the above terms, please immediately exit and discontinue use of the Site and Services. Shyroboto has the discretion to update this Policy at any time. In the event this Policy is updated, we will also update the date set forth below. Please check this page regularly to keep informed of any such updates.</p>
            <h5>XII. MISCELLANEOUS</h5>
            <p>You may be subject to additional Policy that may apply when you use affiliate services, third-party content or third-party software.  Every provision herein is intended to be severable.  In the event any term or provision hereof is declared to be illegal or invalid for any reason whatsoever by a court of competent jurisdiction, such illegality or invalidity shall not affect the balance of the terms and provisions hereof, which terms and provisions shall remain binding and enforceable.</p>
            <p>If you have any questions about this Policy, the practices of Shyroboto, or your dealings with the Sites or Services, please contact us at hello@peelchat.com.</p>
            <p>This Policy has been drawn up in the English language. In case of discrepancies between the English text version of this Policy and any translation, the English version shall prevail.</p>
            <p className="mt-12">Last Revised: {privacyPolicyDate}</p>
        </div>
    )
}