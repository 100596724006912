import { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { functions } from '../../../firebase/config';
import { httpsCallable } from 'firebase/functions';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';
import ProfilePic from '../../../components/ProfilePic';

import './SearchUsers.css';

export default function SearchUsers({ returnFxn }) {
    const findUserFxn = httpsCallable(functions, 'findUser');
    const friendRequestFxn = httpsCallable(functions, 'handleFriendRequests');
    const { user } = useAuthContext();
    const [searchPending, setSearchPending] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState(null);
    const [requestPending, setRequestPending] = useState(false);

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        if (searchPending) return;
        setSearchPending(true);

        const res = await findUserFxn({ search: searchValue });
        setSearchResult(res.data);
        setSearchPending(false);
    }

    const handleFriendRequestClick = async (receiver) => {
        if (requestPending) return;
        setRequestPending(true);
        friendRequestFxn({
            method: 'send',
            displayName: user.displayName,
            photoURL: user.photoURL || '',
            username: user.username || '',
            role: user.role || 'other',
            sender: user.uid,
            receiver: receiver.uid
        }).then(res => {
            setRequestPending(false);
        }).catch(err => {
            console.log(err);
            setRequestPending(false);
        });
    }

    return <div className="screen" onClick={(e) => {
        if (!e.target.closest('.center-modal')) returnFxn();
    }}>
        <div className="center-modal">
            <div className="center-modal-close-btn" onClick={() => returnFxn()}>
                <ClickableIcon type="close" addClass="small no-hover invert" />
            </div>
            <h5>Find User</h5>
            <form className="mt-4 flex justify-between gap-2" onSubmit={handleSearchSubmit}>
                <input 
                    type="text"
                    className="wide" 
                    onChange={(e) => {
                        setSearchResult(null);
                        setSearchValue(e.target.value);
                    }}
                    placeholder="search usernames"
                    value={searchValue}
                />
                <button className="btn btn-small">
                    <ClickableIcon type="check" addClass="small invert no-hover no-background" />
                </button>
            </form>
            <div className={`search-user-results mt-4 ${searchPending || searchResult ? 'show' : ''}`}>
                {searchPending && <LoadingIcon addClass="absolute" />}
                {searchResult && !searchResult.length && <p className="absolute">No user found</p>}
                {searchResult && searchResult.length > 0 && searchResult.map((entry, index) => {
                    if (entry.uid === user.uid) {
                        return (<div key={index} className="flex justify-between w-full absolute px-8">
                            <div className="flex">
                                <ProfilePic size="small" url={entry.photoURL} />
                                <div className="self-center flex flex-col justify-center ml-1">
                                    <p className="p-emphasiz whitespace-nowrap">{entry.displayName}</p>
                                    <div className="badge">@{entry.username}</div>
                                </div>
                            </div>
                        </div>);
                    } else if (user?.friends?.indexOf(entry.uid) !== -1) {
                        return (<div key={index} className="flex justify-between w-full absolute px-8">
                            <div className="flex">
                                <ProfilePic size="small" url={entry.photoURL} />
                                <div className="self-center flex flex-col justify-center ml-1">
                                    <p className="p-emphasiz whitespace-nowrap">{entry.displayName}</p>
                                    <p className="p-emphasize whitespace-nowrap"></p>
                                    <div className="badge">@{entry.username}</div>
                                </div>
                            </div>
                            <div className="self-center mr-4 relative">
                                <ClickableIcon type="personAdd" addClass="no-hover alt-color opacity-50" />
                                <p className="friend-request-sent-status badge">friends 🙌</p>
                            </div>
                        </div>)
                    } else {
                        return (<div key={index} className="flex justify-between w-full absolute px-8">
                            <div className="flex">
                                <ProfilePic size="small" url={entry.photoURL} />
                                <div className="self-center flex flex-col justify-center ml-1">
                                    <p className="p-emphasiz whitespace-nowrap">{entry.displayName}</p>
                                    <p className="p-emphasize whitespace-nowrap"></p>
                                    <div className="badge">@{entry.username}</div>
                                </div>
                            </div>
                            {(!user?.friendRequests?.sent || !user.friendRequests.sent[entry.uid]) && <div className="self-center mr-4 cursor-pointer" onClick={() => handleFriendRequestClick(entry)}>
                                {!requestPending && <ClickableIcon type="personAdd" />}
                                {requestPending && <div className="pin">
                                    <LoadingIcon size="small" />
                                </div>}
                            </div>}
                            {user?.friendRequests?.sent && user.friendRequests.sent[entry.uid] && <div className="self-center mr-4 relative">
                                {!requestPending && <ClickableIcon type="personAdd" addClass="no-hover alt-color opacity-50" />}
                                {requestPending && <div className="pin">
                                    <LoadingIcon size="small" />
                                </div>}
                                <p className="friend-request-sent-status badge invert">sent</p>
                            </div>}
                        </div>)
                    }
                })}
            </div>
        </div>
    </div>
}