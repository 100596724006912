import { useEffect, useState } from 'react'
import { auth, storage } from '../../firebase/config'
import { signInAnonymously, updateProfile, updateEmail } from 'firebase/auth'
import { useAuthContext } from '../useAuthContext'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { functions, analytics } from '../../firebase/config'
import { httpsCallable } from 'firebase/functions'
import { setUserProperties } from 'firebase/analytics'
import { updateUILang } from '../../tools/tools'

import { randomIDGenerator } from '../../tools/tools'

export const useGuestAuth = () => {
    const signLegalFxn = httpsCallable(functions, 'signLegal');
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { dispatch } = useAuthContext();
        
    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const loginAsGuest = async (photo, displayName, lang, role, session) => {
        return new Promise(async (resolve, reject) => {
            setError(null);
            setPending(true);

            try {
                const res = await signInAnonymously(auth);
                if (!res.user) throw new Error('Failed to login in as guest');
                let imgURL = '';
                if (photo) {
                    const storageRef = ref(storage, `profiles/guests/${res.user.uid}.jpg`);
                    const img = await uploadBytesResumable(storageRef, photo);
                    imgURL = await getDownloadURL(img.ref);
                }

                const random = randomIDGenerator(8);
                // save 
                // currently unable to read custom claims => temp store in displayName field.
                await updateProfile(auth.currentUser, { 
                    displayName, 
                    photoURL: imgURL
                });
                await updateEmail(auth.currentUser, `${lang}_${role}@${session}${random}.com`);
                await signLegalFxn({ uid: res.user.uid });

                dispatch({ type: 'LOGIN_GUEST', payload: { ...res.user, photoURL: imgURL, lang, role, session }});

                setUserProperties(analytics, {
                    role: 'guest',
                    lang
                });

                // update UI language
                updateUILang(lang);
                
                reset();
                resolve({ user: res.user, imgURL, lang, role });
                return;
            } catch(err) {
                console.log(err.message);
                reset(err);
                reject(err.message);
                return;
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, loginAsGuest };
}