import { useAuthContext } from "./useAuthContext"
import { serverTimestamp } from "firebase/firestore"
import { useState, useEffect } from 'react'
import { functions } from "../firebase/config"
import { httpsCallable } from 'firebase/functions'

export const useRequestChat = () => {
    const handleChatFxn = httpsCallable(functions, 'handleChatEntryExit');
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { user } = useAuthContext();
    
    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const requestChat = async (session, guest) => {
        return new Promise(async (resolve, reject) => {
            setPending(true);

            if (!user) {
                console.log('User not authenticated')
                setError('User not authenticated');
                setPending(false);
                reject('User not authenticated');
            } else {
                let save = {
                    method: 'request',
                    session,
                    user: {
                        uid: user.uid,
                        payload: {
                            username: user.username || false,
                            displayName: user.displayName || 'Unknown',
                            gender: user.gender || 'NEUTRAL',
                            lang: user.lang || 'en',
                            license: user.license || '',
                            organization: user.organization || '',
                            photoURL: user.photoURL || '',
                            tempID: user.uid,
                            timestamp: serverTimestamp(),
                            role: user.role,
                            ban: false,
                            owner: false,
                            user: guest ? 'guest' : user.uid
                        },
                        guest
                    }
                }

                try {
                    const res = await handleChatFxn(save);
                    
                    reset();
                    resolve(res);
                    return;
                } catch(err) {
                    console.log('failed', err.message);
                    reset(err);
                    reject(err.message);
                    return;
                }        
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { pending, error, requestChat };
}