import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyAEtsxTkt5N5wTejdVSRa4EVljwww1ttEg",
    authDomain: "medchat-d8c76.firebaseapp.com",
    databaseURL: "https://medchat-d8c76-default-rtdb.firebaseio.com",
    projectId: "medchat-d8c76",
    storageBucket: "medchat-d8c76.appspot.com",
    messagingSenderId: "690628003219",
    appId: "1:690628003219:web:c90f180642ff4447cbd466",
    measurementId: "G-TPKN3Q1SDY"
};

const app = initializeApp(firebaseConfig);

const fs = getFirestore(app);
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { fs, db, auth, storage, functions, analytics };