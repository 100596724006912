import { useState } from 'react';
import { useDocListener } from '../../hooks/firebase/useDocListener';
import { useAuthContext } from '../../hooks/useAuthContext';

import './Limits.css'

import LimitList from './LimitList';
import LimitForm from './components/LimitForm';
import ClickableIcon from '../../components/ClickableIcon';

import LockImg from '../../assets/lock.png';

export default function Limits() {
    const { user } = useAuthContext();
    const { document, pending, error } = useDocListener('users', user.uid);
    const [newLimit, setNewLimit] = useState(false);
    const [editLimit, setEditLimit] = useState(false);
    const [payload, setPayload] = useState(null);
            
    const handlePageClick = (target, id) => {
        if (target === 'edit') {
            setNewLimit(false);
            setPayload(id);
            setEditLimit(true);
        }
    }

    const handleScreenClear = (e) => {
        if (e.target && e.target.closest('.center-modal')) {
            if (!e.target.closest('.cancel-form-btn')) return;
        }
        setNewLimit(false);
        setEditLimit(false);
    }

    const handleAddClick = () => {
        setEditLimit(false);
        newLimit ? setNewLimit(false) : setNewLimit(true);
    }

    return (
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <div className="flex gap-1">
                <h5 className="mt-1 self-center">Restrict Responses</h5>
                <img className="h-4 w-4 self-center" src={LockImg} alt="Padlock" />
            </div>
            <div className="mt-4 grow bg-neutral-200 flex flex-col">
                <div className="limit-container flex flex-col overflow-hidden">
                    <p className=""><span className="p-emphasize">Restrict responses</span> help capture relevant and accurate information by limiting how others can respond to your messaage.</p>
                </div>
                <div className="mt-4 grow flex items-center mx-auto">
                    {!pending && !error && <LimitList data={document} returnFxn={handlePageClick} />}
                    {error && <p className="error mx-auto">{error}</p>}
                </div>
                {!newLimit && !editLimit && <div className="btn-circle mx-auto mb-4" onClick={() => handleAddClick('new')}>
                    <ClickableIcon type="add" addClass="alt-color small no-hover" />
                </div>}
            </div>
            {!pending && newLimit && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal limit-form">
                    <h5>Create a Response</h5>
                    <div className="cancel-form-btn" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <LimitForm data={document} method="new" returnFxn={handleScreenClear} />
                </div>
            </div>}
            {editLimit && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal limit-form">
                    <h5>Edit a Response</h5>
                    <div className="cancel-form-btn" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <LimitForm data={document} method="edit" id={payload} returnFxn={handleScreenClear} />
                </div>
            </div>}
        </div>
    )
}

