
import * as imageConversion from 'image-conversion';
import { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import { functions, storage } from '../../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { useChatContext } from '../../../hooks/useChatContext';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useDocListener } from '../../../hooks/firebase/useDocListener';
import { useAppContext } from '../../../hooks/useAppContext';

import './ChatPanel.css';

import ClickableIcon from '../../../components/ClickableIcon';
import ChatEntry from './ChatEntry';
import ChatProviderTools from './ChatProviderTools';
import SpeechRec from './SpeechRec';
import ChatTools from './ChatTools';
import Instruction from './subcomponents/Instructions';
import TwoWayClass from './subcomponents/TwoWayClass';
import LockImg from '../../../assets/lock.png';

import ChatInterpreterTools from './ChatInterpreterTools';
import LoadingIcon from '../../../components/LoadingIcon';

import UserPicker from './subcomponents/UserPicker';

export default function ChatPanel() {
    const speakMsgFxn = httpsCallable(functions, 'textToSpeech');
    const addMsgFxn = httpsCallable(functions, 'translateAndSaveMessage');
    const translateAndSpeakMsgFxn = httpsCallable(functions, 'translateAndTextToSpeech');
    const addMsgWithoutTranslationFxn = httpsCallable(functions, 'saveMessage');
    const addImgMsgFxn = httpsCallable(functions, 'saveImageMessage');
    const { id } = useParams();
    const { session, self, users } = useChatContext();
    const { user } = useAuthContext();
    const { document: doc, pending } = useDocListener('sessions', id, false, 'chatPanel');
    const [messageKeys, setMessageKeys] = useState([]);
    const [message, setMessage] = useState('');
    const [senderAction, setSenderAction] = useState(false);
    const [receiverAction, setReceiverAction] = useState(false);
    const [selfData, setSelfData] = useState({});
    const [tools, setTools] = useState(false);
    const [toolsPayload, setToolsPayload] = useState(null);
    const [limitData, setLimitData] = useState(null);
    const [instructLang, setInstructLang] = useState(null);
    const [providerToolsState, setProviderToolsState] = useState(null);
    const input = useRef();
    const outputElement = useRef();
    const scrollAnchor = useRef();
    const [msgStatus, setMsgStatus] = useState('');
    const [msgLine, setMsgLine] = useState([]);
        
    const { languages, tools: toolsFormat, output, outputState, instruct, twoWay, otherSpeechLang, speechStatus, selfSpeechAuto, selfAudio, selfAudioLang, selfAudioIgnore, dispatch, chromeIOS, isIOS } = useAppContext();
    const [outputLang, setOutputLang] = useState([]);
    
    let scrolling = false;
    const [scrollToTop, setScrollToTop] = useState(false);
    const [displayMessageKeys, setDisplayMessageKeys] = useState([]);
    const [displayMsgLength, setDisplayMsgLength] = useState(20);
    const [msgLength, setMsgLength] = useState(0);
    const [targeted, setTargeted] = useState([]);
    
    const handleScroll = (e) => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            const top = e.target.scrollTop;
            if (top === 0) {
                if (displayMsgLength < msgLength) {
                    setScrollToTop(true);
                    setTimeout(() => {
                        let newDisplayLength = displayMsgLength + 10;
                        setDisplayMsgLength(newDisplayLength);
                        setScrollToTop(false);
                    }, [1000]);
                }
            } else {
                setScrollToTop(false);
            }
            scrolling = false;
        }, 300);
    }
    useEffect(() => {
        if (msgLength === 0) return;
        let result = [];
        if (displayMsgLength > msgLength) {
            result = [...messageKeys];
        } else {
            for (let i = messageKeys.length - 1; i >= (messageKeys.length - displayMsgLength); i--) {
                result.unshift(messageKeys[i]);
            }
        }
        setDisplayMessageKeys(result);
    }, [displayMsgLength, msgLength, messageKeys]);

    useEffect(() => {
        setMsgLength(messageKeys.length);
    }, [messageKeys]);

    useEffect(() => {
        let result = [...languages];
        if (otherSpeechLang) {
            if (result.indexOf(otherSpeechLang)) result.push(otherSpeechLang);
        }

        setOutputLang(result);
    }, [languages, otherSpeechLang]);

    useEffect(() => {
        if (!users) return;
        if (!users[self]) return;
        if (!instructLang) {
            setInstructLang(users[self].lang);
        }
    }, [users, self])
    
    const memoUsers = useRef(users);
    useEffect(() => {
        if (!memoUsers.current) return;
        Object.values(memoUsers.current).forEach(r => {
            if (r.tempID === self) setSelfData(r);
        });
    }, [memoUsers, self]);

    useEffect(() => {
        if (instruct || twoWay) {
            setLimitData('');
        }

        if (!outputElement.current) return;
        if (tools) {
            // outputElement.current.style.flexGrow = 0;
            // if (outputState === 'full') outputElement.current.style.height = 'calc(100% - 9rem - env(safe-area-inset-bottom))';
            // if (outputState === 'half') outputElement.current.style.height = 'calc(100% - 7.5rem - 15.5rem - env(safe-area-inset-bottom))';
            // if (outputState === 'shrink') outputElement.current.style.height = 'calc(100% - 3.5rem - 15.5rem - env(safe-area-inset-bottom))';
            // if (outputState === 'shrink') outputElement.current.style.height = 'calc(100% - 14.5rem - 120px)';
        } else {
            // outputElement.current.style.height = '';
            // outputElement.current.style.flexGrow = '';
        }
        setTimeout(() => scrollAnchor.current.scrollIntoView(), 200);
    }, [tools, outputState, instruct, twoWay, outputElement]);

    useEffect(() => {
        if (selfAudio) {
            if (!selfAudioIgnore) {
                setSenderAction({ action: 'audio', lang: selfAudioLang });
            } else {
                setSenderAction(false);    
            }
        } else {
            setSenderAction(false);
        }
    }, [selfAudio, selfAudioLang, selfAudioIgnore]);
   
    const limitFxn = (data) => {
        setLimitData(data);
    }

    const addImage = async (imgURL, imgFileName, timestamp, responseTo) => {
        if (!imgURL) return console.log('no image URL');

        // setStatus
        setMsgStatus('sending');
        setMsgLine([...msgLine, imgURL]);

        const data = {
            timestamp,
            session,
            type: 'image',
            imgURL,
            imgFileName,
            sender: self,
            text: '',
            textLang: selfData.lang,
            lang: outputLang,
            responseTo: responseTo || '',
            actions: {
                sender: false,
                receiver: false,
                limit: false,
                targeted
            }
        }
        addImgMsgFxn(data)
            .then(() => {
                setTimeout(() => scrollAnchor.current.scrollIntoView(), 200);
                // complete status
                msgLine.splice(msgLine.indexOf(imgURL), 1);
                setMsgLine([...msgLine]);
            
                if (msgLine.length === 0) {
                    setMsgStatus('done');
                    setTimeout(() => setMsgStatus(''), 2000);
                }
            }).catch(err => {
                console.log(err.message);
                msgLine.splice(msgLine.indexOf(imgURL), 1);
                setMsgLine([...msgLine]);
                setMsgStatus(`failed to process imgURL: ${imgURL}`);

                setTimeout(() => {
                    if (msgLine.length > 0) {
                        setMsgStatus('sending');
                    } else {
                        setMsgStatus('');
                    }
                }, 2000);
            });
    }
    
    const addMessage = async (e, content, responseTo, twoWay) => {
        let msg = content ? content.toString() : message;
        if (!msg) return console.log('empty message');
        
        //set status
        setMsgStatus('sending');
        setMsgLine([...msgLine, msg]);

        if (!twoWay) {
            if (content && e.target) e.target.classList.add('speaking');
            if (e.preventDefault) e.preventDefault();
        }
        if (senderAction.action === 'audio') {
            if (senderAction.lang === selfData.lang) {
                speakMessage(msg, selfData.lang, user.gender);
            } else {
                speakMessage(msg, senderAction.lang, user.gender, true);
            }
        }
        const sender = twoWay ? 'twoWay-guest' : self;
        const textLang = twoWay ? otherSpeechLang : selfData.lang;
        let limitValue = limitData ? { ...limitData } : false;
        limitValue = responseTo ? false : limitValue;
        
        addMsgFxn({
            session,
            type: 'text',
            sender,
            text: msg,
            textLang,
            lang: outputLang,
            responseTo: responseTo || '',
            actions: { 
                sender: senderAction.action, 
                receiver: receiverAction,
                limit: limitValue,
                targeted
            }
        }).then(() => {
            setTimeout(() => scrollAnchor.current.scrollIntoView(), 200);
            if (!twoWay) if (content && e.target) e.target.classList.remove('speaking');

            // complete status
            msgLine.splice(msgLine.indexOf(msg), 1);
            setMsgLine([...msgLine]);
            
            if (msgLine.length === 0) {
                setMsgStatus('done');
                setTimeout(() => setMsgStatus(''), 2000);
            }
        }).catch(err => {
            console.log(err.message);
            msgLine.splice(msgLine.indexOf(msg), 1);
            setMsgLine([...msgLine]);
            setMsgStatus(`failed to process message: ${msg}`);

            setTimeout(() => {
                if (msgLine.length > 0) {
                    setMsgStatus('sending');
                } else {
                    setMsgStatus('');
                }
            }, 2000);
        });
        
        setMessage('');
    }
    const addMessageWithoutTranslation = async (e, content) => {
        if (e.preventDefault) e.preventDefault();
        let msg = content ? content.toString() : message;
        if (!msg) return console.log('empty message');

        //set status
        setMsgStatus('sending');
        setMsgLine([...msgLine, msg]);

        addMsgWithoutTranslationFxn({
            session,
            type: 'text',
            sender: self,
            text: msg,
            textLang: selfData.lang,
            lang: [],
            responseTo: '',
            actions: {
                sender: false,
                receiver: false,
                limit: false
            }
        }).then(() => {
            console.log('message sent');
            setTimeout(() => scrollAnchor.current.scrollIntoView(), 200);

            // complete status
            msgLine.splice(msgLine.indexOf(msg), 1);
            setMsgLine([...msgLine]);
            if (msgLine.length === 0) {
                setMsgStatus('done');
                setTimeout(() => setMsgStatus(''), 2000);
            }
        }).catch(err => {
            console.log(err.message);
            msgLine.splice(msgLine.indexOf(msg), 1);
            setMsgLine([...msgLine]);
            setMsgStatus(`failed to process message: ${msg}`);

            setTimeout(() => {
                if (msgLine.length > 0) {
                    setMsgStatus('sending');
                } else {
                    setMsgStatus('');
                }
            }, 2000);
        });

        setMessage('');
    }

    const speakMessage = (text, lang, gender = 'NEUTRAL', translate) => {
        // const audioContext = new AudioContext();
        if (text[0] === '@') {
            let strArr = text.split(' ');
            strArr.shift();
            text = strArr.join(' ');
        }
        var audioContxt = window.AudioContext || window.webkitAudioContext;
        const audioContext = new audioContxt();
        audioContext.resume()
            .then(() => {
                if (translate) {
                    const genderParam = gender.toUpperCase();
                    translateAndSpeakMsgFxn({ text, lang, gender: genderParam })
                        .then(async resp => {
                            const audioContent = resp.data;
                            const content = Object.values(audioContent);
                            const arr = Uint8Array.from(content);
                            
                            const audio = await audioContext.decodeAudioData(arr.buffer.slice(0));
                            const source = audioContext.createBufferSource();
                            source.buffer = audio;
                            source.connect(audioContext.destination);
                            source.start(0);
                        })
                } else {
                    const genderParam = gender.toUpperCase();
                    speakMsgFxn({ text, lang, gender: genderParam })
                        .then(async resp => {
                            const audioContent = resp.data;
                            const content = Object.values(audioContent);
                            const arr = Uint8Array.from(content);
                            
                            const audio = await audioContext.decodeAudioData(arr.buffer.slice(0));
                            const source = audioContext.createBufferSource();
                            source.buffer = audio;
                            source.connect(audioContext.destination);
                            source.start(0);
                        })
                }
            }).catch(err => console.log(err.message));
    }

    useEffect(() => {
        if (pending || !doc) return;
        if (!selfData) return;
        let keys = Object.keys(doc).filter(key => {
            if (key !== 'info' && key !== 'status' && key !== 'timestamp') {
                if (Number(key) > Number(selfData.timestamp)) return key;
            }
        });
        keys = keys.sort();
        setMessageKeys(keys);

        setTimeout(() => scrollAnchor?.current?.scrollIntoView(), 200);        
    }, [doc, pending, selfData]);

    useLayoutEffect(() => {
        setTimeout(() => scrollAnchor?.current?.scrollIntoView(), 200);
    }, [messageKeys]);

    const speechToText = (msg) => {
        setMessage(msg);
    }

    useEffect(() => {
        if (speechStatus.status === 'interim' && speechStatus.source === 'self') {
            setMessage(speechStatus.text);
        }
    }, [speechStatus]);

    const handleToolClick = (method) => {
        setSenderAction(false);
        setReceiverAction(false);
        if (!method) return;
        if (method.action === 'receiverAudio') return setReceiverAction('audio');
        if (method.action === 'toolsOpen') {
            setTools(true);
            setToolsPayload(method.payload);
        }
        if (method.action === 'toolsClose') {
            setTools(false);
            setLimitData('');
            dispatch({ type: 'RESET_OUTPUTSTATE' });
        }
    }

    const instructHandler = (method, { text, direct }) => {
        if (method === 'text') {
            if (direct) {
                if (text !== '(Not enough confidence in translation. Please try again.)') {
                    addMessage({}, text);
                }
            } else {
                setMessage(text);
            }
        }
        if (method === 'exit') {
            setProviderToolsState('tools');
        }
    }

    const twoWayHandler = (method, { text, direct }) => {
        if (method === 'text') {
            if (direct) {
                if (text !== '(Not enough confidence in translation. Please try again.)') {
                    addMessage({}, text);
                }
            } else {
                console.log('msg', text);
                setMessage(text);
            }
        }
        if (method === 'exit') {
            setProviderToolsState('tools');
        }
    }

    const twoWayHandlerAlt = (method, { text }) => {
        if (method === 'text') {
            addMessage(null, text, null, true);
        }
    }
    
    const forceToolState = (state) => {
        if (state === 'reset-instruct') {
            dispatch({ type: 'INSTRUCT_OFF' });
            if (users && self && users[self]) dispatch({ type: 'SET_SELFAUDIOLANG', payload: selfData.lang || 'en' });
        }
        if (state === 'reset-twoWay') {
            dispatch({ type: 'TWOWAY_OFF' });
            if (users && self && users[self]) dispatch({ type: 'SET_SELFAUDIOLANG', payload: selfData.lang || 'en' });
        }
        if (!toolsFormat) dispatch({ type: 'RESET_OUTPUTSTATE' });
        setProviderToolsState('tools');
    }
    
    const resetProviderToolState = () => {
        setProviderToolsState(null);
    }

    const closeToolTips = (e) => {
        if (!e.target.closest('.msg-status') && e.currentTarget.querySelector('.msg-status')) {
            e.currentTarget.querySelector('.msg-status').classList.remove('tip');
        }
    }

    const handleUserPickerReturn = ({ method, text, targetID }) => {
        if (!text || !targetID) return;
        const targetIndex = targeted.indexOf(targetID);
        let original = targeted.map(r => r);
        if (method === 'add') {
            if (targetIndex === -1) {
                original.push(targetID);
                setTargeted(original);

                let modMsg = message.split(' ');
                modMsg.pop();
                setMessage(modMsg.join(' ') + ' ');
            }
        }
        if (method === 'remove') {
            if (targetIndex !== -1) {
                original.splice(targetIndex, 1)
                setTargeted(original);
            }
        }
    }

    const [photoError, setPhotoError] = useState(null);
    const handleFileChange = (e) => {
        let selected = e.target.files[0];
        const extension = e.target.value.split('.').pop();

        if (!selected) {
            setPhotoError('Please select a file');
            setTimeout(() => setPhotoError(null), 3000);
            return;
        }
        if (!selected.type.includes('image')) {
            setPhotoError('Selected file must be an image');
            setTimeout(() => setPhotoError(null), 3000);
            return;
        }
        if (selected.size > 10000000) {
            setPhotoError('Image file size must be less than 10mb');
            setTimeout(() => setPhotoError(null), 3000);
            return;
        }
        // image compression
        imageConversion.compressAccurately(selected, 200)
            .then(async res => {
                const timestamp = new Date().getTime();
                const imgFileName = `${timestamp}-${self}.${extension}`;
                const storageRef = ref(storage, `sessions/${session}/${imgFileName}`);
                const img = await uploadBytesResumable(storageRef, res);
                const imgURL = await getDownloadURL(img.ref);
                addImage(imgURL, imgFileName, timestamp);

                e.target.value = '';
            });
        
        setPhotoError(null);
    }

    return (
        <div className={`chat-panel overflow-hidden ${instruct ? 'instruction-mode' : ''} ${twoWay ? 'twoWay-mode' : ''} ${isIOS ? 'isIOS' : ''}`} onClick={closeToolTips}>
            {output && <div className="output grow hide-scroll" ref={outputElement} onScroll={handleScroll}>
                <div className={`msg-loading gap-2 ${scrollToTop ? 'show' : ''}`}>
                    <LoadingIcon size="small" />
                </div>
                {doc && displayMessageKeys.length > 0 && displayMessageKeys.map(key => (
                    <div className="chat-entry-wrapper" key={key}>
                        <ChatEntry data={doc[key]} messageFxn={addMessage} />
                    </div>
                ))}
                <div className="mt-4" ref={scrollAnchor}></div>
            </div>}
            {instruct && <div className="output instruct hide-scroll" ref={outputElement}>
                <Instruction data={doc} lang={instructLang}/>
                <div className="mt-2" ref={scrollAnchor}></div>
                <div className="instruction-banner">
                    <p>Speakerphone Mode</p>
                    <div className="instruction-banner-close" onClick={() => forceToolState('reset-instruct')}>
                        <ClickableIcon type="close" addClass="large no-background no-hover invert" />
                    </div>
                </div>
            </div>}
            {twoWay && <div className={`output twoWay ${selfSpeechAuto ? 'extend' : ''}`} ref={outputElement}>
                {/* <TwoWay data={document} returnFxn={twoWayHandlerAlt} /> */}
                <TwoWayClass data={doc} returnFxn={twoWayHandlerAlt} self={self} users={users} otherSpeechLang={otherSpeechLang} speechStatus={speechStatus} selfSpeechAuto={selfSpeechAuto} />
                <div className="mt-2" ref={scrollAnchor}></div>
                <div className="instruction-banner">
                    <p>Two Way Mode /</p>
                    <p className="flip-180 ml-1">Two Way Mode</p>
                    <div className="instruction-banner-close" onClick={() => forceToolState('reset-twoWay')}>
                        <ClickableIcon type="close" addClass="large no-background no-hover invert" />
                    </div>
                </div>
            </div>}
            <form onSubmit={(e) => selfData.role !== 'interpreter' ? addMessage(e) : addMessageWithoutTranslation(e)} className={`panel-form flex justify-between ${selfSpeechAuto ? 'hide' : ''}`}>
                {/* <div className={`userPicker hide-scroll ${userPicker ? 'show' : ''}`}></div> */}
                <UserPicker input={message} targeted={targeted} returnFxn={handleUserPickerReturn} />
                <label className="relative">
                    {/* <img src={URL.createObjectURL(photo)} className="fixed top-0 left-0" /> */}
                    {!twoWay && !instruct && <ClickableIcon type="image" addClass="small mr-2 no-hover" />}
                    <input 
                        hidden
                        className="flex-auto ml-2"
                        type="file"
                        onChange={handleFileChange}
                    />
                    {photoError && <p className="error photo-file-error">{photoError}</p>}
                </label>
                <input type="text" 
                    required
                    ref={input} 
                    className="grow send-msg-input notranslate"
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />
                <button className="send-msg-btn">
                    <ClickableIcon 
                        type="check"
                        addClass="no-background small invert"
                    />
                </button>
                {limitData && !msgStatus && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    <img src={LockImg} className="w-4 h-4"/><div className="msg-status-tip shift-left">restricting responses</div>
                </div>}
                {msgStatus === 'sending' && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    <LoadingIcon size="small" /><div className="msg-status-tip">{msgStatus}...</div>
                </div>}
                {msgStatus === 'done' && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    👍<div className="msg-status-tip">{msgStatus}</div>
                </div>}
                {msgStatus.includes('fail') && <div className="msg-status" onClick={(e) => e.currentTarget.classList.toggle('tip')}>
                    😩<div className="msg-status-tip">{msgStatus}</div>
                </div>}
            </form>
            {!instruct && !twoWay && <div className="flex justify-center gap-2 mt-2 mb-2 px-2">
                {selfData && selfData.role !== 'interpreter' && <ChatProviderTools handler={handleToolClick} languages={languages} defaultState={providerToolsState} resetDefaultState={resetProviderToolState} />}
                {selfData && selfData.role === 'interpreter' && <ChatInterpreterTools />
                }
                {!chromeIOS && !isIOS && selfData && selfData.role !== 'interpreter' && <SpeechRec messageFxn={speechToText} lang={selfData.lang} watchStatus="self" />}
            </div>}
            {(instruct || twoWay) && <div className={`${toolsFormat ? 'mt-2' : 'mt-6'}`}></div>}
            <div className={`chat-tools-wrapper overflow-hidden ${tools ? 'show' : ''}`}>
                <ChatTools data={toolsPayload} lang={languages} toolStatus={tools} messageFxn={addMessage} limitFxn={limitFxn} instructReturnFxn={instructHandler} twoWayReturnFxn={twoWayHandler} />
            </div>
        </div>
    )
}