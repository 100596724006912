import { useState, useEffect } from 'react'

import './Tutorial.css'
import ClickableIcon from './ClickableIcon';

export default function Tutorial({returnFxn}) {
    const [currentStep, setCurrentStep] = useState(1);
    const [wrapperClass, setWrapperClass] = useState('');

    useEffect(() => {
        setTimeout(() => setWrapperClass('expand'), 100);
        setTimeout(() => setWrapperClass('no-transparency'), 1000);
    }, []);

    const closeTutorial = () => {
        returnFxn({ action: 'close' });
    }

    const advanceStep = () => {
        let step = currentStep + 1;
        setCurrentStep(step);
    }

    return (<div className={`tutorial-wrapper ${wrapperClass}`}>
            <div className="tutorial-close-btn" onClick={closeTutorial}>
                <ClickableIcon type="close" addClass="no-background large" />
            </div>
            <h1>Tutorial</h1>
            <div className="tutorial-content">
                {currentStep == 1 && <p className="p-emphasize">Let's go over the basics quickly.</p>}
                {currentStep == 2 && <>
                    <p className="p-emphasize">This is the navigation menu.</p>
                    <ClickableIcon type="menu" addClass="no-background no-hover" />
                </>}
                {currentStep == 3 && <>
                    <p className="p-emphasize">Which can take you to these pages.</p>
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center">
                            <ClickableIcon type="home" addClass="no-background no-hover"/>
                            <p className="p-emphasize">Home</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="chat" addClass="no-background no-hover" />
                            <p className="p-emphasize">Chat</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="tree" addClass="no-background no-hover" />
                            <p className="p-emphasize">Phrases</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="lock" addClass="no-background no-hover" />
                            <p className="p-emphasize">Limit Actions</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="campaign" addClass="no-background no-hover" />
                            <p className="p-emphasize">Speakerphone</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="settings" addClass="no-background no-hover" />
                            <p className="p-emphasize">Settings</p>
                        </div>
                        <div className="flex items-center">
                            <ClickableIcon type="logout" addClass="no-background no-hover" />
                            <p className="p-emphasize">Logout</p>
                        </div>
                    </div>
                </>}
                {currentStep == 4 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="home" addClass="large no-background no-hover" />
                        <h5>Home</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">This is your homepage, where you handle your friends and chat invites.</p>
                </div>}
                {currentStep == 5 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="chat" addClass="large no-background no-hover" />
                        <h5>Chat</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">Start or find chat sessions here.</p>
                    <p className="ml-16 mt-4">Each chat session is associated it with a short code that you can share even with others that don't have a PeelChat account.</p>
                    <p className="ml-16 mt-4">Only one chat can be active at a time for now during beta. All chat conversastions will be deleted once a chat session ends or if a chat session has been idle for more than 12 hrs.</p>
                </div>}
                {currentStep == 6 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="tree" addClass="large no-background no-hover" />
                        <h5>Phrases</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">Edit your saved phrases here for quick access during chat.</p>
                    <p className="ml-16 mt-4">You can edit and save your own special phrases that can be recalled during a chat with just a click of a button. These phrases are organized into a tree like structure. Best used for common or repeative phrases you might often use.</p>
                    <p className="ml-16 mt-4">Some default phrases have been premade for you in English. Give it a try!</p>
                </div>}
                {currentStep == 7 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="lock" addClass="large no-background no-hover" />
                        <h5>Limit Actions</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">Limit the kind of responses you want here.</p>
                    <p className="ml-16 mt-4">Get specific answers to your questions from another user by requiring them to respond with multiple choice options or through a sliding numberical scale.</p>
                </div>}
                {currentStep == 8 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="campaign" addClass="large no-background no-hover" />
                        <h5>Speakerphone</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">This is the speakphone tool.</p>
                    <p className="ml-16 mt-4">A personal translation tool for quick access to some of the chat features without initiating a chat, including saved phrases and audio text-to-speech.</p>
                </div>}
                {currentStep == 9 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="settings" addClass="large no-background no-hover" />
                        <h5>Settings</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="p-emphasize self-center ml-16 mt-4">Change your account settings and manage your subscription here.</p>
                    <p className="ml-16 mt-4">You can also find a contact form or start this tutorial again here.</p>
                </div>}
                {currentStep == 10 && <div className="tutorial-step-wrapper">
                    <div className="flex px-4 items-center w-full">
                        <ClickableIcon type="logout" addClass="large no-background no-hover" />
                        <h5>Logout</h5>
                    </div>
                    <hr className="ml-16 tutorial-line" />
                    <p className="ml-16 mt-4">This will end any chat sessions that you have hosted.</p>
                </div>}
                {currentStep == 11 && <div className="text-center">
                    <p className="p-emphasize">Thank you for trying PeelChat during beta.</p>
                    <p className="mt-4">Feel free to contact us for any questions or issues via the contact form under settings or at <span className="p-emphasize">hello@peelchat.com</span>.</p>
                    </div>
                }
            </div>
            {currentStep !== 11 && <div className="tutorial-nav-btn" onClick={advanceStep}>
                <ClickableIcon type={'arrow_right'} addClass="invert no-hover" />
            </div>}
            {currentStep === 11 && <div className="tutorial-nav-btn">
                <button className="btn mt-4" onClick={closeTutorial}>end tutorial</button>
            </div>}
        </div>)
}