import { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';

import Welcome from '../../components/Welcome';
import SearchUsers from './components/SearchUsers';
import ActiveChatAlert from './components/ActiveChatAlert';
import FriendReqList from './components/FriendReqList';
import FriendsList from '../../components/FriendsList';
import EmailVerificationModal from '../../components/EmailVerificationModal';

import ClickableIcon from '../../components/ClickableIcon';
import Orange from '../../assets/orange-2.png';
import HouseImg from '../../assets/house.png';

import './Dashboard.css'

import ChatInvites from '../../components/ChatInvites';
import LoadingIcon from '../../components/LoadingIcon';

export default function Dashboard() {
    const fetchFriendsFxn = httpsCallable(functions, 'fetchFriendsData');
    const [bounce, setBounce] = useState(false);
    const [searchUser, setSearchUser] = useState(false);
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([]);
    const [friendsNumber, setFriendsNumber] = useState(0);
    const [friendsData, setFriendsData] = useState([]);
    const { user } = useAuthContext();
    const [holder, setHolder] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    const [emailVeri, setEmailVeri] = useState(false);
    const [friendsPending, setFriendsPending] = useState(true);
    const [friendRequestsPending, setFriendRequestsPending] = useState(true);
    const [loadingDone, setLoadingDone] = useState(false);
    
    // scroll handlers
    let scrolling = false;
    const handleScroll = () => {
        if (scrolling) return;
        if (!holder) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);

    const onLoad = (e) => {
        setHolder(e.target.closest('.friends-wrapper'));
    }
        
    // interface animations
    const [titleDisplayClass, setTitleDisplayClass] = useState('Hello');
    useEffect(() => {
        if (!user) return;
        if (receivedFriendRequests.length > 0 || (user.friends && user.friends.length > 0)) {
            setTimeout(() => setTitleDisplayClass('next-banner'), 3000);
        }
    }, [user, receivedFriendRequests]);  

    useEffect(() => {
        if (!user) return;
        let results = [];
        if (user?.friendRequests?.received) {
            Object.values(user.friendRequests.received).map(r => {
                if (r.status === 'pending') results.push(r);
            });
        }
        
        setReceivedFriendRequests(results);
        setFriendRequestsPending(false);
        
        if (!user.friends || user.friends.length === 0) return setFriendsPending(false);

        fetchFriendsFxn({
            friends: user.friends
        }).then((results) => {
            if (results.data.length === 0) {
                setFriendsNumber(0);
                setFriendsPending(false);
            } else {
                setFriendsNumber(results.data.length);
            }
        }).catch(err => console.log(err));
    }, [user]);

    useEffect(() => {
        if (friendsPending || friendRequestsPending) return;
        setLoadingDone(true);
    }, [friendsPending, friendRequestsPending]);
    
    const toggleBounce = () => {
        setBounce(true);
        setTimeout(() => setBounce(false), 4000);
    }

    const handleFriendsListReturn = (returnData = {}) => {
        setFriendsData(returnData.data || []);
        setFriendsPending(false);
    }
   
    return (
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <div className={`relative mt-1 title-display ${titleDisplayClass}`}>
                <div className="top-banner flex">
                    <img src={HouseImg} className="w-4 h-4" />
                    <h5 className="ml-1">Home</h5>
                </div>
                <h5 className="bottom-banner">Hello <span className="h5-notranslate notranslate"> {user.displayName || user.username}</span></h5>
            </div>
            <div className="app-background mt-4 grow bg-neutral-200 flex flex-col items-center relative">
                {!loadingDone && <div className="flex grow items-center">
                    <LoadingIcon />
                </div>}
                {loadingDone && !receivedFriendRequests.length && !friendsNumber && <div className="self-center text-center flex flex-col justify-center gap-2 h-full">
                    {!bounce && <h1 className="just-showing" onClick={toggleBounce} unselectable="on"><img className="h-20 w-20 mx-auto" src={Orange} alt="Orange" /></h1>}
                    {bounce && <h1 onClick={toggleBounce} className="just-showing animate-custom-bounce" unselectable="on"><img className="h-20 w-20 mx-auto" src={Orange} alt="Orange" /></h1>}
                    <i className="p-emphasize notranslate">c'est une orange</i>
                </div>}
                <div className={`friends-wrapper hide-scroll ${scrollClass} ${loadingDone? '' : 'hide'}`} onScroll={handleScroll} onLoad={onLoad}>
                    {receivedFriendRequests.length > 0 && <FriendReqList />}
                    {friendsNumber > 0 && <>
                        <div className="w-full flex flex-col gap-2">
                            <div className={`pl-4 ${friendsNumber ? 'mt-2' : ''}`} style={ {display : friendsNumber ? 'block' : 'none' }}>
                                <p className="p-emphasize pt-2">Friends</p>
                                <FriendsList returnFxn={handleFriendsListReturn} />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            <div className="search-user-btn" onClick={() => {
                if (!user.emailVerified) {
                    setEmailVeri(true);
                    return;
                }
                
                setSearchUser(true);
            }}>
                <ClickableIcon type="personAdd" addClass="shadow" />
            </div>
            {user?.activeChats?.length > 0 && <ActiveChatAlert />}
            {searchUser && <SearchUsers returnFxn={() => setSearchUser(false)} />}
            <ChatInvites friendsData={friendsData} />
            <EmailVerificationModal returnFxn={(emailVeriStatus) => setEmailVeri(emailVeriStatus)} status={emailVeri} />
        </div>
    )
}