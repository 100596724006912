
import React from 'react';
import { analytics } from '../../../../firebase/config';
import { logEvent } from 'firebase/analytics';

import './TwoWay.css';

import SpeechRec from '../SpeechRec';

class TwoWayClass extends React.Component {
    constructor(props) {
        // props: self, users, otherSpeechLang, speechStatus, selfSpeechAuto
        super(props);
        this.data = props.data;
        this.returnFxn = props.returnFxn;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state = {
            otherContent: '',
            content: '',
            initTime: 0,
            selfLang: 'en',
            selfID: null,
            status: null,
            statusText: ''
        }
    }
    
    messageFxn = (text) => {
        this.props.returnFxn('text', { text });
    }
    forceUpdateHandler() {
        this.forceUpdate();
    }
    loadHandler(e) {
        e.target.closest('.twoWay-other-holder').classList.add('show');
    }
    updateContents(content, contentOther) {
        this.setState({
            otherContent: content,
            content: contentOther
        });
    }
    updateStatus() {
        if (this.props.speechStatus) {
            switch (this.props.speechStatus.status) {
                case 'start' :
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: 'Mic is listening...'
                    });
                    return;
                case 'stop' :
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: 'Mic stopped.'
                    });
                    setTimeout(() => {
                        this.setState({
                            status: null,
                            statusText: '...'
                        });
                    }, 3000);
                    return;
                case 'processing':
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: 'Processing audio...'
                    });
                    return;
                case 'interim':
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: `"${this.props.speechStatus.text}"`
                    });
                    return;
                case 'success':
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: `"${this.props.speechStatus.text}"`
                    });
                    setTimeout(() => {
                        this.setState({
                            status: null,
                            statusText: '...'
                        });
                    }, 3000);
                    return;
                case 'failed':
                    this.setState({
                        status: this.props.speechStatus.status,
                        statusText: 'Failed :('
                    });
                    setTimeout(() => {
                        this.setState({
                            status: null,
                            statusText: '...'
                        });
                    }, 3000);
                    return;
                default:
                    this.setState({
                        status: null,
                        statusText: '...'
                    });
                    return;
            }
        }
    }
    componentDidMount() {
        if (this.props.users && this.props.self && this.props.users[this.props.self]) {
            this.setState({
                initTime: new Date().getTime(),
                selfLang: this.props.users[this.props.self].lang,
                selfID: this.props.users[this.props.self].tempID
            });
        }

        logEvent(analytics, 'init_twoway', {
            content_type: 'event',
            twoWay_param: true
        });
    }
    componentDidUpdate(prevProps) {
        const { initTime, selfLang, selfID } = this.state;
        if (!this.props.data) return;
        if (!this.props.users) return;
        if (initTime === 0) return;

        let keys = Object.keys(this.props.data);
        keys.splice(keys.indexOf('info'), 1);
        keys.splice(keys.indexOf('status'), 1);
        if (keys.indexOf('timestamp') !== -1) keys.splice(keys.indexOf('timestamp'), 1);

        let senderKeys = keys.filter(r => this.props.data[r].sender === selfID);
        let otherKeys = keys.filter(r => this.props.data[r].sender === 'twoWay-guest');
                
        senderKeys = senderKeys.map(r => Number(r));
        otherKeys = otherKeys.map(r => Number(r));
        senderKeys = senderKeys.sort();
        otherKeys = otherKeys.sort();

        let largestKey = Math.max(...senderKeys);
        let largestKeyOther = Math.max(...otherKeys);
        if (isNaN(largestKey) || largestKey < 0) largestKey = 0;
        if (isNaN(largestKeyOther) || largestKeyOther < 0) largestKeyOther = 0;
            
        let result = '';
        let resultOther = '';
        let match = {};
        let matchOther = {};
        
        if (largestKey >= initTime) {
            match = this.props.data[largestKey];
            if (match.translatedText) {
                // console.log('Matched entry for Other:', match.translatedText);
                match.translatedText.forEach(entry => {
                    if (entry.lang === this.props.otherSpeechLang) result = entry.text;
                });
            }

            // console.log('Match translated entry for other:', result);
        }
        if (largestKeyOther >= initTime) {
            matchOther = this.props.data[largestKeyOther];
            if (matchOther.translatedText) {
                // console.log('Matched entry for Content:', matchOther.translatedText);
                matchOther.translatedText.forEach(entry => {
                    if (entry.lang === selfLang) resultOther = entry.text;
                });
            }
            
            // console.log('Match translated entry for content:', resultOther);
        }

        let resultContent = result ? `"${result}"` : '';
        let resultOtherContent = resultOther ? `"${resultOther}"` : '';
        if (matchOther.text === '(Not enough confidence in translation. Please try again.)') resultOtherContent = 'Not enough confidence in translation. Please try again.';

        if (this.state.otherContent !== resultContent || this.state.content !== resultOtherContent) {
            this.updateContents(resultContent, resultOtherContent);
        }

        // speechStatus
        if (this.props.speechStatus.status !== prevProps.speechStatus.status) {
            // console.log('status changed');
            this.updateStatus();
        }
        
    }
    render() {

        const { content, otherContent, statusText } = this.state;

        return (
            <>
                <div className="twoWay-other-holder md:max-w-2xl" onLoad={this.loadHandler}>
                    <div className="flex justify-center">
                        <SpeechRec messageFxn={this.messageFxn} method="circle" add="flip" watchStatus="other" />
                    </div>
                    <div className="twoWay-other-content mx-auto mt-4 flip-180 hide-scroll notranslate">    
                        {otherContent}
                    </div>
                    <p className={`other-status p-small mt-2 mx-auto`}>{statusText}</p>
                </div>
                <div className={`twoWay-holder`}>
                    <div className={`twoWay-content hide-scroll notranslate ${this.props.selfSpeechAuto ? 'extend' : ''}`}>
                        {content}
                    </div>
                </div>
            </>
        )
    }

}

export default TwoWayClass;