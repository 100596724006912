
import * as imageConversion from 'image-conversion';
import { useState, useEffect, useRef } from 'react';
import { useUpdateUser } from '../../hooks/firebase/useUpdateUser';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useAppContext } from '../../hooks/useAppContext';
import { useChatContext } from '../../hooks/useChatContext';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';

import { appName } from '../../tools/tools';
import { langSrc } from '../../tools/languageSource';
import './Settings.css'
import AvatarIcon from '../../assets/avatar_icon.svg';
import LoadingIcon from '../../components/LoadingIcon';
import { useFirestore } from '../../hooks/firebase/useFirestore';
import HelpBubble from '../../components/HelpBubble';
import ClickableIcon from '../../components/ClickableIcon';
import CheatForm from '../../components/CheatForm';

import RedXImg from '../../assets/red_x.png';

import FeedbackForm from './subcomponents/FeedbackForm';

export default function Settings() {
    const deleteUserFxn = httpsCallable(functions, 'deleteUser');
    const checkCheatCodesFxn = httpsCallable(functions, 'checkDiscountCodes');
    const [loading, setLoading] = useState(true);
    const { user, dispatch, tutorialDone, welcomeViewed } = useAuthContext();
    const { error, pending, updateUser } = useUpdateUser();
    const { listenDocument } = useFirestore('users');
    const { chatting, isIOS, dispatch: appDispatch } = useAppContext();
    const { dispatch: chatDispatch } = useChatContext();
    // const { document, error: docError } = useDocListener('users', user.uid);
    
    const [name, setName] = useState(user.displayName);
    const [nameChange, setNameChange] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [emailChange, setEmailChange] = useState(false);
    const [lang, setLang] = useState('en');
    const [role, setRole] = useState('other');
    const [organization, setOrganization] = useState('');
    const [license, setLicense] = useState('');
    const [gender, setGender] = useState('other');
    const [docChange, setDocChange] = useState(false);
    const [photo, setPhoto] = useState(user.photoURL);
    const [photoChange, setPhotoChange] = useState(false);
    const [photoError, setPhotoError] = useState(null);
    const [deleteUserPending, setDeleteUserPending] = useState(false);
    const [newsLetter, setNewsLetter] = useState(false);
    const [translateUI, setTranslateUI] = useState(true);
    const [feedback, setFeedback] = useState(false);
    const [showMenuOptions, setShowMenuOptions] = useState(false);
    const [cheatSuccess, setCheatSuccess] = useState(false);
    const navigate = useNavigate();
    let imgSrc = photo && photoChange ? URL.createObjectURL(photo) : photo;
    if (!imgSrc) imgSrc = AvatarIcon;
        
    const memoizedListenDocument = useRef(listenDocument);
    
    // scroll variables
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    const holder = useRef();
    let scrolling = false;

    // scroll handlers
    const handleScroll = () => {
        if (!holder.current) return;
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.current.scrollTop);
            scrolling = false;
        }, 300);
    }
    const checkScroll = () => {
        let height = holder.current.offsetHeight || 0;
        let totalHeight = holder.current.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }
    useEffect(() => {
        if (!holder.current) return;
        checkScroll();
    }, [holder, scrollTop, checkScroll]);
    
    useEffect(() => {
        if (!user || !user.uid) return;
        memoizedListenDocument.current(user.uid)
            .then((res) => {
                if (res.lang) setLang(res.lang);
                if (res.role) setRole(res.role);
                if (res.gender) setGender(res.gender);
                if (res.organization) setOrganization(res.organization);
                if (res.license) setLicense(res.license);
                setNewsLetter(res.newsLetter || false);
                setTranslateUI(res.translateUI || false);
                setLoading(false);
            });
    }, [memoizedListenDocument, user]);
    
    const langOptions = langSrc.map(item => (
        <option key={item.code} value={item.code}>{item.name}</option>
    ));
   
    const handleFileChange = (e) => {
        setPhoto(null);
        let selected = e.target.files[0];

        if (!selected) {
            setPhotoError('Please select a file');
            return;
        }
        if (!selected.type.includes('image')) {
            setPhotoError('Selected file must be an image');
            return;
        }
        if (selected.size > 10000000) {
            setPhotoError('Image file size must be less than 10mb');
            return;
        }

        // image compression
        imageConversion.compressAccurately(selected, 200)
            .then(res => {
                    setPhoto(res);
            });
        
        setPhotoError(null);
        setPhotoChange(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        updateUser(email, name, photo, lang, role, gender, organization, license, newsLetter, translateUI, { emailChange, nameChange, photoChange, docChange });
    }

    const [deleteUserScreen, setDeleteUserScreen] = useState(false);
    const [deleteUserError, setDeleteUserError] = useState(false);
    const deleteUserAccount = async () => {
        setDeleteUserPending(true);
        try {
            const resp = await deleteUserFxn({ uid: user.uid });
            if (resp.data === 'success') {
                appDispatch({ type: 'END_CHAT'});
                chatDispatch({ type: 'LOGOUT'});
                dispatch({ type: 'LOGOUT', source: 'SETTINGS#DUA' });
            } else {
                setDeleteUserError('Error deleting account. Please contact support.');
            }
        } catch(err) {
            console.log(err);
            setDeleteUserError('Error deleting account. Please contact support.');
        }
    }
    
    const handleFeedbackReturn = (method) => {
        if (method === 'close') setFeedback(false);
    }

    const showTutorial = () => {
        dispatch({ type: 'UPDATE_OTHER', payload: { tutorialDone: false }, source: 'S#sT' });
    }

    return (
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <h5 className="mt-1">Settings</h5>
            <div className={`mt-4 grow bg-neutral-200 flex flex-col justify-center items-center relative ${loading ? 'loading' : ''}`}>
                <img className="delete-user-btn" src={RedXImg} alt="red cross" onClick={() => setDeleteUserScreen(true) } />
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 settings-form">
                    <div className={`img-holder`}>
                        <img className={`profile-pic mx-auto object-cover ${photo ? 'has-file' : ''}`} src={imgSrc} alt="user avatar" />
                    </div>
                    <label className="flex mx-auto">
                        {/* <span className="btn btn-border self-center mr-2 change-photo-btn">change photo</span> */}
                        <div className="photo-change-btn">
                            <ClickableIcon type="edit" />
                        </div>
                        <input 
                            hidden
                            className="flex-auto ml-2"
                            type="file"
                            onChange={handleFileChange}
                        />
                    </label>
                    <div className="px-2 whitespace-nowrap">
                        <div className="subscription-btn btn" onClick={() => navigate('/subscriptions')}>manage subscription</div>
                    </div>
                    <div className={`settings-form-wrapper hide-scroll ${scrollClass} ${isIOS ? 'iOS-shrink' : ''}`} onScroll={handleScroll} ref={holder}>
                        <label className="flex">
                            <span className="flex-1 self-center">username:</span>
                            <p className="p-emphasize notranslate">{user.username || '??'}</p>
                        </label>
                        <label className="flex">
                            <span className="flex-1 self-center">display name:</span>
                            <input 
                                className="flex-auto ml-2"
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    setNameChange(true);
                                }}
                                required
                            />
                        </label>
                        <label className="flex">
                            <span className="flex-1 self-center">email:</span>
                            <input 
                                className="flex-auto ml-2"
                                type="email"
                                value={email}
                                onChange={(e) => {
                                    setEmailChange(true);
                                    setEmail(e.target.value);
                                }}
                                required
                            />
                        </label>
                        <label className="flex">
                            <span className="flex-1 self-center">role:</span>
                            <select
                                className="flex-auto ml-2"
                                onChange={(e) => {
                                    setDocChange(true);
                                    setRole(e.target.value);
                                    checkScroll();
                                }}
                                value={role}
                            >
                                <option value="general">General</option>
                                {/* <option value="provider">Provider</option> */}
                                {/* <option value="patient">Patient</option> */}
                                <option value="interpreter">Interpreter</option>
                                {/* <option value="other">Other</option> */}
                            </select> 
                        </label>
                        <label className="flex">
                            {role !=='interpreter' && <span className="flex-1 self-center">language:</span>}
                            {role ==='interpreter' && <span className="flex-1 self-center">default language:</span>}
                            <select
                                className="flex-auto ml-2"
                                onChange={(e) => {
                                    setLang(e.target.value);
                                    setDocChange(true);
                                }}
                                value={lang}
                            >
                                {langOptions}
                            </select> 
                        </label>
                        {role === 'interpreter' && <label className="flex">
                            <span className="flex-1 self-center">organization:</span>
                            <input 
                                className="flex-auto ml-2"
                                type="text"
                                value={organization}
                                onChange={(e) => {
                                    setDocChange(true);
                                    setOrganization(e.target.value);
                                }}
                            />
                        </label>}
                        {role === 'interpreter' && <label className="flex">
                            <span className="flex-1 self-center">license:</span>
                            <input 
                                className="flex-auto ml-2"
                                type="text"
                                value={license}
                                onChange={(e) => {
                                    setDocChange(true);
                                    setLicense(e.target.value);
                                }}
                            />
                        </label>}
                        {role !== 'interpreter' && <label className="flex">
                            <span className="flex-1 self-center">gender:</span>
                            <select
                                className="flex-auto ml-2"
                                onChange={(e) => {
                                    setDocChange(true);
                                    setGender(e.target.value);
                                }}
                                value={gender}
                            >
                                <option value=""></option>
                                <option value="female">Feminine</option>
                                <option value="male">Masculine</option>
                                <option value="other">Other</option>
                            </select> 
                        </label>}
                        <label className="flex justify-between">
                            <span className="self-center">translate interface:</span>
                            <div className="switch mr-4">
                                <input
                                    type="checkbox"
                                    onChange={e => {
                                        setTranslateUI(e.target.checked);
                                        setDocChange(true);
                                    }}
                                    checked={translateUI}
                                />
                                <span className="slider"></span>
                            </div>
                        </label>
                        <label className="flex justify-between">
                            <span className="self-center">receive newsletters:</span>
                            <div className="switch mr-4">
                                <input
                                    type="checkbox"
                                    onChange={e => {
                                        setNewsLetter(e.target.checked);
                                        setDocChange(true);
                                    }}
                                    checked={newsLetter}
                                />
                                <span className="slider"></span>
                            </div>
                        </label>
                    </div>
                    {!pending && <button className="btn mx-auto mt-2">save settings</button>}
                    {pending && <button className="btn mx-auto mt-2"><LoadingIcon size={'small'} addClass={'mx-auto'} /></button>}
                    {error && <p className="error">{error}</p>}
                    {photoError && <p className="error">{photoError}</p>}
                </form>
                {chatting && <HelpBubble text={`Any setting changes will not reflect in this current chat session.`} button={false} shadow={true} launchOnLoad={true} />}
                <div className="settings-tutorial-button" onClick={() => setShowMenuOptions(true)}>
                    <ClickableIcon type="tips" addClass="small no-background" />
                </div>
                {/* <p className="p-emphasize contact-footer" onClick={() => setFeedback(true)}>contact us</p> */}
            </div>
            {deleteUserScreen && <div className="screen" onClick={(e) => {
                if (!e.target.closest('.center-modal')) setDeleteUserScreen(false);
            }}>
                <div className="center-modal">
                    <div className="center-modal-close-btn" onClick={(e) => {
                        setDeleteUserScreen(false);
                    }}>
                        <ClickableIcon type="close" addClass="invert" />
                    </div>
                    <h5>🥺 Delete Account</h5>
                    <p className="mt-2">Sorry to see you go. We are trying our best to build and improve <span className="font-semibold">{appName}</span> for everyone. We hope you will come back soon and try us out again!</p>
                    <div className="flex justify-center">
                        {!deleteUserPending && <button className="mt-8" onClick={deleteUserAccount}>delete</button>}
                        {deleteUserPending && <button className="flex justify-center mt-8" >
                            <LoadingIcon size="small" addClass="self-center" />
                        </button>}
                        {deleteUserError && <p className="p-error">{deleteUserError}</p>}
                    </div>
                </div>
            </div>}
            {showMenuOptions && <div className={`screen ${cheatSuccess ? 'hide-screen-content' : ''}`} onClick={(e) => {
                if (!e.target.closest('.center-modal') && !e.target.closest('.cheat-form')) setShowMenuOptions(false);
            }}>
                <div className="center-modal main-modal">
                    <div className="center-modal-close-btn" onClick={(e) => {
                        setShowMenuOptions(false);
                    }}>
                        <ClickableIcon type="close" addClass="invert" />
                    </div>
                    <div className="w-full flex flex-col justify-center gap-4">
                        <div className="tipOptionsHeader">
                            <ClickableIcon type="tips" addClass="large no-background" />
                            <h5>some more options...</h5>
                        </div>
                        <button className="btn" style={{ width: '100%' }} onClick={() => {
                            setShowMenuOptions(false);
                            showTutorial();
                        }}>tutorial</button>
                        <button className="btn" style={{ width: '100%' }}onClick={() => {
                            setShowMenuOptions(false);
                            setFeedback(true);
                        }}>contact us</button>
                    </div>
                </div>
                <CheatForm returnFxn={(method) => {
                    if (method === 'hide') setCheatSuccess(true);
                    if (method === 'close') {
                        setCheatSuccess(false);
                        setShowMenuOptions(false);
                    }
                }} />
            </div>}
            {feedback && <FeedbackForm returnFxn={handleFeedbackReturn} />}
        </div>
    )
}