import { useReducer, useEffect, useState, useRef } from "react"
import { fs } from "../../firebase/config";
import { collection, doc, getDoc, getDocs, setDoc, updateDoc, deleteDoc, serverTimestamp, addDoc, onSnapshot, query, where } from 'firebase/firestore'

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null,
};

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { isPending: true, document: null, success: false, error: null };
        case 'ADDED_DOCUMENT':
            return { isPending: false, document: action.payload, success: true, error: null };
        case 'DELETED_DOCUMENT':
            return { isPending: false, document: null, success: true, error: null };
        case 'CHECK_DOCUMENT_EXIST':
            return { isPending: false, document: action.payload, success: true, error: null };
        case 'GET_COLLECTION':
            return { isPending: false, document: action.payload, success: true, error: null };
        case 'ERROR':
            return { isPending: false, document: null, success: false, error: action.payload };
        case "UPDATED_DOCUMENT":
            return { isPending: false, document: action.payload, success: true,  error: null };
        case "LISTENING_DOCUMENT":
            return { isPending: false, document: action.payload.document, listener: action.payload.listener, success: true, error: null };
        default:
            return state;
  }
}

export const useFirestore = (collectionID, listenerParams) => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState);
    const [isCancelled, setIsCancelled] = useState(false);
    const queryParams = useRef(listenerParams).current;
    let unsub = null;

    // collection ref
    const ref = collection(fs, collectionID);
    
    // only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }
  
    // add a document
    const addDocument = async (id, data) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });
            data = { ...data, timestamp: serverTimestamp() };
            
            try {
                //   const createdAt = timestamp.fromDate(new Date())
                const addedDocument = id ? await setDoc(doc(fs, collectionID, id), data) : await addDoc(ref, data);
                dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument });
                
                resolve(addedDocument);
                return;
            } catch (err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
                reject(new Error('failed to add document'));
                return;
            }
        });
    }

    // add document to a subcollection
    const addSubCollectionDocument = async (docID, newDocID, newCollectionID, subCollectionID, data) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });
            data = { ...data, timestamp: serverTimestamp() };
            
            const docRef = doc(fs, newCollectionID, docID);
            const colRef = newDocID ? collection(docRef, subCollectionID, newDocID) : collection(docRef, subCollectionID);
            
            try {
                const addedDocument = await addDoc(colRef, data);
                dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument });
                
                resolve(addedDocument);
                return;
            } catch (err) {
                console.log(err.message);
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
                reject(new Error('failed to add document'));
                return;
            }
        });
    }

    // set a document
    const setDocument = async (id, data) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING'});
            data = { ...data, timestamp: serverTimestamp() };

            try {
                const setDocument = await setDoc(doc(fs, collectionID, id), data);
                dispatchIfNotCancelled({ type: 'SET_DOCUMENT', payload: setDocument });

                resolve(setDocument);
                return;
            } catch(err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message});
                reject(new Error('fail to set the document'));
                return;
            }
        });
    }

    // update a document
    const updateDocument = async (id, data) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: "IS_PENDING" });
            data = { ...data, timestamp: serverTimestamp() };
            
            try {
                const updatedDocument = await updateDoc(doc(fs, collectionID, id), data);
                dispatchIfNotCancelled({ type: "UPDATED_DOCUMENT", payload: updatedDocument });
                // nothing is returned
                resolve(updatedDocument);
                return;
            } catch (error) {
                console.log(error.message);
                dispatchIfNotCancelled({ type: "ERROR", payload: error });
                reject(new Error('failed to update the document'));
                return;
            }
        });
    }

    // delete a document
    const deleteDocument = async (id) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });

            try {
                await deleteDoc(doc(fs, collectionID, id));
                dispatchIfNotCancelled({ type: 'DELETED_DOCUMENT' });

                resolve('document deleted');
                return;
            } catch (err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not delete' });
                reject(new Error('failed to delete document'));
                return;
            }
        });
    }

    // check if document exists
    const checkDocumentExists = async (id) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });

            try {
                const res = await getDoc(doc(fs, collectionID, id));
                if (res.exists()) dispatchIfNotCancelled({ type: 'CHECK_DOCUMENT_EXIST', payload: res.data() });
                res.exists() ? resolve(true) : resolve(false);
                return;
            } catch(err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not check document' });
                reject(new Error('failed to check document existance'));
                return;
            }
        });
    }

    // get document once
    const getDocument = async (id) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });

            try {
                const res = await getDoc(doc(fs, collectionID, id));
                dispatchIfNotCancelled({ type: 'CHECK_DOCUMENT_EXIST', payload: res.data() });
                res.exists() ? resolve(res.data()) : resolve(null);
                return;
            } catch(err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not get document' });
                reject(new Error('failed to get document'));
                return;
            }
        });
    }

    // get collection once 
    const getCollection = async () => {
        return new Promise (async (resolve, reject) => {
            dispatch ({ type: 'IS_PENDING'});

            try {
                const res = await getDocs(collection(fs, collectionID));
                let results = [];
                res.forEach(doc => {
                    results.push({ ...doc.data(), id: doc.id});
                })
                dispatchIfNotCancelled({ type: 'GET_COLLECTION', payload: results });

                resolve(results);
                return;
            } catch(err) {
                dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not get collection' });
                reject(new Error('failed to get collection'));
                return;
            }
        });
    }

    // causes infinite loop?
    const listenDocument = async (id) => {
        return new Promise(async (resolve, reject) => {
            unsub = onSnapshot(doc(fs, collectionID, id), (snapshot) => {
                if (snapshot.data()) {
                    dispatchIfNotCancelled({ 
                        type: 'LISTENING_DOCUMENT',
                        payload: {
                            document: snapshot.data(),
                            listener: unsub
                        }
                    });
                    resolve(snapshot.data());
                    return;
                } else {
                    dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not find document' });
                    resolve('Document not found');
                    return;
                }
            }, (err => {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.messsage });
                reject('Error establishing listener to document');
                return;
            }));
        });
    }

    const listenSubCollectionDocument = async (baseCollectionID, baseDocID, subCollectionID, subDocID, method) => {
        return new Promise(async (resolve, reject) => {            
            const docRef = doc(fs, baseCollectionID, baseDocID);
            const colRef = collection(docRef, subCollectionID);
            const subRef = doc(colRef, subDocID);

            const unsubscribe = onSnapshot(subRef, (snapshot) => {
                if (snapshot.data()) {
                    if (method === 'stripe') {
                        if (snapshot.data().sessionId) {
                            resolve(snapshot.data());
                            unsubscribe();
                            return;
                        }
                    } else {
                        resolve(snapshot.data());
                        return;
                    }
                } else {
                    resolve('Document not found');
                    unsubscribe();
                    return;
                }
            }, (err => {
                reject('Error establishing listener to document');
                return;
            }));
        });
    }

    const listenCollection = async () => {
        return new Promise(async (resolve, reject) => {

            let collectionRef = ref;
            if (queryParams) collectionRef = query(ref, where(...queryParams));

            unsub = onSnapshot(collectionRef, (snapshot) => {
                let result = [];
                snapshot.docs.forEach(snap => result.push(snap.data()));
                dispatchIfNotCancelled({ 
                    type: 'LISTENING_DOCUMENT',
                    payload: {
                        document: result,
                        listener: unsub
                    }
                });
                resolve(result);
                return;    
            }, (err => {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.messsage });
                reject('Error establishing listener to document');
                return;
            }));
        });
    }

    useEffect(() => {
        setIsCancelled(false);
        return () => {
            if (unsub) {
                unsub();
            }
            setIsCancelled(true);
        }
    }, [unsub]);

    return { addDocument, addSubCollectionDocument, setDocument, deleteDocument, updateDocument, checkDocumentExists, getDocument, getCollection, listenDocument, listenSubCollectionDocument, listenCollection, response };

}

