
import { useState } from 'react'
import { functions } from '../../../firebase/config'
import { httpsCallable } from 'firebase/functions'
import { useAuthContext } from '../../../hooks/useAuthContext'

import { appName } from '../../../tools/tools'
import ClickableIcon from '../../../components/ClickableIcon'
import LoadingIcon from '../../../components/LoadingIcon'

export default function FeedbackForm({ returnFxn }) {
    const feedbackFxn = httpsCallable(functions, 'handleFeedBack');
    const { user } = useAuthContext();
    const [topic, setTopic] = useState('');
    const [content, setContent] = useState('');
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pending) return;
        setPending(true);

        feedbackFxn({
            user: user.uid,
            topic,
            content
        }).then(() => {
            setPending(false);
            setStatus('feedback sent! 😃');
            setTimeout(() => setStatus(''), 5000);
        }).catch(err => {
            console.log(err.message);
            setPending(false);
            setStatus('failed to submit form 😩');
            setTimeout(() => setStatus(''), 5000);
        });
    }

    return <div className="screen" onClick={(e) => {
        if (!e.target.closest('.center-modal')) returnFxn('close');
    }}>
        <div className="center-modal">
            <div className="center-modal-close-btn" onClick={() => returnFxn('close')}>
                <ClickableIcon type="close" addClass="small invert" />
            </div>
            <h5><span className="text-xl relative" style={{ top: '-0.125em', left: '-0.5rem' }}>✍</span>Contact Us</h5>
            <p className="mt-2">Have questions or suggestions? Noticed some bugs? Please let us know! Your feedback will help us make <span className="p-emphasize">{appName}</span> even better.</p>
            <form className="mt-6 w-full flex flex-col gap-1" onSubmit={handleSubmit}>
                <label className="w-full">
                    <select 
                        className="w-full"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                    >
                        <option value="" disabled>Select Topic</option>
                        <option>Feedback</option>
                        <option>Questions</option>
                        <option>Bugs</option>
                        <option>Other</option>
                    </select>
                </label>
                <label>
                    <textarea 
                        placeholder="type message here"
                        className="mt-1 w-full h-28" 
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    />
                </label>
                <div className="flex justify-center h-4">
                    <p className="p-emphasize self-end">{status}</p>
                </div>
                <div className="flex justify-center">
                    {!pending && <button className="btn mt-2">submit</button>}
                    {pending && <button className="btn mt-2">
                        <LoadingIcon size="small" addClass="mx-auto" />
                    </button>}
                </div>
            </form>
        </div>
    </div>

}