import { useEffect, useState } from "react"
import { fs } from '../../firebase/config'
import { doc, onSnapshot } from 'firebase/firestore'
import { useAppContext } from "../useAppContext"
import { useAuthContext } from "../useAuthContext"

export const useDocListener = (collection, id, chat, source) => {
  const [document, setDocument] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [exist, setExist] = useState('pending');
  const { dispatch } = useAppContext();
  const { user } = useAuthContext();

  // useEffect(() => {
  //   console.log('source: ', source);
  // }, [source]);
    
  useEffect(() => {
    if (!id) return;
    setPending(true);
    let ref = doc(fs, collection, id);
    
    const unsubscribe = onSnapshot(ref, snapshot => {
      if (snapshot.exists()) {
        setExist('exists');
        setDocument(snapshot.data());
        setError(null);
        
        if (user && chat && snapshot.data().status.active) {
          if (snapshot.data().info[user.uid] && snapshot.data().info[user.uid].active) {
            dispatch({ type: 'UPDATE_DOCUMENT', payload: { data: snapshot.data() }, source: 'hook' });
          }
        }
      } else {
        setExist(false);
        // console.log('Error uDL-02:', error, 'params:', collection, id, chat, 'source:', source);
        setError('data does not exist');
      }
      setPending(false);
    }, error => {
      console.log('Error uDL-03:', error, 'params:', collection, id, chat);
      setError('could not fetch the data');
      setPending(false);
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [collection, id]);

  return { document, error, pending, exist };
}