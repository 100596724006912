import { useState, useEffect } from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useChatContext } from '../../../../hooks/useChatContext';
import { analytics } from '../../../../firebase/config';
import { logEvent } from 'firebase/analytics';

import './InstructionsTool.css';

import ClickableIcon from '../../../../components/ClickableIcon';
import SpeechRec from '../SpeechRec';
import { langSrc } from '../../../../tools/languageSource';

export default function InstructionsTool({ returnFxn }) {
    const { languages, instructLang, selfAudio, selfSpeechAuto, speechStatus, dispatch, chromeIOS } = useAppContext();
    const { users, self } = useChatContext();
    const [status, setStatus] = useState('Toggle audio and speech recognition.');

    useEffect(() => {
        logEvent(analytics, 'init_instructions', {
            content_type: 'event',
            instructions_param: true
        })
    }, []);
    
    const handleLanguageClick = (lang) => {
        dispatch({ type: 'SET_INSTRUCTLANG', payload: lang });
        dispatch({ type: 'SET_SELFAUDIOLANG', payload: lang });
    }

    const handleAudioClick = () => {
        if (selfAudio) {
            dispatch({ type: 'SELFAUDIO_OFF' });
            dispatch({ type: 'SET_SELFAUDIOLANG', payload: users[self].lang });
        } else {
            dispatch({ type: 'SELFAUDIO_ON' });
        }
    }

    const handleAutoClick = () => {
        (selfSpeechAuto) ? dispatch({ type: 'SELFSPEECHAUTO_OFF' }) : dispatch({ type: 'SELFSPEECHAUTO_ON' });
    }

    useEffect(() => {
        switch (speechStatus.status) {
            case 'start' :
                setStatus('Mic is active and listening...');
                return;
            case 'stop' :
                setStatus('Mic stopped.');
                setTimeout(() => setStatus('Toggle audio and speech recognition.'), 3000);
                return;
            case 'processing':
                setStatus('Processing received audio...');
                return;
            case 'interim':
                setStatus(`"${speechStatus.text}"`);
                return;
            case 'success':
                setStatus(`"${speechStatus.text}"`);
                setTimeout(() => setStatus('Toggle audio and speech recognition.'), 5000);
                return;
            case 'failed':
                setStatus('Audio failed to process :(');
                setTimeout(() => setStatus('Toggle audio and speech recognition.'), 3000);
                return;
            default:
                setStatus('Toggle audio and speech recognition.');
                return;
        }
    }, [speechStatus]);

    return (<>
        <p className="p-small mt-2">Select output language.</p>
        <div className="instruct-lang-wrapper hide-scroll">
            {languages && languages.sort().map(item => {
                let formattedLang = item;
                langSrc.forEach(entry => {
                    if (entry.code ===  item) formattedLang = entry.name;
                });
                return (<div key={item} className={`instruct-lang-option ${instructLang === item ? 'active' : ''}`} data-lang={item} onClick={() => handleLanguageClick(item)}>{formattedLang}</div>)
            })}
        </div>
        <hr className="opacity-30"></hr>
        <p className="speech-tool-status p-small mt-2">{status}</p>
        <div className="instruct-audio-wrapper mt-2">
            <div className={`instruct-audio ${selfAudio ? 'active' : ''}`} onClick={handleAudioClick}>
                <ClickableIcon type="speaker" addClass="no-background no-hover invert" />
            </div>
            {!chromeIOS && <div className="relative flex">
                <div className={`auto-send ${selfSpeechAuto ? 'active' : ''}`} onClick={handleAutoClick}>auto</div>
                <SpeechRec messageFxn={(text) => returnFxn('text', { text, direct: selfSpeechAuto })} method="circle" watchStatus="self" />
            </div>}
        </div>
    </>)
}