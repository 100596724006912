import { useState, useRef, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { randomIDGenerator } from '../../../tools/tools';
import { useFirestore } from '../../../hooks/firebase/useFirestore';

import './Scenarios.css';

import LoadingIcon from '../../../components/LoadingIcon';
import ScenarioList from './ScenarioList';
import ClickableIcon from '../../../components/ClickableIcon';

export default function Scenarios({ data }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [newScenario, setNewScenario] = useState(false);
    const [editScenario, setEditScenario] = useState(false);
    const [payload, setPayload] = useState(null);
    const [addScenario, setAddScenario] = useState(false);
    const [newScenarioValue, setNewScenarioValue] = useState('');
    const [editScenarioValue, setEditScenarioValue] = useState('');
    const [bucketOptions, setBucketOptions] = useState([]);
    const [pickedBuckets, setPickedBuckets] = useState([]);
    const [editNameSucess, setEditNameSuccess] = useState(false);
    
    const handlePageClick = (page, id) => {
        if (page === 'new') {
            setNewScenario(true);
            setEditScenario(false);
        }
        if (page === 'edit') {
            setNewScenario(false);
            setEditScenario(true);

            setPayload(id);
            setEditScenarioValue(data.scenarios[id].title);
        }
    }

    const handleAddClick = () => {
        addScenario ? setAddScenario(false) : setAddScenario(true);
    }

    const handleScreenClear = (e) => {
        if (e.target && e.target.closest('.center-modal')) {
            if (!e.target.closest('.cancel-tree-form')) return;
        }
        setAddScenario(false);
        setEditScenario(false);
        setEditNameSuccess(false);

        setNewScenarioValue('');
        setEditScenarioValue('');
    }

    const handleScenarioSubmit = async (e, method) => {
        e.preventDefault();

        if (method === 'new') {
            let randomID = randomIDGenerator(16);
            if (data.scenarios) {
                while (Object.keys(data.scenarios).indexOf(randomID) !== -1) {
                    randomID = randomIDGenerator(16);
                }
            }

            let save = { scenarios: data.scenarios || {}, scenarioSequence: data.scenarioSequence || [] };
            save.scenarios[randomID] = { id: randomID, title: newScenarioValue };
            save.scenarioSequence.push(randomID);

            await updateDocument(user.uid, save);

            setEditNameSuccess(true);

            setTimeout(() => handleScreenClear('back'), 500);
        }
        if (method === 'edit') {
            let save = { scenarios: data.scenarios || {}, scenarioSequence: data.scenarioSequence || [] };
            save.scenarios[payload] = { ...data.scenarios[payload], id: payload, title: editScenarioValue };

            await updateDocument(user.uid, save);

            setEditNameSuccess(true);
        }
    }

    // scroll handlers
    // const [holder1, setHolder1] = useState(null);
    const holder1 = useRef();
    const holder2 = useRef();
    const [scrollTop1, setScrollTop1] = useState(0);
    const [scrollTop2, setScrollTop2] = useState(0);
    const [scrollClass1, setScrollClass1] = useState('');
    const [scrollClass2, setScrollClass2] = useState('');
    let scrolling1 = false;
    let scrolling2 = false;

    const handleScroll1 = () => {
        if (scrolling1) return;
        scrolling1 = true;
        setTimeout(() => {
            setScrollTop1(holder1.current.scrollTop);
            scrolling1 = false;
        }, 300);
    }
    const handleScroll2 = () => {
        if (scrolling2) return;
        scrolling2 = true;
        setTimeout(() => {
            setScrollTop2(holder2.current.scrollTop);
            scrolling2 = false;
        }, 300);
    }
    
    useEffect(() => {
        if (holder1.current) {
            let height = holder1.current.offsetHeight || 0;
            let totalHeight = holder1.current.scrollHeight || 0;
            let classes = '';
            if ((height + scrollTop1) < totalHeight - 1) {
                classes = ' scroll-shadow-bottom';
            } 
            if (scrollTop1 > 0) classes += ' scroll-shadow-top';
            setScrollClass1(classes);
        }
    }, [holder1, scrollTop1]);
    useEffect(() => {
        if (holder2.current) {
            let height = holder2.current.offsetHeight || 0;
            let totalHeight = holder2.current.scrollHeight || 0;
            let classes = '';
            if ((height + scrollTop2) < totalHeight - 1) {
                classes = ' scroll-shadow-bottom';
            } 
            if (scrollTop2 > 0) classes += ' scroll-shadow-top';
            setScrollClass2(classes);
        }
    }, [holder2, scrollTop2]);

    useEffect(() => {
        if (!data) return;
        if (!data.buckets) return;
        let entryOptions = [];
        let pickedEntries = [];
        data.bucketSequence.forEach(item => {
            if (!data.buckets[item]) return;
            if (payload && data.scenarios[payload] && data.scenarios[payload].buckets) {
                if (data.scenarios[payload].buckets.indexOf(item) > -1) {
                    pickedEntries.push(data.buckets[item]);
                } else {
                    entryOptions.push(data.buckets[item]);
                }
            } else {
                entryOptions.push(data.buckets[item]);
            }
        });

        setBucketOptions(entryOptions);
        setPickedBuckets(pickedEntries);
    }, [data, payload]);

    const addToScenario = async (id) => {
        let save = { scenarios: data.scenarios };
        if (save.scenarios[payload].buckets) {
            save.scenarios[payload].buckets.push(id);
        } else {
            save.scenarios[payload].buckets = [id]
        }

        await updateDocument(user.uid, save);
    }

    const removeFromScenario = async (id) => {
        let save = { scenarios: data.scenarios };
        if (save.scenarios[payload].buckets) {
            const index = save.scenarios[payload].buckets.indexOf(id);
            save.scenarios[payload].buckets.splice(index, 1);
        }

        await updateDocument(user.uid, save);
    }

    return (
        <>
            {data && <div className="tree-container flex flex-col overflow-hidden">
                <div className="flex">
                    <h5>Manage Scenarios</h5>
                    {/* <div className="scenario-animation-wrapper">
                        <div className="scenario-header-emoji-1">📦</div>
                        <div className="scenario-header-emoji-2">📦</div>
                        <div className="scenario-header-emoji-3">📦</div>
                    </div> */}
                </div>
                <p className="mt-1 relative inline">Scenarios are collections of buckets(boxes) that contain phrases.</p>
                <div className="mt-4 grow flex items-center mx-auto">
                    <ScenarioList data={data} returnFxn={handlePageClick} />
                </div>
                {!newScenario && <div className="btn-circle mx-auto" onClick={() => handleAddClick('new')}>
                    <ClickableIcon type="add" addClass="alt-color small no-hover" />
                </div>}
            </div>}
            {!data &&
            <div className="m-auto self-center flex flex-col items-center">
                <LoadingIcon />
                <i className="mt-2">loading...</i>
            </div>}
            {addScenario && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal scenario-form">
                    <h5>Add a Scenario</h5>
                    <div className="cancel-tree-form" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <form className="mt-4" onSubmit={(e) => handleScenarioSubmit(e, 'new')}>
                        <label className="w-full flex justify-between gap-4">
                            <span className="self-center">Name:</span>
                            <input 
                                required
                                type="text"
                                onChange={e => setNewScenarioValue(e.target.value)}
                                value={newScenarioValue}
                            />
                        </label>
                        <p className="p-small mt-2 w-60">Recommend to keep the name short but distinct for better recognizability.</p>
                        {response.error && <p className="error mt-2">{response.error}</p>}
                        {!response.isPending && <button className="btn btn-circle mx-auto mt-4">
                            <ClickableIcon type="check" addClass="no-background no-hover invert mini" />
                        </button>}
                        {response.isPending && <button className="btn btn-circle mx-auto mt-4" disabled>
                            <LoadingIcon size="small" />
                        </button>}
                    </form>
                </div>
            </div>}
            {editScenario && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal scenario-form">
                    <h5>Edit Scenario</h5>
                    <div className="cancel-tree-form" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <form className="mt-4" onSubmit={(e) => handleScenarioSubmit(e, 'edit')}>
                        <label className="w-full flex justify-between gap-2">
                            <span className="self-center">Name:</span>
                            <input 
                                className="grow"
                                required
                                type="text"
                                onChange={e => setEditScenarioValue(e.target.value)}
                                value={editScenarioValue}
                            />
                            {!response.isPending && <button className="btn btn-circle">
                                <ClickableIcon type="check" addClass="no-background no-hover invert mini" />
                            </button>}
                            {response.isPending && <button className="btn btn-circle" disabled>
                                <LoadingIcon size="small" />
                            </button>}
                        </label>
                        {!editNameSucess && <p className="p-small mt-2 w-60">Recommend to keep the name short but distinct for better recognizability.</p>}
                        {editNameSucess && <p className="p-small mt-2 w-60">Name change saved.</p>}
                        <p className="mt-4">Buckets:</p>
                        <div className="grid grid-cols-2 gap-2 scenario-columns">
                            <div className={`column flex flex-col gap-2 items-start first-column hide-scroll ${scrollClass1}`} onScroll={handleScroll1} ref={holder1}>
                                {bucketOptions && bucketOptions.map(item => (
                                    <div key={item.id} className="column-entry" onClick={e => addToScenario(item.id)}>
                                        <p>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className={`column flex flex-col gap-2 items-start second-column hide-scroll ${scrollClass2}`} onScroll={handleScroll2} ref={holder2}>
                                {pickedBuckets && pickedBuckets.map(item => (
                                    <div key={item.id} className="column-picked-entry" onClick={e => removeFromScenario(item.id)}>
                                        <p>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 bucket-columns mt-1">
                            <p className="p-emphasize ml-2">options</p>
                            <p className="p-emphasize ml-2">inside</p>
                        </div>
                    </form>
                    {response.error && <p className="error mt-2">{response.error}</p>}
                </div>
            </div>}
        </>
    )
}