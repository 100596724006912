import { useState, useEffect } from 'react';
import { useChatContext } from '../hooks/useChatContext';

import './HelpBubble.css';

import ClickableIcon from './ClickableIcon';

export default function HelpBubble({ text, launchOnLoad, button, shadow }) {
    const { clear } = useChatContext();
    const [help, setHelp] = useState(false);
    const [helpClasses, setHelpclasses] = useState(`help-bubble hide ${shadow ? 'shadow' : ''}`);

    useEffect(() => {
        if (clear) setHelp(false);
    }, [clear]);
    
    useEffect(() => {
        launchOnLoad ? setHelp(true) : setHelp(false);
    }, []);

    useEffect(() => {
        help ? setHelpclasses(`help-bubble ${shadow ? 'shadow' : ''}`) : setHelpclasses(`help-bubble hide ${shadow ? 'shadow' : ''}`);
    }, [help]);

    const toggleHelp = (method) => {
        if (method === 'close') setHelp(false);
        if (method === 'open') setHelp(true);
    }
    
    return (
        <>
            <p className={helpClasses} onClick={toggleHelp}>{text}</p>
            {!help && button && <div className="help-bubble-btn absolute bottom-4" onClick={() => toggleHelp('open')}>
                <ClickableIcon type="question" addClass="small" />
            </div>}
            {help && button && <div className="help-bubble-btn absolute bottom-4 active" onClick={() => toggleHelp('close')}>
                <ClickableIcon type="question" addClass="small" />
            </div>}
        </>
    )
}