import { useState, useRef, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { randomIDGenerator } from '../../../tools/tools';
import { useFirestore } from '../../../hooks/firebase/useFirestore';

import './Buckets.css';

import LoadingIcon from '../../../components/LoadingIcon';
import BucketList from './BucketList';
import ClickableIcon from '../../../components/ClickableIcon';

export default function Buckets({ data }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [newBucket, setNewBucket] = useState(false);
    const [editBucket, setEditBucket] = useState(false);
    const [payload, setPayload] = useState(null);
    const [addBucket, setAddBucket] = useState(false);
    const [newBucketValue, setNewBucketValue] = useState('');
    const [editBucketValue, setEditBucketValue] = useState('');
    const [phraseOptions, setPhraseOptions] = useState([]);
    const [pickedPhrases, setPickedPhrases] = useState([]);
    const [editNameSucess, setEditNameSuccess] = useState(false);

    const handlePageClick = (page, id) => {
        if (page === 'new') {
            setNewBucket(true);
            setEditBucket(false);
        }
        if (page === 'edit') {
            setNewBucket(false);
            setEditBucket(true);

            setPayload(id);
            setEditBucketValue(data.buckets[id].title);
        }
    }

    const handleAddClick = () => {
        addBucket ? setAddBucket(false) : setAddBucket(true);
    }

    const handleScreenClear = (e) => {
        if (e.target && e.target.closest('.center-modal')) {
            if (!e.target.closest('.cancel-tree-form')) return;
        }
        setAddBucket(false);
        setEditBucket(false);
        setEditNameSuccess(false);

        setNewBucketValue('');
        setEditBucketValue('');
    }

    const handleBucketSubmit = async (e, method) => {
        e.preventDefault();

        if (method === 'new') {
            let randomID = randomIDGenerator(16);
            if (data.buckets) {
                while (Object.keys(data.buckets).indexOf(randomID) !== -1) {
                    randomID = randomIDGenerator(16);
                }
            }

            let save = { buckets: data.buckets || {}, bucketSequence: data.bucketSequence || [] };
            save.buckets[randomID] = { id: randomID, title: newBucketValue };
            save.bucketSequence.push(randomID);

            await updateDocument(user.uid, save);

            setEditNameSuccess(true);

            setTimeout(() => handleScreenClear('back'), 500);
        }
        if (method === 'edit') {
            let save = { buckets: data.buckets || {}, bucketSequence: data.bucketSequence || [] };
            save.buckets[payload] = { ...data.buckets[payload], id: payload, title: editBucketValue };

            await updateDocument(user.uid, save);
            
            setEditNameSuccess(true);
        }
    }

    // scroll handlers
    // const [holder1, setHolder1] = useState(null);
    const holder1 = useRef();
    const holder2 = useRef();
    const [scrollTop1, setScrollTop1] = useState(0);
    const [scrollTop2, setScrollTop2] = useState(0);
    const [scrollClass1, setScrollClass1] = useState('');
    const [scrollClass2, setScrollClass2] = useState('');
    let scrolling1 = false;
    let scrolling2 = false;

    const handleScroll1 = () => {
        if (scrolling1) return;
        scrolling1 = true;
        setTimeout(() => {
            setScrollTop1(holder1.current.scrollTop);
            scrolling1 = false;
        }, 300);
    }
    const handleScroll2 = () => {
        if (scrolling2) return;
        scrolling2 = true;
        setTimeout(() => {
            setScrollTop2(holder2.current.scrollTop);
            scrolling2 = false;
        }, 300);
    }
    
    useEffect(() => {
        if (holder1.current) {
            let height = holder1.current.offsetHeight || 0;
            let totalHeight = holder1.current.scrollHeight || 0;
            let classes = '';
            if ((height + scrollTop1) < totalHeight - 1) {
                classes = ' scroll-shadow-bottom';
            } 
            if (scrollTop1 > 0) classes += ' scroll-shadow-top';
            setScrollClass1(classes);
        }
    }, [holder1, scrollTop1]);
    useEffect(() => {
        if (holder2.current) {
            let height = holder2.current.offsetHeight || 0;
            let totalHeight = holder2.current.scrollHeight || 0;
            let classes = '';
            if ((height + scrollTop2) < totalHeight - 1) {
                classes = ' scroll-shadow-bottom';
            } 
            if (scrollTop2 > 0) classes += ' scroll-shadow-top';
            setScrollClass2(classes);
        }
    }, [holder2, scrollTop2]);

    useEffect(() => {
        if (!data) return;
        if (!data.phrases) return;
        let entryOptions = [];
        let pickedEntries = [];
        data.phraseSequence.forEach(item => {
            if (!data.phrases[item]) return;
            if (payload && data.buckets[payload] && data.buckets[payload].phrases) {
                if (data.buckets[payload].phrases.indexOf(item) > -1) {
                    pickedEntries.push(data.phrases[item]);
                } else {
                    entryOptions.push(data.phrases[item]);
                }
            } else {
                entryOptions.push(data.phrases[item]);
            }
        });

        setPhraseOptions(entryOptions);
        setPickedPhrases(pickedEntries);
    }, [data, payload]);

    const addToBucket = async (id) => {
        let save = { buckets: data.buckets };
        if (save.buckets[payload].phrases) {
            save.buckets[payload].phrases.push(id);
        } else {
            save.buckets[payload].phrases = [id]
        }

        await updateDocument(user.uid, save);
    }

    const removeFromBucket = async (id) => {
        let save = { buckets: data.buckets };
        if (save.buckets[payload].phrases) {
            const index = save.buckets[payload].phrases.indexOf(id);
            save.buckets[payload].phrases.splice(index, 1);
        }

        await updateDocument(user.uid, save);
    }

    return (
        <>
            {data && <div className="tree-container flex flex-col overflow-hidden">
                <div className="flex">
                    <h5>Manage Buckets</h5>
                    {/* <div className="bucket-animation-wrapper">
                        <div className="bucket-header-emoji-1">🎴</div>
                        <div className="bucket-header-emoji-2">🃏</div>
                        <div className="bucket-header-emoji-3">🎴</div>
                        <div className="bucket-header-emoji-4">🃏</div>
                        <div className="bucket-header-emoji-5">🎴</div>
                        <div className="bucket-header-emoji-6">🃏</div>
                        <div className="bucket-header-emoji-7">🎴</div>
                    </div> */}
                </div>
                <p className="mt-1">Buckets are collections of phrases, grouped together for various scenarios.</p>
                <div className="mt-4 grow flex items-center mx-auto">
                    <BucketList data={data} returnFxn={handlePageClick} />
                </div>
                {!newBucket && <div className="btn-circle mx-auto" onClick={() => handleAddClick('new')}>
                    <ClickableIcon type="add" addClass="alt-color small no-hover" />
                </div>}
            </div>}
            {!data &&
            <div className="m-auto self-center flex flex-col items-center">
                <LoadingIcon />
                <i className="mt-2">loading...</i>
            </div>}
            {addBucket && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal bucket-form">
                    <h5>Add a Bucket</h5>
                    <div className="cancel-tree-form" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <form className="mt-4" onSubmit={(e) => handleBucketSubmit(e, 'new')}>
                        <label className="w-full flex justify-between gap-2">
                            <span className="self-center">Name:</span>
                            <input 
                                className="grow"
                                required
                                type="text"
                                onChange={e => setNewBucketValue(e.target.value)}
                                value={newBucketValue}
                            />
                        </label>
                        <p className="p-small mt-2 w-60">Recommend to keep the name short but distinct for better recognizability.</p>
                        {response.error && <p className="error mt-2">{response.error}</p>}
                        {!response.isPending && <button className="btn btn-circle mx-auto mt-4">
                            <ClickableIcon type="check" addClass="no-background no-hover invert mini" />
                        </button>}
                        {response.isPending && <button className="btn btn-circle mx-auto mt-4" disabled>
                            <LoadingIcon size="small" />
                        </button>}
                    </form>
                </div>
            </div>}
            {editBucket && <div className="screen" onClick={handleScreenClear}>
                <div className="center-modal bucket-form">
                    <h5>Edit Bucket</h5>
                    <div className="cancel-tree-form" onClick={handleScreenClear}>
                        <ClickableIcon type="close" addClass="invert no-hover mini" />
                    </div>
                    <form className="mt-4" onSubmit={(e) => handleBucketSubmit(e, 'edit')}>
                        <label className="w-full flex justify-between gap-2">
                            <span className="self-center">Name:</span>
                            <input 
                                required
                                className="grow"
                                type="text"
                                onChange={e => setEditBucketValue(e.target.value)}
                                value={editBucketValue}
                            />
                            {!response.isPending && <button className="btn btn-circle">
                                <ClickableIcon type="check" addClass="no-background no-hover invert mini" />
                            </button>}
                            {response.isPending && <button className="btn btn-circle" disabled>
                                <LoadingIcon size="small" />
                            </button>}
                        </label>
                        {!editNameSucess && <p className="p-small mt-2 w-60">Recommend to keep the name short but distinct for better recognizability.</p>}
                        {editNameSucess && <p className="p-small mt-2 w-60">Name change saved.</p>}
                        <p className="mt-4">Phrases:</p>
                        <div className="grid grid-cols-2 gap-2 bucket-columns">
                            <div className={`column flex flex-col gap-2 items-start first-column hide-scroll ${scrollClass1}`} onScroll={handleScroll1} ref={holder1}>
                                {phraseOptions && phraseOptions.map(item => (
                                    <div key={item.id} className="column-entry" onClick={e => addToBucket(item.id)}>
                                        <p>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                            <div className={`column flex flex-col gap-2 items-start second-column hide-scroll ${scrollClass2}`} onScroll={handleScroll2} ref={holder2}>
                                {pickedPhrases && pickedPhrases.map(item => (
                                    <div key={item.id} className="column-picked-entry" onClick={e => removeFromBucket(item.id)}>
                                        <p>{item.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 bucket-columns mt-1">
                            <p className="p-emphasize ml-2">options</p>
                            <p className="p-emphasize ml-2">inside</p>
                        </div>
                        {response.error && <p className="error mt-2">{response.error}</p>}
                    </form>
                </div>
            </div>}
        </>
    )
}