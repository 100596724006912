import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from "../../../hooks/useAuthContext"
import { useFirestore } from '../../../hooks/firebase/useFirestore';

import ClickableIcon from '../../../components/ClickableIcon';

import './ActiveChatAlert.css';

export default function ActiveChatAlert() {
    const { user } = useAuthContext();
    const { updateDocument } = useFirestore('users');
    const [activeChatClass, setActiveChatClass] = useState('');
    const [haveActiveChats, setHaveActiveChats] = useState(false);
    const { getDocument } = useFirestore('sessionSnaps');
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!user) return;
        if (user.activeChats?.length > 0) {
            getDocument(user.activeChats[0]).then(doc => {
                if (!doc) {
                    updateDocument(user.uid, { activeChats: [] });
                }
                if (doc?.status?.active) {
                    if (doc.info[user.uid]?.active && ! doc.info[user.uid]?.banned) {
                        setHaveActiveChats(true);
                        setTimeout(() => setActiveChatClass('shrink'), 3000);
                    }
                }
            }).catch(err => console.log(err));
        }
    }, [user]);
    
    return <>
        {haveActiveChats && <div className={`active-chat-notification ${activeChatClass}`} onClick={() => navigate(`/chatlobby/${user.activeChats[0]}`)}>
            <div className="self-start">
                <ClickableIcon type="forum" addClass="no-background large invert" />
            </div>
            <div className="text-start">
                <p>Active Chat:</p>
                <h5>{user.activeChats[0]}</h5>
            </div>
        </div>}
    </>
}