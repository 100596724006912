import { useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import './JoinChatForm.css';

import LoginForm from "../../../components/LoginForm";
import SignupForm from '../../../components/SignupForm';
import GuestForm from '../../../components/GuestForm'
import { appName } from '../../../tools/tools';

export default function JoinChatForm({ guests, gdpr, returnFxn }) {
    const [form, setForm] = useState('login');
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [params, setParams] = useState(false);
    
    const toggleForms = (e) => {
        setForm(e.target.closest('.btn').dataset.form);
    }

    // look for url params
    useEffect(() => {
        const urlQuery = window.location.search;
        const urlParams = new URLSearchParams(urlQuery);
        const urlParamName = urlParams.get('name');
        
        if (urlParamName) setForm('guest');
    }, []);
    
    return (
        <div className="screen">
            <div className="w-full">
                <div className={`screen-title ${form === 'guest' ? 'guest' : ''}`} onClick={() => navigate('/')}>
                    <p className="p-small">Welcome to</p>
                    <h2>{appName}</h2>
                </div>
            </div>
            <div className="center-modal login-form-modal transparent no-padding" style={form === 'guest' ? { marginTop: '2rem' } : {}}>
                {gdpr !== false && <div className="modal-section">
                        {!user && form === 'login' && <LoginForm />}
                        {!user && form === 'signup' && <SignupForm expanded={true} />}
                        {!user && form === 'guest' && <GuestForm expanded={true} />}
                </div>}
                {gdpr === false && <>
                    <div className="flex flex-col items-center gdpr-block" style={{ maxWidth: '400px' }}>
                        <div className="flex justify-between">
                            <h4 className="self-center">⛔</h4>
                            <p className="p-emphasize self-center text-center px-4">Agreeing to our <a href="/privacy-policy" target="block">privacy policy</a> and <a href="/terms-and-conditions" target="block">terms & conditions</a> is required to use our services</p>
                            <h4 className="self-center">⛔</h4>
                        </div>
                        <button className="mt-6 invert" onClick={() => returnFxn({ method: 'gdpr', value: true })}>I Agree</button>
                    </div>
                </>}
                {gdpr !== false && <div className="join-form-options modal-section transparent flex justify-center gap-4">
                    {form !== 'signup' && <button onClick={toggleForms} className="btn btn-white btn-small" data-form="signup">signup</button>}
                    {form !== 'login' && <button onClick={toggleForms} className="btn btn-white btn-small" data-form="login">login</button>}
                    {guests && form !== 'guest' && <button onClick={toggleForms} className="btn btn-white btn-small" data-form="guest">guest</button>}
                </div>}
            </div>
        </div>
    )

}

