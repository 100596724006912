import { useState, useEffect, useRef } from 'react';

import LoginForm from '../../components/LoginForm';
import SignupForm from '../../components/SignupForm';

import './Home.css';

import HoldingDevice from '../../assets/holding_device.png';
import Astronaut from '../../assets/astronaut.png';
import Hero from '../../assets/hero.png';
import Elf from '../../assets/elf.png';
import Stethoscope from '../../assets/stethoscope.png';
import Toolbox from '../../assets/toolbox.png';
import Trash from '../../assets/trash.png';
import Secret from '../../assets/japanese_secret.png';
import Message from '../../assets/message.png';
import GoogleTrans from '../../assets/google_translate.png';
import PhraseScreenshot from '../../assets/phrase_tree_screenshot.JPG';
import InstructionsScreenshot from '../../assets/instructions_mode_screenshot.JPG';
import InterpreterScreenshot from '../../assets/interpreter_screenshot.JPG';
import Bubble from '../../assets/speech_bubble.png';

import { homeContent } from '../../tools/content';

import { appName, appVersion } from '../../tools/tools';
import TranslateButton from '../../components/TranslateButton';

export default function Home({ gdpr, returnFxn }) {
    const [form, setForm] = useState('login');
    const [randomArr, setRandomArr] = useState([]);
    // const [medVersion, setMedVersion] = useState(false);
    // const location = window.location.href;
    // if (location.includes('medchat')) setMedVersion(true);
    const banner01 = useRef();
    const banner02 = useRef();
    const banner03 = useRef();
    const banner04 = useRef();
    const bubble01 = useRef();
    const bubble02 = useRef();
    const bubble03 = useRef();

    const handleToggle = () => {
        form === 'login' ? setForm('signup') : setForm('login');
    }
    
    useEffect(() => {
        let helloArr = [
            'Hello',
            'नमस्ते',
            'Bonjour',
           '你好',
            'Hola',
            'こんにちは',
            'Hallo',
            'Hallå',
            'Xin Chào',
            '안녕하세요',
            'ciao',
            'שלום',
            'привіт',
            'olá',
            'Բարեւ Ձեզ',
            'Χαίρετε',
            'أهلا',
            'สวัสดี'
        ];
        let random = [];
        let pickedIndexes = [];
        for (let i = 0; i < helloArr.length; i++) {
            let index = Math.floor(Math.random() * helloArr.length);
            while (pickedIndexes.indexOf(index) > -1) {
                index = Math.floor(Math.random() * helloArr.length);
            }
            pickedIndexes.push(index);
            let entry = helloArr[index];
            random.push(entry);
        }
        
        setRandomArr(random);
    }, []);

    const [speechBubble1, setSpeechBubble1] = useState('Hello');
    const [speechBubble2, setSpeechBubble2] = useState('Bonjour');
    const [speechBubble3, setSpeechBubble3] = useState('Hello');
    useEffect(() => {
        let speechArr = [
            'Hello',
            'नमस्ते',
            'Bonjour',
            '你好',
            'Hola',
            'Hallo',
            'Hallå',
            'Xin Chào',
            'ciao',
            'שלום',
            'привіт',
            'olá',
            'Բարեւ Ձեզ',
            'Χαίρετε',
            'أهلا',
            'สวัสดี'
        ];
        
        let order = 0;
        const invervalBubbles = setInterval(() => {
            if (!bubble01.current || !bubble02.current || !bubble03.current) {
                clearInterval(invervalBubbles);
                return;
            }

            if (order % 2 === 0) {
                let index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble1(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble2(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble3(speechArr[index]);

                bubble01.current.classList.remove('show');
                bubble02.current.classList.remove('show');
                bubble03.current.classList.remove('show');
            } else if (order === 1) {
                let index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble1(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble2(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble3(speechArr[index]);

                bubble01.current.classList.add('show');
                bubble02.current.classList.remove('show');
                bubble03.current.classList.remove('show');
            } else if (order === 3) {
                let index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble1(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble2(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble3(speechArr[index]);

                bubble01.current.classList.remove('show');
                bubble02.current.classList.add('show');
                bubble03.current.classList.remove('show');
            } else if (order === 5) {
                let index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble1(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble2(speechArr[index]);
                index = Math.floor(Math.random() * speechArr.length);
                setSpeechBubble3(speechArr[index]);

                bubble01.current.classList.remove('show');
                bubble02.current.classList.remove('show');
                bubble03.current.classList.add('show');
            }
            
            order++;
            if (order > 5) order = 0;
        }, 9000);
    }, []);

    useEffect(() => {
        const interval01 = setInterval(() => {
            if (!document.querySelector('.home-banner-01')) return clearInterval(interval01);

            banner01.current.classList.add('bottom');
            banner02.current.classList.remove('top');
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner01.current.classList.add('top');
                banner01.current.classList.remove('bottom');
                let random01 = Math.floor(Math.random() * homeContent.heads.length);
                banner01.current.innerHTML = homeContent.heads[random01];
            }, 600);
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner02.current.classList.add('bottom');
                banner01.current.classList.remove('top');
            }, 5000);
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner02.current.classList.add('top');
                banner02.current.classList.remove('bottom');
                let random02 = Math.floor(Math.random() * homeContent.heads.length);
                banner02.current.innerHTML = homeContent.heads[random02];
            }, 5600);
        }, 10000);
    }, [banner01, banner02]);

    useEffect(() => {
        const interval02 = setInterval(() => {
            if (!document.querySelector('.home-banner-01')) {
                clearInterval(interval02);
                return;
            }

            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner03.current.classList.add('bottom');
                banner04.current.classList.remove('top');
            }, 2500);
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner03.current.classList.add('top');
                banner03.current.classList.remove('bottom');
                let random01 = Math.floor(Math.random() * homeContent.heads.length);
                banner03.current.innerHTML = homeContent.heads[random01];
            }, 3100);
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner04.current.classList.add('bottom');
                banner03.current.classList.remove('top');
            }, 7500);
            setTimeout(() => {
                if (!document.querySelector('.home-banner-01')) return;
                banner04.current.classList.add('top');
                banner04.current.classList.remove('bottom');
                let random02 = Math.floor(Math.random() * homeContent.heads.length);
                banner04.current.innerHTML = homeContent.heads[random02];
            }, 8100);
        }, 10000);
    }, [banner03, banner04]);

    
    return (
        <>
        <div className="home-bg">
            <div className="home-backdrop hide-scroll">
                <div className="home-content-wrapper">
                    <div className="content-holder hide-scroll">
                        <div className="absolute top-0 right-0">
                            <TranslateButton addClass="no-background" />
                        </div>
                            <div className="center-element flex flex-col gap-1 m-auto section-form">
                            <div className="p-2">
                                <div className="relative">                   
                                    <h2 className="inline notranslate" style={{fontSize: '3.5rem'}}>{appName}<span>🍊</span></h2>
                                    <span className="absolute top-0 right-0 notranslate" style={{ fontSize: '10px' }}>beta v{appVersion}</span> 
                                </div>
                                <p>{homeContent.en.title01} 
                                <span className="home-banner-holder ml-1 notranslate">
                                    <span style={{ opacity: 0 }}>🧔</span>
                                    <span ref={banner01} className="home-banner-01">🧔</span>
                                    <span ref={banner02} className="home-banner-02 top">👨</span>
                                </span>
                                🙌
                                <span className="home-banner-holder notranslate">
                                    <span style={{ opacity: 0 }}>👵</span>
                                    <span ref={banner03} className="home-banner-01">👵</span>
                                    <span ref={banner04} className="home-banner-02 top">👩‍🦰</span>
                                </span>
                                <span></span></p>
                            </div>
                            {/* {medVersion && <div className="p-2">
                                <div>
                                    <span className="inline text-sm mt-0 align-top">my</span>                    
                                    <h2 className="inline">MedChat<span>💊</span></h2>
                                </div>
                                <p className="mt-2">Instant translation chat and tools built for healthcare practioners and patients 👵🙌👩‍⚕️</p>
                            </div>} */}
                            <div className="flex flex-col">
                                {form === 'login' && gdpr !== false && <LoginForm />}
                                {form === 'signup' && gdpr !== false && <SignupForm />}
                                {gdpr === false && <div className="flex flex-col items-center gdpr-block">
                                    <div className="flex justify-between">
                                        <h4 className="self-center">⛔</h4>
                                        <p className="p-emphasize self-center text-center px-4">Agreeing to our <a href="/privacy-policy" target="block">privacy policy</a> and <a href="/terms-and-conditions" target="block">terms & conditions</a> is required to use our services</p>
                                        <h4 className="self-center">⛔</h4>
                                    </div>
                                    <button className="mt-6 invert" onClick={() => returnFxn({ method: 'gdpr', value: true })}>I Agree</button>
                                </div>}
                            </div>
                            {form === 'login' && gdpr !== false && (
                                <div className="bottom-button">
                                    <button className="btn-border" onClick={handleToggle}>sign up</button> 
                                </div>
                            )}
                            {form === 'signup' && gdpr !== false && (
                                <div className="bottom-button">
                                    <button className="btn-border" onClick={handleToggle}>login</button>
                                </div>
                            )}
                        </div>
                        
                        <div className="section-bg notranslate">
                            {randomArr.length && randomArr.map((entry, index) => (
                                <h3 key={index}>{entry}</h3>
                            ))}
                        </div>
                        <div className="section-text">
                            <div className="text-section grid grid-cols-2 gap-2 section01">
                                <p className="p-emphasize self-center">{homeContent.en.section01}</p>
                                <div className="img-holder">
                                    <img className="self-center img01" src={Astronaut} alt="astronaut" />
                                    <img className="self-center img02" src={Hero} alt="super hero" />
                                    <img className="self-center img03" src={Elf} alt="elf" />
                                    <img className="self-center img04" src={HoldingDevice} alt="arm holding a mobile device" />
                                    <div className="speech-bubble" ref={bubble01}>
                                        <img className="img05" src={Bubble} alt="speech bubble saying hello in various languages" />
                                        <div className="speech-bubble-content img06 ">
                                            <p className="p-emphasize notranslate">{speechBubble1}</p>
                                        </div>
                                    </div>
                                    <div className="speech-bubble" ref={bubble03}>
                                        <img className="img07" src={Bubble} alt="speech bubble saying hello in various languages" />
                                        <div className="speech-bubble-content img08">
                                            <p className="p-emphasize notranslate">{speechBubble2}</p>
                                        </div>
                                    </div>
                                    <div className="speech-bubble" ref={bubble02}>
                                        <img className="img09" src={Bubble} alt="speech bubble saying hello in various languages" />
                                        <div className="speech-bubble-content img10">
                                            <p className="p-emphasize notranslate">{speechBubble3}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-section flex justify-between  gap-2 section05">
                                <p className="p-emphasize self-center">{homeContent.en.section02}</p>
                                <div className="img-holder flex-shrink-0">
                                    <img className="self-center w-28 h-28" src={Stethoscope} alt="stethoscope" />
                                </div>
                            </div>
                            {/* <div className="text-section grid grid-cols-2 gap-2 section02">
                                <p className="p-emphasize">Inspired by healthcare providers and patients but made for everyone.</p>
                                <div className="img-holder">
                                    <img className="self-center img01" src={Stethoscope} alt="stethoscope" />
                                    <img className="self-center img02" src={Pill} alt="pill" />
                                    <img className="self-center img03" src={Bandaid} alt="bandaid" />
                                </div>
                            </div> */}
                            <div className="text-section flex justify-between  gap-2 section05">
                                <p className="p-emphasize self-center">{homeContent.en.section03}</p>
                                <div className="img-holder flex-shrink-0">
                                    <img className="self-center w-28 h-28" src={Toolbox} alt="toolbox" />
                                </div>
                            </div>
                            {/* <div className="text-section grid grid-cols-2 gap-2 section03">
                                <p className="p-emphasize">Simple tools to break down language barriers. Designed to be as simple and natural as possible.</p>
                                <div className="img-holder">
                                    <img className="self-center img01" src={TriangularRuler} alt="triangular ruler" />
                                    <img className="self-center img02" src={Magnet} alt="magnet" />
                                    <img className="self-center img03" src={Tools} alt="tools" />
                                    <img className="self-center img04" src={Toolbox} alt="toolbox" />
                                </div>
                            </div> */}
                            <div className="text-section flex justify-between gap-2 section05">
                                <p className="p-emphasize self-center">{homeContent.en.section04}</p>
                                <div className="img-holder flex-shrink-0">
                                    <img className="self-center w-28 h-28" src={GoogleTrans} alt="Google Translate Icon" />
                                </div>
                            </div>

                            {/* <div className="text-section grid grid-cols-2 gap-2 section04">
                                <p className="p-emphasize">Powered by Goolge Translate.</p>
                                <div className="img-holder">
                                    <img className="self-center img01" src={Globe01} alt="globe on AsiaAustralia" />
                                    <img className="self-center img02" src={Globe02} alt="globe on Americas" />
                                    <img className="self-center img03" src={Globe03} alt="globe on EuroAfrica" />
                                    <img className="self-center img04" src={GoogleTrans} alt="Google Translate Icon" />
                                </div>
                            </div> */}
                            <div className="text-section grid grid-cols-2 gap-2 section05">
                                <p className="p-emphasize">{homeContent.en.section05}</p>
                                <div className="img-holder">
                                    <img src={PhraseScreenshot} alt="chat screenshot showing phrase tree" />
                                </div>
                            </div>
                            <div className="text-section grid grid-cols-2 gap-2 section05">
                                <p className="p-emphasize">{homeContent.en.section06}</p>
                                <div className="img-holder">
                                    <img src={InstructionsScreenshot} alt="chat screenshot of instructions mode" />
                                </div>
                            </div>
                            {/* <div className="text-section grid grid-cols-2 gap-2 section05">
                                <p className="p-emphasize">{homeContent.en.section07}</p>
                                <div className="img-holder">
                                    <img src={TwoWayScreenshot} alt="chat screenshot showing Two-way conversation" />
                                </div>
                            </div> */}
                            <div className="text-section grid grid-cols-2 gap-2 section05">
                                <p className="p-emphasize">{homeContent.en.section08}</p>
                                <div className="img-holder">
                                    <img src={InterpreterScreenshot} alt="chat screenshot showing interpreter correction a message" />
                                </div>
                            </div>
                            <div className="text-section grid grid-cols-2 gap-2 section06">
                                <p className="p-emphasize">{homeContent.en.section09}</p>
                                <div className="img-holder">
                                    <img className="img01" src={Trash} alt="trashbin" />
                                    <img className="img02" src={Message} alt="message bubble" />
                                    <img className="img03" src={Secret} alt="secret character in Japanese" />
                                </div>
                            </div>
                            {/* <p className="p-emphasize">Powered by Google Translate</p> */}
                            {/* <p className="p-emphasize">Simple tools to break down language barriers</p>
                            <p className="p-emphasize">Designed to be simple and natural to use</p>                             */}
                            <div className="text-section grid grid-cols-2 gap-2 section05">
                                <p className="p-emphasize">{homeContent.en.section10}</p>
                                <div className="img-holder mt-4 flex justify-center" style={{ filter: 'none' }}>
                                    <button className="mx-auto special-btn" onClick={(e) => {
                                        setForm('signup');
                                        e.target.closest('.content-holder').scroll({ top: 0, behavior: 'smooth' });
                                    }}>sign up</button>
                                </div>
                            </div>
                            {/* <div className="flex justify-end pr-8" style={{ marginBottom: '-3.5rem', zIndex: '-1' }}>
                                <img  className="w-14 h-14" src={CatFace} alt="cat face" />
                            </div> */}
                            <div className="footer grid grid-col-2 mt-8">
                                <div className="col-span-2 md:col-span-1">
                                    <h2 className="notranslate">{appName}<span className="notranslate">🍊</span></h2>
                                    <p className="p-small notranslate">beta v{appVersion}</p>
                                </div>
                                <div className="flex justify-end mt-4 col-span-2 md:col-span-1">
                                    <div className="w-fit">
                                        <p><a href="/privacy-policy" target="blank">{homeContent.en.footer01}</a></p>
                                        <p className="mt-2"><a href="/terms-and-conditions" target="blank">{homeContent.en.footer02}</a></p>
                                        <p className="mt-2">
                                        <a className="" href="mailto:shyroboto@gmail.com">{homeContent.en.footer03}</a>
                                        </p>
                                    </div>
                                </div>
                                <p className="text-center col-span-2 p-emphasize mt-4 notranslate">
                                    2022 © ShyRoboto LLC 
                                </p>
                                <div className="absolute left-4 bottom-4 md:left-8" style={{ zIndex: 100 }}>
                                    <TranslateButton addClass="no-hover" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
