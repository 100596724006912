import { useState, useEffect } from 'react';
import { functions } from "../firebase/config";
import { httpsCallable } from 'firebase/functions';
import { useAuthContext } from "./useAuthContext";
import { useChatContext } from './useChatContext';

export const useReturnChat = () => {
    const handleChatFxn = httpsCallable(functions, 'handleChatEntryExit');
    const { user } = useAuthContext();
    const { dispatch } = useChatContext();
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const [cancel, setCancel] = useState(false);

    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }
    
    const returnChat = async (session) => {
        return new Promise(async (resolve, reject) => {
            setPending(true);

            if (!user) {
                console.log('User not authenticated')
                setError('User not authenticated');
                setPending(false);
            } else {
                if (user.invites) {
                    Object.values(user.invites).forEach(item => {
                        if (item.session === session) delete user.invites[item.timestamp];
                    });
                }

                let save = {
                    method: 'rejoin',
                    session,
                    user: {
                        uid: user.uid,
                        activeChats: user.activeChats ? [...user.activeChats, session] : [session],
                        invites: user.invites
                    }
                }

                try {
                    const res = await handleChatFxn(save);

                    dispatch({ type: 'RETURN' });

                    reset();
                    resolve(res);
                    return;
                } catch(err) {
                    console.log('failed', err.message);
                    reset(err);
                    reject(err.message);
                    return;
                }        
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { error, pending, returnChat };
}