import { useEffect, useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useChatContext } from '../hooks/useChatContext';
import { useFirestore } from '../hooks/firebase/useFirestore';
import { functions } from '../firebase/config';
import { httpsCallable } from 'firebase/functions';

import './UserCard.css';
import { langSrc } from '../tools/languageSource';

import ProfilePic from './ProfilePic';
import ClickableIcon from './ClickableIcon';
import LoadingIcon from './LoadingIcon';
import NoAccessImg from '../assets/no-access.png';
import PencilImg from '../assets/pencil.png';
import RedXImg from '../assets/red_x.png';
import CheckImg from '../assets/check.png';

export default function UserCard ({ user, profileBackground, context, edit, request, document }) {    
    const banFxn = httpsCallable(functions, 'banChatUser');
    const requestFxn = httpsCallable(functions, 'handleRequests');
    const friendRequestFxn = httpsCallable(functions, 'handleFriendRequests');
    const [formattedLang, setFormattedLang] = useState('');
    const [formattedRole, setFormattedRole] = useState('');
    const [guest, setGuest] = useState(false);
    const [ban, setBan] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [editRole, setEditRole] = useState(null);
    const [banStatus, setBanStatus] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const { user: reader } = useAuthContext();
    const { session, users, self, owner } = useChatContext();
    const { updateDocument, response } = useFirestore('sessions');
    const { updateDocument: updateSnapshotDocument, response: snapResponse } = useFirestore('sessionSnaps');
    const [isFriend, setIsFriend] = useState(false);
    const [isSelf, setIsSelf] = useState(false);

    useEffect(() => {
        if (owner && self) if (owner === self) setIsOwner(true);
    }, [owner, self])
    
    useEffect(() => {
        if (!user) return;
        if (!user.role) return;
        
        if (context === 'chat') {
            if (users && users[user.tempID]) {
                let role = users[user.tempID].role;
                role === 'guest' ? setEditRole('other') : setEditRole(role);

                role = role === 'other' ? 'Limited' : role[0].toUpperCase() + role.substring(1);
                role = user.role === 'provider' ? 'Member' : role;
                role = user.role === 'general' ? 'Member' : role;
                role = user.owner ? 'Host' : role;
                setFormattedRole(role);
            }
        } else {
            setEditRole(user.role);
            let role = user.role === 'other' ? 'Limited' : user.role[0].toUpperCase() + user.role.substring(1);
            role = user.role === 'provider' ? 'Member' : role;
            role = user.role === 'general' ? 'Member' : role;
            role = user.owner ? 'Host' : role;
            setFormattedRole(role);
        }
        
        langSrc.forEach(item => {
            if (item.code === user.lang) setFormattedLang(item.name);
        });
        
        if (!request) if (users && users[user.tempID].ban) {
            setBan(true);
        } else {
            setBan(false);
        }
        
        if (user.user === 'guest' || user.role === 'guest') setGuest(true);
    }, [user, users, context, request]);

    useEffect(() => {
        if (!reader || !user) return;
        if (user.user === 'guest' || user.role === 'guest') return;
        if (!reader.friends || reader.friends.length === 0) return;
        if (reader.uid === user.user) return;

        if (user.user && reader.friends.indexOf(user.user) !== -1) setIsFriend(true);
        if (user.uid && reader.friends.indexOf(user.uid) !== -1) setIsFriend(true);
    }, [reader, user]);
    
    useEffect(() => {
        if (!reader || !user) return;
        if (reader.uid === user.user || reader.uid === user.tempID || reader.uid === user.uid) setIsSelf(true);
    }, [reader, user]);

    const handleEditRole = () => {
        if (editStatus) {
            setEditStatus(false);
        } else  {
            setEditStatus(true);
        }
    }

    const handleBan = () => {
        if (banStatus) {
            setBanStatus(false);
        } else {
            setBanStatus(true);
        }
    }

    const saveEdit = async () => {
        if (!document) return console.log('no document');
        let save = { info: document.info };
        save.info[user.tempID].role = editRole;
        
        await updateDocument(session, save);

        setEditStatus(false);
    }

    const [banPending, setBanPending] = useState(false);
    const banUser = async (toBan) => {
        if (!document) return console.log('no document');
        if (banPending) return;
        setBanPending(true);

        banFxn({
            banUser: user.tempID,
            session,
            ban: toBan
        }).then(() => {
            setBanStatus(false);
            setBanPending(false);
        }).catch(err => {
            console.log(err.message);
            setBanStatus(false);
            setBanPending(false);
        });
    }
    
    const [requestPending, setRequestPending] = useState(false);
    const handleRequest = async (method) => {
        if (requestPending) return;
        setRequestPending(true);

        requestFxn({ 
            session,
            requestUser: user.tempID,
            method
        }).then(() => {
            setRequestPending(false);
        }).catch(err => {
            console.log(err);
            setRequestPending(false);
        })
    }

    const [friendRequestPending, setFriendRequestPending] = useState(false);
    const handleFriendRequestClick = () => {
        if (friendRequestPending) return;
        setFriendRequestPending(true);
        
        friendRequestFxn({
            method: 'send',
            displayName: reader.displayName,
            photoURL: reader.photoURL || '',
            username: reader.username || '',
            role: reader.role || 'other',
            sender: reader.uid,
            receiver: user.tempID
        }).then(res => {
            console.log(res);
            setFriendRequestPending(false);
        }).catch(err => {
            console.log(err);
            setFriendRequestPending(false);
        });
    }
    
    return (
        <div className="user-card flex flex-col gap-2 items-center">
            <div className="profile-holder">
                <ProfilePic size="large" url={user.photoURL} background={profileBackground} />
            </div>
            <div className="flex justify-center gap-4">
                {guest && <div className="guest-badge">guest</div>}
                {ban && <div className="ban-badge">banned</div>}
            </div>
            <div className="relative">
                {isFriend && <div className="friend-flag">
                    <ClickableIcon type="group" addClass="mini no-background" />
                </div>}
                <h4 className="notranslate">{user.displayName}</h4>
            </div>
            {user.username && <p className="p-emphasize notranslate">@{user.username}</p>}
            {request && <p className={`request-tag ${user.username ? 'mt-2' : ''}`}>Requested to join</p>}
            {user.role !== 'interpreter' && <p className={`${request ? '' : 'mt-4'}`}>{formattedRole}</p>}
            {user.role === 'interpreter' && <div className={`interpreter-info ${request ? '' : 'mt-4'}`}>
                <p>{formattedRole}</p>
                <p className="notranslate">{user.organization}</p>
                <p className="notranslate">{user.license}</p>
            </div>}
            <p className="p-emphasize">{formattedLang}</p>
            
            {isOwner && <>
                <div className="edit-holder mt-8">
                    <div className="edit-wrapper">
                        {!request && <>
                            <div className={`edit-icons flex justify-around ${editStatus || banStatus ? 'shift' : ''}`}>
                                {!ban && <img className="h-8 w-8 edit-element cursor-pointer" src={PencilImg} onClick={handleEditRole} />}
                                {self !== user.tempID && <img className="h-8 w-8 edit-element cursor-pointer" src={NoAccessImg} onClick={handleBan} />}
                            </div>
                            <div className="edit-element edit-form flex justify-between gap-4">
                                {editStatus && <div className="edit-element w-full flex justify-between gap-2">
                                    <div onClick={handleEditRole}>
                                        <ClickableIcon type="arrow_back" addClass="no-hover small" />
                                    </div>
                                    {!response.error && <>
                                        <select 
                                            className="self-center grow"
                                            onChange={(e) => setEditRole(e.target.value)}
                                            value={editRole}
                                        >
                                            {/* <option value="patient">Patient</option> */}
                                            {!guest && <option value="general">General</option>}
                                            {/* <option value="provider">Provider</option>
                                            <option value="patient">Patient</option> */}
                                            <option value="interpreter">Interpreter</option>
                                            <option value="other">Limited</option>
                                        </select>
                                        {!response.isPending && <div className="self-center" onClick={saveEdit}>
                                            <ClickableIcon type="check" addClass="no-hover invert small"/>
                                        </div>}
                                        {response.isPending && <div className="self-center btn-wrapper">
                                            <LoadingIcon size="small" />
                                        </div>}
                                    </>}

                                    {response.error && <p className="error w-full">{response.error}</p>}
                                </div>}
                                {banStatus && <div className="edit-element w-full flex justify-between gap-4">
                                    {!response.error && !snapResponse.error && <>
                                        <div onClick={handleBan}>
                                            <ClickableIcon type="arrow_back" addClass="no-hover small" />
                                        </div>
                                        {!ban && !banPending && <p className="warning w-full" onClick={() => banUser(true)}>Ban from Chat?</p>}
                                        {ban && !banPending && <p className="warning w-full" onClick={() => banUser(false)}>Remove Ban?</p>}
                                        {banPending && <div className="warning w-full flex justify-center">
                                            <LoadingIcon size="small" />
                                        </div>}
                                    </>}
                                    {(response.error || snapResponse.error) && <p className="error w-full">{response.error || snapResponse.error}</p>}
                                </div>}
                            </div>
                        </>}
                        {request && <div className={`edit-icons flex justify-around relative`}>
                                {!requestPending && <>
                                    <img className="h-8 w-8 self-center  edit-element cursor-pointer" src={RedXImg} onClick={() => handleRequest('denied')} />
                                    <img className="h-10 w-10 self-center edit-element cursor-pointer" src={CheckImg} onClick={() => handleRequest('approved')} />
                                </>}
                                {requestPending && <div className="py-3 flex justify-center">
                                    <p className="self-center p-emphasize mr-1">saving...</p>
                                    <LoadingIcon size="small" />
                                </div>}
                        </div>}
                    </div>
                </div>
            </>}
            {!guest && !isFriend && !isSelf && <>
                {!friendRequestPending && <div className="card-add-friend-btn" onClick={handleFriendRequestClick}>
                    <ClickableIcon type="personAdd" addClass="invert" />
                </div>}
                {friendRequestPending && <div className="card-add-friend-btn pending">
                    <LoadingIcon size="small" />
                </div>}
                {reader?.friendRequests?.sent && reader.friendRequests.sent[user.tempID] && <div className="card-add-friend-btn">
                    <ClickableIcon type="personAdd" addClass="no-hover alt-color invert opacity-80" />
                    <p className="friend-request-sent-status badge invert">sent</p>
                </div>}
            </>}
        </div>
    )
}