import { useAuthContext } from '../hooks/useAuthContext';
import { useDocListener } from '../hooks/firebase/useDocListener';
import { useChatContext } from '../hooks/useChatContext';

import './ProfilePic.css'

import AvatarIcon from '../assets/avatar_icon.svg';
import ClickableIcon from './ClickableIcon';

export default function ProfilePicWithStatus({ size, badge, text, url, status, owner, background, user }) {
    const { users } = useChatContext();
    const { user: userData } = useAuthContext();
    let queryUser = user.user ? user.user : userData.uid;
    // const { document } = useDocListener('users', queryUser );

    let photo = url;
    let classes = `profile-pic object-cover has-file`;
    if (!url) {
        photo = AvatarIcon;
        classes = `profile-pic object-cover`;
    }
    
    let statusHTML = '';
    if (status) {
        // if (user.user && user.user !== 'guest' && document) {
        //     if (document.online) statusHTML = <div className="profile-status active"></div>;
        //     if (!document.online) statusHTML = <div className="profile-status"></div>;
        // }
        if (user.user) {
            if (users && users[user.tempID]) if (users[user.tempID].active) {
                statusHTML = <div className="profile-status active"></div>;
            } else {
                statusHTML = <div className="profile-status"></div>;
            }
        }
        if (user.user && user.ban) {
            statusHTML = <div className="profile-status banned">
                <ClickableIcon type="close" addClass="mini no-background no-hover" />
            </div>;
        }
    }
    if (!status) statusHTML = <div className="profile-status"></div>
    
    
    if (badge && text) {
        return (
            <div className={`relative flex flex-col items-center profile-pic-holder ${size ? size : ''} ${background ? 'background' : ''} ${owner ? 'owner' : ''}`}>
                <img className={classes} src={photo} alt="user avatar" />
                {statusHTML}
                <p className="badge">{text[0].toUpperCase() + text.substring(1)}</p>
            </div>
        )
    } else {
        return (
            <div className={`relative profile-pic-holder ${size ? size : ''} ${background ? 'background' : ''} ${owner ? 'owner' : ''}`}>
                <img className={classes} src={photo} alt="user avatar" />
                {statusHTML}
            </div>
        )
    }
}