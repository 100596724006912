import { useState, useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { functions } from '../../../firebase/config';
import { httpsCallable } from 'firebase/functions';

import UserCard from '../../../components/UserCard';
import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';
import ProfilePic from '../../../components/ProfilePic';
import EmailVerificationModal from '../../../components/EmailVerificationModal';

import './FriendReqList.css'

export default function FriendReqList() {
    const friendRequestFxn = httpsCallable(functions, 'handleFriendRequests');
    const { user } = useAuthContext();
    const [requestPending, setRequestPending] = useState(false);
    const [receivedFriendRequests, setReceivedFriendRequests] = useState([]);
    const [emailVeri, setEmailVeri] = useState(false);

    useEffect(() => {
        if (!user) return;
        let results = [];
        if (user?.friendRequests?.received) {
            Object.values(user.friendRequests.received).map(r => {
                if (r.status === 'pending') results.push(r);
            });
        }
        setReceivedFriendRequests(results);
    }, [user]);

    const handleFriendRequestResponse = async (method, sender) => {
        if (!user.emailVerified) {
            setEmailVeri(true);
            return;
        }

        if (requestPending) return;
        setRequestPending(true);
        
        friendRequestFxn({
            method,
            sender,
            receiver: user.uid
        }).then(res => {
            console.log(res);
            setRequestPending(false);
        }).catch(err => {
            console.log(err);
            setRequestPending(false);
        })
    }

    const [viewProfile, setViewProfile] = useState(false);
    const [viewProfileTarget, setViewProfileTarget] = useState(null);
    const handleProfileClick = (e, entry) => {
        if (e.target.closest('.friend-request-accept') || e.target.closest('.friend-request-deny')) return;
        if (!entry) return;

        setViewProfileTarget(entry);
        setViewProfile(true);
    }

    return <>
        <div className="w-full">
            <div className="w-full mt-4 pl-4">
                <p className="p-emphasize">Friend Requests</p>
            </div>
            <div className="flex flex-col gap-2 mt-2 items-center hide-scroll">
            {receivedFriendRequests.map((entry, index) => (
                <div key={index} className="friend-request-entry" onClick={(e) => handleProfileClick(e, entry)}>
                    <ProfilePic size="mini" url={entry.photoURL} />
                    <div className="self-center ml-1">
                        <p className="p-emphasize notranslate">{entry.displayName || entry.username}</p>
                        <p className="p-emphasize badge invert notranslate">@{entry.username}</p>
                    </div>
                    <div className="ml-auto self-center friend-request-accept" onClick={() => handleFriendRequestResponse('accepted', entry.uid)}>
                        <ClickableIcon type="check" addClass="" />
                    </div>
                    <div className="self-center ml-6 friend-request-deny" onClick={() => handleFriendRequestResponse('denied', entry.uid)}>
                        <ClickableIcon type="close" addClass="" />
                    </div>
                    <div className={`friend-request-status-block ${requestPending ? 'show' : ''}`}>
                        <LoadingIcon size="medium" />
                    </div>
                </div>
            ))}
            </div>
        </div>
        <EmailVerificationModal returnFxn={(emailVeriStatus) => setEmailVeri(emailVeriStatus)} status={emailVeri} />
        {viewProfile && <div className="screen items-center" onClick={() => {
            setViewProfile(false);
            setViewProfileTarget(null);
        }}>
            <UserCard user={viewProfileTarget} profileBackground={true} />
        </div>}
    </>
}