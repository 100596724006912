import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useChatContext } from '../../../hooks/useChatContext';
import { useDocListener } from '../../../hooks/firebase/useDocListener';
import { useAppContext } from '../../../hooks/useAppContext';

import './ChatProviderTools.css';

import ClickableIcon from '../../../components/ClickableIcon';

export default function ChatProviderTools({ handler, defaultState, resetDefaultState }) {
    const { user } = useAuthContext();
    const { self, users } = useChatContext();
    const [selfData, setSelfData] = useState(null);
    const { document, error, pending } = useDocListener('users', user.uid, false, 'chatCPT');
    const { languages, selfAudio, dispatch } = useAppContext();
    const [receiveAudio, setReceiveAudio] = useState(false);
    const [tools, setTools] = useState(false);
    const [langIndex, setLangIndex] = useState(0);
    const [senderAudioIcon, setSenderAudioIcon] = useState((
        <ClickableIcon type="senderAudio" addClass="no-background small" />
    ));
    
    useEffect(() => {
        if (!users) return;
        setSelfData(users[self]);
    }, [self, users]);

    useEffect(() => {
        if (defaultState === 'tools') {
            setTools(true);
            resetDefaultState();
        }
    }, [defaultState]);

    const handleClick = (e, category) => {
        if (category === 'receiverAudio') {
            if (receiveAudio) {
                setReceiveAudio(false);
                handler(null);
            } else {
                setReceiveAudio(true);    
                handler({ action: 'receiverAudio' });
                setSenderAudioIcon((<ClickableIcon type="senderAudio" addClass="no-background small" />));
                setLangIndex(0);
            } 
            
            dispatch({ type: 'SELFAUDIO_OFF' });
            dispatch({ type: 'SET_SELFAUDIOLANG', payload: selfData.lang, source: 'provider-tool-1' });
        }
        if (category === 'tools') {
            if (tools) {
                setTools(false);
                handler({ action: 'toolsClose' });
            } else {
                if (!pending) {
                    setTools(true);
                    if (error) {
                        handler({ action: 'toolsOpen', payload: {} });
                    } else {
                        handler({ action: 'toolsOpen', payload: document });
                    }
                }
            } 
            
        }
        if (category === 'senderAudio') {
            setReceiveAudio(false);
            
            let index = langIndex;
            if (index >= languages.length) {
                setSenderAudioIcon((<ClickableIcon type="senderAudio" addClass="no-background small" />))

                dispatch({ type: 'SELFAUDIO_OFF' });
                dispatch({ type: 'SET_SELFAUDIOLANG', payload: selfData.lang, source: 'provider-tool-2' });
                setLangIndex(0);
            } else {
                const lang = languages[index];
                setSenderAudioIcon(lang);

                dispatch({ type: 'SELFAUDIO_ON' });
                dispatch({ type: 'SET_SELFAUDIOLANG', payload: lang, source: 'provider-tool-3' }); 
                setLangIndex(index + 1);
            }

            return;
        }
    }

    return (
        <div className="flex justify-center">
            {selfData && (selfData.role === 'provider' || selfData.role === 'general') && <div className={`chat-tools-btn ${receiveAudio ? 'active' : ''}`} onClick={e => handleClick(e, 'receiverAudio')}>
                <ClickableIcon type="receiveAudio" addClass="mx-auto no-background small" />
            </div>}
            {!user.isAnonymous && ( <>
                {!error && <div className={`chat-tools-btn flex ${tools ? 'active' : ''}`} onClick={e => handleClick(e, 'tools')}>
                    <ClickableIcon type="widgets" addClass="mx-auto no-background small" />
                </div>}
                {error && <div className={`chat-tools-btn flex`}>
                    <p className="self-center p-title">🚫</p>
                </div>}
            </>)}
            <div className={`chat-tools-btn flex justify-center items-center notranslate ${selfAudio ? 'active' : ''}`} onClick={e => handleClick(e, 'senderAudio')}>
                {senderAudioIcon}
            </div>
        </div>
    )
}