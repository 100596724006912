import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { functions, fs } from '../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'

import './DXTools.css';

export default function DXTools() {
    const { user } = useAuthContext();
    const [functionResponse, setFunctionResponse] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) return;
        if (!user.admin) return navigate('/dashboard');
    }, []);

    const testFunction = async () => {
        console.log('triggered');
        const docData = await getDocs(collection(fs, 'sessionSnaps'))
        // const docData = await getDoc(doc(fs, 'sessionSnaps', 'her123'));
        
        console.log(docData);
        console.log(docData.empty);
        // console.log(docData.exists());
        console.log('done');
    }
    
    return (<>
        <div className="ml-2 p-5 pt-4 flex flex-col w-full">
            <h5 className="mt-1 inline-block">Diagnostics</h5>
            <div className="mt-4 p-4">
                <div className="flex flex-col w-100">  
                    <button className="btn self-center" onClick={testFunction}>test</button>
                    <p className="mt-2">{functionResponse}</p>
                </div>
            </div>
        </div>
    </>)
}
