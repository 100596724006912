import { useAuthContext } from "../../../hooks/useAuthContext";
import { useChatContext } from "../../../hooks/useChatContext";
import { useState, useRef, useEffect } from 'react';
import { useQRCode } from 'next-qrcode';

import ClickableIcon from "../../../components/ClickableIcon";
import FriendsList from "../../../components/FriendsList";
import LightingImg from '../../../assets/lighting.png';

import { langSrc } from '../../../tools/languageSource';

import './LinkShare.css';

export default function LinkShare({ url }) {
    const { user } = useAuthContext();
    const { guests } = useChatContext();
    const { Canvas } = useQRCode();
    const copyRef = useRef();
    const copyRef2 = useRef();
    const [invite, setInvite] = useState(false);
    const [inviteDone, setInviteDone] = useState(false);
    const [name, setName] = useState('');
    const [lang, setLang] = useState('en');
    const [role, setRole] = useState('guest');
    const [inviteURL, setInviteURL] = useState(url);
    const [guestInvite, setGuestInvite] = useState(false);
    const [holder, setHolder] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    
    const langOptions = langSrc.map(item => (
        <option key={item.code} value={item.code}>{item.name}</option>
    ));

    let scrolling = false;
    // scroll handlers
    const handleScroll = () => {
        if (scrolling) return;
        if (!holder) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);

    const onLoad = (e) => {
        setHolder(e.target.closest('.invite-friendsList-wrapper'));
    }

    const handleCopy = (e, inviteLink) => {
        let target = inviteLink ? inviteLink : url;
        navigator.clipboard.writeText(target);
        
        if (invite) {
            copyRef2.current.classList.add('active');
            setTimeout(() => {
                copyRef2.current.classList.remove('active');
            }, 3000);
        } else {
            copyRef.current.classList.add('active');
            setTimeout(() => {
                copyRef.current.classList.remove('active');
            }, 3000);
        }
    }

    const handleEmail = (e, inviteLink) => {
        let target = inviteLink ? inviteLink : url;
        let subject = 'You are invited to a myMedChat Session'
        let body = `${user.displayName} has invited to you to a chat session on myMedChat. Click link to join now: ${target}`;
        const eSubject = subject.replaceAll(' ', '%20');
        const eBody = body.replaceAll(' ', '%20');
        window.open(`mailto:?subject=${eSubject}&&body=${eBody}`);
    }

    const handleSMS = (e, inviteLink) => {
        let target = inviteLink ? inviteLink : url;
        let body = `${user.displayName} has invited to you to a chat session on myMedChat. Click link to join now: ${target}`;
        if (navigator.userAgent.match(/Android/i)) {
            window.open(`sms:///?body=${body}`, '_blank');
        }
        if (navigator.userAgent.match(/iPhone/i)) {
            window.open(`sms:///&body=${body}`,'_blank');
        }
    }

    const handleInvite = () => {
        if (invite) {
            setInvite(false); 
            resetInviteForm();
        } else {
            setInvite(true);
        }
    }

    const resetInviteForm = () => {
        setInvite(false);
        setName('');
        setLang('en');
        setRole('guest');
        setInviteDone(false);
        setInviteURL(url);
    }

    const submitInviteForm = (e) => {
        e.preventDefault();

        let newURL = `${url}?name=${name}&lang=${lang}&role=${role}`;
        newURL = newURL.replace('??', '?');
        setInviteURL(newURL);
        setInviteDone(true);
    }

    return (
        <>
            <div className="flex justify-between mt-4">
                <div ref={copyRef} onClick={handleCopy}>
                    <ClickableIcon 
                        type="copy" 
                        addClass="no-hover no-background small"
                        tipText="Copied link to clipboard"
                        tipDirection="top"
                    />
                </div>
                <div onClick={handleEmail}>
                    <ClickableIcon type="email" addClass="no-hover no-background small"/>    
                </div>
                <div onClick={handleSMS}>
                    <ClickableIcon type="sms" addClass="no-hover no-background small" />
                </div>
                <div onClick={handleInvite}>
                    <ClickableIcon type="group_add" addClass="no-hover no-background small" />
                </div>
            </div>
            {invite && <div className="screen">
                <div className="center-modal invite-modal">
                    <div className="center-modal-close-btn" onClick={handleInvite}>
                        <ClickableIcon type="close" addClass="invert" />
                    </div>
                    {!guestInvite && <>
                        <h5 className="text-left">Invite Friends</h5>
                        <div className={`invite-friendsList-wrapper hide-scroll ${scrollClass}`} onScroll={handleScroll} onLoad={onLoad}>
                            <FriendsList returnFxn={() => {}} removable={false} chat={true} />
                        </div>
                    </>}
                    {guestInvite && <>
                        <div className="text-left">
                            <img src={LightingImg} className="w-4 h-4 inline-block" />
                            <h5 className="inline-block ml-1">Guest Invite</h5>
                        </div>
                        {!inviteDone && <form className="guest-form flex flex-col gap-2 p-2" onSubmit={submitInviteForm}>
                            <p className="text-left">Input guest information to a generate unique URL and QR code for instant acccess.</p>
                            <label className="flex mt-2">
                                <span className="self-center">name:</span>
                                <input 
                                    className="flex-auto grow ml-2"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </label>
                            <label className="flex">
                                <span className="self-center">language:</span>
                                <select
                                    className="flex-auto grow ml-2"
                                    onChange={(e) => {
                                        setLang(e.target.value);
                                    }}
                                    value={lang}
                                >
                                    {langOptions}
                                </select> 
                            </label>
                            <label className="flex">
                                <span className="self-center">role:</span>
                                <select
                                    className="flex-auto grow ml-2"
                                    onChange={(e) => {
                                        setRole(e.target.value);
                                    }}
                                    value={role}
                                >
                                    <option value="other">Guest</option>
                                    <option value="interpreter">Guest Interpreter</option>
                                </select> 
                            </label>
                            <button className="btn mt-4 mx-auto">generate</button>
                        </form>}
                        {inviteDone && <>
                            <p className="text-left mx-2 mt-2">Show this QR code or share a link to your guest for instant access.</p>
                            <div className="w-full flex justify-center mt-4">
                                <Canvas
                                    text={inviteURL}
                                    options={{
                                        color: {
                                            dark: '#0f172a'
                                        }
                                    }}
                                />
                            </div>
                            <div className="flex justify-between mt-4">
                                <div ref={copyRef2} onClick={(e) => handleCopy(e, inviteURL)}>
                                    <ClickableIcon 
                                        type="copy" 
                                        addClass="no-hover no-background small"
                                        tipText="Copied link to clipboard"
                                        tipDirection="top"
                                    />
                                </div>
                                <div onClick={(e) => handleEmail(e, inviteURL)}>
                                    <ClickableIcon type="email" addClass="no-hover no-background small"/>    
                                </div>
                                <div onClick={(e) => handleSMS(e, inviteURL)}>
                                    <ClickableIcon type="sms" addClass="no-hover no-background small" />
                                </div>
                            </div>
                        </>}
                    </>}
                </div>
                <div className="flex justify-center gap-4 mt-4">
                    {guests && <>
                        {guestInvite && <button className="btn" onClick={() => setGuestInvite(false)}>friends</button>}
                        {!guestInvite && <button className="btn btn-white">friends</button>}
                        {!guestInvite && <button className="btn" onClick={() => setGuestInvite
                        (true)}>guest</button>}
                        {guestInvite && <button className="btn btn-white">guest</button>}
                    </>}
                </div>
            </div>}
        </>
    )
}