import { createContext, useReducer, useEffect, useRef } from "react";
import { auth, analytics, functions, fs } from "../firebase/config";
import { onAuthStateChanged, signOut, getIdTokenResult } from "firebase/auth";
import { useFirestore } from "../hooks/firebase/useFirestore";
import { setUserProperties } from "firebase/analytics";
import { httpsCallable } from "firebase/functions";
import { onSnapshot, doc as fsDoc } from "firebase/firestore";
import { updateUILang } from "../tools/tools";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    let unsub;
    switch (action.type) {
        case 'LOGGINGIN':
            return { ...state, screen: true };
        case 'LOGIN':
            return { ...state, user: 
                { ...state.user, ...action.payload }, screen: false };
        case 'LOGIN_GUEST':
            return { ...state, user: action.payload, screen: false };
        case 'UPDATE':
            console.log('update', action.payload.init);
            return { ...state, user: { ...state.user, ...action.payload }, authIsReady: true, screen: false, tutorialDone: action.payload.tutorialDone || false, welcomeViewed: !action.payload.init || false};
        case 'UPDATE_OTHER':
            return {...state, ...action.payload };
        case 'LOGGINGOUT':
            if (unsub) state.user.unsub();
            return { ...state, screen: true };
        case 'LOGGINGOUT_WARNING':
            return { ...state, loggingOut: true };
        case 'RESET_LOGGINGOUT_WARNING':
            return { ...state, loggingOut: false };
        case 'LOGOUT':
            return { ...state, user: null, screen: false };
        case 'AUTH_IS_READY':
            return { user: action.payload, authIsReady: true, screen: false };
        case 'SET_LISTENER':
            unsub = onSnapshot(fsDoc(fs, 'users', action.payload.uid), async snapshot => {
                if (!snapshot.exists()) return;
                const doc = snapshot.data();
                if (!doc) return;

                setUserProperties(analytics, {
                    role: doc.role,
                    gender: doc.gender,
                    lang: doc.lang,
                    tier: action.payload.tier
                });

                doc.translateUI ? updateUILang(doc.lang) : updateUILang('en');
                const userState = {
                    ...state.user,
                    friends: doc.friends || [],
                    friendRequests: doc.friendRequests || {},
                    username: doc.username,
                    lang: doc.lang, 
                    role: doc.role, 
                    gender: doc.gender,
                    license: doc.license,
                    organization: doc.organization,
                    activeChats: doc.activeChats,
                    init: doc.init,
                    tutorialDone: doc.tutorialDone || false,
                    tier: action.payload.tier, 
                    trialDaysLeft: action.payload.trialDaysLeft,
                    invites: action.payload.invites || [],
                    admin: action.payload.admin || false,
                    welcomeViewed: !doc.init,
                    unsub
                }

                authReducer(state, { type: 'UPDATE', payload: userState });
            }, (err) => {
                console.log('listener', err);
            });
        default:
            return state;
    }
}

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        screen: true,
        loggingOut: false,
        welcomeViewed: false,
        tutorialDone: false,
        unsub: null
    });
    const { getDocument, listenDocument } = useFirestore('users');
    const getUserSubscriptionStatusFxn = httpsCallable(functions, 'getUserSubscriptionStatus');
    const memoGetDocument = useRef(getDocument);
    const memoListenDocument = useRef(listenDocument);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async user => {
            if (user && !user.isAnonymous) {
                const unsub = onSnapshot(fsDoc(fs, 'users', user.uid), async snapshot => {
                    if (!snapshot.exists()) return;
                    if (!user) return;
                    const doc = snapshot.data();
                    if (doc) {
                        // don't update context if doc is offline
                        if (!doc.online) return;

                        // check for other logins, signoff other sites
                        if (doc.refreshToken !== user.stsTokenManager.refreshToken) {
                            await signOut(auth);
                            return;
                        }

                        // get subscription status
                        const subStatus = await getUserSubscriptionStatusFxn({ uid: user.uid });

                        dispatch({ 
                            type: 'UPDATE', 
                            payload: {
                                ...user,
                                friends: doc.friends || [],
                                friendRequests: doc.friendRequests || {},
                                online: doc.online,
                                username: doc.username,
                                lang: doc.lang, 
                                role: doc.role, 
                                gender: doc.gender,
                                license: doc.license,
                                organization: doc.organization,
                                activeChats: doc.activeChats,
                                init: doc.init,
                                tutorialDone: doc.tutorialDone || false,
                                translateUI: doc.translateUI,
                                tier: subStatus.data.tier, 
                                trialDaysLeft: subStatus.data.trialDaysLeft,
                                invites: doc.invites || [],
                                admin: doc.admin || false,
                                welcomeViewed: !doc.init,
                                unsub
                            },
                            source: 'authContext'
                        });

                        setUserProperties(analytics, { 
                            role: doc.role, 
                            gender: doc.gender, 
                            lang: doc.lang, 
                            tier: subStatus.data.tier
                        });

                        // set language 
                        doc.translateUI ? updateUILang(doc.lang) : updateUILang('en');
                    }
                }, (err) => {
                    console.log(err);
                    dispatch({ type: 'AUTH_IS_READY', payload: null });
                });
            } else if (user && user.isAnonymous) {
                // unable to retrieve custom claims, info temp stored in email
                // const tokens = await getIdTokenResult(user);
                let payload = {};
                if (state.user) payload = { ...state.user };
                payload = { ...payload, ...user };
                let lang = payload.user?.lang || 'en';
                let role = payload.user?.role || 'guest';
                if (user.email) {
                    lang = user.email.split('@')[0].split('_')[0];
                    role = user.email.split('@')[0].split('_')[1];
                }

                dispatch({ type: 'AUTH_IS_READY', payload });
            } else {
                dispatch({ type: 'AUTH_IS_READY', payload: null });
            }
        })

        return () => unsubscribe();

    }, []);

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>
    )
}