import { useReducer, useEffect, useState } from "react"
import { fs } from "../../firebase/config";
import { collection, doc, serverTimestamp, addDoc, onSnapshot } from 'firebase/firestore'

let initialState = {
    document: null,
    pending: false,
    error: null,
    success: null,
};

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return { pending: true, document: null, success: false, error: null };
        case 'ADDED_DOCUMENT':
            return { pending: false, document: action.payload, success: true, error: null };
        case 'DELETED_DOCUMENT':
            return { pending: false, document: null, success: true, error: null };
        case 'CHECK_DOCUMENT_EXIST':
            return { pending: false, document: action.payload, success: true, error: null };
        case 'GET_COLLECTION':
            return { pending: false, document: action.payload, success: true, error: null };
        case 'ERROR':
            return { pending: false, document: null, success: false, error: action.payload };
        case "UPDATED_DOCUMENT":
            return { pending: false, document: action.payload, success: true,  error: null };
        case "LISTENING_DOCUMENT":
            return { pending: false, document: action.payload.document, listener: action.payload.listener, success: true, error: null };
        default:
            return state;
  }
}

export const useSubFirestore = () => {
    const [response, dispatch] = useReducer(firestoreReducer, initialState);
    const [isCancelled, setIsCancelled] = useState(false);
    let unsub = null;
    
    // only dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCancelled) {
            dispatch(action)
        }
    }

    // add document to a subcollection
    const addSubCollectionDocument = async (baseCollectionID, baseDocID, subCollectionID, subDocID, data) => {
        return new Promise(async (resolve, reject) => {
            dispatch({ type: 'IS_PENDING' });
            data = { ...data, timestamp: serverTimestamp() };
            
            const docRef = doc(fs, baseCollectionID, baseDocID);
            const colRef = subDocID ? collection(docRef, subCollectionID, subDocID) : collection(docRef, subCollectionID);
            
            try {
                const addedDocument = await addDoc(colRef, data);
                dispatchIfNotCancelled({ type: 'ADDED_DOCUMENT', payload: addedDocument });
                
                resolve(addedDocument);
                return;
            } catch (err) {
                console.log(err.message);
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
                reject(new Error('failed to add document'));
                return;
            }
        });
    }

    const listenSubCollectionDocument = async (baseCollectionID, baseDocID, subCollectionID, subDocID, method) => {
        dispatch({ type: 'IS_PENDING' });

        return new Promise(async (resolve, reject) => {            
            const docRef = doc(fs, baseCollectionID, baseDocID);
            const colRef = collection(docRef, subCollectionID);
            const subRef = doc(colRef, subDocID);

            unsub = onSnapshot(subRef, (snapshot) => {
                if (snapshot.data()) {
                    dispatchIfNotCancelled({ 
                        type: 'LISTENING_DOCUMENT',
                        payload: {
                            document: snapshot.data(),
                            listener: unsub
                        }
                    });
                    resolve(snapshot.data());
                    return;
                } else {
                    dispatchIfNotCancelled({ type: 'ERROR', payload: 'could not find document' });
                    resolve('Document not found');
                    return;
                }
            }, (err => {
                dispatchIfNotCancelled({ type: 'ERROR', payload: err.messsage });
                reject('Error establishing listener to document');
                return;
            }));
        });
    }

    useEffect(() => {
        setIsCancelled(false);
        return () => {
            if (unsub) {
                unsub();
            }
            setIsCancelled(true);
        }
    }, [unsub]);

    return { addSubCollectionDocument, listenSubCollectionDocument, response };

}

