import { useEffect, useState, useRef } from 'react';
import { useFirestore } from '../../../hooks/firebase/useFirestore';
import { useAuthContext } from '../../../hooks/useAuthContext';

import './BucketList.css';

import ClickableIcon from '../../../components/ClickableIcon';
import LoadingIcon from '../../../components/LoadingIcon';
import SnowmanImg from '../../../assets/snowman.png';

export default function BucketList({ data, returnFxn }) {
    const { user } = useAuthContext();
    const { updateDocument, response } = useFirestore('users');
    const [animate, setAnimate] = useState(false);
    const [buckets, setBuckets] = useState(null);   
    const [isEmpty, setIsEmpty] = useState(false);
    const [holder, setHolder] = useState(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollClass, setScrollClass] = useState('');
    const [processingID, setProcessingID] = useState(null);
    const [doubleTap, setDoubleTap] = useState(null);
    // const memoData = useRef(data).current;
    let scrolling = false;

    // scroll handlers
    const handleScroll = () => {
        if (scrolling) return;
        scrolling = true;
        setTimeout(() => {
            setScrollTop(holder.scrollTop);
            scrolling = false;
        }, 300);
    }
    
    useEffect(() => {
        if (!holder) return;
        let height = holder.offsetHeight || 0;
        let totalHeight = holder.scrollHeight || 0;
        let classes = '';
        if ((height + scrollTop) < totalHeight - 1) {
            classes = ' scroll-shadow-bottom';
        } 
        if (scrollTop > 0) classes += ' scroll-shadow-top';
        setScrollClass(classes);
    }, [holder, scrollTop]);

    useEffect(() => {
        if (!data) return setIsEmpty(true);
        if (!data.buckets) return setIsEmpty(true);
        setIsEmpty(false);
        let entries = [];
        data.bucketSequence.forEach(item => {
            if (!data.buckets[item]) return;
            entries.push(data.buckets[item]);
        });

        setBuckets(entries);
        if (entries.length === 0) setIsEmpty(true);
    }, [data])

    const toggleAnimation = () => {
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500);
    }

    const onLoad = (e) => {
        setHolder(e.target.closest('.bucket-list-wrapper'));
        document.addEventListener('click', (e) => {
            if (e.target.closest('.bucket-list-entry')) return;
            document.querySelectorAll('.bucket-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
            setDoubleTap(null);
        })
    }

    const handleEditEntry = async (e, id) => {
        if (e.target.closest('.delete-entry')) {
            setProcessingID(id);
            let save = { buckets: data.buckets || {}, bucketSequence: data.bucketSequence };
            delete save.buckets[id];
            save.bucketSequence.splice(save.bucketSequence.indexOf(id), 1);

            await updateDocument(user.uid, save);
        } else if (e.target.closest('.up-arrow') || e.target.closest('.down-arrow')) {
            return;
        } else {
            if (doubleTap === id) {
                returnFxn('edit', id);
            } else {
                document.querySelectorAll('.bucket-list-entry.highlight').forEach(el => el.classList.remove('highlight'));
                e.target.closest('.bucket-list-entry').classList.add('highlight');
                setDoubleTap(id);
            }
        }
    }

    const changeSequence = async (method, id, index) => {
        if (response.isPending) return;
        if (method === 'up') {
            if (index - 1 < 0) return;
            const target = data.bucketSequence[index];
            data.bucketSequence.splice(index, 1);
            data.bucketSequence.splice(index - 1, 0, target);
        } else if (method === 'down') {
            const target = data.bucketSequence[index];
            if (index + 1 === data.bucketSequence.length) return;
            data.bucketSequence.splice(index, 1);
            data.bucketSequence.splice(index + 1, 0, target);
        }

        await updateDocument(user.uid, { bucketSequence: data.bucketSequence });
    }
    
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    const handleSort = async () => {
        let items = [...buckets];
        const draggedItemContent = items[dragItem.current];
        items.splice(dragItem.current, 1);
        items.splice(dragOverItem.current, 0, draggedItemContent);
        setBuckets(items);

        let seq = [];
        items.forEach(item => seq.push(item.id));
        
        await updateDocument(user.uid, {bucketSequence: seq});
    }

    return (
        <>
            {isEmpty && (
                <div className="bucket-list-wrapper flex items-center" onLoad={(e) => setHolder(e.target.closest('.bucket-list-wrapper'))}>
                    <div className="m-auto flex flex-col items-center">
                        <div className="my-auto">
                            {!animate && <h1 className="just-showing" onClick={toggleAnimation} unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            {animate && <h1 onClick={toggleAnimation} className="just-showing animate-shake" unselectable="on"><img className="h-20 w-20 mx-auto" src={SnowmanImg} alt="Snowman" /></h1>}
                            <i className="p-emphasize mt-2">No items</i>
                        </div>
                    </div>
                </div>
            )}
            {buckets && buckets.length > 0 && (
                <div className={`bucket-list-wrapper hide-scroll ${scrollClass} grid grid-cols-1 gap-4 auto-rows-max md:pt-16 md:pb-16`} onScroll={handleScroll} onLoad={onLoad}>
                    {buckets && buckets.map((bu, index) => (
                        <div 
                            key={bu.id} 
                            className="bucket-list-entry" 
                            onClick={(e) => handleEditEntry(e, bu.id)} 
                            data-index={index}
                            onDragStart={(e)=> dragItem.current = index} 
                            onDragEnter={(e) => dragOverItem.current = index} 
                            onDragEnd={handleSort} 
                            onDragOver={(e) => e.preventDefault()}
                            draggable
                        >
                            <div className="grow flex flex-col  h-full">
                                <div className="entry-header flex justify-between">
                                    <p className="p-title self-center">{bu.title}</p>
                                    <div className="delete-entry">
                                        {!response.isPending && <ClickableIcon type="trash" addClass="mini no-background self-center" />}
                                        {response.isPending && (processingID === bu.id) && <LoadingIcon size="small" />}
                                    </div>
                                </div>
                            </div>
                            <div className="up-arrow" onClick={e => changeSequence('up', bu.id, index)}>
                                <ClickableIcon type="arrow_up" addClass="alt-color no-hover" />
                            </div>
                            <div className="down-arrow" onClick={e => changeSequence('down', bu.id, index)}>
                                <ClickableIcon type="arrow_down" 
                                addClass="alt-color no-hover" />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    )
}