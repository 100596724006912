

export const homeContent = {
    en: {
        title01: 'Instant translation chat and tools to connect us all',
        title02: 'email',
        title03: 'password',
        title04: 'forgot password?',
        title05: 'login',
        title06: 'sign up',
        title07: 'name',
        title08: 'I agree to the Terms & Conditions',
        title09: 'I agree to the Privacy Policy',
        section01: 'Instant chat with multiple people in multiple languages on one account.',
        section02: 'Inspired by healthcare providers and patients but made for everyone.',
        section03: 'Simple tools to break down language barriers. Designed to be as simple and natural as possible.',
        section04: 'Powered by Goolge Translate.',
        section05: 'Save your own commonly used phrases and collect them into different topics and scenarios for quick access during conversations.',
        section06: 'Give translated instructions on your device with text translation and text-to-speech.',
        section07: 'Or have a two-way auto-translated conversation with someone on a single device.',
        section08: 'Invite a 3rd party interpreter to monitor and flag mistranslated messages.',
        section09: 'All messages and identifying user information are deleted in 24hrs after each chat session.',
        section10: 'Give PPeel a try with a 1 week free trial. No credit card needed.',
        footer01: 'Privacy Policy',
        footer02: 'Terms and Conditions',
        footer03: 'Contact'
    },
    heads: [
        '👩','👨','🧑','👧','👦','🧒','👶','👵','👴','🧓','👩‍🦰','👨‍🦰','👩‍🦱','👨‍🦱','👩‍🦲','👨‍🦲','👩‍🦳','👨‍🦳','👱‍♀️','👱‍♂️','👸','🤴','👳‍♀️','👳‍♂️','👲','🧔','👮‍♀️','👮‍♂️','🕵️‍♀️','🕵️‍♂️','💂‍♀️','💂‍♂️','👷‍♀️','👷‍♂️','👩‍⚕️','👨‍⚕️','👩‍🎓','👨‍🎓','👩‍🏫','👨‍🏫','👩‍⚖️','👨‍⚖️','👩‍🌾','👨‍🌾','👩‍🍳','👨‍🍳','👩‍🔧','👨‍🔧','👩‍🏭','👨‍🏭','👩‍💼','👨‍💼','👩‍🔬','👨‍🔬','👩‍🎤','👨‍🎤','👩‍🎨','👨‍🎨','👩‍✈️','👨‍✈️','👩‍🚀','👨‍🚀','👩‍🚒','👨‍🚒','🧕','👰','🤵','🤱','🦸‍♀️','🦸‍♂️','🦹‍♀️','🦹‍♂️','🧙‍♀️','🧙‍♂️','🧚‍♀️','🧚‍♂️','🧛‍♀️','🧛‍♂️','🧝‍♀️','🧝‍♂️'
    ]
}

export const settingsContent = {
    en: {
        title01: 'Settings',
        entry01: 'name',
        entry02: 'email',
        entry03: 'role',
        entry04: 'language',
        entry05: 'gender',
        entry06: 'receive newsletters',
        entry07: 'default language',
        entry08: 'organization',
        entry09: 'license',
        button01: 'change photo',
        button02: 'save',
        button03: 'subscriptions',
        delete01: 'Delete Account',
        delete02: 'Sorry to see you go. We are trying our best to build and improve PeelChat for everyone. We hope you will come back soon and try us out again!',
        delete03: 'delete'
    }
}

export const subscriptionsContent = {
    en: {
        title01: 'Subscriptions',
        section01: 'Free Trial',
        section02: 'days left',
        section03: 'One week free trial. We hope you like us!',
        section04: 'Free',
        section05: 'month',
        section06: 'Join chats with your personalized chat tools!',
        section07: 'Basic',
        section08: "Host chats and invite others to join even if they don't have an account.",
        section09: 'select',
        section10: 'Pro',
        section11: 'For professionals that require certified interpretations.',
        section12: 'coming soon',
        section13: 'Powered by',
        notice01: 'Level Up!',
        notice02: 'You now have the power to host chats! Lucky!'
    }
}

export const limitsContent = {
    en: {
        title01: 'Restrict Responses',
        title02: 'Restricted responses help capture relevant and accurate information by limiting how others can respond to your messaage.',
        create01: 'Create a Response',
        edit01: 'Edit a Response'
    }
}