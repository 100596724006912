import { useState } from 'react';

import './Phrases.css';

import PhraseForm from './PhraseForm';
import PhraseList from './PhraseList';
import ClickableIcon from '../../../components/ClickableIcon';

import LightingImg from '../../../assets/lighting.png';

export default function Phrases({ data }) {
    const [newPhrase, setNewPhrase] = useState(false);
    const [editPhrase, setEditPhrase] = useState(false);
    const [payload, setPayload] = useState(null);

    const handlePageClick = (page, id) => {
        if (page === 'new') {
            setNewPhrase(true);
            setEditPhrase(false);
        }
        if (page === 'edit') {
            setNewPhrase(false);
            setEditPhrase(true);

            setPayload(id);
        }
        if (page === 'list') {
            setNewPhrase(false);
            setEditPhrase(false);
        }
    }

    const handleScreenClear = (e) => {
        if (e.target && e.target.closest('.center-modal')) {
            if (!e.target.closest('.cancel-form-btn')) return;
        }
        setNewPhrase(false);
        setEditPhrase(false);
    }

    return (
        <>
            {data && <div className="tree-container flex flex-col overflow-hidden">
                <h5>Design Phrases</h5>
                <div className="flex gap-1">
                    <p className="mt-1 relative">For quicker access & faster responses during chat</p>
                    <img className="self-center h-4 w-4" src={LightingImg} alt="lightningbolt" />
                    <img className="self-center h-4 w-4 mr-2" src={LightingImg} alt="lightningbolt" />
                </div>
                <div className="mt-4 grow flex items-center mx-auto">
                    <PhraseList data={data} returnFxn={handlePageClick} />
                </div>
                <div className="btn-circle mx-auto" onClick={() => setNewPhrase(true)}>
                    <ClickableIcon type="add" addClass="alt-color small no-hover" />
                </div>
                {newPhrase && <div className="screen" onClick={handleScreenClear}>
                    <div className="center-modal phrase-modal">
                        <PhraseForm data={data} returnFxn={handleScreenClear} method="new" />
                    </div>
                </div>}
                {editPhrase && <div className="screen" onClick={handleScreenClear}>
                    <div className="center-modal phrase-modal">
                        <PhraseForm data={data} returnFxn={handleScreenClear} method="edit" id={payload} />
                    </div>
                </div>}
            </div>}
            {!data &&
            <div className="m-auto self-center flex flex-col items-center"></div>}
        </>
    )
}