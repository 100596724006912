import { useAuthContext } from "./useAuthContext"
import { serverTimestamp } from "firebase/firestore"
import { useState, useEffect } from 'react'
import { functions, analytics } from "../firebase/config"
import { httpsCallable } from 'firebase/functions'
import { logEvent } from "firebase/analytics"

export const useJoinChat = (source) => {
    const handleChatFxn = httpsCallable(functions, 'handleChatEntryExit');
    const [cancel, setCancel] = useState(false);
    const [error, setError] = useState(null);
    const [pending, setPending] = useState(false);
    const { user } = useAuthContext();
    
    const reset = (err) => {
        if (cancel) return;
        setError((err && err.message) || null);
        setPending(false);
    }

    const joinChat = async (session, guest) => {
        return new Promise(async (resolve, reject) => {
            setPending(true);

            if (!user) {
                console.log('User not authenticated')
                setError('User not authenticated');
                setPending(false);
                reject('user not authenticated');
            } else {
                let activeChats = user.activeChats ? [...user.activeChats] : [];
                if (activeChats.indexOf(session) === -1) activeChats.push(session);
                if (user.invites) {
                    Object.values(user.invites).forEach(item => {
                        if (item.session === session) delete user.invites[item.timestamp];
                    });
                }

                let userRole = user.role;
                if (guest || !user.role) userRole = 'guest';
                
                let save = {
                    method: 'join',
                    session,
                    user: {
                        uid: user.uid,
                        payload: {
                            active: true,
                            username: user.username || false,
                            displayName: user.displayName || 'Unknown',
                            gender: user.gender || 'NEUTRAL',
                            lang: user.lang || 'en',
                            license: user.license || '',
                            organization: user.organization || '',
                            photoURL: user.photoURL || '',
                            tempID: user.uid,
                            timestamp: serverTimestamp(),
                            role: userRole,
                            ban: false,
                            owner: false,
                            user: guest ? 'guest' : user.uid
                        },
                        invites: user.invites,
                        guest,
                        activeChats,
                    }
                }
                try {
                    const res = await handleChatFxn(save);
                    
                    logEvent(analytics, 'join_chat', {
                        content_type: 'event',
                        chat_id: session
                    });
                    
                    reset();
                    resolve(res);
                    return;
                } catch(err) {
                    console.log('failed', err.message);
                    reset(err);
                    reject(err.message);
                    return;
                }        
            }
        });
    }

    useEffect(() => {
        setCancel(false);
        return () => setCancel(true);
    }, []);

    return { pending, error, joinChat };
}