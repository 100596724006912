
import './ProfilePic.css'

import AvatarIcon from '../assets/avatar_icon.svg';

export default function ProfilePic({ size, badge, text, badgeSize, url, owner, background, bgNeutral }) {

    let photo = url;
    let classes = `profile-pic object-cover has-file`;
    if (!url) {
        photo = AvatarIcon;
        classes = `profile-pic object-cover`;
    }
    
    if (badge && text) {
        return (
            <div className={`relative flex flex-col items-center profile-pic-holder ${size ? size : ''} ${background ? 'background' : ''} ${owner ? 'owner' : ''} ${bgNeutral ? 'bgNeutral' : ''}`}>
                <img className={classes} src={photo} alt="user avatar" />
                <p className={`badge ${badgeSize}`}>{text[0].toUpperCase() + text.substring(1)}</p>
            </div>
        )
    } else {
        return (

            <div className={`relative profile-pic-holder ${size ? size : ''} ${background ? 'background' : ''} ${owner ? 'owner' : ''}`}>
                <img className={classes} src={photo} alt="user avatar" />
            </div>
        )
    }
}